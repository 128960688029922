import { useMutation, type PureQueryOptions } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { SOFormFields } from '~/components/upload/supporting-object/so-form-fields';
import type { SOParent } from '~/components/upload/supporting-object/so-manager';
import { useZodForm } from '~/utils/forms';
import {
  initialWellLog,
  wellLogSchema,
} from '~/utils/modules/supporting-object';

const CREATE_WELL_LOG = graphql(`
  mutation CreateWellLog($input: WellLogCreateInput!) {
    wellLogCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

type Props = SOParent & {
  refetchQueries?: PureQueryOptions[];
};

export function WellLogCreateForm({
  outcropId,
  studyId,
  refetchQueries,
}: Props) {
  const [createWellLog, { loading, error }] = useMutation(CREATE_WELL_LOG, {
    refetchQueries,
  });

  const form = useZodForm({
    schema: wellLogSchema,
    values: initialWellLog(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await createWellLog({
        variables: {
          input: {
            ...values,
            outcropId,
            studyId,
          },
        },
      });
      toast.success('Well log created successfully.');
      form.reset();
    } catch (err) {
      console.log('Error creating well log', err);
      toast.error('There was a problem creating the well log.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <SOFormFields soType="WELL_LOG" studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="card-actions justify-end">
          <Button type="submit" loading={loading} color="primary">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
