import { useMutation, type PureQueryOptions } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { SOFormFields } from '~/components/upload/supporting-object/so-form-fields';
import type { SOParent } from '~/components/upload/supporting-object/so-manager';
import { useZodForm } from '~/utils/forms';
import {
  initialReservoirModel,
  reservoirModelSchema,
} from '~/utils/modules/supporting-object';

const CREATE_RESERVOIR_MODEL = graphql(`
  mutation CreateReservoirModel($input: ReservoirModelCreateInput!) {
    reservoirModelCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

type Props = SOParent & {
  refetchQueries?: PureQueryOptions[];
};

export function ReservoirModelCreateForm({
  outcropId,
  studyId,
  refetchQueries,
}: Props) {
  const [createReservoirModel, { loading, error }] = useMutation(
    CREATE_RESERVOIR_MODEL,
    { refetchQueries },
  );

  const form = useZodForm({
    schema: reservoirModelSchema,
    values: initialReservoirModel(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await createReservoirModel({
        variables: {
          input: {
            ...values,
            outcropId,
            studyId,
          },
        },
      });
      toast.success('Reservoir model created successfully.');
      form.reset();
    } catch (err) {
      console.log('Error creating reservoir model', err);
      toast.error('There was a problem creating the reservoir model.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <SOFormFields soType="RESERVOIR_MODEL" studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="card-actions justify-end">
          <Button type="submit" loading={loading} color="primary">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
