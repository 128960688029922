import { useQuery } from '@apollo/client';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SOManagerV4 } from '~/components/upload/supporting-object/so-manager';
import { useRouteParam } from '~/hooks/routing';
import { createRefetchQueries } from '~/utils/graphql';

export const UPLOAD_OUTCROP_RESERVOIR_MODELS_PAGE = graphql(`
  query UploadOutcropReservoirModelsPage($id: ID!) {
    outcropGet(id: $id) {
      id
      reservoirModels {
        ...soManagerReservoirModelParts
      }
    }
  }
`);

export default function UploadOutcropReservoirModelsPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

export function PageInner() {
  const outcropId = useRouteParam('outcropId', parseInt);

  const { data, loading } = useQuery(UPLOAD_OUTCROP_RESERVOIR_MODELS_PAGE, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropGet;

  const refetchQueries = createRefetchQueries([
    {
      query: UPLOAD_OUTCROP_RESERVOIR_MODELS_PAGE,
      variables: { id: outcropId },
    },
  ]);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <SOManagerV4
      soType="RESERVOIR_MODEL"
      items={outcrop.reservoirModels}
      parent={{ outcropId }}
      refetchQueries={refetchQueries}
    />
  );
}
