import type { ComponentPropsWithoutRef, ComponentPropsWithRef } from 'react';
import { forwardRef } from 'react';
import type { FormLabelProps } from '~/components/ui/forms/FormLabel';
import { InputBase } from '~/components/ui/forms/InputBase';
import { cn } from '~/utils/common';

export type TextAreaInputProps = {
  name: string;
  containerProps?: ComponentPropsWithoutRef<'div'>;
  label?: FormLabelProps['label'];
} & ComponentPropsWithRef<'textarea'>;

export const TextAreaInput = forwardRef<
  HTMLTextAreaElement,
  TextAreaInputProps
>(
  (
    { containerProps, label, className, ...inputProps }: TextAreaInputProps,
    ref,
  ) => {
    // Default the ID to the name in case an ID isn't set
    const elementId = inputProps.id ?? inputProps.name;

    return (
      <InputBase
        id={elementId}
        name={inputProps.name}
        label={label}
        required={inputProps.required}
      >
        <textarea
          {...inputProps}
          ref={ref}
          id={elementId}
          className={cn('textarea w-full', className)}
        />
      </InputBase>
    );
  },
);
TextAreaInput.displayName = 'TextAreaInput';
