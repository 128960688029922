import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { GeoreferenceDataType } from '~/apollo/generated/v4/graphql';
import { FormLabel } from '~/components/ui/forms/FormLabel';
import { Select } from '~/components/ui/forms/Select';
import { TextInput } from '~/components/ui/forms/text-input';
import type { GeoreferenceFormValues } from '~/utils/modules/georeference-v4';
import { readableGqlEnum } from '~/utils/text';

export function GeoreferenceFormFields({
  hasCenter,
  hasOutline,
}: {
  hasCenter: boolean;
  hasOutline: boolean;
}) {
  const { register, watch } = useFormContext<GeoreferenceFormValues>();

  const hasData = watch('data') !== null;

  return (
    <div className="space-y-2">
      <Select
        {...register('dataType')}
        label="Type"
        required
        options={Object.values(GeoreferenceDataType).map(value => ({
          value,
          label: readableGqlEnum(value),
          disabled:
            (value === GeoreferenceDataType.Centre && hasCenter) ||
            (value === GeoreferenceDataType.Outline && hasOutline),
        }))}
      />
      <TextInput {...register('name')} label="Name" required />
      <TextInput {...register('description')} label="Description" />

      <div>
        <FormLabel name="data" label="Coordinates" />
        <div className="input">
          {hasData ? (
            <>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="text-success" />
              Coordinates set
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="text-slate-300"
              />
              Use the map to select coordinates
            </>
          )}
        </div>
      </div>
    </div>
  );
}
