import { useQuery } from '@apollo/client';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SOManagerV4 } from '~/components/upload/supporting-object/so-manager';
import { useRouteParam } from '~/hooks/routing';
import { createRefetchQueries } from '~/utils/graphql';

export const UPLOAD_STUDY_PRODUCTION_PAGE = graphql(`
  query UploadStudyProductionPage($id: ID!) {
    studyGet(id: $id) {
      id
      production {
        ...soManagerProductionParts
      }
    }
  }
`);

export default function UploadStudyProductionPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

export function PageInner() {
  const studyId = useRouteParam('studyId', parseInt);

  const { data, loading } = useQuery(UPLOAD_STUDY_PRODUCTION_PAGE, {
    variables: { id: studyId },
  });

  const study = data?.studyGet;

  const refetchQueries = createRefetchQueries([
    {
      query: UPLOAD_STUDY_PRODUCTION_PAGE,
      variables: { id: studyId },
    },
  ]);

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <SOManagerV4
      soType="PRODUCTION"
      items={study.production}
      parent={{ studyId }}
      refetchQueries={refetchQueries}
    />
  );
}
