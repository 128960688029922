import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type { UploadOutcropPicturesPageQuery } from '~/apollo/generated/v4/graphql';
import {
  LifecycleStatus,
  PictureInitialParentType,
} from '~/apollo/generated/v4/graphql';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { OutcropThumbnailManager } from '~/components/upload/outcrop/OutcropThumbnailManager';
import { SOPictureManager } from '~/components/upload/supporting-object/so-picture-manager';
import { useShowUnpublished } from '~/components/upload/supporting-object/use-show-unpublished';
import { useRouteParam } from '~/hooks/routing';
import { createRefetchQueries } from '~/utils/graphql';

export const UPLOAD_OUTCROP_PICTURES_PAGE = graphql(`
  query UploadOutcropPicturesPage($id: ID!) {
    outcropGet(id: $id) {
      id
      thumbnail {
        ...fileParts
      }
      pictures {
        ...pictureParts
        file {
          ...fileParts
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }
`);

export default function UpdateOutcropPicturesRoute() {
  return (
    <ApolloProviderV4>
      <PageLoader />
    </ApolloProviderV4>
  );
}

export function PageLoader() {
  const outcropId = useRouteParam('outcropId', parseInt);

  const { data, loading } = useQuery(UPLOAD_OUTCROP_PICTURES_PAGE, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropGet;

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  const pictures = R.sortBy(pic => pic.priority ?? Infinity, outcrop.pictures);

  return (
    <PageInner
      outcropId={outcropId}
      thumbnail={outcrop.thumbnail}
      pictures={pictures}
    />
  );
}

type Outcrop = NonNullable<UploadOutcropPicturesPageQuery['outcropGet']>;
type Picture = Outcrop['pictures'][number];

function PageInner({
  outcropId,
  thumbnail,
  pictures: picturesProp,
}: {
  outcropId: number;
  thumbnail: Outcrop['thumbnail'];
  pictures: Picture[];
}) {
  const refetchQueries = createRefetchQueries([
    { query: UPLOAD_OUTCROP_PICTURES_PAGE, variables: { id: outcropId } },
  ]);

  const unpublishedCount = picturesProp.reduce(
    (acc, picture) =>
      acc + (picture.lifecycleStatus === LifecycleStatus.Published ? 0 : 1),
    0,
  );
  const { showUnpublished, PublishedToggle } =
    useShowUnpublished(unpublishedCount);

  const pictures = picturesProp.filter(picture => {
    return (
      showUnpublished || picture.lifecycleStatus === LifecycleStatus.Published
    );
  });

  return (
    <div className="space-y-4">
      <Panel>
        <Panel.Heading>
          <Panel.Title>Thumbnail</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <OutcropThumbnailManager
            outcropId={outcropId}
            thumbnail={thumbnail}
            refetchQueries={refetchQueries}
          />
        </Panel.Body>
      </Panel>

      <PublishedToggle />

      <Panel>
        <Panel.Heading>
          <Panel.Title>Outcrop Pictures</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <SOPictureManager
            parentType={PictureInitialParentType.Outcrop}
            parentId={outcropId}
            pictures={pictures}
            refetchQueries={refetchQueries}
          />
        </Panel.Body>
      </Panel>
    </div>
  );
}
