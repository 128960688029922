import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import type { GeologyType } from '~/apollo/generated/v4/graphql';
import { FormLabel } from '~/components/common/FormikField/FormLabel';
import { Button } from '~/components/ui/button';
import { ReactSelectInput } from '~/components/ui/forms/react-select-input';
import { TextAreaInput } from '~/components/ui/forms/text-area-input';
import { TextInput } from '~/components/ui/forms/text-input';
import { LifecycleStatusSelect } from '~/components/upload/lifecycle/lifecycle-status-select';
import { AEsManager } from '~/components/upload/supporting-object/mini-model/ae-manager';
import type { MiniModelFormValues } from '~/utils/modules/supporting-object';

const equipment = [
  'iPhone 12 Pro',
  'iPhone 13 Pro',
  'iPhone 14 Pro',
  'iPhone 15 Pro',
  'iPhone 16 Pro',
  'iPad Pro 2gen',
  'iPad Pro 3gen',
  'iPad Pro 4gen',
  'iPad Pro 5gen',
  'iPad Pro 6gen',
  'iPad Pro 7gen',
  'other',
];

const urlHelpText = (
  <div>
    <p>Sketchfab embed src:</p>
    <p className="text-xs text-muted">
      https://sketchfab.com/models/&lt;model-id&gt;/embed
    </p>
  </div>
);

export function MiniModelFormFields({
  geologyTypes,
}: {
  geologyTypes: GeologyType[];
}) {
  const { register, setValue, watch } = useFormContext<MiniModelFormValues>();

  function handleDetailsLoaded(details: ModelDetails) {
    setValue('name', details.name);
    setValue('url', details.embedUrl);
    setValue('sketchfabName', details.name);
    setValue('longitude', details.longitude?.toString() ?? '');
    setValue('latitude', details.latitude?.toString() ?? '');
  }

  return (
    <div className="space-y-2">
      <LoadFromSketchfabApi onLoadSuccess={handleDetailsLoaded} />

      <TextInput {...register('name')} label="Name" required />
      <TextInput {...register('description')} label="Description" required />
      <TextInput
        {...register('url')}
        label={{
          label: 'Embed URL',
          helpText: urlHelpText,
        }}
        placeholder="https://sketchfab.com/models/<model-id>/embed"
        required
      />

      <div className="grid lg:grid-cols-2 gap-6">
        <TextInput {...register('collectedBy')} label="Collected by" required />
        <TextInput
          type="number"
          {...register('yearCollected')}
          label="Year collected"
          placeholder={new Date().getFullYear().toString()}
          required
        />
      </div>

      <Controller
        name="equipment"
        render={({ field }) => (
          <ReactSelectInput
            {...field}
            label="Equipment"
            options={equipment.map(value => ({ value, label: value }))}
            required
          />
        )}
      />

      <TextInput
        {...register('scaniverseName')}
        label="Scaniverse name"
        required
      />
      <TextInput {...register('sketchfabName')} label="Sketchfab name" />
      <TextAreaInput {...register('comments')} label="Comments" />

      <div className="grid lg:grid-cols-2 gap-6">
        <TextInput type="text" {...register('longitude')} label="Longitude" />
        <TextInput type="text" {...register('latitude')} label="Latitude" />
      </div>

      <div className="bg-slate-50 p-4">
        <AEsManager
          name="architecturalElements"
          geologyTypes={geologyTypes}
          architecturalElements={watch('architecturalElements')}
        />
      </div>

      <TextInput
        type="number"
        step="1"
        {...register('priority')}
        label="Priority"
      />

      <Controller
        name="lifecycleStatus"
        render={({ field }) => (
          <LifecycleStatusSelect {...field} label="Status" />
        )}
      />
    </div>
  );
}

const sketchfabDetailsSchema = z
  .object({
    uid: z.string(),
    name: z.string(),
    description: z.string(),
    embedUrl: z.string().url(),
  })
  .transform(parsed => {
    // Try to read the latitude and longitude from the description
    const regex = /-?\d+(\.\d+)?,\s?-?\d+(\.\d+)?/;
    const matches = parsed.description.match(regex);
    const coordinates = matches?.[0]?.split(',').map(s => s.trim());
    const latRaw = coordinates?.at(0);
    const lngRaw = coordinates?.at(1);
    const latitude = typeof latRaw === 'string' ? parseFloat(latRaw) : null;
    const longitude = typeof lngRaw === 'string' ? parseFloat(lngRaw) : null;

    return { ...parsed, latitude, longitude };
  });

type ModelDetails = z.infer<typeof sketchfabDetailsSchema>;

async function loadDetails(uid: string) {
  const url = `https://api.sketchfab.com/v3/models/${uid}`;

  const result = await fetch(url)
    .then(res => res.json())
    .catch(() => {
      console.log('Error fetching from Sketchfab');
      return null;
    });

  try {
    return sketchfabDetailsSchema.parse(result);
  } catch (err) {
    console.log("Couldn't parse response body?");
    return null;
  }
}

function LoadFromSketchfabApi({
  onLoadSuccess,
}: {
  onLoadSuccess: (details: ModelDetails) => void;
}) {
  const [modelUrl, setModelUrl] = useState('');

  const [loading, setLoading] = useState(false);

  async function loadModel() {
    const parts = modelUrl.split('-');
    const modelUid = parts.at(parts.length - 1);
    if (!modelUid?.length) return;

    console.log('Loading model with URL:', modelUrl);

    setLoading(true);
    const details = await loadDetails(modelUid);
    console.log('Loaded details:', details);
    setLoading(false);

    if (details) {
      toast.success(`Model details successfully loaded for ${details.name}.`);
      onLoadSuccess(details);
      setModelUrl('');
    } else {
      toast.error("Model details couldn't be loaded automatically.");
    }
  }

  const handleKey: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.code === 'Enter') {
      event.preventDefault();
      // event.stopPropagation();
      loadModel();
    }
  };

  return (
    <div className="bg-slate-100 p-4 w-full">
      <FormLabel name="modelUrl" label="Fetch details from Sketchfab" />
      <div className="join w-full">
        <input
          id="modelUrl"
          type="text"
          value={modelUrl}
          onChange={event => setModelUrl(event.target.value)}
          disabled={loading}
          className="input input-bordered join-item w-full"
          placeholder="https://sketchfab.com/3d-models/<model-name>-<id>"
          onKeyDown={handleKey}
        />

        <Button
          type="button"
          onClick={loadModel}
          loading={loading}
          className="join-item"
          color="info"
        >
          Load
        </Button>
      </div>
    </div>
  );
}
