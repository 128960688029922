import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type {
  GeologyType,
  MiniModelPartsFragment,
} from '~/apollo/generated/v4/graphql';
import { MiniModelFormFields } from '~/components/upload/supporting-object/mini-model/mini-model-form-fields';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { useZodForm } from '~/utils/forms';
import {
  initialMiniModel,
  miniModelSchema,
} from '~/utils/modules/supporting-object';

export const UPDATE_MINI_MODEL = graphql(`
  mutation UpdateMiniModel($id: ID!, $input: MiniModelUpdateInput!) {
    miniModelUpdate(id: $id, input: $input) {
      result {
        ...miniModelParts
      }
    }
  }
`);

export function UpdateMiniModelForm({
  geologyTypes,
  miniModel,
  onUpdateSuccess,
}: {
  geologyTypes: GeologyType[];
  miniModel: MiniModelPartsFragment;
  onUpdateSuccess?: () => void;
}) {
  const [updateMiniModel, { loading, error }] = useMutation(UPDATE_MINI_MODEL);

  const form = useZodForm({
    schema: miniModelSchema,
    values: initialMiniModel(miniModel),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateMiniModel({
        variables: {
          id: miniModel.id,
          input: values,
        },
      });
      toast.success('Mini-model saved successfully.');
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error saving mini-model', err);
      toast.error('There was a problem saving the mini-model.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <MiniModelFormFields geologyTypes={geologyTypes} />
        <HookFormErrors graphQLError={error} />

        <div className="text-center">
          <Button color="primary" loading={loading} disabled={loading}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
