import { Link } from 'react-router';
import type { FilterSearchProps } from '~/components/common/FilterSearch';
import { FilterSearch } from '~/components/common/FilterSearch';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { GeologyTypeIconV4 } from '~/components/common/icons/geology-type-icon-v4';
import { LifecycleStatusIcon } from '~/components/common/icons/LifecycleStatusIcon';
import type { SortIndicatorProps } from '~/components/common/icons/SortIndicator';
import { NoItemsRow } from '~/components/common/NoItemsRow';
import { SortTrigger } from '~/components/common/SortTrigger';
import * as routes from '~/paths';
import type { SortableOutcropV4 } from '~/utils/modules/outcrop-v4';
import { readableGqlEnum } from '~/utils/text';

export function UploadOutcropList({
  outcrops,
  sortIndicatorProps: siProps,
  filterSearchProps,
}: {
  outcrops: SortableOutcropV4[];
  sortIndicatorProps: SortIndicatorProps;
  filterSearchProps: FilterSearchProps;
}) {
  return (
    <div className="space-y-4">
      <FilterSearch {...filterSearchProps} />

      <table className="table w-full table-pin-rows table-compact">
        <thead>
          <tr>
            <th className="text-center">
              <SortTrigger
                colName="lifecycleStatus"
                sortIndicatorProps={siProps}
                filterable
                renderFilterOption={readableGqlEnum}
              >
                <span>Status</span>
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="id" sortIndicatorProps={siProps}>
                ID
              </SortTrigger>
            </th>
            <th>
              <SortTrigger colName="name" sortIndicatorProps={siProps}>
                Name
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="geologyType"
                sortIndicatorProps={siProps}
                filterable
              >
                Geo. Type
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="region.name"
                sortIndicatorProps={siProps}
                filterable
              >
                Region
              </SortTrigger>
            </th>
            <th>
              <SortTrigger
                colName="region.country"
                sortIndicatorProps={siProps}
                filterable
              >
                Country
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="numStudies"
                sortIndicatorProps={siProps}
                filterable
              >
                Studies
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="hasPaleoMaps"
                sortIndicatorProps={siProps}
                filterable
              >
                Paleomaps
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="numVoms"
                sortIndicatorProps={siProps}
                filterable
              >
                VOMs
              </SortTrigger>
            </th>
            <th className="text-center">
              <SortTrigger
                colName="qcCompleted"
                sortIndicatorProps={siProps}
                filterable
              >
                QC
              </SortTrigger>
            </th>
          </tr>
        </thead>

        <tbody>
          <NoItemsRow colSpan={10} show={!outcrops.length} />
          {outcrops.map(outcrop => (
            <OutcropRow key={outcrop.id} outcrop={outcrop} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function OutcropRow({ outcrop }: { outcrop: SortableOutcropV4 }) {
  const outcropId = parseInt(outcrop.id);
  const regionId = parseInt(outcrop.region.id);

  return (
    <tr>
      <td className="text-center">
        <LifecycleStatusIcon status={outcrop.lifecycleStatus} />
      </td>
      <td>{outcrop.id}</td>
      <td>
        <Link to={routes.uploadOutcropUpdateRoute(outcropId)} className="link">
          {outcrop.name}
        </Link>
      </td>
      <td className="space-x-1">
        {outcrop.geologyType?.map(gt => (
          <div key={gt} className="inline-block w-5 h-5">
            <GeologyTypeIconV4 geologyType={gt} hideTooltip />
          </div>
        ))}
      </td>
      <td>
        <Link to={routes.uploadRegionUpdateRoute(regionId)} className="link">
          {outcrop.region.name}
        </Link>
      </td>
      <td>{outcrop.region.country}</td>
      <td className="text-center">
        <Link
          to={routes.uploadOutcropUpdateStudiesRoute(outcropId)}
          className="link"
        >
          {outcrop.numStudies}
        </Link>
      </td>

      <td className="text-center">
        <EnabledIndicator value={outcrop.hasPaleoMaps} />
      </td>

      <td className="text-center">
        <Link
          to={routes.uploadOutcropUpdateVirtualOutcropsRoute(outcropId)}
          className="link"
        >
          {outcrop.numVoms}
        </Link>
      </td>

      <td className="text-center">
        <EnabledIndicator value={outcrop.qcCompleted} />
      </td>
    </tr>
  );
}
