import type { PureQueryOptions } from '@apollo/client';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import {
  useEffect,
  useRef,
  useState,
  type CSSProperties,
  type ReactNode,
} from 'react';
import { Button } from '~/components/ui/button';
import {
  type FilePartsFragment,
  type PicturePartsFragment,
} from '~/apollo/generated/v3/graphql';
import type { TargetBookmarksManagerModalProps } from '~/components/bookmark/TargetBookmarksManagerModal';
import { TargetBookmarksManagerModal } from '~/components/bookmark/TargetBookmarksManagerModal';
import { Modal } from '~/components/common/Modal';
import { PublishedContainer } from '~/components/common/Published';
import { useRefetchQueries } from '~/hooks/apollo';
import { useModalState } from '~/hooks/modal';
import { cn, truncateText } from '~/utils/common';

type Picture = PicturePartsFragment & {
  file: FilePartsFragment;
};

type DescriptionRowProps = {
  label: ReactNode;
  value: string | null | undefined;
};
function DescriptionRow({ label, value }: DescriptionRowProps) {
  if (R.isNil(value)) return null;
  return (
    <tr>
      <th>{label}</th>
      <td>{value}</td>
    </tr>
  );
}

type Props = {
  picture: Picture;
  imgSrc: string;
  fade?: boolean;
  imgStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  bookmarkable?: TargetBookmarksManagerModalProps;
  showCaption?: boolean;
  refetchQueries: PureQueryOptions[];
};
export function PictureThumbnail({
  picture: p,
  imgSrc,
  fade = false,
  imgStyle,
  containerStyle,
  bookmarkable,
  showCaption = true,
  refetchQueries,
}: Props) {
  const { show, showModal, hideModal } = useModalState();

  const [shouldOpenInNewWindow, setShouldOpenInNewWindow] = useState(false);
  const [refetch] = useRefetchQueries(refetchQueries);
  const signedUrl = useRef(p.file.signedUrl);

  useEffect(() => {
    if (shouldOpenInNewWindow && signedUrl.current !== p.file.signedUrl) {
      setShouldOpenInNewWindow(false);
      window.open(p.file.signedUrl, '_blank');
    }

    signedUrl.current = p.file.signedUrl;
  }, [p.file.signedUrl, shouldOpenInNewWindow]);

  async function reloadAndOpenInNewWindow() {
    setShouldOpenInNewWindow(true);
    await refetch();
  }

  return (
    <>
      <div className="thumbnail" style={containerStyle}>
        <PublishedContainer published={p.published} withIcon>
          <a
            href="#enlarge-image"
            onClick={showModal}
            className={cn('thumbnail w-full aspect-square', {
              'fade-img': fade,
            })}
          >
            <div
              // For non-faded images, use a bg image to make a nice thumbnail
              className={cn(fade ? '' : 'w-full h-full bg-cover bg-center')}
              style={fade ? {} : { backgroundImage: `url(${imgSrc})` }}
            >
              {/* On faded images use an img tag for the ::after effect */}
              {fade && <img src={imgSrc} alt="" />}
            </div>
          </a>

          {p && p.description && showCaption && (
            <div className="caption text-center">
              {truncateText(p.description, 50)}
            </div>
          )}
        </PublishedContainer>
      </div>

      <Modal open={show} onHide={hideModal} size="lg">
        <Modal.Body heading={p.name ?? 'Picture'}>
          {bookmarkable && (
            <div className="text-right">
              <TargetBookmarksManagerModal {...bookmarkable} />
            </div>
          )}

          <div className="w-full space-y-4">
            <div className="text-center space-y-0.5">
              <img
                src={imgSrc}
                alt={p.name}
                className="mx-auto inline max-w-full"
              />
              <div className="text-right">
                <Button
                  type="button"
                  onClick={reloadAndOpenInNewWindow}
                  color="ghost"
                  size="sm"
                  endIcon={<FontAwesomeIcon icon={faExternalLink} />}
                  loading={shouldOpenInNewWindow}
                >
                  View in full resolution
                </Button>
              </div>
            </div>

            {p && (
              <div>
                <table className="table table-compact w-full">
                  <tbody>
                    <DescriptionRow label="Description" value={p.description} />
                    <DescriptionRow label="Author" value={p.author} />
                    <DescriptionRow label="Type" value={p.type} />
                    <DescriptionRow label="Scale" value={p.scale} />
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
