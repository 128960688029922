import type { NearestOutcropsQuery } from '~/apollo/generated/v4/graphql';
import { Heading } from '~/components/common/Heading';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { cn } from '~/utils/common';

type NearestOutcrop = NonNullable<
  NonNullable<NearestOutcropsQuery['outcropList']>['results']
>[number];

type Props = {
  selectedOutcropId: number | null;
  nearestOutcrops: NearestOutcrop[];
  onOutcropClick: (outcropId: number) => unknown;
  disabled?: boolean;
};

export function OutcropSuggestions({
  disabled,
  selectedOutcropId,
  nearestOutcrops,
  onOutcropClick,
}: Props) {
  function distanceText(distance: number) {
    if (distance < 1000) {
      return Math.round(distance) + ' meters away';
    }
    return Math.round(Math.round((distance / 1000) * 100) / 100) + ' km away';
  }

  return (
    <div>
      <Heading level={3}>Nearest Outcrops</Heading>
      <ul className="list-disc list-inside">
        {nearestOutcrops.map(outcrop => {
          const isSelected = parseInt(outcrop.id) === selectedOutcropId;
          return (
            <li key={outcrop.id}>
              <button
                type="button"
                className={cn('space-x-2', {
                  'btn-link': !disabled && !isSelected,
                  'text-muted': isSelected,
                })}
                onClick={() => onOutcropClick(parseInt(outcrop.id))}
                disabled={disabled || isSelected}
              >
                {outcrop.name}{' '}
                {outcrop.distanceFromPoint && (
                  <span className="text-muted text-sm font-mono">
                    ({distanceText(outcrop.distanceFromPoint)})
                  </span>
                )}
                {isSelected && <EnabledIndicator value={true} />}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
