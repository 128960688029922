import { cn } from '~/utils/common';
import type { HTMLProps } from 'react';

function FieldSetComponent({
  className,
  ...fieldSetProps
}: HTMLProps<HTMLFieldSetElement>) {
  return (
    <fieldset
      className={cn('fieldset border border-slate-200 p-4', className)}
      {...fieldSetProps}
    />
  );
}

function Legend({ className, ...legendProps }: HTMLProps<HTMLLegendElement>) {
  return (
    <legend
      className={cn('fieldset-legend px-2 text-lg', className)}
      {...legendProps}
    />
  );
}

FieldSetComponent.Legend = Legend;

export const FieldSet = FieldSetComponent;
