import { useMutation, type PureQueryOptions } from '@apollo/client';
import {
  faEyeSlash,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import {
  LifecycleStatus,
  type SoManagerPicturePartsFragment,
} from '~/apollo/generated/v4/graphql';
import { Confirm } from '~/components/common/Confirm';
import { PublishedContainer } from '~/components/common/Published';
import { Button } from '~/components/ui/button';
import { SODetailText } from '~/components/upload/supporting-object/so-detail-text';
import { SOUpdatePictureModal } from '~/components/upload/supporting-object/so-picture-update-modal';
import { TogglePublishedButton } from '~/components/upload/supporting-object/toggle-published-button';
import { uploadOutcropUpdateRoute } from '~/paths';
import { cn } from '~/utils/common';
import { truncate } from '~/utils/text';

export function SOPictureCard({
  studyId,
  picture,
  refetchQueries,
  condensed,
}: {
  studyId?: number;
  picture: SoManagerPicturePartsFragment;
  refetchQueries: PureQueryOptions[];
  condensed?: boolean;
}) {
  return (
    <PublishedContainer
      published={picture.lifecycleStatus === LifecycleStatus.Published}
      strokeWidth={condensed ? 'sm' : 'md'}
    >
      <div className="border border-slate-200 p-2">
        <div className="w-full flex gap-6">
          <div
            className="w-32 h-32 shrink-0 bg-cover bg-center border border-slate-200"
            style={{ backgroundImage: `url(${picture.file.signedUrl}` }}
          />

          <div className="grow">
            <div className="w-full flex justify-between items-start gap-2 text-left break-words">
              <div className={cn('font-bold', { 'text-sm': condensed })}>
                {picture.lifecycleStatus !== LifecycleStatus.Published && (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className="mr-1 text-info"
                  />
                )}
                {picture.name}
              </div>

              <div className="space-x-1 shrink-0">
                <TogglePublishedButton entity={picture} />

                <SOUpdatePictureModal
                  studyId={studyId}
                  picture={picture}
                  refetchQueries={refetchQueries}
                >
                  {showEditModal => (
                    <Button
                      type="button"
                      onClick={showEditModal}
                      color="ghost"
                      size="xs"
                      startIcon={<FontAwesomeIcon icon={faPencil} />}
                    >
                      Edit
                    </Button>
                  )}
                </SOUpdatePictureModal>

                <DeletePictureButton
                  pictureId={parseInt(picture.id)}
                  refetchQueries={refetchQueries}
                >
                  {(confirmDelete, loadingDelete) => (
                    <Button
                      type="button"
                      color="ghost"
                      size="xs"
                      onClick={confirmDelete}
                      disabled={loadingDelete}
                      startIcon={<FontAwesomeIcon icon={faTrash} />}
                    >
                      Delete
                    </Button>
                  )}
                </DeletePictureButton>
              </div>
            </div>

            <div
              className={cn({
                'space-y-0': condensed,
                'space-y-0.5': !condensed,
              })}
            >
              <SODetailText
                label="Description"
                value={truncate(140)(picture.description)}
              />
              <SODetailText label="Type" value={picture.type} />
              <SODetailText label="Author" value={picture.author} />
              <SODetailText label="Scale" value={picture.scale} />
              <SODetailText label="Comments" value={picture.comments} />
              <SODetailText
                label="Priority"
                value={String(picture.priority ?? '')}
              />
              {picture.outcropTag && (
                <SODetailText
                  label="Outcrop Tag"
                  value={
                    <Link
                      to={uploadOutcropUpdateRoute(
                        parseInt(picture.outcropTag.id),
                      )}
                      className="link"
                    >
                      {picture.outcropTag.name}
                    </Link>
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </PublishedContainer>
  );
}

const DELETE_PICTURE = graphql(`
  mutation DeletePicture($id: ID!) {
    pictureDelete(id: $id) {
      result {
        id
      }
    }
  }
`);

function DeletePictureButton({
  pictureId,
  refetchQueries,
  children,
}: {
  pictureId: number;
  refetchQueries: PureQueryOptions[];
  children: (confirmDelete: () => void, loading: boolean) => ReactNode;
}) {
  const [deletePicture, { loading }] = useMutation(DELETE_PICTURE, {
    variables: { id: pictureId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deletePicture();
      toast.success('Picture deleted.');
    } catch (err) {
      console.log('Error deleting picture', err);
      toast.error('There was a problem deleting the picture.');
    }
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      text="Are you sure you want to delete this picture?"
    >
      {onConfirm => children(onConfirm, loading)}
    </Confirm>
  );
}
