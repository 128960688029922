import { useQuery } from '@apollo/client';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Link } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import {
  BookmarkParentType,
  BookmarkTargetType,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { PictureThumbnail } from '~/components/common/PictureThumbnail';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useShowUnpublished } from '~/components/upload/supporting-object/use-show-unpublished';
import { studyRoute } from '~/paths';
import { useOutcropOutletContext } from '~/routes/outcrop/$outcropId';
import { createRefetchQueries } from '~/utils/graphql';
import { soBookmarkPath } from '~/utils/modules/bookmark';

const OUTCROP_PICTURES_ROUTE = graphql(`
  query OutcropPicturesRoute($outcropId: Int!) {
    outcropList(id: $outcropId) {
      ...outcropParts
      pictures {
        ...pictureParts
        file {
          ...fileParts
          signedUrl
        }
      }

      studies {
        ...studyParts

        dataHistory {
          ...dataHistoryParts
        }

        pictures {
          ...pictureParts
          file {
            ...fileParts
            signedUrl
          }
        }
      }
    }
  }
`);

export default function OutcropPicturesRoute() {
  const ctx = useOutcropOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery(OUTCROP_PICTURES_ROUTE, {
    variables: { outcropId },
  });

  const refetchQueries = createRefetchQueries([
    { query: OUTCROP_PICTURES_ROUTE, variables: { outcropId } },
  ]);

  const outcrop = data?.outcropList.find(oc => oc.id === outcropId);

  const allOutcropPictures = outcrop?.pictures ?? [];
  const studies = outcrop?.studies ?? [];
  type Study = (typeof studies)[number];

  const unpublishedCount = studies
    .flatMap(s => s.pictures)
    .concat(allOutcropPictures)
    .reduce((acc, pic) => acc + (pic.published ? 0 : 1), 0);

  const { showUnpublished, PublishedToggle } =
    useShowUnpublished(unpublishedCount);

  const outcropPictures = allOutcropPictures
    .filter(picture => showUnpublished || picture.published)
    .sort(R.ascend(picture => picture.priority ?? Infinity));

  function studyTaggedPictures(study: Study) {
    return study.pictures
      .filter(picture => {
        if (picture.outcropTagId !== outcropId) return false;
        return showUnpublished || picture.published;
      })
      .sort(R.ascend(picture => picture.priority ?? Infinity));
  }

  function authorLine(dataHistory?: Study['dataHistory']) {
    if (!dataHistory) return null;
    if (!dataHistory.collectedBy && !dataHistory.date) {
      return null;
    }

    return (
      <div className="space-x-2">
        {dataHistory.collectedBy && <span>{dataHistory.collectedBy}</span>}
        {dataHistory.date && (
          <span className="text-muted">{dataHistory.date}</span>
        )}
      </div>
    );
  }

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      <PublishedToggle />

      <div className="grid md:grid-cols-2 gap-4">
        {outcropPictures.map(picture => (
          <div key={picture.id}>
            <PictureThumbnail
              picture={picture}
              imgSrc={picture.file.signedUrl}
              bookmarkable={{
                parentType: BookmarkParentType.Outcrop,
                parentId: outcropId,
                targetType: BookmarkTargetType.Picture,
                targetId: picture.id,
                path: soBookmarkPath(
                  'outcrop',
                  outcropId,
                  BookmarkParentType.Outcrop,
                  picture.outcropTagId,
                ),
              }}
              refetchQueries={refetchQueries}
            />
          </div>
        ))}
      </div>

      {studies.map(study => {
        const taggedPics = studyTaggedPictures(study);
        if (!taggedPics.length) return null;

        return (
          <Panel key={study.id}>
            <Panel.Heading className="flex justify-between items-center">
              <Panel.Title>
                <strong>{study.name}</strong>
                {authorLine(study.dataHistory)}
              </Panel.Title>

              <Link
                to={studyRoute(study.id)}
                target="_blank"
                className="btn btn-ghost btn-xs"
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} /> View Study
              </Link>
            </Panel.Heading>

            <Panel.Body>
              <div className="grid md:grid-cols-2 gap-4">
                {taggedPics.map(picture => (
                  <div key={picture.id}>
                    <PictureThumbnail
                      picture={picture}
                      imgSrc={picture.file.signedUrl}
                      bookmarkable={{
                        parentType: BookmarkParentType.Study,
                        parentId: study.id,
                        targetType: BookmarkTargetType.Picture,
                        targetId: picture.id,
                        path: soBookmarkPath(
                          'study',
                          study.id,
                          BookmarkParentType.Study,
                          picture.outcropTagId,
                        ),
                      }}
                      refetchQueries={refetchQueries}
                    />
                  </div>
                ))}
              </div>
            </Panel.Body>
          </Panel>
        );
      })}
    </div>
  );
}
