import { create } from 'xmlbuilder2';
import type {
  GeoreferencePartsFragment,
  LatLng,
} from '~/apollo/generated/v4/graphql';
import { GeoreferenceDataType } from '~/apollo/generated/v4/graphql';
import { assertExhaustive } from '~/utils/common';

type KmlElementName = 'Point' | 'Polygon' | 'LineString';

function elementName(dataType: GeoreferenceDataType): KmlElementName {
  switch (dataType) {
    case GeoreferenceDataType.Centre:
    case GeoreferenceDataType.Point:
      return 'Point';

    case GeoreferenceDataType.Outline:
    case GeoreferenceDataType.Polygon:
      return 'Polygon';

    case GeoreferenceDataType.Polyline:
      return 'LineString';

    default:
      throw assertExhaustive(dataType);
  }
}

type CoordinateString = string;

type FormattedCoordinates =
  | { coordinates: CoordinateString }
  | {
      outerBoundaryIs: {
        LinearRing: {
          coordinates: CoordinateString;
        };
      };
    };

export function formatCoordinates(
  elName: KmlElementName,
  coordinates: LatLng[],
): FormattedCoordinates {
  console.log('Formatting', elName, coordinates);

  const toString = (coordinates: LatLng) =>
    `${coordinates.lng},${coordinates.lat},0`;

  if (elName === 'Point') {
    return {
      coordinates: coordinates.map(toString).join(' '),
    };
  } else if (coordinates instanceof Array) {
    if (elName === 'LineString') {
      return {
        coordinates: coordinates.map(toString).join(' '),
      };
    } else if (elName === 'Polygon') {
      const nextCoords = [...coordinates];
      const firstCoord = nextCoords.at(0);
      const lastCoord = nextCoords.at(-1);

      if (
        firstCoord?.lat !== lastCoord?.lat ||
        firstCoord?.lng !== lastCoord?.lng
      ) {
        // Polygons created by google maps are not "closed" automatically and
        // are missing a side when viewed in Google Earth.
        // Fix this by appending the first coordinate to the list.
        nextCoords.push(nextCoords[0]);
      }

      return {
        outerBoundaryIs: {
          LinearRing: {
            coordinates: nextCoords.map(toString).join(' '),
          },
        },
      };
    }
  }

  console.log('Error in ', elName);
  console.log('coordinates:', coordinates);
  throw new Error(`Unhandled element type '${elName}'`);
}

export function buildPlacemark(georeference: GeoreferencePartsFragment) {
  if (!georeference.data) return null;

  const el = elementName(georeference.dataType);

  return {
    name: georeference.name,
    description: georeference.description,
    [el]: {
      ...formatCoordinates(el, georeference.data),
    },
    styleUrl: 'style1',
  };
}

export function createKmlFolder(
  name: string,
  description: string | null,
  georeferences: GeoreferencePartsFragment[],
  folders?: any[],
): any {
  const placemarks = georeferences.map(buildPlacemark).filter(Boolean);

  const item: any = {
    name,
    description,
    Placemark: placemarks,
  };
  if (folders && folders.length > 0) {
    item.Folder = folders;
  }
  return item;
}

export async function createKmlDocument(folders: any[]): Promise<string> {
  const jsonDocument = {
    kml: {
      '@xmlns': 'http://www.opengis.net/kml/2.2',
      Document: {
        Style: [
          {
            '@id': 'style1',
            LineStyle: {
              color: 'ff0000d8',
              width: 4,
            },
            PolyStyle: {
              color: '4c0000d8',
            },
          },
        ],
        Folder: folders,
      },
    },
  };

  const doc = create(jsonDocument)
    .dec({ version: '1.0', encoding: 'UTF-8' })
    .end({ prettyPrint: true });
  return doc;
}
