import type { PureQueryOptions } from '@apollo/client';
import type {
  FileParentType,
  FilePartsFragment,
} from '~/apollo/generated/v4/graphql';
import { Heading } from '~/components/common/Heading';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { SOFileCard } from '~/components/upload/supporting-object/so-file-card';
import { UploadFilesModal } from '~/components/upload/supporting-object/upload-files-modal';

export function SOFileManager({
  parentType,
  parentId,
  files,
  refetchQueries,
}: {
  parentType: FileParentType;
  parentId: number;
  files: FilePartsFragment[];
  refetchQueries: PureQueryOptions[];
}) {
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between gap-4">
        <Heading level={3}>Files</Heading>
        <UploadFilesModal
          parentType={parentType}
          parentId={parentId}
          refetchQueries={refetchQueries}
        />
      </div>

      <div className="space-y-2">
        {files.map(file => (
          <SOFileCard
            key={file.id}
            file={file}
            refetchQueries={refetchQueries}
          />
        ))}
        <NoItemsAlert show={!files.length} entity="files" />
      </div>
    </div>
  );
}
