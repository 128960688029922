import { useMutation } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { Confirm } from '~/components/common/Confirm';
import { Tooltip } from '~/components/common/Tooltip';
import { Button } from '~/components/ui/button';
import type { RefetchQueries } from '~/utils/graphql';

const DELETE_FIELD_PICTURE = graphql(`
  mutation DeleteFieldPicture($id: ID!) {
    fieldPictureDelete(id: $id) {
      result {
        id
      }
    }
  }
`);

type Props = {
  fieldPictureId: number;
  refetchQueries: RefetchQueries;
};

export function DeleteFieldPicture({ fieldPictureId, refetchQueries }: Props) {
  const [deleteFieldPicture, { loading }] = useMutation(DELETE_FIELD_PICTURE, {
    variables: { id: fieldPictureId },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deleteFieldPicture();
      toast.success('Field picture deleted successfully.');
    } catch (err) {
      console.log('Error deleting field picture:', err);
      toast.error('There was a problem deleting the field picture.');
    }
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      text="Are you sure you want to delete this field picture?"
    >
      {confirmDelete => (
        <Tooltip message="Delete field picture">
          <Button
            type="button"
            color="ghost"
            size="xs"
            onClick={confirmDelete}
            disabled={loading}
            startIcon={<FontAwesomeIcon icon={faTrash} />}
          >
            Delete
          </Button>
        </Tooltip>
      )}
    </Confirm>
  );
}
