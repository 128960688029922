import type { HTMLProps } from 'react';
import React from 'react';
import type { FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';
import { cn } from '~/utils/common';

type Props = HTMLProps<HTMLDivElement> & {
  className?: string | null;
};

export const DropzoneContainer = React.forwardRef<HTMLInputElement, Props>(
  ({ className, ...props }: Props, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          'flex flex-1 flex-col items-center p-10 border-2 border-dashed border-slate-200 bg-slate-100 text-slate-400 outline-hidden cursor-pointer',
          className,
        )}
      />
    );
  },
);
DropzoneContainer.displayName = 'DropzoneContainer';

export function handleDropRejected(rejects: FileRejection[]) {
  toast.error(
    <div>
      Some files couldn't be added:
      {rejects.map(r => (
        <div key={r.file.name}>
          <strong>{r.file.name}</strong>:{' '}
          {r.errors.map(e => e.message).join('; ')}
        </div>
      ))}
    </div>,
  );
}
