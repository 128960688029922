import { useQuery } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { graphql } from '~/apollo/generated/v4';
import type { SourceAreaFieldOptionsQuery } from '~/apollo/generated/v4/graphql';
import { InputGroup } from '~/components/common/InputGroup';
import { FormLabel } from '~/components/ui/forms/FormLabel';
import { Select } from '~/components/ui/forms/Select';
import { TextAreaInput } from '~/components/ui/forms/text-area-input';
import { TextInput } from '~/components/ui/forms/text-input';
import type { OutcropFormValues } from '~/utils/modules/outcrop-v4';

const SOURCE_AREA_FIELD_OPTIONS = graphql(`
  query SourceAreaFieldOptions {
    climate: outcropEnumerations(type: CLIMATE) {
      values
    }
    geography: outcropEnumerations(type: GEOGRAPHY) {
      values
    }
    structure: outcropEnumerations(type: STRUCTURE) {
      values
    }
    distance: outcropEnumerations(type: DISTANCE) {
      values
    }
  }
`);

export function OutcropSourceAreaFormFields() {
  const { register, control } = useFormContext<OutcropFormValues>();
  const { data, loading } = useQuery(SOURCE_AREA_FIELD_OPTIONS);

  type EnumOptionsKey = keyof Omit<SourceAreaFieldOptionsQuery, '__typename'>;

  function enumOptions(field: EnumOptionsKey) {
    const values = data?.[field]?.values ?? [];
    return values.map((val: string) => ({ value: val, label: val }));
  }

  return (
    <div className="space-y-2">
      <TextInput {...register('sourceAreaName')} label="Name" />

      <TextAreaInput
        {...register('sourceAreaDescription')}
        label="Description"
      />

      <Controller
        control={control}
        name="sourceAreaGeography"
        render={({ field }) => (
          <Select
            {...field}
            label="Geography"
            options={enumOptions('geography')}
            disabled={loading}
          />
        )}
      />
      <Controller
        control={control}
        name="sourceAreaStructure"
        render={({ field }) => (
          <Select
            {...field}
            label="Structure"
            options={enumOptions('structure')}
            disabled={loading}
          />
        )}
      />
      <Controller
        control={control}
        name="sourceAreaClimate"
        render={({ field }) => (
          <Select
            {...field}
            label="Climate"
            options={enumOptions('climate')}
            disabled={loading}
          />
        )}
      />

      <div>
        <FormLabel label="Estimated Distance to Source Area" />
        <div className="grid grid-cols-2 gap-2">
          <Controller
            control={control}
            name="sourceAreaDistanceToSourceAreaDesc"
            render={({ field }) => (
              <Select
                {...field}
                options={enumOptions('distance')}
                disabled={loading}
              />
            )}
          />

          <TextInput
            {...register('sourceAreaDistanceToSourceArea')}
            type="number"
            step="1"
            join
            renderInput={input => (
              <InputGroup>
                {input}
                <InputGroup.Addon right>km</InputGroup.Addon>
              </InputGroup>
            )}
          />
        </div>
      </div>

      <div>
        <FormLabel label="Estimated Distance to Shoreline" />
        <div className="grid grid-cols-2 gap-2">
          <Controller
            control={control}
            name="sourceAreaDistanceToShorelineDesc"
            render={({ field }) => (
              <Select
                {...field}
                options={enumOptions('distance')}
                disabled={loading}
              />
            )}
          />

          <TextInput
            {...register('sourceAreaDistanceToShoreline')}
            type="number"
            step="1"
            join
            renderInput={input => (
              <InputGroup>
                {input}
                <InputGroup.Addon right>km</InputGroup.Addon>
              </InputGroup>
            )}
          />
        </div>
      </div>

      <div>
        <FormLabel label="Estimated Distance to Shelf Edge" />
        <div className="grid grid-cols-2 gap-2">
          <Controller
            control={control}
            name="sourceAreaDistanceToShelfEdgeDesc"
            render={({ field }) => (
              <Select
                {...field}
                options={enumOptions('distance')}
                disabled={loading}
              />
            )}
          />

          <TextInput
            {...register('sourceAreaDistanceToShelfEdge')}
            type="number"
            step="1"
            join
            renderInput={input => (
              <InputGroup>
                {input}
                <InputGroup.Addon right>km</InputGroup.Addon>
              </InputGroup>
            )}
          />
        </div>
      </div>

      <TextAreaInput {...register('sourceAreaComments')} label="Comments" />
    </div>
  );
}
