import { GoogleMap, MarkerF } from '@react-google-maps/api';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import type {
  NearestOutcropsQuery,
  PointInput,
} from '~/apollo/generated/v4/graphql';
import { Modal } from '~/components/common/Modal';
import { Button } from '~/components/ui/button';
import { Checkbox } from '~/components/ui/forms/Checkbox';
import { OutcropSuggestions } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader/outcrop-suggestions';
import { CoordinatesText } from '~/components/upload/supporting-object/field-picture/coordinates-text';
import type { ShowModalFn } from '~/hooks/modal';
import { useModalState } from '~/hooks/modal';
import { markerIcon } from '~/utils/georeference';

type NearestOutcrop = NonNullable<
  NonNullable<NearestOutcropsQuery['outcropList']>['results']
>[number];

export function LocationEditor({
  children,
  nearestOutcrops,
  outcropsDisabled,
  outcropId,
  location,
  exifLocation,
  defaultLocation,
  locationApproximate,
  onLocationChange,
  onLocationApproximateChange,
  onOutcropIdChange,
}: {
  children: (showModal: ShowModalFn) => ReactNode;
  nearestOutcrops: NearestOutcrop[];
  outcropsDisabled: boolean;
  outcropId: number | null;
  location: PointInput | null;
  exifLocation: PointInput | null;
  defaultLocation: PointInput | null;
  locationApproximate: boolean;
  /** Handler for changing picture location. **MUST BE MEMOIZED** */
  onLocationChange: (location: PointInput | null) => unknown;
  onLocationApproximateChange: (approximate: boolean) => unknown;
  onOutcropIdChange: (outcropId: number | null) => unknown;
}) {
  const [map, setMap] = useState<google.maps.Map>();
  const { showModal, hideModal, modalProps } = useModalState();

  function handleMapLoaded(map: google.maps.Map) {
    map.setCenter({ lat: 0, lng: 0 });
    map.setZoom(2);
    zoomToLocation(map, location ?? { longitude: 0, latitude: 0 });

    setMap(map);
  }

  function zoomToLocation(map?: google.maps.Map, loc?: PointInput | null) {
    if (map && loc) {
      map.panTo({ lat: loc.latitude, lng: loc.longitude });
      map.setZoom(10);
    }
  }

  function handleShow() {
    showModal();
    if (map) zoomToLocation(map, location ?? { longitude: 0, latitude: 0 });
  }

  function handleMouseEvent(event: google.maps.MapMouseEvent) {
    const latitude = event.latLng?.lat();
    const longitude = event.latLng?.lng();

    if (latitude && longitude) {
      onLocationChange({ latitude, longitude });
      onLocationApproximateChange(false);
    } else {
      onLocationChange(null);
    }
  }

  function clearLocation() {
    if (window.confirm('Are you sure you want to remove the location?')) {
      onLocationChange(null);
      onLocationApproximateChange(true);
    }
  }

  function resetToExifLocation() {
    if (exifLocation) {
      onLocationChange(exifLocation);
      onLocationApproximateChange(false);
      zoomToLocation(map, exifLocation);
    }
  }

  useEffect(() => {
    if (!exifLocation && defaultLocation) {
      onLocationChange(defaultLocation);
    }
  }, [exifLocation, defaultLocation, onLocationChange]);

  return (
    <>
      {children(handleShow)}

      <Modal {...modalProps} size="lg">
        <Modal.Body heading={location ? 'Adjust Location' : 'Set Location'}>
          <div className="space-y-4">
            <GoogleMap
              mapContainerClassName="w-full h-[500px]"
              onLoad={handleMapLoaded}
              onClick={handleMouseEvent}
              mapTypeId="terrain"
            >
              {location && (
                <MarkerF
                  position={{ lat: location.latitude, lng: location.longitude }}
                  draggable
                  onDragEnd={handleMouseEvent}
                />
              )}

              {nearestOutcrops.map(
                outcrop =>
                  outcrop.center && (
                    <MarkerF
                      key={outcrop.id}
                      position={outcrop.center}
                      draggable={false}
                      clickable={false}
                      options={{
                        icon: markerIcon(
                          parseInt(outcrop.id) === outcropId
                            ? {
                                fill: 'yellow',
                                stroke: 'yellow',
                                fillOpacity: 0.5,
                              }
                            : {
                                fill: 'blue',
                                stroke: 'blue',
                                fillOpacity: 0.5,
                              },
                        ),
                      }}
                    />
                  ),
              )}
            </GoogleMap>

            <div className="text-center space-y-2">
              {location && (
                <CoordinatesText
                  exifLocation={exifLocation}
                  location={location}
                />
              )}

              <Checkbox
                checked={locationApproximate}
                onChange={() =>
                  onLocationApproximateChange(!locationApproximate)
                }
                label="Approximate location"
              />

              <div className="space-x-2">
                {location && (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    onClick={clearLocation}
                  >
                    Remove Location
                  </Button>
                )}

                {exifLocation && (
                  <Button
                    type="button"
                    color="ghost"
                    size="sm"
                    onClick={resetToExifLocation}
                  >
                    Reset to Image Location
                  </Button>
                )}
              </div>
            </div>
          </div>

          {nearestOutcrops.length > 0 && (
            <div className="w-full flex justify-center">
              <div className="inline w-auto">
                <OutcropSuggestions
                  disabled={outcropsDisabled}
                  selectedOutcropId={outcropId}
                  nearestOutcrops={nearestOutcrops}
                  onOutcropClick={onOutcropIdChange}
                />
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button type="button" color="ghost" onClick={hideModal}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
