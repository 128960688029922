import {
  DrawingManagerF,
  GoogleMap,
  MarkerF,
  PolygonF,
  PolylineF,
} from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import type {
  GeoreferencePartsFragment,
  LatLngInput,
} from '~/apollo/generated/v4/graphql';
import {
  createBoundsFromGeoreferences,
  defaultMarkerProps,
  defaultPolygonProps,
  defaultPolylineProps,
  isMarkerType,
  isPolygonType,
  isPolylineType,
} from '~/utils/modules/georeference-v4';

function createMapMarkers(georeferences: GeoreferencePartsFragment[]) {
  return georeferences
    .filter(g => isMarkerType(g.dataType))
    .map(g => <MarkerF key={g.id} {...defaultMarkerProps(g)} />);
}

function createMapPolygons(georeferences: GeoreferencePartsFragment[]) {
  return georeferences
    .filter(g => isPolygonType(g.dataType))
    .map(g => <PolygonF key={g.id} {...defaultPolygonProps(g)} />);
}

function getMapPolylines(georeferences: GeoreferencePartsFragment[]) {
  return georeferences
    .filter(g => isPolylineType(g.dataType))
    .map(g => <PolylineF key={g.id} {...defaultPolylineProps(g)} />);
}

type LatLng = google.maps.LatLng;

export type MapEditorProps = {
  georeferences: GeoreferencePartsFragment[];
  drawingModes: google.maps.drawing.OverlayType[];
  onDrawingComplete: (coordinates: LatLngInput[] | null) => void;
};

export function MapEditor({
  georeferences,
  drawingModes,
  onDrawingComplete,
}: MapEditorProps) {
  const [tmpMarker, setTmpMarker] = useState<LatLng | null>(null);
  const [tmpPolygon, setTmpPolygon] = useState<LatLng[] | null>(null);
  const [tmpPolyline, setTmpPolyline] = useState<LatLng[] | null>(null);

  function handleLoad(mapInstance: google.maps.Map) {
    if (georeferences.length) {
      const bounds = createBoundsFromGeoreferences(georeferences);
      mapInstance.fitBounds(bounds);
    } else {
      mapInstance.setZoom(2);
      mapInstance.setCenter({ lat: 30, lng: -20 });
    }
  }

  function handlePolygonComplete(polygon: google.maps.Polygon) {
    const path = polygon.getPath().getArray();
    const coordinates = path.map<LatLngInput>(coords => ({
      lat: coords.lat(),
      lng: coords.lng(),
    }));

    polygon.setMap(null);
    setTmpPolygon(path);
    onDrawingComplete(coordinates);
  }

  function handlePolylineComplete(polyline: google.maps.Polyline) {
    const path = polyline.getPath().getArray();
    const coordinates = path.map<LatLngInput>(coords => ({
      lat: coords.lat(),
      lng: coords.lng(),
    }));

    polyline.setMap(null);
    setTmpPolyline(path);
    onDrawingComplete(coordinates);
  }

  function handleMarkerComplete(marker: google.maps.Marker) {
    const position = marker.getPosition();
    if (!position) {
      onDrawingComplete(null);
      return;
    }

    marker.setMap(null);
    setTmpMarker(position);
    onDrawingComplete([{ lat: position.lat(), lng: position.lng() }]);
  }

  function handleMarkerDragged(event: google.maps.MapMouseEvent) {
    const position = event.latLng;
    if (!position) {
      onDrawingComplete(null);
      return;
    }

    setTmpMarker(position);
    onDrawingComplete([{ lat: position.lat(), lng: position.lng() }]);
  }

  useEffect(() => {
    console.log('Drawing modes updated, clearing temporary drawn elements');
    setTmpMarker(null);
    setTmpPolygon(null);
    setTmpPolyline(null);
  }, [drawingModes]);

  const markers = createMapMarkers(georeferences);
  const polygons = createMapPolygons(georeferences);
  const polylines = getMapPolylines(georeferences);

  const isDrawingEnabled = drawingModes.length > 0;

  return (
    <GoogleMap
      onLoad={handleLoad}
      mapContainerClassName="w-full h-[406px] rounded"
      mapTypeId={google.maps.MapTypeId.TERRAIN}
    >
      <DrawingManagerF
        options={{
          drawingMode: drawingModes.at(0) ?? null,
          drawingControl: isDrawingEnabled,
          drawingControlOptions: {
            drawingModes,
            position: google.maps.ControlPosition.TOP_CENTER,
          },
        }}
        onMarkerComplete={handleMarkerComplete}
        onPolygonComplete={handlePolygonComplete}
        onPolylineComplete={handlePolylineComplete}
      />

      {markers}
      {polygons}
      {polylines}

      {tmpMarker && (
        <MarkerF
          position={tmpMarker}
          draggable
          onDragEnd={handleMarkerDragged}
        />
      )}
      {tmpPolygon && <PolygonF path={tmpPolygon} />}
      {tmpPolyline && <PolylineF path={tmpPolyline} />}
    </GoogleMap>
  );
}
