import { useMutation } from '@apollo/client';
import { Controller, FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { graphql } from '~/apollo/generated/v4';
import type {
  DepositionalHierarchyQuery,
  KeyParametersPartsFragment,
} from '~/apollo/generated/v4/graphql';
import { GeologyType } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { Select } from '~/components/ui/forms/Select';
import type { KeyParametersParent } from '~/components/upload/key-parameters-v4/key-parameters-manager';
import { useZodForm } from '~/utils/forms';
import type { RefetchQueries } from '~/utils/graphql';
import { readableGqlEnum } from '~/utils/text';

type Hierarchy = DepositionalHierarchyQuery['depositionalHierarchy'];

function gdeOptions(hierarchy: Hierarchy, geologyType: string) {
  return (
    hierarchy.find(h => h.name === geologyType)?.grossDepositionalEnvironment ??
    []
  );
}

function deOptions(hierarchy: Hierarchy, geologyType: string, gde: string) {
  const gdes = gdeOptions(hierarchy, geologyType);
  return gdes.find(g => g.name === gde)?.depositionalEnvironment ?? [];
}

function dseOptions(
  hierarchy: Hierarchy,
  geologyType: string,
  gde: string,
  de: string,
) {
  const des = deOptions(hierarchy, geologyType, gde);
  return des.find(d => d.name === de)?.depositionalSubEnvironment ?? [];
}

function aeOptions(
  hierarchy: Hierarchy,
  geologyType: string,
  gde: string,
  de: string,
  dse: string,
) {
  const dses = dseOptions(hierarchy, geologyType, gde, de);
  return dses.find(d => d.name === dse)?.architecturalElement ?? [];
}

const kpSchema = z.object({
  geologyType: z.nativeEnum(GeologyType),
  grossDepositionalEnvironment: z.string().min(1),
  depositionalEnvironment: z.string().min(1),
  depositionalSubEnvironment: z.string().min(1),
  architecturalElement: z.string().min(1),
});
type FormValues = Omit<z.infer<typeof kpSchema>, 'geologyType'> & {
  geologyType: string;
};

function initialValues(): FormValues {
  return {
    geologyType: '',
    grossDepositionalEnvironment: '',
    depositionalEnvironment: '',
    depositionalSubEnvironment: '',
    architecturalElement: '',
  };
}

const CREATE_KEY_PARAMETERS = graphql(`
  mutation CreateKeyParameters($input: KeyParametersCreateInput!) {
    keyParametersCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

export function CreateKeyParametersForm({
  parent,
  keyParameters,
  hierarchy,
  geologyTypes,
  refetchQueries,
}: {
  parent: KeyParametersParent;
  keyParameters: KeyParametersPartsFragment[];
  hierarchy: Hierarchy;
  geologyTypes: GeologyType[];
  refetchQueries: RefetchQueries;
}) {
  const [createKP, { loading, error }] = useMutation(CREATE_KEY_PARAMETERS, {
    refetchQueries,
  });

  const form = useZodForm({
    schema: kpSchema,
    defaultValues: initialValues(),
  });

  const handleSubmit = form.handleSubmit(async data => {
    await createKP({ variables: { input: { ...data, ...parent } } });
    toast.success('Key parameters created successfully');
    // Only clear the AE for easy multiple submissions
    form.reset({ ...data, architecturalElement: '' });
  });

  function handleReset() {
    form.reset(initialValues());
  }

  function unsetChildren(field: keyof FormValues) {
    const fields: Array<keyof FormValues> = [
      'geologyType',
      'grossDepositionalEnvironment',
      'depositionalEnvironment',
      'depositionalSubEnvironment',
      'architecturalElement',
    ];
    const index = fields.indexOf(field);
    fields.forEach((field, i) => {
      if (i > index) {
        form.setValue(field, '');
      }
    });
  }

  const geologyType = form.watch('geologyType');
  const gde = form.watch('grossDepositionalEnvironment');
  const de = form.watch('depositionalEnvironment');
  const dse = form.watch('depositionalSubEnvironment');

  const disabledAEs = keyParameters
    .filter(kp => {
      return (
        kp.geologyType === geologyType &&
        kp.grossDepositionalEnvironment === gde &&
        kp.depositionalEnvironment === de &&
        kp.depositionalSubEnvironment === dse
      );
    })
    .map(kp => kp.architecturalElement);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-2">
        <Controller
          control={form.control}
          name="geologyType"
          render={({ field }) => (
            <Select
              {...field}
              label="Geology Type"
              options={geologyTypes.map(value => ({
                value,
                label: readableGqlEnum(value),
              }))}
              onChange={event => {
                field.onChange(event);
                unsetChildren('geologyType');
              }}
              required
            />
          )}
        />
        <Controller
          control={form.control}
          name="grossDepositionalEnvironment"
          render={({ field }) => (
            <Select
              {...field}
              label="Gross Depositional Environment"
              options={gdeOptions(hierarchy, geologyType)
                .map(gde => gde.name)
                .sort()}
              onChange={event => {
                field.onChange(event);
                unsetChildren('grossDepositionalEnvironment');
              }}
              disabled={!geologyType}
              required
            />
          )}
        />
        <Controller
          control={form.control}
          name="depositionalEnvironment"
          render={({ field }) => (
            <Select
              {...field}
              label="Depositional Environment"
              options={deOptions(hierarchy, geologyType, gde)
                .map(de => de.name)
                .sort()}
              onChange={event => {
                field.onChange(event);
                unsetChildren('depositionalEnvironment');
              }}
              disabled={!gde}
              required
            />
          )}
        />
        <Controller
          control={form.control}
          name="depositionalSubEnvironment"
          render={({ field }) => (
            <Select
              {...field}
              label="Depositional Sub Environment"
              options={dseOptions(hierarchy, geologyType, gde, de)
                .map(dse => dse.name)
                .sort()}
              onChange={event => {
                field.onChange(event);
                unsetChildren('depositionalSubEnvironment');
              }}
              disabled={!de}
              required
            />
          )}
        />
        <Controller
          control={form.control}
          name="architecturalElement"
          render={({ field }) => (
            <Select
              {...field}
              label="Architectural Element"
              options={aeOptions(hierarchy, geologyType, gde, de, dse)
                .map(ae => ae.name)
                .sort()
                .map(ae => ({
                  value: ae,
                  label: ae,
                  disabled: disabledAEs.includes(ae),
                }))}
              disabled={!dse}
              required
            />
          )}
        />

        <HookFormErrors graphQLError={error} />

        <div className="text-center space-x-1">
          <Button type="button" onClick={handleReset} color="ghost">
            Reset
          </Button>
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
