import { useMutation } from '@apollo/client';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import invariant from 'tiny-invariant';
import type {
  FieldPicturePartsFragment,
  SoManagerCrossSectionPartsFragment,
  SoManagerFaciesPartsFragment,
  SoManagerGigaPanPartsFragment,
  SoManagerPicturePartsFragment,
  SoManagerProductionPartsFragment,
  SoManagerReservoirModelPartsFragment,
  SoManagerSedimentaryLogPartsFragment,
  SoManagerTrainingImagePartsFragment,
  SoManagerVariogramPartsFragment,
  SoManagerWellLogPartsFragment,
} from '~/apollo/generated/v4/graphql';
import { LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import {
  UPDATE_CROSS_SECTION,
  UPDATE_FACIES,
  UPDATE_FIELD_PICTURE,
  UPDATE_GIGA_PAN,
  UPDATE_PICTURE,
  UPDATE_PRODUCTION,
  UPDATE_RESERVOIR_MODEL,
  UPDATE_SEDLOG,
  UPDATE_TRAINING_IMAGE,
  UPDATE_VARIOGRAM,
  UPDATE_WELL_LOG,
} from '~/components/upload/supporting-object/so-operations';
import { assertExhaustive } from '~/utils/common';

type ToggleableEntity =
  | SoManagerPicturePartsFragment
  | SoManagerCrossSectionPartsFragment
  | SoManagerFaciesPartsFragment
  | SoManagerSedimentaryLogPartsFragment
  | SoManagerWellLogPartsFragment
  | SoManagerProductionPartsFragment
  | SoManagerReservoirModelPartsFragment
  | SoManagerTrainingImagePartsFragment
  | SoManagerVariogramPartsFragment
  | SoManagerGigaPanPartsFragment
  | FieldPicturePartsFragment;

export function TogglePublishedButton({
  entity,
}: {
  entity: ToggleableEntity;
}) {
  const nextLifecycle = (status: LifecycleStatus | undefined | null) =>
    status === LifecycleStatus.Published
      ? LifecycleStatus.Draft
      : LifecycleStatus.Published;

  const variables = {
    id: entity.id,
    input: {
      lifecycleStatus: nextLifecycle(entity.lifecycleStatus),
    },
  };

  const [publishPicture, { loading: loadingPicture }] = useMutation(
    UPDATE_PICTURE,
    { variables },
  );
  const [publishCrossSection, { loading: loadingCrossSection }] = useMutation(
    UPDATE_CROSS_SECTION,
    { variables },
  );
  const [publishFacies, { loading: loadingFacies }] = useMutation(
    UPDATE_FACIES,
    { variables },
  );
  const [publishSedlog, { loading: loadingSedlog }] = useMutation(
    UPDATE_SEDLOG,
    { variables },
  );
  const [publishWellLog, { loading: loadingWellLog }] = useMutation(
    UPDATE_WELL_LOG,
    { variables },
  );
  const [publishProduction, { loading: loadingProduction }] = useMutation(
    UPDATE_PRODUCTION,
    { variables },
  );
  const [publishResModel, { loading: loadingResModel }] = useMutation(
    UPDATE_RESERVOIR_MODEL,
    { variables },
  );
  const [publishTrainingImage, { loading: loadingTrainingImage }] = useMutation(
    UPDATE_TRAINING_IMAGE,
    { variables },
  );
  const [publishVariogram, { loading: loadingVariogram }] = useMutation(
    UPDATE_VARIOGRAM,
    { variables },
  );
  const [publishGigaPan, { loading: loadingGigaPan }] = useMutation(
    UPDATE_GIGA_PAN,
    { variables },
  );
  const [publishFieldPicture, { loading: loadingFieldPicture }] = useMutation(
    UPDATE_FIELD_PICTURE,
    { variables },
  );

  // "Toggling" the published lifecycle alternates between published and created

  async function togglePublished() {
    const parentType = entity.__typename;
    invariant(parentType, 'Parent type is not defined');

    switch (parentType) {
      case 'Picture':
        return publishPicture();
      case 'CrossSection':
        return publishCrossSection();
      case 'FaciesSchema':
        return publishFacies();
      case 'SedimentaryLog':
        return publishSedlog();
      case 'WellLog':
        return publishWellLog();
      case 'Production':
        return publishProduction();
      case 'ReservoirModel':
        return publishResModel();
      case 'TrainingImage':
        return publishTrainingImage();
      case 'Variogram':
        return publishVariogram();
      case 'GigaPan':
        return publishGigaPan();
      case 'FieldPicture':
        return publishFieldPicture();
      default:
        throw assertExhaustive(parentType);
    }
  }

  const loading =
    loadingPicture ||
    loadingCrossSection ||
    loadingFacies ||
    loadingSedlog ||
    loadingWellLog ||
    loadingProduction ||
    loadingResModel ||
    loadingTrainingImage ||
    loadingVariogram ||
    loadingGigaPan ||
    loadingFieldPicture;

  const isPublished = entity.lifecycleStatus === LifecycleStatus.Published;

  return (
    <Button
      type="button"
      onClick={togglePublished}
      color={isPublished ? 'ghost' : 'info'}
      size="xs"
      startIcon={<FontAwesomeIcon icon={isPublished ? faEyeSlash : faEye} />}
      loading={loading}
    >
      {isPublished ? 'Unpublish' : 'Publish'}
    </Button>
  );
}
