import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import type { SoManagerCrossSectionPartsFragment } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { SOFormFields } from '~/components/upload/supporting-object/so-form-fields';
import { UPDATE_CROSS_SECTION } from '~/components/upload/supporting-object/so-operations';
import { useZodForm } from '~/utils/forms';
import {
  crossSectionSchema,
  initialCrossSection,
} from '~/utils/modules/supporting-object';

export function CrossSectionUpdateForm({
  crossSection,
  studyId,
  onUpdateSuccess,
}: {
  crossSection: SoManagerCrossSectionPartsFragment;
  studyId?: number;
  onUpdateSuccess?: () => unknown;
}) {
  const [updateCrossSection, { loading, error }] =
    useMutation(UPDATE_CROSS_SECTION);

  const form = useZodForm({
    schema: crossSectionSchema,
    values: initialCrossSection(crossSection),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateCrossSection({
        variables: {
          id: crossSection.id,
          input: values,
        },
      });
      toast.success('Cross section updated successfully.');
      form.reset();
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error updating cross section', err);
      toast.error('There was a problem updating the cross section.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <SOFormFields soType="CROSS_SECTION" studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="card-actions justify-end">
          <Button type="submit" loading={loading} color="primary">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
