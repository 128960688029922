import { type PureQueryOptions } from '@apollo/client';
import invariant from 'tiny-invariant';
import type {
  SoManagerCrossSectionPartsFragment,
  SoManagerFaciesPartsFragment,
  SoManagerGigaPanPartsFragment,
  SoManagerProductionPartsFragment,
  SoManagerReservoirModelPartsFragment,
  SoManagerSedimentaryLogPartsFragment,
  SoManagerTrainingImagePartsFragment,
  SoManagerVariogramPartsFragment,
  SoManagerWellLogPartsFragment,
} from '~/apollo/generated/v4/graphql';
import {
  FileParentType,
  GeoreferenceParentType,
  LifecycleStatus,
  PictureInitialParentType,
} from '~/apollo/generated/v4/graphql';
import { Panel } from '~/components/common/Panel';
import { PublishedContainer } from '~/components/common/Published';
import { Badge } from '~/components/ui/badge';
import { CrossSectionUpdateForm } from '~/components/upload/supporting-object/cross-section/cross-section-update-form';
import { DeleteSOButton } from '~/components/upload/supporting-object/delete-so-button';
import { FaciesUpdateForm } from '~/components/upload/supporting-object/facies/facies-update-form';
import { GigaPanUpdateForm } from '~/components/upload/supporting-object/giga-pan/giga-pan-update-form';
import { ProductionUpdateForm } from '~/components/upload/supporting-object/production/production-update-form';
import { ReservoirModelUpdateForm } from '~/components/upload/supporting-object/reservoir-model/reservoir-model-update-form';
import { SedlogUpdateForm } from '~/components/upload/supporting-object/sedimentary-log/sedlog-update-form';
import {
  CrossSectionDetails,
  FaciesDetails,
  GigaPanDetails,
  ProductionDetails,
  ReservoirModelDetails,
  SedlogDetails,
  TrainingImageDetails,
  VariogramDetails,
  WellLogDetails,
} from '~/components/upload/supporting-object/so-details';
import { SOFileManager } from '~/components/upload/supporting-object/so-file-manager';
import { SOGeoreferenceManager } from '~/components/upload/supporting-object/so-georeference-manager';
import type { SOParent } from '~/components/upload/supporting-object/so-manager';
import { SOPictureManager } from '~/components/upload/supporting-object/so-picture-manager';
import { TogglePublishedButton } from '~/components/upload/supporting-object/toggle-published-button';
import { TrainingImageUpdateForm } from '~/components/upload/supporting-object/training-image/training-image-update-form';
import { useEditToggle } from '~/components/upload/supporting-object/use-edit-toggle';
import { VariogramUpdateForm } from '~/components/upload/supporting-object/variogram/variogram-update-form';
import { WellLogUpdateForm } from '~/components/upload/supporting-object/well-log/well-log-update-form';
import { assertExhaustive } from '~/utils/common';
import type { RefetchQueries } from '~/utils/graphql';

type SOItem =
  | SoManagerCrossSectionPartsFragment
  | SoManagerFaciesPartsFragment
  | SoManagerSedimentaryLogPartsFragment
  | SoManagerWellLogPartsFragment
  | SoManagerProductionPartsFragment
  | SoManagerReservoirModelPartsFragment
  | SoManagerTrainingImagePartsFragment
  | SoManagerVariogramPartsFragment
  | SoManagerGigaPanPartsFragment;

export function SOManagerItem({
  item,
  parent,
  refetchQueries,
}: {
  item: SOItem;
  parent: SOParent;
  refetchQueries: PureQueryOptions[];
}) {
  const { isEditing, setIsEditing, EditButtonToggle } = useEditToggle();

  function onUpdateSuccess() {
    setIsEditing(false);
  }

  const managerProps = { item, refetchQueries };

  const hasPicturesOrFiles = 'pictures' in item || 'files' in item;
  const hasGeoreferences = 'georeferences' in item;

  return (
    <PublishedContainer
      published={item.lifecycleStatus === LifecycleStatus.Published}
    >
      <Panel>
        <Panel.Heading className="flex justify-between items-start gap-4">
          <Panel.Title>
            {item.name} <Badge color="ghost">{item.id}</Badge>
          </Panel.Title>

          <div className="space-x-1 shrink-0">
            <TogglePublishedButton entity={item} />
            <EditButtonToggle />
            <DeleteSOButton entity={item} refetchQueries={refetchQueries} />
          </div>
        </Panel.Heading>

        <Panel.Body>
          {isEditing && (
            <UpdateForm
              item={item}
              parent={parent}
              onUpdateSuccess={onUpdateSuccess}
            />
          )}

          {!isEditing && (
            <div className="space-y-4">
              <DetailsDisplay item={item} />

              {hasPicturesOrFiles && <hr />}
              <div className="grid lg:grid-cols-3 gap-4">
                <div className="lg:col-span-2">
                  <PictureManagerByType {...managerProps} />
                </div>
                <div>
                  <FileManagerByType {...managerProps} />
                </div>
              </div>

              {hasGeoreferences && <hr />}
              <GeoreferenceManagerByType {...managerProps} />
            </div>
          )}
        </Panel.Body>
      </Panel>
    </PublishedContainer>
  );
}

function UpdateForm({
  item,
  parent,
  onUpdateSuccess,
}: {
  item: SOItem;
  parent: SOParent;
  onUpdateSuccess: () => unknown;
}) {
  const typeName = item.__typename;
  invariant(typeName);

  const updateProps = {
    ...parent,
    onUpdateSuccess,
  };

  switch (typeName) {
    case 'CrossSection':
      return <CrossSectionUpdateForm {...updateProps} crossSection={item} />;
    case 'FaciesSchema':
      return <FaciesUpdateForm {...updateProps} facies={item} />;
    case 'SedimentaryLog':
      return <SedlogUpdateForm {...updateProps} sedlog={item} />;
    case 'WellLog':
      return <WellLogUpdateForm {...updateProps} wellLog={item} />;
    case 'Production':
      return <ProductionUpdateForm {...updateProps} production={item} />;
    case 'ReservoirModel':
      return (
        <ReservoirModelUpdateForm {...updateProps} reservoirModel={item} />
      );
    case 'TrainingImage':
      return <TrainingImageUpdateForm {...updateProps} trainingImage={item} />;
    case 'Variogram':
      return <VariogramUpdateForm {...updateProps} variogram={item} />;
    case 'GigaPan':
      return <GigaPanUpdateForm {...updateProps} gigaPan={item} />;
    default:
      throw assertExhaustive(typeName);
  }
}

function DetailsDisplay({ item }: { item: SOItem }) {
  const typeName = item.__typename;
  invariant(typeName);

  switch (typeName) {
    case 'CrossSection':
      return <CrossSectionDetails crossSection={item} />;
    case 'FaciesSchema':
      return <FaciesDetails facies={item} />;
    case 'SedimentaryLog':
      return <SedlogDetails sedlog={item} />;
    case 'WellLog':
      return <WellLogDetails wellLog={item} />;
    case 'Production':
      return <ProductionDetails production={item} />;
    case 'ReservoirModel':
      return <ReservoirModelDetails reservoirModel={item} />;
    case 'TrainingImage':
      return <TrainingImageDetails trainingImage={item} />;
    case 'Variogram':
      return <VariogramDetails variogram={item} />;
    case 'GigaPan':
      return <GigaPanDetails gigaPan={item} />;
    default:
      throw assertExhaustive(typeName);
  }
}

function PictureManagerByType({
  item,
  refetchQueries,
}: {
  item: SOItem;
  refetchQueries: RefetchQueries;
}) {
  const typeName = item.__typename;
  invariant(typeName);

  const pmProps = {
    parentId: parseInt(item.id),
    refetchQueries: refetchQueries,
  };

  switch (typeName) {
    case 'CrossSection':
      return (
        <SOPictureManager
          {...pmProps}
          parentType={PictureInitialParentType.CrossSection}
          pictures={item.pictures}
        />
      );
    case 'FaciesSchema':
      return (
        <SOPictureManager
          {...pmProps}
          parentType={PictureInitialParentType.Facies}
          pictures={item.pictures}
        />
      );
    case 'SedimentaryLog':
      return (
        <SOPictureManager
          {...pmProps}
          parentType={PictureInitialParentType.SedimentaryLog}
          pictures={item.pictures}
        />
      );
    case 'WellLog':
      return (
        <SOPictureManager
          {...pmProps}
          parentType={PictureInitialParentType.WellLog}
          pictures={item.pictures}
        />
      );
    case 'Production':
      return (
        <SOPictureManager
          {...pmProps}
          parentType={PictureInitialParentType.Production}
          pictures={item.pictures}
        />
      );
    case 'ReservoirModel':
      return (
        <SOPictureManager
          {...pmProps}
          parentType={PictureInitialParentType.ReservoirModel}
          pictures={item.pictures}
        />
      );
    case 'TrainingImage':
      return (
        <SOPictureManager
          {...pmProps}
          parentType={PictureInitialParentType.TrainingImage}
          pictures={item.pictures}
        />
      );
    case 'Variogram':
      return (
        <SOPictureManager
          {...pmProps}
          parentType={PictureInitialParentType.Variogram}
          pictures={item.pictures}
        />
      );
    case 'GigaPan':
      return (
        <SOPictureManager
          {...pmProps}
          parentType={PictureInitialParentType.GigaPan}
          pictures={item.pictures}
        />
      );
    default:
      throw assertExhaustive(typeName);
  }
}

function FileManagerByType({
  item,
  refetchQueries,
}: {
  item: SOItem;
  refetchQueries: RefetchQueries;
}) {
  const typeName = item.__typename;
  invariant(typeName);

  const fmProps = {
    parentId: parseInt(item.id),
    refetchQueries: refetchQueries,
  };

  switch (typeName) {
    case 'CrossSection':
      return (
        <SOFileManager
          {...fmProps}
          parentType={FileParentType.CrossSection}
          files={item.files}
        />
      );
    case 'SedimentaryLog':
      return (
        <SOFileManager
          {...fmProps}
          parentType={FileParentType.SedimentaryLog}
          files={item.files}
        />
      );
    case 'WellLog':
      return (
        <SOFileManager
          {...fmProps}
          parentType={FileParentType.WellLog}
          files={item.files}
        />
      );
    case 'Production':
      return (
        <SOFileManager
          {...fmProps}
          parentType={FileParentType.Production}
          files={item.files}
        />
      );
    case 'ReservoirModel':
      return (
        <SOFileManager
          {...fmProps}
          parentType={FileParentType.ReservoirModel}
          files={item.files}
        />
      );
    case 'TrainingImage':
      return (
        <SOFileManager
          {...fmProps}
          parentType={FileParentType.TrainingImage}
          files={item.files}
        />
      );
    case 'Variogram':
      return (
        <SOFileManager
          {...fmProps}
          parentType={FileParentType.Variogram}
          files={item.files}
        />
      );
    case 'FaciesSchema':
    case 'GigaPan':
      return null;
    default:
      throw assertExhaustive(typeName);
  }
}

function GeoreferenceManagerByType({
  item,
  refetchQueries,
}: {
  item: SOItem;
  refetchQueries: RefetchQueries;
}) {
  const typeName = item.__typename;
  invariant(typeName);

  const gmProps = {
    parentId: parseInt(item.id),
    refetchQueries: refetchQueries,
  };

  switch (typeName) {
    case 'CrossSection':
      return (
        <SOGeoreferenceManager
          {...gmProps}
          parentType={GeoreferenceParentType.CrossSection}
          georeferences={item.georeferences}
        />
      );
    case 'SedimentaryLog':
      return (
        <SOGeoreferenceManager
          {...gmProps}
          parentType={GeoreferenceParentType.SedimentaryLog}
          georeferences={item.georeferences}
        />
      );
    case 'WellLog':
      return (
        <SOGeoreferenceManager
          {...gmProps}
          parentType={GeoreferenceParentType.WellLog}
          georeferences={item.georeferences}
        />
      );
    case 'GigaPan':
      return (
        <SOGeoreferenceManager
          {...gmProps}
          parentType={GeoreferenceParentType.GigaPan}
          georeferences={item.georeferences}
        />
      );
    case 'FaciesSchema':
    case 'Production':
    case 'ReservoirModel':
    case 'TrainingImage':
    case 'Variogram':
      return null;
    default:
      throw assertExhaustive(typeName);
  }
}
