import { useQuery } from '@apollo/client';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { KeyParametersManager } from '~/components/upload/key-parameters-v4/key-parameters-manager';
import { useRouteParam } from '~/hooks/routing';
import { filterUnique } from '~/utils/common';
import { createRefetchQueries } from '~/utils/graphql';

const UPLOAD_STUDY_KEY_PARAMETERS_PAGE = graphql(`
  query UploadStudyKeyParametersPage($id: ID!) {
    studyGet(id: $id) {
      id
      keyParameters {
        ...keyParametersParts
      }
      outcrops {
        id
        geologyType
      }
    }
  }
`);

export default function UpdateStudyKeyParametersRoute() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const studyId = useRouteParam('studyId', parseInt);

  const { data, loading } = useQuery(UPLOAD_STUDY_KEY_PARAMETERS_PAGE, {
    variables: { id: studyId },
  });

  const refetchQueries = createRefetchQueries([
    {
      query: UPLOAD_STUDY_KEY_PARAMETERS_PAGE,
      variables: { id: studyId },
    },
  ]);

  const study = data?.studyGet;

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  const geologyTypes = study.outcrops
    .flatMap(oc => oc.geologyType ?? [])
    .filter(filterUnique);

  return (
    <KeyParametersManager
      parent={{ studyId: parseInt(study.id) }}
      keyParameters={study.keyParameters}
      geologyTypes={geologyTypes}
      refetchQueries={refetchQueries}
    />
  );
}
