import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { useZodForm } from '~/utils/forms';
import type { RefetchQueries } from '~/utils/graphql';
import {
  initialLiterature,
  literatureSchema,
} from '~/utils/modules/literatureReference';
import { LiteratureFormFields } from './literature-form-fields';

export const CREATE_LITERATURE_REFERENCE = graphql(`
  mutation CreateLiteratureReference($input: LiteratureReferenceCreateInput!) {
    literatureReferenceCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

export function CreateLiteratureForm({
  studyId,
  refetchQueries,
  onCreateSuccess,
}: {
  studyId: number;
  refetchQueries: RefetchQueries;
  onCreateSuccess: () => unknown;
}) {
  const [createLiteratureReference, { loading, error }] = useMutation(
    CREATE_LITERATURE_REFERENCE,
    { refetchQueries },
  );

  const form = useZodForm({
    schema: literatureSchema,
    values: initialLiterature(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await createLiteratureReference({
        variables: {
          input: {
            studyId,
            ...values,
          },
        },
      });

      toast.success('Literature reference saved successfully.');
      onCreateSuccess();
      form.reset(initialLiterature());
    } catch (err) {
      console.log('Error saving literature reference', err);
      toast.error('There was a problem saving the literature reference.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <LiteratureFormFields studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
