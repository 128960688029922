import { useQuery } from '@apollo/client';
import type { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { graphql } from '~/apollo/generated/v4';
import { Select } from '~/components/ui/forms/Select';
import { TextInput } from '~/components/ui/forms/text-input';
import type { AgeFormValues } from '~/utils/modules/lithostrat-age-v4';

const AGE_HIERARCHY = graphql(`
  query AgeHierarchy {
    ageHierarchy {
      eons {
        name
        eras {
          name
          systems {
            name
            series {
              name
              stages {
                name
              }
            }
          }
        }
      }
    }
  }
`);

const hierarchyOrder = ['eon', 'era', 'system', 'series', 'stage'] as const;
type HierarchyKey = (typeof hierarchyOrder)[number];

export function LithostratAgeFormFieldsV4<TPrefix extends string>({
  fieldNamePrefix,
}: {
  fieldNamePrefix?: TPrefix;
}) {
  const { watch, setValue, register } =
    useFormContext<Record<string, string>>();

  function prefix<Key extends keyof AgeFormValues>(key: Key) {
    return key === undefined ? key : `${fieldNamePrefix}.${key}`;
  }

  const { data } = useQuery(AGE_HIERARCHY);

  const eon = watch(prefix('eon'));
  const era = watch(prefix('era'));
  const system = watch(prefix('system'));
  const series = watch(prefix('series'));
  const stage = watch(prefix('stage'));

  const eons = data?.ageHierarchy.eons ?? [];
  const eras = eons.find(e => e.name === eon)?.eras ?? [];
  const systems = eras.find(e => e.name === era)?.systems ?? [];
  const serieses = systems.find(sy => sy.name === system)?.series ?? [];
  const stages = serieses.find(ser => ser.name === series)?.stages ?? [];

  const handleChange =
    (field: HierarchyKey) => (event: ChangeEvent<HTMLSelectElement>) => {
      setValue(prefix(field), event.target.value);
      const fieldIndex = hierarchyOrder.findIndex(f => f === field);
      hierarchyOrder.forEach((f, i) => {
        if (i > fieldIndex) {
          setValue(prefix(f), '');
        }
      });
    };

  return (
    <div className="space-y-4">
      <Select
        name="eon"
        label="Eon"
        value={eon}
        onChange={handleChange('eon')}
        options={eons.map(eon => eon.name)}
        disabled={!eons.length}
      />
      <Select
        name="era"
        label="Era"
        value={era}
        onChange={handleChange('era')}
        options={eras.map(era => era.name)}
        disabled={!eras.length || !eon}
      />
      <Select
        name="system"
        label="System"
        value={system}
        onChange={handleChange('system')}
        options={systems.map(system => system.name)}
        disabled={!systems.length || !era}
      />
      <Select
        name="series"
        label="Series"
        value={series}
        onChange={handleChange('series')}
        options={serieses.map(series => series.name)}
        disabled={!serieses.length || !system}
      />
      <Select
        name="stage"
        label="Stage"
        value={stage}
        onChange={handleChange('stage')}
        options={stages.map(stage => stage.name)}
        disabled={!stages.length || !series}
      />
      <TextInput {...register(prefix('comments'))} label="Comments" />
    </div>
  );
}
