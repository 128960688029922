import { useQuery } from '@apollo/client';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SOManagerV4 } from '~/components/upload/supporting-object/so-manager';
import { useRouteParam } from '~/hooks/routing';
import { createRefetchQueries } from '~/utils/graphql';

export const UPLOAD_STUDY_PANORAMAS_PAGE = graphql(`
  query UploadStudyPanoramasPage($id: ID!) {
    studyGet(id: $id) {
      id
      gigaPans {
        ...soManagerGigaPanParts
      }
    }
  }
`);

export default function UploadStudyPanoramasPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

export function PageInner() {
  const studyId = useRouteParam('studyId', parseInt);

  const { data, loading } = useQuery(UPLOAD_STUDY_PANORAMAS_PAGE, {
    variables: { id: studyId },
  });

  const study = data?.studyGet;

  const refetchQueries = createRefetchQueries([
    {
      query: UPLOAD_STUDY_PANORAMAS_PAGE,
      variables: { id: studyId },
    },
  ]);

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <SOManagerV4
      soType="GIGA_PAN"
      items={study.gigaPans}
      parent={{ studyId }}
      refetchQueries={refetchQueries}
    />
  );
}
