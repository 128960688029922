import { gql } from '~/apollo/client-v3';

export const OUTCROP_ADDTL_FIELDS_ENUMS = gql`
  query OutcropAddtlFieldsEnums {
    netToGross: outcropEnumerations(type: NET_TO_GROSS) {
      values
    }
    systemsTract: outcropEnumerations(type: SYSTEMS_TRACT) {
      values
    }
    shorelineTractory: outcropEnumerations(type: SHORELINE_TRACTORY) {
      values
    }
    duneShape: outcropEnumerations(type: DUNE_SHAPE) {
      values
    }
    channelMorphology: outcropEnumerations(type: CHANNEL_MORPHOLOGY) {
      values
    }
    riverProfileLocation: outcropEnumerations(type: RIVER_PROFILE_LOCATION) {
      values
    }
    dominantLithology: outcropEnumerations(type: DOMINANT_LITHOLOGY) {
      values
    }
    waterTemperature: outcropEnumerations(type: WATER_TEMPERATURE) {
      values
    }
    diageneticProcess: outcropEnumerations(type: DIAGENETIC_PROCESS) {
      values
    }
    diageneticSetting: outcropEnumerations(type: DIAGENETIC_SETTING) {
      values
    }
    diageneticGeometry: outcropEnumerations(type: DIAGENETIC_GEOMETRY) {
      values
    }
    tectonicSetting: outcropEnumerations(type: TECTONIC_SETTING) {
      values
    }
    synSedimentaryDeformation: outcropEnumerations(
      type: SYN_SEDIMENTARY_DEFORMATION
    ) {
      values
    }
    faultRocksMembranes: outcropEnumerations(type: FAULT_ROCKS_MEMBRANES) {
      values
    }
    interactionNetwork: outcropEnumerations(type: INTERACTION_NETWORK) {
      values
    }
    reactivation1stPhase: outcropEnumerations(type: REACTIVATION) {
      values
    }
    reactivation2ndPhase: outcropEnumerations(type: REACTIVATION) {
      values
    }
    symmetryGeometry: outcropEnumerations(type: SYMMETRY_GEOMETRY) {
      values
    }
    multipleFolds: outcropEnumerations(type: MULTIPLE_FOLDS) {
      values
    }
    secondaryStructures: outcropEnumerations(type: SECONDARY_STRUCTURES) {
      values
    }
    lateralAggregation: outcropEnumerations(type: LATERAL_AGGREGATION) {
      values
    }
  }
`;
