import { faMap, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import type { PointInput } from '~/apollo/generated/v4/graphql';

export function PreviewMap({ location }: { location: PointInput | null }) {
  if (!location) {
    return (
      <div className="w-full h-36 bg-slate-100 rounded-md flex items-center justify-center">
        <FontAwesomeIcon
          icon={faMap}
          className="text-5xl text-slate-300 ml-4"
        />
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="text-3xl text-slate-400 -ml-5 -mt-10"
        />
      </div>
    );
  }

  return (
    <GoogleMap
      center={{ lat: location.latitude, lng: location.longitude }}
      zoom={10}
      mapContainerClassName="w-full h-36 rounded-md"
      options={{
        fullscreenControl: false,
        zoomControl: false,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
      }}
    >
      <MarkerF position={{ lat: location.latitude, lng: location.longitude }} />
    </GoogleMap>
  );
}
