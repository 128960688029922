import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type { LiteraturePartsFragment } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { useZodForm } from '~/utils/forms';
import {
  initialLiterature,
  literatureSchema,
} from '~/utils/modules/literatureReference';
import { LiteratureFormFields } from './literature-form-fields';

export const UPDATE_LITERATURE_REFERENCE = graphql(`
  mutation UpdateLiteratureReference(
    $id: ID!
    $input: LiteratureReferenceUpdateInput!
  ) {
    literatureReferenceUpdate(id: $id, input: $input) {
      result {
        id
      }
    }
  }
`);

type Props = {
  studyId: number;
  literature: LiteraturePartsFragment;
  onUpdateSuccess: () => unknown;
};

export function UpdateLiteratureForm({
  studyId,
  literature,
  onUpdateSuccess,
}: Props) {
  const [updateLiterature, { loading, error }] = useMutation(
    UPDATE_LITERATURE_REFERENCE,
  );

  const form = useZodForm({
    schema: literatureSchema,
    values: initialLiterature(literature),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateLiterature({
        variables: {
          id: literature.id,
          input: values,
        },
      });

      toast.success('Literature reference saved successfully.');
      onUpdateSuccess();
    } catch (err) {
      console.log('Error saving literature reference', err);
      toast.error('There was a problem saving the literature reference.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <LiteratureFormFields studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
