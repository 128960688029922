import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { graphql } from '~/apollo/generated/v4';
import type {
  SoManagerCrossSectionPartsFragment,
  SoManagerFaciesPartsFragment,
  SoManagerGigaPanPartsFragment,
  SoManagerProductionPartsFragment,
  SoManagerReservoirModelPartsFragment,
  SoManagerSedimentaryLogPartsFragment,
  SoManagerTrainingImagePartsFragment,
  SoManagerVariogramPartsFragment,
  SoManagerWellLogPartsFragment,
} from '~/apollo/generated/v4/graphql';
import { Confirm } from '~/components/common/Confirm';
import { Button } from '~/components/ui/button';
import { assertExhaustive } from '~/utils/common';
import { ucwords } from '~/utils/text';

export const DELETE_CROSS_SECTION = graphql(`
  mutation DeleteCrossSection($id: ID!) {
    crossSectionDelete(id: $id) {
      result {
        id
      }
    }
  }
`);
export const DELETE_FACIES = graphql(`
  mutation DeleteFacies($id: ID!) {
    faciesSchemaDelete(id: $id) {
      result {
        id
      }
    }
  }
`);
export const DELETE_SEDLOG = graphql(`
  mutation DeleteSedlog($id: ID!) {
    sedimentaryLogDestroy(id: $id) {
      result {
        id
      }
    }
  }
`);
export const DELETE_WELL_LOG = graphql(`
  mutation DeleteWellLog($id: ID!) {
    wellLogDelete(id: $id) {
      result {
        id
      }
    }
  }
`);
export const DELETE_PRODUCTION = graphql(`
  mutation DeleteProduction($id: ID!) {
    productionDelete(id: $id) {
      result {
        id
      }
    }
  }
`);
export const DELETE_RES_MODEL = graphql(`
  mutation DeleteResModel($id: ID!) {
    reservoirModelDelete(id: $id) {
      result {
        id
      }
    }
  }
`);
export const DELETE_TRAINING_IMAGE = graphql(`
  mutation DeleteTrainingImage($id: ID!) {
    trainingImageDelete(id: $id) {
      result {
        id
      }
    }
  }
`);
export const DELETE_VARIOGRAM = graphql(`
  mutation DeleteVariogram($id: ID!) {
    variogramDelete(id: $id) {
      result {
        id
      }
    }
  }
`);
export const DELETE_GIGA_PAN = graphql(`
  mutation DeleteGigaPan($id: ID!) {
    gigaPanDelete(id: $id) {
      result {
        id
      }
    }
  }
`);

type DeletableSOEntity =
  | SoManagerCrossSectionPartsFragment
  | SoManagerFaciesPartsFragment
  | SoManagerSedimentaryLogPartsFragment
  | SoManagerWellLogPartsFragment
  | SoManagerProductionPartsFragment
  | SoManagerReservoirModelPartsFragment
  | SoManagerTrainingImagePartsFragment
  | SoManagerVariogramPartsFragment
  | SoManagerGigaPanPartsFragment;

export function DeleteSOButton({
  entity,
  refetchQueries,
}: {
  entity: DeletableSOEntity;
  refetchQueries: PureQueryOptions[];
}) {
  const [deleteCrossSection, { loading: loadingCrossSection }] = useMutation(
    DELETE_CROSS_SECTION,
    { refetchQueries },
  );
  const [deleteFacies, { loading: loadingFacies }] = useMutation(
    DELETE_FACIES,
    { refetchQueries },
  );
  const [deleteSedlog, { loading: loadingSedlog }] = useMutation(
    DELETE_SEDLOG,
    { refetchQueries },
  );
  const [deleteWellLog, { loading: loadingWellLog }] = useMutation(
    DELETE_WELL_LOG,
    { refetchQueries },
  );
  const [deleteProduction, { loading: loadingProduction }] = useMutation(
    DELETE_PRODUCTION,
    { refetchQueries },
  );
  const [deleteResModel, { loading: loadingResModel }] = useMutation(
    DELETE_RES_MODEL,
    { refetchQueries },
  );
  const [deleteTrainingImage, { loading: loadingTrainingImage }] = useMutation(
    DELETE_TRAINING_IMAGE,
    { refetchQueries },
  );
  const [deleteVariogram, { loading: loadingVariogram }] = useMutation(
    DELETE_VARIOGRAM,
    { refetchQueries },
  );
  const [deleteGigaPan, { loading: loadingGigaPan }] = useMutation(
    DELETE_GIGA_PAN,
    { refetchQueries },
  );

  function soLabel() {
    switch (entity.__typename) {
      case undefined:
        return '';
      default:
        return ucwords(entity.__typename);
    }
  }

  function deleteMutation() {
    const typeName = entity.__typename;
    invariant(typeName);

    const mutationArgs = { variables: { id: entity.id } };

    switch (typeName) {
      case 'CrossSection':
        return deleteCrossSection(mutationArgs);
      case 'FaciesSchema':
        return deleteFacies(mutationArgs);
      case 'SedimentaryLog':
        return deleteSedlog(mutationArgs);
      case 'WellLog':
        return deleteWellLog(mutationArgs);
      case 'Production':
        return deleteProduction(mutationArgs);
      case 'ReservoirModel':
        return deleteResModel(mutationArgs);
      case 'TrainingImage':
        return deleteTrainingImage(mutationArgs);
      case 'Variogram':
        return deleteVariogram(mutationArgs);
      case 'GigaPan':
        return deleteGigaPan(mutationArgs);
      default:
        throw assertExhaustive(typeName);
    }
  }

  async function handleDelete() {
    try {
      await deleteMutation();
      toast.info(`${soLabel()} deleted.`);
    } catch (err) {
      console.log(`Error deleting ${soLabel()}`, err);
      toast.error(`There was a problem deleting the ${soLabel()}.`);
    }
  }

  const loading =
    loadingCrossSection ||
    loadingFacies ||
    loadingSedlog ||
    loadingWellLog ||
    loadingProduction ||
    loadingResModel ||
    loadingTrainingImage ||
    loadingVariogram ||
    loadingGigaPan;

  return (
    <Confirm
      onConfirm={handleDelete}
      text={`The selected ${soLabel()} and all attached files, pictures, georeferences, etc. will be permanently deleted.`}
    >
      {confirmDelete => (
        <Button
          type="button"
          onClick={confirmDelete}
          color="ghost"
          size="xs"
          loading={loading}
        >
          <FontAwesomeIcon icon={faTrash} /> Delete
        </Button>
      )}
    </Confirm>
  );
}
