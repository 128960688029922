import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import {
  faCancel,
  faMapMarkerAlt,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  LifecycleStatus,
  type UploadOutcropFieldPicturesPageQuery,
} from '~/apollo/generated/v4/graphql';
import { Panel } from '~/components/common/Panel';
import { PublishedContainer } from '~/components/common/Published';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';
import { DeleteFieldPicture } from '~/components/upload/supporting-object/field-picture/delete-field-picture';
import { UpdateFieldPictureForm } from '~/components/upload/supporting-object/field-picture/update-field-picture-form';
import { SODetailText } from '~/components/upload/supporting-object/so-detail-text';
import { TogglePublishedButton } from '~/components/upload/supporting-object/toggle-published-button';
import type { RefetchQueries } from '~/utils/graphql';

type Outcrop = NonNullable<UploadOutcropFieldPicturesPageQuery['outcropGet']>;
type FieldPicture = Outcrop['fieldPictures'][number];

export function FieldPictureCard({
  fieldPicture,
  refetchQueries,
}: {
  fieldPicture: FieldPicture;
  refetchQueries: RefetchQueries;
}) {
  const [cardMode, setCardMode] = useState<'display' | 'edit'>('display');

  return (
    <PublishedContainer
      published={fieldPicture.lifecycleStatus === LifecycleStatus.Published}
    >
      <Panel>
        <Panel.Heading className="flex items-center justify-between gap-4">
          <Panel.Title>{fieldPicture.file.name}</Panel.Title>
          <div className="shrink-0 space-x-1">
            <LocationAccuracyIndicator
              approximate={fieldPicture.locationApproximate}
            />

            <TogglePublishedButton entity={fieldPicture} />

            <Button
              type="button"
              className="btn btn-xs btn-ghost gap-1"
              onClick={() =>
                setCardMode(cardMode === 'edit' ? 'display' : 'edit')
              }
              startIcon={
                <FontAwesomeIcon
                  icon={cardMode === 'edit' ? faCancel : faPencil}
                />
              }
            >
              {cardMode === 'edit' ? 'Cancel' : 'Edit'}
            </Button>

            <DeleteFieldPicture
              fieldPictureId={parseInt(fieldPicture.id)}
              refetchQueries={refetchQueries}
            />
          </div>
        </Panel.Heading>

        <Panel.Body>
          {cardMode === 'edit' && (
            <EditMode
              fieldPicture={fieldPicture}
              onUpdateSuccess={() => setCardMode('display')}
            />
          )}
          {cardMode === 'display' && (
            <DisplayModeCard fieldPicture={fieldPicture} />
          )}
        </Panel.Body>
      </Panel>
    </PublishedContainer>
  );
}

function DisplayModeCard({ fieldPicture }: { fieldPicture: FieldPicture }) {
  return (
    <div className="flex gap-4">
      <div className="w-32">
        <div
          className="aspect-square w-full border border-slate-200 bg-cover bg-center"
          style={{
            backgroundImage: `url(${fieldPicture.file.signedUrl})`,
          }}
        />
      </div>

      <div className="grow">
        <SODetailText label="Author" value={fieldPicture.author} />
        <SODetailText label="Description" value={fieldPicture.description} />
      </div>
    </div>
  );
}

function EditMode({
  fieldPicture,
  onUpdateSuccess,
}: {
  fieldPicture: FieldPicture;
  onUpdateSuccess: () => unknown;
}) {
  return (
    <div>
      <div className="flex justify-between items-start gap-6">
        <div className="space-y-6 text-center">
          <img
            src={fieldPicture.file.signedUrl}
            className="w-48 h-auto shadow-sm border border-slate-200"
            alt={fieldPicture.file.name ?? 'Field picture image'}
          />
        </div>

        <div className="grow space-y-4">
          <UpdateFieldPictureForm
            fieldPicture={fieldPicture}
            onUpdateSuccess={onUpdateSuccess}
          />
        </div>
      </div>
    </div>
  );
}

function LocationAccuracyIndicator({ approximate }: { approximate: boolean }) {
  if (approximate) {
    return (
      <Badge color="warning">
        <FontAwesomeIcon icon={faCircleDot} /> Approximate
      </Badge>
    );
  } else {
    return (
      <Badge color="success">
        <FontAwesomeIcon icon={faMapMarkerAlt} /> Precise
      </Badge>
    );
  }
}
