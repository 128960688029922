import { useQuery } from '@apollo/client';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import ReactSelect from 'react-select';
import type { GeologyType } from '~/apollo/generated/v4/graphql';
import { DEPOSITIONAL_HIERARCHY } from '~/components/upload/key-parameters-v4/operations';
import type { SelectOption } from '~/components/common/FormikField/FormikSelectField';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';
import { FormLabel } from '~/components/ui/forms/FormLabel';
import { filterUnique, rejectNil } from '~/utils/common';

function useHierarchyAEs(geologyTypes: GeologyType[]) {
  const { data, loading } = useQuery(DEPOSITIONAL_HIERARCHY);
  const hierarchy = data?.depositionalHierarchy;

  let aes: string[] = [];

  if (hierarchy) {
    aes = hierarchy
      .filter(geologyType => geologyTypes.includes(geologyType.name))
      .flatMap(geologyType =>
        geologyType.grossDepositionalEnvironment.flatMap(gde =>
          gde.depositionalEnvironment
            .flatMap(de => de.depositionalSubEnvironment)
            .flatMap(dse => dse.architecturalElement)
            .map(ae => ae.name),
        ),
      )
      .filter(rejectNil)
      .filter(filterUnique)
      .sort(R.ascend(ae => ae));
  }

  return {
    loading,
    aes,
  };
}

export function AEsManager({
  geologyTypes,
  name,
  architecturalElements,
}: {
  geologyTypes: GeologyType[];
  name: string;
  architecturalElements: string[];
}) {
  const { aes, loading } = useHierarchyAEs(geologyTypes);
  const { field } = useController({ name });

  const [selectedAE, setSelectedAE] = useState<SelectOption | null>(null);

  function handleAddAE() {
    if (!selectedAE) return;
    field.onChange(
      [...architecturalElements, selectedAE.value].filter(filterUnique),
    );
    setSelectedAE(null);
  }

  function handleRemoveAE(aeToRemove: string) {
    field.onChange(architecturalElements.filter(ae => ae !== aeToRemove));
  }

  const options: SelectOption[] = aes.map(value => ({
    value,
    label: value,
    isDisabled: architecturalElements.includes(value),
  }));

  if (loading) return <SpinnerPlaceholder />;

  return (
    <div className="space-y-4">
      <div className="flex gap-2 items-end">
        <div className="grow">
          <FormLabel label="Architectural Elements" name="aes" />
          <ReactSelect<SelectOption>
            name="aes"
            isSearchable
            options={options}
            onChange={setSelectedAE}
            value={selectedAE}
            classNames={{
              control: () => 'w-full h-8',
            }}
          />
        </div>
        <div className="flex-shrink">
          <Button type="button" onClick={handleAddAE} disabled={!selectedAE}>
            Add
          </Button>
        </div>
      </div>

      <div className="flex flex-wrap gap-2">
        {R.sortBy(ae => ae, architecturalElements).map(ae => (
          <Badge key={ae} color="ghost" className="gap-2 p-1 px-4">
            <span>{ae}</span>
            <Button
              size="xs"
              color="ghost"
              className="rounded-full"
              onClick={() => handleRemoveAE(ae)}
            >
              <FontAwesomeIcon icon={faRemove} />
            </Button>
          </Badge>
        ))}
      </div>
    </div>
  );
}
