import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type { GeologyType } from '~/apollo/generated/v4/graphql';
import { MiniModelFormFields } from '~/components/upload/supporting-object/mini-model/mini-model-form-fields';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { useZodForm } from '~/utils/forms';
import type { RefetchQueries } from '~/utils/graphql';
import {
  initialMiniModel,
  miniModelSchema,
} from '~/utils/modules/supporting-object';

const CREATE_MINI_MODEL = graphql(`
  mutation CreateMiniModel($input: MiniModelCreateInput!) {
    miniModelCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

export function CreateMiniModelForm({
  outcropId,
  geologyTypes,
  refetchQueries,
  onCreateSuccess,
}: {
  outcropId: number;
  geologyTypes: GeologyType[];
  refetchQueries: RefetchQueries;
  onCreateSuccess?: () => void;
}) {
  const [createMiniModel, { loading, error }] = useMutation(CREATE_MINI_MODEL, {
    refetchQueries,
  });

  const form = useZodForm({
    schema: miniModelSchema,
    values: initialMiniModel(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await createMiniModel({
        variables: {
          input: { ...values, outcropId },
        },
      });
      toast.success('Mini-model created successfully.');
      form.reset(initialMiniModel());
      if (onCreateSuccess) onCreateSuccess();
    } catch (err) {
      console.log('Error creating mini-model', err);
      toast.error(
        'There was a problem creating the mini-model. Please try again.',
      );
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <MiniModelFormFields geologyTypes={geologyTypes} />
        <HookFormErrors graphQLError={error} />

        <div className="text-center">
          <Button color="primary" loading={loading} disabled={loading}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
