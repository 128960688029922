import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { panelLifecycleVariant, SimplePanel } from '~/components/common/Panel';
import { Checkbox } from '~/components/ui/forms/Checkbox';
import { TagsInput } from '~/components/ui/forms/tags-input';
import { TextInput } from '~/components/ui/forms/text-input';
import { TinyMceInput } from '~/components/ui/forms/tinymce-input';
import { LifecycleStatusSelect } from '~/components/upload/lifecycle/lifecycle-status-select';
import { OutcropEnumFields } from '~/components/upload/outcrop/outcrop-enum-fields';
import { RegionSelect } from '~/components/upload/region/region-select-v4';
import { fieldLabel, type OutcropFormValues } from '~/utils/modules/outcrop-v4';

const REQUIRED = true;

const tinyMceFields = [
  ['description', REQUIRED],
  ['introduction'],
  ['environments'],
  ['stratigraphy'],
  ['diagenesisAndPetrophysicalProperties'],
  ['structuralGeology'],
  ['notablesAndAnalogues'],
  ['location', REQUIRED],
  ['safari'],
] satisfies [fieldName: keyof OutcropFormValues, required?: boolean][];

export function OutcropOverviewFormFields() {
  const { register, watch, setValue } = useFormContext<OutcropFormValues>();

  const outcropCategory = watch('outcropCategory');
  const lifecycleStatus = watch('lifecycleStatus');

  useEffect(() => {
    if (lifecycleStatus === LifecycleStatus.Draft) {
      setValue('isBeingPublished', false);
    } else {
      setValue('isBeingPublished', true);
    }
  }, [lifecycleStatus, setValue]);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <TextInput {...register('name')} label="Name" required />

        <Controller
          name="regionId"
          render={({ field }) => (
            <RegionSelect {...field} label="Region" required />
          )}
        />

        <OutcropEnumFields />

        <TagsInput name="keyWords" label="Keywords" mode="string" />

        <TextInput {...register('author')} label="Author" />

        {tinyMceFields.map(([name, required]) => (
          <TinyMceInput
            key={name}
            {...register(name)}
            label={fieldLabel(name, outcropCategory)}
            required={required}
          />
        ))}
      </div>

      <div className="space-y-4 mt-4">
        <SimplePanel
          title="Lifecycle"
          variant={panelLifecycleVariant(lifecycleStatus)}
        >
          <div className="space-y-2">
            <Controller
              name="lifecycleStatus"
              render={({ field }) => (
                <LifecycleStatusSelect {...field} label="Lifecycle Status" />
              )}
            />

            <Checkbox {...register('qcCompleted')} label="QC completed" />
          </div>
        </SimplePanel>
      </div>
    </div>
  );
}
