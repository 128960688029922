import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';

type Props = {
  shouldClear: boolean;
  setShouldClear: (val: boolean) => void;
};

export function ClearExistingOption({ shouldClear, setShouldClear }: Props) {
  const handleClick = () => setShouldClear(!shouldClear);

  return (
    <Panel variant={shouldClear ? 'error' : 'default'}>
      <Panel.Heading>
        <Panel.Title>Delete Existing Georeferences</Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <div className="space-y-2">
          <p>
            Use this option to replace the existing georeferences with the ones
            imported below.
          </p>
          <p>
            Note: For safety, existing georeferences will only be deleted if all
            imported georeferences are saved successfully.
          </p>

          <label className="label">
            <input
              type="checkbox"
              value="1"
              onChange={handleClick}
              checked={shouldClear}
              className="checkbox checked:checkbox-error"
            />
            <span className="label-text">Delete Existing Georeferences</span>
          </label>

          {shouldClear && (
            <div className="space-y-2 mt-4">
              <Heading level={5} className="text-error">
                WARNING
              </Heading>
              <p>
                All existing georeferences will be <b>permanently deleted</b>{' '}
                and replaced with the georeferences below. Proceed with caution!
              </p>
            </div>
          )}
        </div>
      </Panel.Body>
    </Panel>
  );
}
