import { Editor } from '@tinymce/tinymce-react';
import type { IProps as EditorProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { FormLabelProps } from '~/components/common/FormikField/FormLabel';
import { FormLabel } from '~/components/common/FormikField/FormLabel';
import { FieldErrors } from '~/components/ui/forms/FieldErrors';

const toolbar = [
  'undo redo',
  'styleselect fontsizeselect forecolor',
  'bold italic underline',
  'justifyleft justifycenter justifyright',
  'bullist numlist',
  'link unlink',
  'image',
  'code removeformat',
];

const defaultConfig: EditorProps['init'] = {
  branding: false,
  promotion: false,
  plugins: 'link table code image',
  paste_as_text: true,
  toolbar: toolbar.join(' | '),
  convert_urls: false,
  browser_spellcheck: true,
  contextmenu: false,
  sandbox_iframes: false,
};

export type TinyMceInputProps = {
  name: string;
  label?: FormLabelProps['label'];
  config?: EditorProps['init'];
  required?: boolean;
};

function TinyMceInputInner({
  name,
  label = null,
  config,
  required = false,
}: TinyMceInputProps) {
  const { watch, setValue } = useFormContext();
  const value = watch(name);

  const tinyMceConfig: EditorProps['init'] = {
    ...defaultConfig,
    ...config,
  };

  return (
    <div>
      <FormLabel name={name} label={label} required={required} />
      <Editor
        licenseKey="gpl"
        plugins={tinyMceConfig.plugins}
        initialValue={value}
        onBlur={e => setValue(name, e.target.getContent())}
        init={tinyMceConfig}
      />
      <FieldErrors name={name} />
    </div>
  );
}

export class TinyMceInput extends React.Component<TinyMceInputProps> {
  state = {
    caught: false,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log('Caught an error in TinyMCE', error);
    console.log('Error info:', errorInfo);
    this.setState({ caught: true });
  }

  render() {
    if (this.state.caught) {
      return (
        <div>
          There was a problem rendering this field, please reload the page.
        </div>
      );
    }

    return <TinyMceInputInner {...this.props} />;
  }
}
