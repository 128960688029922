import { useQuery } from '@apollo/client';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { STUDY_ENUMS } from '~/components/upload/study/study-operations';
import { UpdateStudyForm } from '~/components/upload/study/update-study-form';
import { useRouteParam } from '~/hooks/routing';

const UPLOAD_STUDY_UPDATE_PAGE = graphql(`
  query UploadStudyUpdatePage($id: ID!) {
    studyGet(id: $id) {
      ...studyUpdateParts
    }
  }
`);

export default function UploadStudyUpdateOverviewPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const studyId = useRouteParam('studyId', parseInt);

  const { data: studyData, loading: loadingStudy } = useQuery(
    UPLOAD_STUDY_UPDATE_PAGE,
    { variables: { id: studyId } },
  );

  const { data: enumsData, loading: loadingEnums } = useQuery(STUDY_ENUMS);

  const study = studyData?.studyGet;

  if (loadingStudy || loadingEnums) return <SpinnerPlaceholder />;
  if (!study || !enumsData) return <NotFound />;

  return <UpdateStudyForm study={study} studyEnums={enumsData} />;
}
