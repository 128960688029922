import { useQuery } from '@apollo/client';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { graphql } from '~/apollo/generated/v4';
import type {
  KeyParametersPartsFragment,
  OutcropAddtlFieldOptionsQuery,
} from '~/apollo/generated/v4/graphql';
import { GeologyType } from '~/apollo/generated/v4/graphql';
import { GeologyTypeIconV4 } from '~/components/common/icons/geology-type-icon-v4';
import { CollapsibleCard } from '~/components/ui/collapse';
import { ReactSelectInput } from '~/components/ui/forms/react-select-input';
import type { OutcropAdditionalFieldsFormValues } from '~/routes/upload/model/outcrop/$outcropId/additional-fields';
import { readableGqlEnum, ucwords } from '~/utils/text';

export const OUTCROP_ADDTL_FIELD_OPTIONS = graphql(`
  query OutcropAddtlFieldOptions {
    netToGross: outcropEnumerations(type: NET_TO_GROSS) {
      values
    }
    systemsTract: outcropEnumerations(type: SYSTEMS_TRACT) {
      values
    }
    shorelineTractory: outcropEnumerations(type: SHORELINE_TRACTORY) {
      values
    }
    duneShape: outcropEnumerations(type: DUNE_SHAPE) {
      values
    }
    channelMorphology: outcropEnumerations(type: CHANNEL_MORPHOLOGY) {
      values
    }
    riverProfileLocation: outcropEnumerations(type: RIVER_PROFILE_LOCATION) {
      values
    }
    dominantLithology: outcropEnumerations(type: DOMINANT_LITHOLOGY) {
      values
    }
    waterTemperature: outcropEnumerations(type: WATER_TEMPERATURE) {
      values
    }
    diageneticProcess: outcropEnumerations(type: DIAGENETIC_PROCESS) {
      values
    }
    diageneticSetting: outcropEnumerations(type: DIAGENETIC_SETTING) {
      values
    }
    diageneticGeometry: outcropEnumerations(type: DIAGENETIC_GEOMETRY) {
      values
    }
    tectonicSetting: outcropEnumerations(type: TECTONIC_SETTING) {
      values
    }
    synSedimentaryDeformation: outcropEnumerations(
      type: SYN_SEDIMENTARY_DEFORMATION
    ) {
      values
    }
    faultRocksMembranes: outcropEnumerations(type: FAULT_ROCKS_MEMBRANES) {
      values
    }
    interactionNetwork: outcropEnumerations(type: INTERACTION_NETWORK) {
      values
    }
    reactivation1stPhase: outcropEnumerations(type: REACTIVATION) {
      values
    }
    reactivation2ndPhase: outcropEnumerations(type: REACTIVATION) {
      values
    }
    symmetryGeometry: outcropEnumerations(type: SYMMETRY_GEOMETRY) {
      values
    }
    multipleFolds: outcropEnumerations(type: MULTIPLE_FOLDS) {
      values
    }
    secondaryStructures: outcropEnumerations(type: SECONDARY_STRUCTURES) {
      values
    }
    lateralAggregation: outcropEnumerations(type: LATERAL_AGGREGATION) {
      values
    }
  }
`);

function GeologyTypeSection({
  children,
  geologyType,
  enabled,
  visible,
  toggleVisibility,
}: {
  children: ReactNode;
  geologyType: GeologyType;
  enabled: boolean;
  visible: boolean;
  toggleVisibility: () => unknown;
}) {
  if (!enabled) {
    return null;
  }

  return (
    <CollapsibleCard
      open={visible}
      onToggle={toggleVisibility}
      title={
        <div className="flex gap-2 items-center">
          <GeologyTypeIconV4 geologyType={geologyType} className="w-6 h-6" />
          {readableGqlEnum(geologyType)}
        </div>
      }
    >
      <div className="grid lg:grid-cols-2 gap-4">{children}</div>
    </CollapsibleCard>
  );
}

export type OCAdditionalFieldKey = keyof Omit<
  OutcropAddtlFieldOptionsQuery,
  '__typename'
>;

export type OCAddtlField = {
  name: OCAdditionalFieldKey;
  multiple: boolean;
  label?: string;
  wikiId?: number;
};

function clasticFields(entity: 'outcrop' | 'ae'): OCAddtlField[] {
  const fields: OCAddtlField[] = [];

  if (entity === 'outcrop') {
    fields.push({ name: 'netToGross', multiple: false, wikiId: 71 });
  }

  const baseFields: OCAddtlField[] = [
    { name: 'systemsTract', multiple: true, wikiId: 39 },
    { name: 'shorelineTractory', multiple: true, wikiId: 37 },
    { name: 'duneShape', multiple: true, wikiId: 30 },
    { name: 'channelMorphology', multiple: true, wikiId: 25 },
    { name: 'riverProfileLocation', multiple: true, wikiId: 35 },
    { name: 'dominantLithology', multiple: true, wikiId: undefined },
    { name: 'lateralAggregation', multiple: true, wikiId: 82 },
  ];
  baseFields.forEach(f => fields.push(f));

  return fields;
}

function carbonateFields(): OCAddtlField[] {
  return [
    {
      name: 'waterTemperature',
      multiple: true,
      label: 'Water temp.',
      wikiId: 70,
    },
    { name: 'diageneticProcess', multiple: true, wikiId: 74 },
    { name: 'diageneticSetting', multiple: true, wikiId: 72 },
    { name: 'diageneticGeometry', multiple: true, wikiId: 73 },
    { name: 'systemsTract', multiple: true, wikiId: 39 },
    { name: 'dominantLithology', multiple: true, wikiId: undefined },
  ];
}

export type KPFields = Pick<
  KeyParametersPartsFragment,
  | 'grossDepositionalEnvironment'
  | 'depositionalEnvironment'
  | 'depositionalSubEnvironment'
  | 'architecturalElement'
>;

function structuralFields(kps: KPFields[]): OCAddtlField[] {
  const gdes = kps.map(kp => kp.grossDepositionalEnvironment);
  const hasClassification = (c: string) => gdes.find(gde => gde === c);

  const enumFields: OCAddtlField[] = [
    { name: 'tectonicSetting', multiple: true, wikiId: 72 },
    {
      name: 'synSedimentaryDeformation',
      multiple: true,
      label: 'Syn-sedimentary Deformation',
      wikiId: 73,
    },
    { name: 'dominantLithology', multiple: true },
  ];

  const appendFields = (fieldsToAppend: OCAddtlField[]) =>
    fieldsToAppend.forEach(f => enumFields.push(f));

  if (hasClassification('Faults/Fractures')) {
    appendFields([
      {
        name: 'faultRocksMembranes',
        multiple: true,
        label: 'Fault Rocks/Membranes',
        wikiId: 74,
      },
      {
        name: 'interactionNetwork',
        multiple: true,
        label: 'Interaction/Network',
        wikiId: 75,
      },
      {
        name: 'reactivation1stPhase',
        multiple: true,
        label: 'Reactivation 1st Phase',
        wikiId: 76,
      },
      {
        name: 'reactivation2ndPhase',
        multiple: true,
        label: 'Reactivation 2nd Phase',
        wikiId: 76,
      },
    ]);
  }

  if (hasClassification('Fold') || hasClassification('Fault-related fold')) {
    appendFields([
      {
        name: 'symmetryGeometry',
        multiple: true,
        label: 'Symmetry/Geometry',
        wikiId: 77,
      },
      { name: 'multipleFolds', multiple: true, wikiId: 78 },
      {
        name: 'secondaryStructures',
        multiple: true,
        label: 'Secondary Structures Related To Folds',
        wikiId: 79,
      },
    ]);
  }

  return enumFields;
}

export function OutcropAdditionalFields({
  entity,
  geologyTypes,
  keyParameters,
}: {
  entity: 'outcrop' | 'ae';
  geologyTypes: GeologyType[];
  keyParameters: KPFields[];
}) {
  const { control } = useFormContext<OutcropAdditionalFieldsFormValues>();
  const { data, loading } = useQuery(OUTCROP_ADDTL_FIELD_OPTIONS);

  type SectionName =
    | GeologyType.Clastic
    | GeologyType.Carbonate
    | GeologyType.Structural;

  const enabledSections: Record<SectionName, boolean> = {
    [GeologyType.Clastic]: geologyTypes.includes(GeologyType.Clastic),
    [GeologyType.Carbonate]: geologyTypes.includes(GeologyType.Carbonate),
    [GeologyType.Structural]: geologyTypes.includes(GeologyType.Structural),
  };

  const [visibleSections, setVisibleSections] = useState(enabledSections);

  const toggleSection = (sectionName: SectionName) => () =>
    setVisibleSections({
      ...visibleSections,
      [sectionName]: !visibleSections[sectionName],
    });

  const enumOptions = (fieldName: OCAdditionalFieldKey) => {
    return data?.[fieldName]?.values ?? [];
  };

  const mapField = ({ name, multiple, label }: OCAddtlField) => (
    <Controller
      key={name}
      control={control}
      name={name}
      render={({ field }) => (
        <ReactSelectInput
          {...field}
          label={label ?? ucwords(name)}
          options={enumOptions(name).map(value => ({ value, label: value }))}
          multiple={multiple}
          disabled={loading}
        />
      )}
    />
  );

  return (
    <>
      <GeologyTypeSection
        enabled={enabledSections[GeologyType.Clastic]}
        geologyType={GeologyType.Clastic}
        visible={visibleSections[GeologyType.Clastic]}
        toggleVisibility={toggleSection(GeologyType.Clastic)}
      >
        {clasticFields(entity).map(mapField)}
      </GeologyTypeSection>

      <GeologyTypeSection
        enabled={enabledSections[GeologyType.Carbonate]}
        geologyType={GeologyType.Carbonate}
        visible={visibleSections[GeologyType.Carbonate]}
        toggleVisibility={toggleSection(GeologyType.Carbonate)}
      >
        {carbonateFields().map(f => (
          <Controller
            key={f.name}
            control={control}
            name={f.name}
            render={({ field }) => (
              <ReactSelectInput
                key={f.name}
                {...field}
                label={f.label ?? ucwords(f.name)}
                options={enumOptions(f.name).map(value => ({
                  value,
                  label: value,
                }))}
                multiple={f.multiple}
                disabled={loading}
              />
            )}
          />
        ))}
      </GeologyTypeSection>

      <GeologyTypeSection
        enabled={enabledSections[GeologyType.Structural]}
        geologyType={GeologyType.Structural}
        visible={visibleSections[GeologyType.Structural]}
        toggleVisibility={toggleSection(GeologyType.Structural)}
      >
        {structuralFields(keyParameters).map(mapField)}
      </GeologyTypeSection>
    </>
  );
}
