import type { ComponentPropsWithoutRef, HTMLProps } from 'react';
import { cn } from '~/utils/common';

export function InputGroup({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <div {...props} className={cn('join w-full', className)}>
      {children}
    </div>
  );
}

type InputGroupAddonProps = HTMLProps<HTMLSpanElement> & {
  children: React.ReactNode;
  left?: boolean;
  right?: boolean;
};

function InputGroupAddon({
  children,
  className,
  left,
  right,
  ...props
}: InputGroupAddonProps) {
  if (!children) return null;

  return (
    <span
      {...props}
      className={cn(
        'join-item bg-slate-50 flex justify-center items-center py-1 px-4 text-sm',
        {
          'border border-t border-b border-slate-300': left || right,
          'border-l': left,
          'border-r': right,
        },
        className,
      )}
    >
      {children}
    </span>
  );
}

InputGroup.Addon = InputGroupAddon;
