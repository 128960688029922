import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import type { SoManagerTrainingImagePartsFragment } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { SOFormFields } from '~/components/upload/supporting-object/so-form-fields';
import type { SOParent } from '~/components/upload/supporting-object/so-manager';
import { UPDATE_TRAINING_IMAGE } from '~/components/upload/supporting-object/so-operations';
import { useZodForm } from '~/utils/forms';
import {
  initialTrainingImage,
  trainingImageSchema,
} from '~/utils/modules/supporting-object';

type Props = SOParent & {
  trainingImage: SoManagerTrainingImagePartsFragment;
  onUpdateSuccess?: () => unknown;
};

export function TrainingImageUpdateForm({
  trainingImage,
  studyId,
  onUpdateSuccess,
}: Props) {
  const [updateTrainingImage, { loading, error }] = useMutation(
    UPDATE_TRAINING_IMAGE,
  );

  const form = useZodForm({
    schema: trainingImageSchema,
    values: initialTrainingImage(trainingImage),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateTrainingImage({
        variables: {
          id: trainingImage.id,
          input: values,
        },
      });
      toast.success('Training image updated successfully.');
      form.reset();
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error updating training image', err);
      toast.error('There was a problem updating the training image.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <SOFormFields soType="TRAINING_IMAGE" studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="card-actions justify-end">
          <Button type="submit" loading={loading} color="primary">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
