import { faCancel, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import type {
  GeoreferenceParentType,
  GeoreferencePartsFragment,
} from '~/apollo/generated/v4/graphql';
import { Heading } from '~/components/common/Heading';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { Button } from '~/components/ui/button';
import { GeoreferenceManagerV4 } from '~/components/upload/georeference-v4/georeference-manager';
import type { RefetchQueries } from '~/utils/graphql';

export function SOGeoreferenceManager(props: {
  parentType: GeoreferenceParentType;
  parentId: number;
  georeferences: GeoreferencePartsFragment[];
  refetchQueries: RefetchQueries;
}) {
  const [isMgrVisible, setIsMgrVisible] = useState(false);

  const toggleMode = () => setIsMgrVisible(!isMgrVisible);

  const isGeoreferenceSet = props.georeferences.length > 0;

  return (
    <div className="space-y-2">
      <div className="flex justify-between gap-4">
        <Heading level={3}>Georeferences</Heading>
        <Button
          type="button"
          onClick={toggleMode}
          color={isMgrVisible ? 'ghost' : 'primary'}
          size="xs"
          startIcon={
            <FontAwesomeIcon icon={isMgrVisible ? faCancel : faMapMarkerAlt} />
          }
        >
          {isMgrVisible ? 'Close' : 'Manage Georeferences'}
        </Button>
      </div>

      {isMgrVisible && <GeoreferenceManagerV4 {...props} />}
      {!isMgrVisible && isGeoreferenceSet && (
        <div className="space-x-2 text-base">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="text-success" />
          <span>Georeference set</span>
        </div>
      )}
      {!isMgrVisible && !isGeoreferenceSet && (
        <NoItemsAlert show entity="georeferences" />
      )}
    </div>
  );
}
