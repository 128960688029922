import { graphql } from '~/apollo/generated/v4';

export const DEPOSITIONAL_HIERARCHY = graphql(`
  query DepositionalHierarchy {
    depositionalHierarchy {
      name
      grossDepositionalEnvironment {
        name
        depositionalEnvironment {
          name
          depositionalSubEnvironment {
            name
            architecturalElement {
              name
            }
          }
        }
      }
    }
  }
`);
