import { useMutation, useQuery, type PureQueryOptions } from '@apollo/client';
import type { Cesium3DTileset, Viewer } from 'cesium';
import {
  Cartesian3,
  Cartographic,
  Math as CesiumMath,
  Ellipsoid,
  HeadingPitchRoll,
} from 'cesium';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { z } from 'zod';
import type { UpdateVomRouteQuery } from '~/apollo/generated/v3/graphql';
import { graphql } from '~/apollo/generated/v4';
import type { PlaceableCesiumAssetDetailsQuery } from '~/apollo/generated/v4/graphql';
import {
  CesiumAssetState,
  CesiumAssetUtmHemisphere,
} from '~/apollo/generated/v4/graphql';
import type { LatLngHeightHPR } from '~/components/cesium/CesiumViewer';
import { CesiumViewer } from '~/components/cesium/CesiumViewer';
import type {
  CameraParams,
  TilesetParams,
} from '~/components/cesium/cesiumUtils';
import {
  getCamera,
  getCurrentZoomDistance,
  resetCameraOrientation,
  setDepthTest,
  TerrainProvider,
  updateTilesetModelMatrixWithPositionHpr,
} from '~/components/cesium/cesiumUtils';
import VomPlacementFields from '~/components/cesium/placementFields';
import type { UtmFormValues } from '~/components/cesium/utmFields';
import VomUtmFields from '~/components/cesium/utmFields';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useFullWidthContext } from '~/components/layout/fullWidthContext';
import { Button } from '~/components/ui/button';
import { Collapse } from '~/components/ui/collapse';
import { useUpdateVomOutletContext } from '~/routes/upload/vom/$vomId';
import { cn } from '~/utils/common';
import type { locationSchema } from '~/utils/modules/placement';
import { placementFormSchema, utmFormSchema } from '~/utils/modules/placement';
import { invariantNonNil } from '~/utils/validation';

type CesiumAsset = NonNullable<
  UpdateVomRouteQuery['virtualOutcropModelList'][number]['cesiumAsset']
>;

type PlacementProps = {
  vomId: number;
  cesiumAsset: CesiumAsset;
  state: CesiumAssetState;
  outcropId: number;
};

const defaultPlacement: LatLngHeightHPR = {
  latitude: 0,
  longitude: 0,
  height: 0,
  heading: 0,
  pitch: 0,
  roll: 0,
};

const defaultUtmPlacement: UtmFormValues = {
  northing: 0,
  easting: 0,
  zone: 0,
  hemisphere: 'north',
};

type CALocation = z.infer<typeof locationSchema>;

const outcropCAQuery = graphql(`
  query OutcropCAQuery($id: ID!) {
    outcropGet(id: $id) {
      id
      name
      virtualOutcropModels {
        id
        name
        cesiumAsset {
          ...cesiumAssetParts
          tilesetToken {
            token
          }
          location {
            ...cesiumLocationParts
          }
          defaultCamera {
            ...cesiumLocationParts
          }
        }
      }
    }
  }
`);

const placeableCesiumAssetDetailsQuery = graphql(`
  query PlaceableCesiumAssetDetails($cesiumAssetId: ID!) {
    cesiumAssetGet(id: $cesiumAssetId) {
      ...cesiumAssetParts
      utmData {
        ...cesiumUtmDataParts
      }
      defaultCamera {
        ...cesiumLocationParts
      }
      location {
        ...cesiumLocationParts
      }
    }
  }
`);

const saveTilesetClippingMutation = graphql(`
  mutation SaveTilesetClipping($id: ID!, $input: CesiumAssetSetClippingInput!) {
    cesiumAssetSetClipping(id: $id, input: $input) {
      result {
        id
        ...cesiumAssetParts
      }
    }
  }
`);

const savePlacementMutation = graphql(`
  mutation SaveCesiumAssetPlacement(
    $id: ID!
    $input: CesiumAssetPlaceAssetInput!
  ) {
    cesiumAssetPlaceAsset(id: $id, input: $input) {
      result {
        ...cesiumAssetParts
        location {
          ...cesiumLocationParts
        }
      }
    }
  }
`);

const saveUtmMutation = graphql(`
  mutation SaveCesiumAssetUtmData($id: ID!, $input: CesiumAssetSetUtmInput!) {
    cesiumAssetSetUtm(id: $id, input: $input) {
      result {
        ...cesiumAssetParts
        location {
          ...cesiumLocationParts
        }
      }
    }
  }
`);

const saveDefaultCameraMutation = graphql(`
  mutation SaveCesiumAssetDefaultCamera(
    $id: ID!
    $input: CesiumAssetSaveDefaultCameraInput!
  ) {
    cesiumAssetSaveDefaultCamera(id: $id, input: $input) {
      result {
        ...cesiumAssetParts
        defaultCamera {
          ...cesiumLocationParts
        }
      }
    }
  }
`);

const markAsCompleteMutation = graphql(`
  mutation MarkCesiumAssetComplete($id: ID!) {
    cesiumAssetMarkAsCompleted(id: $id) {
      result {
        ...cesiumAssetParts
      }
    }
  }
`);

export function PlaceableCompleteState({
  vomId,
  cesiumAssetId,
  state,
  refetchQueries,
}: {
  vomId: number;
  cesiumAssetId: number;
  state: CesiumAssetState;
  refetchQueries: PureQueryOptions[];
}) {
  const ctx = useUpdateVomOutletContext();
  const cesiumAsset = ctx.vom.cesiumAsset;
  const outcropId = ctx.vom.outcropId;

  return cesiumAsset && outcropId ? (
    <PlacementPage
      vomId={vomId}
      outcropId={outcropId}
      cesiumAsset={cesiumAsset}
      state={state}
    />
  ) : (
    <SpinnerPlaceholder />
  );
}

function PlacementPage({
  cesiumAsset,
  vomId,
  outcropId,
  state,
}: PlacementProps) {
  const [cesiumTileset, setCesiumTileset] = useState<Cesium3DTileset | null>(
    null,
  );
  const [initialized, setInitialized] = useState<Boolean>(false);

  const {
    loading: caLoading,
    error: caError,
    data: caData,
  } = useQuery(placeableCesiumAssetDetailsQuery, {
    variables: { cesiumAssetId: cesiumAsset.id },
  });

  const {
    loading: ocLoading,
    error: ocError,
    data: ocData,
  } = useQuery(outcropCAQuery, {
    variables: { id: outcropId },
  });

  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [cesiumViewer, setCesiumViewer] = useState<Viewer | null>(null);
  const [placement, setPlacement] = useState<LatLngHeightHPR>(defaultPlacement);
  const [utmPlacement, setUtmPlacement] =
    useState<UtmFormValues>(defaultUtmPlacement);
  const [collapsed, setCollapsed] = useState(true);
  const [cameraLocation, setCameraLocation] = useState<CALocation>();
  const [tilesetClipping, setTilesetClipping] = useState(false);
  const [cameraZoomLevel, setCameraZoomLevel] = useState(1);
  const { setIsFullWidth } = useFullWidthContext();

  const [saveClipping, { loading: loadingSaveClipping }] = useMutation(
    saveTilesetClippingMutation,
  );

  const [savePlacement, { loading: loadingSavePlacement }] = useMutation(
    savePlacementMutation,
  );

  const [saveUtm, { loading: loadingSaveUtm }] = useMutation(saveUtmMutation);

  const [saveDefaultCamera, { loading: loadingSaveDefaultCamera }] =
    useMutation(saveDefaultCameraMutation);

  const [markAsComplete, { loading: loadingMarkAsComplete }] = useMutation(
    markAsCompleteMutation,
  );

  const updateData = useCallback(
    (ca: NonNullable<PlaceableCesiumAssetDetailsQuery['cesiumAssetGet']>) => {
      console.log('Updated cesium asset', ca);
      const loc = ca.location;
      const defaultCamera = ca.defaultCamera;
      const utmData = ca.utmData;

      if (
        utmData &&
        utmData.utmNorthings &&
        utmData.utmEastings &&
        utmData.utmZone &&
        utmData.utmHemisphere
      ) {
        setUtmPlacement({
          northing: utmData.utmNorthings,
          easting: utmData.utmEastings,
          zone: utmData.utmZone,
          hemisphere: utmData.utmHemisphere,
        });
      } else {
        setCollapsed(false);
      }

      if (loc) {
        setPlacement(loc);
        if (loc.latitude !== 0 && loc.longitude !== 0) {
          setIsFormDisabled(true);
        }
      }

      if (defaultCamera) {
        setCameraLocation(defaultCamera);
      }

      if (ca.isClipping) {
        setTilesetClipping(true);
        setDepthTest(cesiumViewer, true);
      }
    },
    [cesiumViewer],
  );

  useEffect(() => {
    if (caData?.cesiumAssetGet) {
      updateData(caData?.cesiumAssetGet);
    }
  }, [caData?.cesiumAssetGet, updateData]);

  if (ocError || caError) return <NotFound />;
  if (caLoading || ocLoading) return <SpinnerPlaceholder />;
  if (!cesiumAsset) return <NotFound />;
  if (!cesiumAsset.localPath) return <NotFound />;
  if (!cesiumAsset.assetToken) return <NotFound />;

  const otherCAData = ocData?.outcropGet?.virtualOutcropModels
    .map(vom => vom.cesiumAsset)
    .filter(ca => ca && ca?.location && ca?.tilesetToken && ca.tilesetUrl)
    .filter(ca => ca?.id !== String(cesiumAsset.id));
  const otherCAs: TilesetParams[] = [];

  otherCAData?.forEach(cesiumAsset => {
    if (
      !cesiumAsset ||
      !cesiumAsset.tilesetToken ||
      !cesiumAsset.tilesetUrlLocal ||
      !cesiumAsset.location
    ) {
      return;
    }
    otherCAs.push({
      assetToken: cesiumAsset.tilesetToken.token,
      localPath: cesiumAsset.tilesetUrlLocal,
      transform: {
        location: Cartographic.fromDegrees(
          cesiumAsset.location.longitude,
          cesiumAsset.location.latitude,
          cesiumAsset.location.height,
        ),
        orientation: new HeadingPitchRoll(
          cesiumAsset.location.heading,
          cesiumAsset.location.pitch,
          cesiumAsset.location.roll,
        ),
      },
    });
  });

  const ca: TilesetParams = {
    assetToken: cesiumAsset.assetToken,
    localPath: cesiumAsset.localPath,
    transform: {
      location: Cartographic.fromDegrees(
        placement.longitude,
        placement.latitude,
        placement.height,
      ),
      orientation: new HeadingPitchRoll(
        placement.heading,
        placement.pitch,
        placement.roll,
      ),
    },
  };

  if (cameraLocation) {
    const dc = cameraLocation;
    ca.defaultCamera = {
      position: Cartesian3.fromDegrees(dc.longitude, dc.latitude, dc.height),
      orientation: new HeadingPitchRoll(dc.heading, dc.pitch, dc.roll),
    };
    if (cesiumViewer && !initialized) {
      cesiumViewer.scene.camera.flyTo({
        destination: ca.defaultCamera.position,
        orientation: ca.defaultCamera.orientation,
      });
      setInitialized(true);
    }
  }

  async function handleSubmit(values: LatLngHeightHPR) {
    try {
      await savePlacement({
        variables: {
          id: cesiumAsset.id,
          input: {
            location: {
              latitude: Number(values.latitude),
              longitude: Number(values.longitude),
              height: Number(values.height),
              heading: Number(values.heading),
              pitch: Number(values.pitch),
              roll: Number(values.roll),
            },
          },
        },
      });
      toast.success('Position saved');
    } catch (err) {
      console.log('Error saving placement', err);
      toast.error('There was a problem saving the placement.');
    }
  }

  async function handleUtmSubmit(values: UtmFormValues) {
    const hemisphere = z
      .nativeEnum(CesiumAssetUtmHemisphere)
      .nullish()
      .parse(values.hemisphere);

    try {
      const res = await saveUtm({
        variables: {
          id: cesiumAsset.id,
          input: {
            utmData: {
              utmNorthings: values.northing,
              utmEastings: values.easting,
              utmZone: values.zone,
              utmHemisphere: hemisphere,
            },
          },
        },
      });

      const caLocation = res.data?.cesiumAssetSetUtm?.result?.location;
      invariant(caLocation, 'Error parsing response body');

      setPlacement({
        latitude: caLocation.latitude,
        longitude: caLocation.longitude,
        height: caLocation.height,
        heading: caLocation.heading,
        pitch: caLocation.pitch,
        roll: caLocation.roll,
      });
      setIsFormDisabled(false);
      setCollapsed(true);
      toast.success('UTM Position saved.');
    } catch (err) {
      toast.error('Error saving UTM as position');
    }
  }

  const saveCameraPosition = async (viewer: Viewer | null) => {
    if (!viewer) {
      return;
    }
    const cameraLocation = getCamera(viewer);
    if (!cameraLocation) {
      toast.error('Error getting camera location');
      return;
    }
    await sendCameraPosition(cameraLocation);
  };

  const sendCameraPosition = async (cameraLocation: CameraParams) => {
    const cart = Ellipsoid.WGS84.cartesianToCartographic(
      cameraLocation.position,
    );

    try {
      await saveDefaultCamera({
        variables: {
          id: cesiumAsset.id,
          input: {
            location: {
              longitude: CesiumMath.toDegrees(cart.longitude),
              latitude: CesiumMath.toDegrees(cart.latitude),
              height: cart.height,
              heading: cameraLocation.orientation.heading,
              pitch: cameraLocation.orientation.pitch,
              roll: cameraLocation.orientation.roll,
            },
          },
        },
      });

      toast.success('Camera position saved.');
    } catch (err) {
      console.log('Error saving default camera', err);
      toast.error('There was a problem saving the camera position.');
    }
  };

  async function handleMarkAsComplete() {
    try {
      await markAsComplete({ variables: { id: cesiumAsset.id } });
      toast.success('Cesium Asset marked as complete.');
    } catch (err) {
      console.log('Error marking cesium asset complete', err);
      toast.error('There was a problem marking the Cesium Asset as complete.');
    }
  }

  const cameraCallback = (viewer: Viewer) => {
    const dist = getCurrentZoomDistance(viewer);
    if (!dist) {
      setCameraZoomLevel(1);
    } else {
      if (dist < 250) {
        setCameraZoomLevel(0.1);
      } else if (dist < 500) {
        setCameraZoomLevel(0.25);
      } else if (dist < 1000) {
        setCameraZoomLevel(0.5);
      } else if (dist < 2000) {
        setCameraZoomLevel(1);
      }
    }
  };

  async function handleSaveTilesetClipping(isClipping: boolean) {
    try {
      const result = await saveClipping({
        variables: {
          id: cesiumAsset.id,
          input: { isClipping },
        },
      });

      const updatedCA = result.data?.cesiumAssetSetClipping?.result;
      invariantNonNil(updatedCA?.isClipping, 'Error parsing result');
      toast.success('Clipping state saved');
      setDepthTest(cesiumViewer, updatedCA.isClipping);
      setTilesetClipping(updatedCA.isClipping);
    } catch (err) {
      console.log('Error updating tileset clipping', err);
      toast.error('There was a problem updating the tileset clipping.');
    }
  }
  return (
    <div className="space-y-6">
      <CesiumViewer
        initialTilesets={[...otherCAs, ca]}
        terrainProvider={TerrainProvider.World}
        sendTileset={setCesiumTileset}
        updatePlacement={setPlacement}
        sendCesiumViewer={setCesiumViewer}
        cameraCallback={cameraCallback}
        enableClickPlacement={true}
      />

      <div className="grid grid-cols-6 items-center gap-2">
        <div className="col-start-1 col-end-2">
          <Button
            onClick={() => {
              setIsFullWidth(prev => !prev);
            }}
            color="primary"
            size="md"
            className="gap-1"
          >
            Toggle Immersive Mode
          </Button>
        </div>
        <div className="col-start-2 col-end-3">
          <label className="label cursor-pointer">
            <input
              type="checkbox"
              checked={tilesetClipping}
              onChange={() => handleSaveTilesetClipping(!tilesetClipping)}
              disabled={loadingSaveClipping}
              className="toggle toggle-primary"
            />
            Tileset clipping
          </label>
        </div>
        <div className="col-end-6">
          <Button
            onClick={() => {
              resetCameraOrientation(cesiumViewer);
            }}
            color="primary"
            size="md"
            className="gap-1"
          >
            Camera Reset
          </Button>
        </div>
        <div className="col-end-7">
          <Button
            onClick={() => {
              saveCameraPosition(cesiumViewer);
            }}
            color="primary"
            size="md"
            className="gap-1 "
            disabled={loadingSaveDefaultCamera}
            loading={loadingSaveDefaultCamera}
          >
            {cameraLocation ? 'Update Camera Location' : 'Save Camera Location'}
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2 text-center py-5">
        <Formik
          initialValues={utmPlacement}
          onSubmit={handleUtmSubmit}
          validationSchema={utmFormSchema}
        >
          <Form>
            <Collapse
              title="UTM Placement"
              icon="arrow"
              open={!collapsed}
              onToggle={() => {
                setCollapsed(!collapsed);
              }}
              className="shadow-sm border border-default pb-2 rounded-none"
            >
              <VomUtmFields utmPlacement={utmPlacement} />

              <p className="text-red-500 m-2">
                Saving will override current changes.
              </p>

              <Button
                type="submit"
                color="neutral"
                size="md"
                className="gap-1"
                disabled={loadingSaveUtm}
                loading={loadingSaveUtm}
              >
                Apply
              </Button>
              <Button
                onClick={() => {
                  setIsFormDisabled(false);
                }}
                color="ghost"
                size="md"
                type="button"
                className="gap-2"
              >
                Skip Placement
              </Button>
            </Collapse>
          </Form>
        </Formik>

        <Formik
          initialValues={placement}
          onSubmit={handleSubmit}
          validationSchema={placementFormSchema}
        >
          <Form>
            {cesiumTileset && <MatrixAutoupdate tileset={cesiumTileset} />}

            <div
              className={cn('shadow-sm border border-default pb-2', {
                'pointer-events-none opacity-25': isFormDisabled,
              })}
            >
              <div className="px-3 py-2 bg-slate-100 text-base-content">
                <div className="text">Placement</div>
              </div>

              <VomPlacementFields
                placement={placement}
                zoomValue={cameraZoomLevel}
              />

              {cameraLocation ? null : (
                <p className="text-red-500 m-2">
                  Camera position must be saved before saving placement or
                  marking as complete
                </p>
              )}

              <Button
                type="submit"
                color="ghost"
                size="md"
                className="gap-1"
                disabled={loadingSavePlacement || !cameraLocation}
                loading={loadingSavePlacement}
              >
                Save Placement
              </Button>

              <Button
                type="button"
                color="neutral"
                size="md"
                onClick={handleMarkAsComplete}
                className="gap-1"
                disabled={
                  loadingMarkAsComplete ||
                  !cameraLocation ||
                  state === CesiumAssetState.Complete
                }
                loading={loadingMarkAsComplete}
              >
                {state === CesiumAssetState.Complete
                  ? 'Completed'
                  : 'Mark As Complete'}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

// Updates Cesium model matrix when form values are changed
function MatrixAutoupdate({ tileset }: { tileset: Cesium3DTileset }) {
  const { values } = useFormikContext<LatLngHeightHPR>();
  const valuesValid = placementFormSchema.isValidSync(values);

  useEffect(() => {
    if (valuesValid && tileset && tileset.modelMatrix) {
      const formValues = placementFormSchema.validateSync(values);
      updateTilesetModelMatrixWithPositionHpr(tileset, formValues);
    }
  }, [
    tileset,
    values,
    values.latitude,
    values.longitude,
    values.height,
    values.heading,
    values.pitch,
    values.roll,
    valuesValid,
  ]);

  return null;
}
