import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type { UploadOutcropPhoto360sPageQuery } from '~/apollo/generated/v4/graphql';
import { Photo360FormFields } from '~/components/upload/supporting-object/photo-360/photo-360-form-fields';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { useZodForm } from '~/utils/forms';
import {
  initialPhoto360,
  photo360Schema,
} from '~/utils/modules/supporting-object';

const UPDATE_PHOTO_360 = graphql(`
  mutation UpdatePhoto360($id: ID!, $input: Photo360UpdateInput!) {
    photo360Update(id: $id, input: $input) {
      result {
        ...photo360Parts
      }
    }
  }
`);

type Outcrop = NonNullable<UploadOutcropPhoto360sPageQuery['outcropGet']>;
type Photo360 = Outcrop['photo360s'][number];

export function UpdatePhoto360Form({
  photo360,
  onUpdateSuccess,
}: {
  photo360: Photo360;
  onUpdateSuccess?: () => unknown;
}) {
  const [updatePhoto360, { loading, error }] = useMutation(UPDATE_PHOTO_360);

  const form = useZodForm({
    schema: photo360Schema,
    values: initialPhoto360(photo360),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updatePhoto360({
        variables: {
          id: photo360.id,
          input: values,
        },
      });
      toast.success('360 Photo saved successfully.');
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error saving 360 Photo', err);
      toast.error('There was a problem saving the 360 Photo.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <Photo360FormFields />
          <HookFormErrors graphQLError={error} />

          <div className="text-center">
            <Button color="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
