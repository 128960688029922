import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import type { SoManagerFaciesPartsFragment } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { SOFormFields } from '~/components/upload/supporting-object/so-form-fields';
import type { SOParent } from '~/components/upload/supporting-object/so-manager';
import { UPDATE_FACIES } from '~/components/upload/supporting-object/so-operations';
import { useZodForm } from '~/utils/forms';
import { faciesSchema, initialFacies } from '~/utils/modules/supporting-object';

type Props = SOParent & {
  facies: SoManagerFaciesPartsFragment;
  onUpdateSuccess?: () => unknown;
};

export function FaciesUpdateForm({ facies, studyId, onUpdateSuccess }: Props) {
  const [updateFacies, { loading, error }] = useMutation(UPDATE_FACIES);

  const form = useZodForm({
    schema: faciesSchema,
    values: initialFacies(facies),
  });

  const handleSubmit = form.handleSubmit(async values => {
    console.log('Submitted with values:', values);
    try {
      await updateFacies({
        variables: {
          id: facies.id,
          input: values,
        },
      });
      toast.success('Facies schema updated successfully.');
      form.reset();
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error updating facies', err);
      toast.error('There was a problem updating the facies schema.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <SOFormFields soType="FACIES_SCHEMA" studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="card-actions justify-end">
          <Button type="submit" loading={loading} color="primary">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
