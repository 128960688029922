import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { useFormContext } from 'react-hook-form';
import { InputBase } from '~/components/ui/forms/InputBase';
import { CoordinatesText } from '~/components/upload/supporting-object/field-picture/coordinates-text';
import type { UpdateFieldPictureFormValues } from '~/components/upload/supporting-object/field-picture/update-field-picture-form';

export function UpdateFieldPictureLocationField() {
  const { watch, setValue } = useFormContext<UpdateFieldPictureFormValues>();

  const location = watch('location');

  function handleMapLoaded(map: google.maps.Map) {
    map.setCenter({
      lat: location.latitude,
      lng: location.longitude,
    });
    map.setZoom(10);
  }

  function handleMapMouseEvent(event: google.maps.MapMouseEvent) {
    const position = event.latLng;
    if (position) {
      setValue('location', {
        latitude: position.lat(),
        longitude: position.lng(),
      });
    }
  }

  return (
    <InputBase id="location" name="location" label="Location">
      <div className="space-y-2">
        <GoogleMap
          mapContainerClassName="w-full h-[500px]"
          onLoad={handleMapLoaded}
          onClick={handleMapMouseEvent}
          options={{
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            fullscreenControl: false,
            streetViewControl: false,
          }}
        >
          <MarkerF
            position={{ lat: location.latitude, lng: location.longitude }}
            draggable
            onDragEnd={handleMapMouseEvent}
          />
        </GoogleMap>

        <CoordinatesText exifLocation={null} location={location} />
      </div>
    </InputBase>
  );
}
