import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { SOFormFields } from '~/components/upload/supporting-object/so-form-fields';
import type { SOParent } from '~/components/upload/supporting-object/so-manager';
import { useZodForm } from '~/utils/forms';
import type { RefetchQueries } from '~/utils/graphql';
import {
  gigaPanSchema,
  initialGigaPan,
} from '~/utils/modules/supporting-object';

const CREATE_GIGA_PAN = graphql(`
  mutation CreateGigaPan($input: GigaPanCreateInput!) {
    gigaPanCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

type Props = SOParent & {
  refetchQueries?: RefetchQueries;
};

export function GigaPanCreateForm({
  outcropId,
  studyId,
  refetchQueries,
}: Props) {
  const [createGigaPan, { loading, error }] = useMutation(CREATE_GIGA_PAN, {
    refetchQueries,
  });

  const form = useZodForm({
    schema: gigaPanSchema,
    values: initialGigaPan(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await createGigaPan({
        variables: {
          input: {
            ...values,
            outcropId,
            studyId,
          },
        },
      });
      toast.success('Panorama created successfully.');
      form.reset();
    } catch (err) {
      console.log('Error creating panorama', err);
      toast.error('There was a problem creating the panorama.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <SOFormFields soType="GIGA_PAN" studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="card-actions justify-end">
          <Button type="submit" loading={loading} color="primary">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
