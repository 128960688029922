import { useQuery } from '@apollo/client';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SOManagerV4 } from '~/components/upload/supporting-object/so-manager';
import { useUploadOutcropUpdateRouteOutletContext } from '~/routes/upload/model/outcrop/$outcropId';
import { createRefetchQueries } from '~/utils/graphql';

export const UPLOAD_OUTCROP_CROSS_SECTIONS_TAB = graphql(`
  query UploadOutcropCrossSectionsTab($id: ID!) {
    outcropGet(id: $id) {
      id
      crossSections {
        ...soManagerCrossSectionParts
      }
    }
  }
`);

export default function UpdateOutcropCrossSectionsRoute() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

export function PageInner() {
  const ctx = useUploadOutcropUpdateRouteOutletContext();
  const outcropId = ctx.outcrop.id;

  const { data, loading } = useQuery(UPLOAD_OUTCROP_CROSS_SECTIONS_TAB, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropGet;

  const refetchQueries = createRefetchQueries([
    {
      query: UPLOAD_OUTCROP_CROSS_SECTIONS_TAB,
      variables: { id: outcropId },
    },
  ]);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <SOManagerV4
      soType="CROSS_SECTION"
      items={outcrop.crossSections}
      parent={{ outcropId: parseInt(outcrop.id) }}
      refetchQueries={refetchQueries}
    />
  );
}
