import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { forwardRef } from 'react';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type { SelectProps } from '~/components/ui/forms/Select';
import { Select } from '~/components/ui/forms/Select';

const PROJECT_SELECT_OPTIONS = graphql(`
  query ProjectSelectOptions {
    projectList {
      results {
        id
        name
      }
    }
  }
`);

type Props = Omit<SelectProps, 'options'>;

export const ProjectSelect = forwardRef<HTMLSelectElement, Props>(
  (props, ref) => {
    return (
      <ApolloProviderV4>
        <ProjectSelectInner {...props} ref={ref} />
      </ApolloProviderV4>
    );
  },
);
ProjectSelect.displayName = 'ProjectSelect';

const ProjectSelectInner = forwardRef<HTMLSelectElement, Props>(
  (props, ref) => {
    const { loading, data } = useQuery(PROJECT_SELECT_OPTIONS);

    const options = R.pipe(
      () => data?.projectList?.results ?? [],
      R.sortBy(project => project.name),
      R.map(project => ({ value: project.id, label: project.name })),
    )();

    return (
      <Select
        {...props}
        ref={ref}
        disabled={props.disabled || loading}
        options={options}
      />
    );
  },
);
ProjectSelectInner.displayName = 'ProjectSelectInner';
