import type {
  KeyParametersPartsFragment,
  OutcropAddtlFieldsEnumsQuery,
  OutcropPartsFragment,
} from '~/apollo/generated/v3/graphql';
import type { LithostratAgeFormValues } from '~/utils/modules/lithostratAge';

export type OutcropGeologicalAgeFormValues = {
  startAge: LithostratAgeFormValues;
  endAge: LithostratAgeFormValues;
};

/** Get the label of the given HTML field based on the outcrop's outcropCategory */
export function fieldLabel(
  field: keyof OutcropPartsFragment,
  ocCategory: string | null = 'outcrop',
) {
  if (field === 'description') {
    return 'Description';
  }
  if (field === 'introduction') {
    return 'Introduction';
  }

  if (field === 'environments') {
    switch (ocCategory) {
      case 'modern':
        return 'Sedimentology and Depositional Environment';
      default:
        return 'Lithology and Depositional Environment';
    }
  }

  if (field === 'stratigraphy') {
    switch (ocCategory) {
      case 'production':
        return 'Structural Complexity';
      default:
        return 'Litho- and Sequence Stratigraphy';
    }
  }

  if (field === 'location') {
    switch (ocCategory) {
      case 'seismic':
        return 'Dataset';
      case 'production':
        return 'Reservoir Engineering Aspects';
      default:
        return 'Location and Accessibility';
    }
  }

  if (field === 'notablesAndAnalogues') {
    switch (ocCategory) {
      case 'production':
        return 'Production Aspects';
      default:
        return 'Analogues and Notable Aspects';
    }
  }

  if (field === 'safari') {
    switch (ocCategory) {
      case 'production':
        return 'Key References and Links';
      default:
        return 'Key References';
    }
  }

  if (field === 'keyWords') return 'Keywords';

  if (field === 'diagenesisAndPetrophysicalProperties')
    return 'Diagenesis and Petrophysical Properties';

  if (field === 'structuralGeology') return 'Structural Geology';

  throw new Error(`Field ${field} does not have a defined label`);
}

export type OCAdditionalFieldKey = keyof Omit<
  OutcropAddtlFieldsEnumsQuery,
  '__typename'
>;

export type OCAddtlField = {
  name: OCAdditionalFieldKey;
  multiple: boolean;
  label?: string;
  wikiId?: number;
};

export const clasticFields = (entity: 'outcrop' | 'ae'): OCAddtlField[] => {
  const fields: OCAddtlField[] = [];

  if (entity === 'outcrop') {
    fields.push({ name: 'netToGross', multiple: false, wikiId: 71 });
  }

  const baseFields: OCAddtlField[] = [
    { name: 'systemsTract', multiple: true, wikiId: 39 },
    { name: 'shorelineTractory', multiple: true, wikiId: 37 },
    { name: 'duneShape', multiple: true, wikiId: 30 },
    { name: 'channelMorphology', multiple: true, wikiId: 25 },
    { name: 'riverProfileLocation', multiple: true, wikiId: 35 },
    { name: 'dominantLithology', multiple: true, wikiId: undefined },
    { name: 'lateralAggregation', multiple: true, wikiId: 82 },
  ];
  baseFields.forEach(f => fields.push(f));

  return fields;
};

export const carbonateFields = (): OCAddtlField[] => [
  {
    name: 'waterTemperature',
    multiple: true,
    label: 'Water temp.',
    wikiId: 70,
  },
  { name: 'diageneticProcess', multiple: true, wikiId: 74 },
  { name: 'diageneticSetting', multiple: true, wikiId: 72 },
  { name: 'diageneticGeometry', multiple: true, wikiId: 73 },
  { name: 'systemsTract', multiple: true, wikiId: 39 },
  { name: 'dominantLithology', multiple: true, wikiId: undefined },
];

export type KPFields = Pick<
  KeyParametersPartsFragment,
  | 'grossDepositionalEnvironment'
  | 'depositionalEnvironment'
  | 'depositionalSubEnvironment'
  | 'architecturalElement'
>;

export const structuralFields = (kps: KPFields[]): OCAddtlField[] => {
  const gdes = kps.map(kp => kp.grossDepositionalEnvironment);
  const hasClassification = (c: string) => gdes.find(gde => gde === c);

  const enumFields: OCAddtlField[] = [
    { name: 'tectonicSetting', multiple: true, wikiId: 72 },
    {
      name: 'synSedimentaryDeformation',
      multiple: true,
      label: 'Syn-sedimentary Deformation',
      wikiId: 73,
    },
    { name: 'dominantLithology', multiple: true },
  ];

  const appendFields = (fieldsToAppend: OCAddtlField[]) =>
    fieldsToAppend.forEach(f => enumFields.push(f));

  if (hasClassification('Faults/Fractures')) {
    appendFields([
      {
        name: 'faultRocksMembranes',
        multiple: true,
        label: 'Fault Rocks/Membranes',
        wikiId: 74,
      },
      {
        name: 'interactionNetwork',
        multiple: true,
        label: 'Interaction/Network',
        wikiId: 75,
      },
      {
        name: 'reactivation1stPhase',
        multiple: true,
        label: 'Reactivation 1st Phase',
        wikiId: 76,
      },
      {
        name: 'reactivation2ndPhase',
        multiple: true,
        label: 'Reactivation 2nd Phase',
        wikiId: 76,
      },
    ]);
  }

  if (hasClassification('Fold') || hasClassification('Fault-related fold')) {
    appendFields([
      {
        name: 'symmetryGeometry',
        multiple: true,
        label: 'Symmetry/Geometry',
        wikiId: 77,
      },
      { name: 'multipleFolds', multiple: true, wikiId: 78 },
      {
        name: 'secondaryStructures',
        multiple: true,
        label: 'Secondary Structures Related To Folds',
        wikiId: 79,
      },
    ]);
  }

  return enumFields;
};
