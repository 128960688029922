import { useFormContext } from 'react-hook-form';
import { Select } from '~/components/ui/forms/Select';
import { TextInput } from '~/components/ui/forms/text-input';
import { OutcropTagField } from '~/components/upload/supporting-object/outcrop-tag-field';
import { pictureTypes } from '~/utils/modules/picture';
import type { PictureUpdateFormValues } from '~/utils/modules/supporting-object';

export function PictureFormFields({ studyId }: { studyId?: number }) {
  const form = useFormContext<PictureUpdateFormValues>();

  return (
    <div className="space-y-2">
      <TextInput {...form.register('name')} label="Name" required />
      <TextInput
        {...form.register('description')}
        label="Description"
        required
      />
      <Select
        {...form.register('type')}
        label="Type"
        options={pictureTypes}
        withBlankOption={false}
        required
      />
      <TextInput {...form.register('author')} label="Author" />
      <TextInput {...form.register('scale')} label="Scale" />
      <TextInput {...form.register('comments')} label="Comments" />
      <TextInput
        type="number"
        {...form.register('priority')}
        label="Priority"
      />

      {studyId && (
        <OutcropTagField
          studyId={studyId}
          name="outcropTagId"
          label="Outcrop Tag"
        />
      )}
    </div>
  );
}
