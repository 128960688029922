import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import type { z } from 'zod';
import { graphql } from '~/apollo/generated/v4';
import type { UploadOutcropOverviewPageQuery } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { OutcropFormFields } from '~/components/upload/outcrop/outcrop-form-fields';
import { useZodForm } from '~/utils/forms';
import { initialOutcrop, outcropSchema } from '~/utils/modules/outcrop-v4';

const UPDATE_OUTCROP = graphql(`
  mutation UpdateOutcrop($id: ID!, $input: OutcropUpdateInput!) {
    outcropUpdate(id: $id, input: $input) {
      result {
        ...outcropParts
      }
    }
  }
`);

export function UpdateOutcropForm({
  outcrop,
}: {
  outcrop: NonNullable<UploadOutcropOverviewPageQuery['outcropGet']>;
}) {
  const [updateOutcrop, { loading, error }] = useMutation(UPDATE_OUTCROP);

  const form = useZodForm({
    schema: outcropSchema,
    values: initialOutcrop(outcrop),
    shouldUseNativeValidation: false,
  });

  const handleSubmit = form.handleSubmit(
    async (values: z.infer<typeof outcropSchema>) => {
      const { isBeingPublished, ...input } = values;
      try {
        const res = await updateOutcrop({
          variables: {
            id: outcrop.id,
            input,
          },
        });

        const nextValues = res.data?.outcropUpdate?.result;
        invariant(nextValues, 'Error parsing update response');

        toast.success('Outcrop details saved successfully.');
        form.reset(initialOutcrop(nextValues));
      } catch (err) {
        console.log('Error updating outcrop', err);
        toast.error('There was a problem saving the outcrop.');
      }
    },
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} noValidate className="space-y-4">
        <OutcropFormFields />
        <HookFormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
