import React from 'react';

type Props = {
  onUploadSuccess: (text: string) => void;
};

export function KmlFileUpload({ onUploadSuccess }: Props) {
  function handlePick(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.item(0);
    if (file) {
      const fr = new FileReader();
      fr.onload = (event: ProgressEvent<FileReader>) => {
        const result = event.target?.result;
        console.log('loaded text:', result);
        if (result) {
          onUploadSuccess(result as string);
        } else {
          throw new Error('Unexpected error reading file result.');
        }
      };
      fr.onerror = (event: ProgressEvent<FileReader>) => {
        const err = event.target?.error;
        console.log('Error reading file', err);
        window.alert('There was a problem reading the file. Please try again.');
      };
      fr.readAsText(file, 'UTF-8');
    }
  }

  return (
    <div>
      <label htmlFor="kmlFile" className="label">
        Select a KML file to import
      </label>
      <input type="file" onChange={handlePick} className="file-input w-full" />
    </div>
  );
}
