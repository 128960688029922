import { graphql } from '~/apollo/generated/v4';

export const studyUpdateParts = graphql(`
  fragment studyUpdateParts on Study {
    ...studyParts
    outcrops {
      id
      geologyType
    }
  }
`);

export const STUDY_ENUMS = graphql(`
  query StudyEnums {
    studyType: outcropEnumerations(type: STUDY_TYPE) {
      values
    }
    spatialObservationType: outcropEnumerations(
      type: SPATIAL_OBSERVATION_TYPE
    ) {
      values
    }
    dataAcquisitionMethod: outcropEnumerations(type: DATA_ACQUISITION_METHOD) {
      values
    }
    publicationType: outcropEnumerations(type: PUBLICATION_TYPE) {
      values
    }
  }
`);
