import { useQuery } from '@apollo/client';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useRouteParam } from '~/hooks/routing';
import { cn } from '~/utils/common';

const OUTCROP_PHOTO_360S_PAGE = graphql(`
  query OutcropPhoto360sPage($id: ID!) {
    outcropGet(id: $id) {
      id
      photo360s {
        id
        name
        url
      }
    }
  }
`);

export default function OutcropPhoto360sPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const outcropId = useRouteParam('outcropId', parseInt);
  const { data, loading } = useQuery(OUTCROP_PHOTO_360S_PAGE, {
    variables: {
      id: outcropId,
    },
  });

  const outcrop = data?.outcropGet;

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div
      className={cn('grid gap-4', {
        'lg:grid-cols-2': outcrop.photo360s.length > 1,
      })}
    >
      {outcrop.photo360s.map(p => (
        <div
          key={p.id}
          className={cn('w-full thumbnail', {
            'h-[640px]': outcrop.photo360s.length === 1,
            'aspect-square': outcrop.photo360s.length > 1,
          })}
        >
          <iframe
            className="w-full h-full"
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            allowFullScreen
            src={p.url}
            title={p.name}
          />
          <div className="caption text-center">{p.name}</div>
        </div>
      ))}
    </div>
  );
}
