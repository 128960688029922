import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import type { z } from 'zod';
import { graphql } from '~/apollo/generated/v4';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { OutcropFormFields } from '~/components/upload/outcrop/outcrop-form-fields';
import { uploadOutcropUpdateRoute } from '~/paths';
import { useZodForm } from '~/utils/forms';
import { initialOutcrop, outcropSchema } from '~/utils/modules/outcrop-v4';

const CREATE_OUTCROP = graphql(`
  mutation CreateOutcrop($input: OutcropCreateInput!) {
    outcropCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

export function CreateOutcropForm() {
  const navigate = useNavigate();
  const [createOutcrop, { loading, error }] = useMutation(CREATE_OUTCROP);

  const form = useZodForm({
    schema: outcropSchema,
    values: initialOutcrop(),
    shouldUseNativeValidation: false,
  });

  const handleSubmit = form.handleSubmit(
    async (values: z.infer<typeof outcropSchema>) => {
      const { isBeingPublished, ...input } = values;
      try {
        const res = await createOutcrop({
          variables: { input },
        });
        const outcropId = res.data?.outcropCreate?.result?.id;
        invariant(outcropId, 'Error parsing create response');
        const redirectPath = uploadOutcropUpdateRoute(parseInt(outcropId));
        navigate(redirectPath);
        toast.success('Outcrop details saved successfully.');
      } catch (err) {
        console.log('Error updating outcrop', err);
        toast.error('There was a problem saving the outcrop.');
      }
    },
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} noValidate className="space-y-4">
        <OutcropFormFields />
        <HookFormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" disabled={loading}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
