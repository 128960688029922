import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type { UploadOutcropVideosPageQuery } from '~/apollo/generated/v4/graphql';
import { VideoFormFields } from '~/components/upload/supporting-object/video/video-form-fields';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { useZodForm } from '~/utils/forms';
import { initialVideo, videoSchema } from '~/utils/modules/supporting-object';

const UPDATE_VIDEO = graphql(`
  mutation UpdateVideo($id: ID!, $input: VideoUpdateInput!) {
    videoUpdate(id: $id, input: $input) {
      result {
        ...videoParts
      }
    }
  }
`);

type Outcrop = NonNullable<UploadOutcropVideosPageQuery['outcropGet']>;
type Video = Outcrop['videos'][number];

export function UpdateVideoForm({
  video,
  onUpdateSuccess,
}: {
  video: Video;
  onUpdateSuccess?: () => void;
}) {
  const [updateVideo, { loading, error }] = useMutation(UPDATE_VIDEO);

  const form = useZodForm({
    schema: videoSchema,
    values: initialVideo(video),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateVideo({
        variables: {
          id: video.id,
          input: values,
        },
      });
      toast.success('Video saved successfully.');
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error saving Video', err);
      toast.error('There was a problem saving the Video.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <VideoFormFields />
          <HookFormErrors graphQLError={error} />

          <div className="text-center">
            <Button color="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
