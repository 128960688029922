import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '~/components/common/Tooltip';

export function RequiredField() {
  return (
    <Tooltip message="This field is required">
      <span className="text-error mx-1 text-xs">
        <FontAwesomeIcon icon={faAsterisk} className="pb-1" />
      </span>
    </Tooltip>
  );
}
