import { useQuery } from '@apollo/client';
import { faCancel, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type { UploadOutcropFieldPicturesPageQuery } from '~/apollo/generated/v4/graphql';
import { LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { NotFound } from '~/components/common/NotFound';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Button } from '~/components/ui/button';
import { BulkFieldPictureUploader } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader';
import { FieldPictureCard } from '~/components/upload/supporting-object/field-picture/field-picture-card';
import { useShowUnpublished } from '~/components/upload/supporting-object/use-show-unpublished';
import { useRouteParam } from '~/hooks/routing';
import type { RefetchQueries } from '~/utils/graphql';
import { createRefetchQueries } from '~/utils/graphql';

const UPLOAD_OUTCROP_FIELD_PICTURES_PAGE = graphql(`
  query UploadOutcropFieldPicturesPage($id: ID!) {
    outcropGet(id: $id) {
      id
      fieldPictures {
        ...fieldPictureParts
        file {
          ...fileParts
        }
      }
    }
  }
`);

export default function UploadOutcropFieldPicsRoute() {
  return (
    <ApolloProviderV4>
      <Loader />
    </ApolloProviderV4>
  );
}

function Loader() {
  const outcropId = useRouteParam('outcropId', parseInt);

  const { data, loading, refetch } = useQuery(
    UPLOAD_OUTCROP_FIELD_PICTURES_PAGE,
    { variables: { id: outcropId } },
  );

  const refetchQueries = createRefetchQueries([
    {
      query: UPLOAD_OUTCROP_FIELD_PICTURES_PAGE,
      variables: { id: outcropId },
    },
  ]);

  const outcrop = data?.outcropGet;

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <PageInner
      outcropId={outcropId}
      fieldPictures={outcrop.fieldPictures}
      refetchQueries={refetchQueries}
      onUploadSuccess={refetch}
    />
  );
}

type Outcrop = NonNullable<UploadOutcropFieldPicturesPageQuery['outcropGet']>;
type FieldPicture = Outcrop['fieldPictures'][number];

function PageInner({
  outcropId,
  fieldPictures: fieldPicturesProp,
  refetchQueries,
  onUploadSuccess,
}: {
  outcropId: number;
  fieldPictures: FieldPicture[];
  refetchQueries: RefetchQueries;
  onUploadSuccess: () => unknown;
}) {
  const [isUploaderVisible, setIsUploaderVisible] = useState(false);

  const unpublishedCount = fieldPicturesProp.reduce(
    (acc, fp) =>
      acc + (fp.lifecycleStatus !== LifecycleStatus.Published ? 1 : 0),
    0,
  );

  const { showUnpublished, PublishedToggle } =
    useShowUnpublished(unpublishedCount);

  const fieldPictures = fieldPicturesProp
    .filter(
      fp => showUnpublished || fp.lifecycleStatus === LifecycleStatus.Published,
    )
    .sort(R.ascend(fp => fp.file.name ?? Infinity));

  return (
    <div className="space-y-4">
      <Panel>
        <Panel.Heading className="flex justify-between items-center">
          <Panel.Title>Upload Field Pictures</Panel.Title>

          <Button
            size="xs"
            color={isUploaderVisible ? 'ghost' : 'primary'}
            onClick={() => setIsUploaderVisible(!isUploaderVisible)}
            startIcon={
              isUploaderVisible ? (
                <FontAwesomeIcon icon={faCancel} />
              ) : (
                <FontAwesomeIcon icon={faUpload} />
              )
            }
          >
            {isUploaderVisible ? 'Hide Uploader' : 'Upload Field Pictures'}
          </Button>
        </Panel.Heading>

        {isUploaderVisible && (
          <Panel.Body>
            <BulkFieldPictureUploader
              outcropId={outcropId}
              onUploadSuccess={onUploadSuccess}
            />
          </Panel.Body>
        )}
      </Panel>

      <PublishedToggle />

      <NoItemsAlert show={!fieldPictures.length} entity="field pictures" />

      {fieldPictures.map(fieldPicture => (
        <FieldPictureCard
          key={fieldPicture.id}
          fieldPicture={fieldPicture}
          refetchQueries={refetchQueries}
        />
      ))}
    </div>
  );
}
