import type {
  SoManagerCrossSectionPartsFragment,
  SoManagerFaciesPartsFragment,
  SoManagerGigaPanPartsFragment,
  SoManagerProductionPartsFragment,
  SoManagerReservoirModelPartsFragment,
  SoManagerSedimentaryLogPartsFragment,
  SoManagerTrainingImagePartsFragment,
  SoManagerVariogramPartsFragment,
  SoManagerWellLogPartsFragment,
} from '~/apollo/generated/v4/graphql';
import {
  SODetailText,
  SOOutcropTagText,
} from '~/components/upload/supporting-object/so-detail-text';

export function CrossSectionDetails({
  crossSection: cs,
}: {
  crossSection: SoManagerCrossSectionPartsFragment;
}) {
  return (
    <div className="space-x-0">
      <SODetailText label="Description" value={cs.description} />
      <SODetailText label="Author" value={cs.author} />
      <SODetailText label="Scale" value={cs.scale} />
      <SODetailText label="Comments" value={cs.comments} />
      <SODetailText label="Priority" value={cs.priority} />
      <SOOutcropTagText label="Outcrop Tag" outcropTag={cs.outcropTag} />
    </div>
  );
}

export function FaciesDetails({
  facies,
}: {
  facies: SoManagerFaciesPartsFragment;
}) {
  return (
    <div className="space-x-0">
      <SODetailText label="Description" value={facies.description} />
      <SODetailText label="Author" value={facies.author} />
      <SODetailText label="Interpretation" value={facies.interpretation} />
      <SODetailText label="Comments" value={facies.comments} />
      <SODetailText label="Priority" value={facies.priority} />
      <SOOutcropTagText label="Outcrop Tag" outcropTag={facies.outcropTag} />
    </div>
  );
}

export function SedlogDetails({
  sedlog: s,
}: {
  sedlog: SoManagerSedimentaryLogPartsFragment;
}) {
  return (
    <div className="space-x-0">
      <SODetailText label="Description" value={s.description} />
      <SODetailText label="Author" value={s.author} />
      <SODetailText label="Scale" value={s.scale} />
      <SODetailText label="Comments" value={s.comments} />
      <SODetailText label="Priority" value={s.priority} />
      <SOOutcropTagText label="Outcrop Tag" outcropTag={s.outcropTag} />
    </div>
  );
}

export function WellLogDetails({
  wellLog: wl,
}: {
  wellLog: SoManagerWellLogPartsFragment;
}) {
  return (
    <div className="space-x-0">
      <SODetailText label="Description" value={wl.description} />
      <SODetailText label="Author" value={wl.author} />
      <SODetailText label="Comments" value={wl.comments} />
      <SODetailText label="Priority" value={wl.priority} />
      <SOOutcropTagText label="Outcrop Tag" outcropTag={wl.outcropTag} />
    </div>
  );
}

export function ProductionDetails({
  production: p,
}: {
  production: SoManagerProductionPartsFragment;
}) {
  return (
    <div className="space-x-0">
      <SODetailText label="Description" value={p.description} />
      <SODetailText label="Author" value={p.author} />
      <SODetailText label="Comments" value={p.comments} />
      <SODetailText label="Priority" value={p.priority} />
      <SOOutcropTagText label="Outcrop Tag" outcropTag={p.outcropTag} />
    </div>
  );
}

export function ReservoirModelDetails({
  reservoirModel: rm,
}: {
  reservoirModel: SoManagerReservoirModelPartsFragment;
}) {
  return (
    <div className="space-x-0">
      <SODetailText label="Description" value={rm.description} />
      <SODetailText label="Author" value={rm.author} />
      <SODetailText label="Comments" value={rm.comments} />
      <SODetailText label="Priority" value={rm.priority} />
      <SOOutcropTagText label="Outcrop Tag" outcropTag={rm.outcropTag} />
    </div>
  );
}

export function TrainingImageDetails({
  trainingImage: ti,
}: {
  trainingImage: SoManagerTrainingImagePartsFragment;
}) {
  return (
    <div className="space-x-0">
      <SODetailText label="Description" value={ti.description} />
      <SODetailText label="Author" value={ti.author} />
      <SODetailText label="Comments" value={ti.comments} />
      <SODetailText label="Priority" value={ti.priority} />
      <SOOutcropTagText label="Outcrop Tag" outcropTag={ti.outcropTag} />
    </div>
  );
}

export function VariogramDetails({
  variogram: v,
}: {
  variogram: SoManagerVariogramPartsFragment;
}) {
  return (
    <div className="space-x-0">
      <SODetailText label="Description" value={v.description} />
      <SODetailText label="Author" value={v.author} />
      <SODetailText label="Comments" value={v.comments} />
      <SODetailText label="Nugget" value={v.nugget} />
      <SODetailText label="Sill" value={v.sill} />
      <SODetailText label="Range" value={v.range} />
      <SODetailText label="Priority" value={v.priority} />
      <SOOutcropTagText label="Outcrop Tag" outcropTag={v.outcropTag} />
    </div>
  );
}
export function GigaPanDetails({
  gigaPan: g,
}: {
  gigaPan: SoManagerGigaPanPartsFragment;
}) {
  return (
    <div className="space-x-0">
      <SODetailText label="GigaPan hash" value={g.gigaPanHash} />
      <SODetailText label="Priority" value={g.priority} />
      <SOOutcropTagText label="Outcrop Tag" outcropTag={g.outcropTag} />
    </div>
  );
}
