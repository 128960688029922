import { useQuery } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { graphql } from '~/apollo/generated/v4';
import type { BasinFieldOptionsOptionsQuery } from '~/apollo/generated/v4/graphql';
import { InputGroup } from '~/components/common/InputGroup';
import { Select } from '~/components/ui/forms/Select';
import { TextAreaInput } from '~/components/ui/forms/text-area-input';
import { TextInput } from '~/components/ui/forms/text-input';
import type { OutcropFormValues } from '~/utils/modules/outcrop-v4';

const BASIN_FIELD_OPTIONS = graphql(`
  query BasinFieldOptionsOptions {
    basinType: outcropEnumerations(type: BASIN_TYPE) {
      values
    }
    climate: outcropEnumerations(type: CLIMATE) {
      values
    }
    terrestrialMarine: outcropEnumerations(type: TERRESTRIAL_MARINE) {
      values
    }
    waterDepth: outcropEnumerations(type: WATER_DEPTH) {
      values
    }
    accommodationRegime: outcropEnumerations(type: ACCOMMODATION_REGIME) {
      values
    }
  }
`);

export function BasinFormFields() {
  const { register, control } = useFormContext<OutcropFormValues>();
  const { data, loading } = useQuery(BASIN_FIELD_OPTIONS);

  function enumOptions(
    fieldName: keyof Omit<BasinFieldOptionsOptionsQuery, '__typename'>,
  ) {
    const values = data?.[fieldName]?.values ?? [];
    return values.map((opt: string) => ({ value: opt, label: opt }));
  }

  return (
    <div className="space-y-2">
      <TextInput {...register('basinName')} label="Basin name" required />

      <TextAreaInput {...register('basinDescription')} label="Description" />

      <Controller
        control={control}
        name="basinType"
        render={({ field }) => (
          <Select
            {...field}
            label="Basin type"
            options={enumOptions('basinType')}
            required
            disabled={loading}
          />
        )}
      />

      <Controller
        control={control}
        name="basinClimate"
        render={({ field }) => (
          <Select
            {...field}
            label="Climate"
            options={enumOptions('climate')}
            required
            disabled={loading}
          />
        )}
      />

      <Controller
        control={control}
        name="basinTerrestrialMarine"
        render={({ field }) => (
          <Select
            {...field}
            label="Terrestrial/Marine"
            options={enumOptions('terrestrialMarine')}
            disabled={loading}
          />
        )}
      />

      <Controller
        control={control}
        name="basinWaterDepth"
        render={({ field }) => (
          <Select
            {...field}
            label="Water depth"
            options={enumOptions('waterDepth')}
            disabled={loading}
          />
        )}
      />

      <TextInput
        {...register('basinCatchmentArea')}
        type="number"
        step="1"
        label="Catchment area"
        join
        renderInput={input => (
          <InputGroup>
            {input}
            <InputGroup.Addon right>km²</InputGroup.Addon>
          </InputGroup>
        )}
      />

      <Controller
        control={control}
        name="basinAccommodationRegime"
        render={({ field }) => (
          <Select
            {...field}
            label="Water depth"
            options={enumOptions('accommodationRegime')}
            disabled={loading}
          />
        )}
      />

      <TextAreaInput {...register('basinComments')} label="Comments" />
    </div>
  );
}
