import type { PureQueryOptions } from '@apollo/client';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FileParentType } from '~/apollo/generated/v4/graphql';
import { Modal } from '~/components/common/Modal';
import { Button } from '~/components/ui/button';
import { FileUploadV4 } from '~/components/upload/file/file-upload-v4';
import { useModalState } from '~/hooks/modal';

export function UploadFilesModal({
  parentType,
  parentId,
  disabled,
  refetchQueries,
}: {
  parentType: FileParentType;
  parentId: number;
  disabled?: boolean;
  refetchQueries: PureQueryOptions[];
}) {
  const { show, showModal, hideModal } = useModalState();

  return (
    <>
      <Button
        type="button"
        color="primary"
        size="xs"
        onClick={showModal}
        disabled={disabled}
        className="inline-block space-x-1"
      >
        <FontAwesomeIcon icon={faUpload} /> Upload Files
      </Button>

      <Modal open={show} onHide={hideModal} size="lg">
        <Modal.Body heading="Upload Files">
          <FileUploadV4
            parentType={parentType}
            parentId={parentId}
            refetchQueries={refetchQueries}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
