import { z } from 'zod';
import type { LithostratAgePartsFragment } from '~/apollo/generated/v4/graphql';
import { ppStringOrNull } from '~/utils/zod';

export const ageSchema = z.object({
  id: z.number().int().optional(),
  eon: z.preprocess(ppStringOrNull, z.string().nullable()),
  era: z.preprocess(ppStringOrNull, z.string().nullable()),
  system: z.preprocess(ppStringOrNull, z.string().nullable()),
  series: z.preprocess(ppStringOrNull, z.string().nullable()),
  stage: z.preprocess(ppStringOrNull, z.string().nullable()),
  comments: z.preprocess(ppStringOrNull, z.string().nullable()),
});
export type AgeFormValues = {
  id?: number;
  eon: string;
  era: string;
  system: string;
  series: string;
  stage: string;
  comments: string;
};

export const initialAge = (
  age?: LithostratAgePartsFragment | null,
): AgeFormValues => ({
  id: age ? parseInt(age.id) : undefined,
  eon: age?.eon ?? '',
  era: age?.era ?? '',
  system: age?.system ?? '',
  series: age?.series ?? '',
  stage: age?.stage ?? '',
  comments: age?.comments ?? '',
});

export function mostSpecificAge(
  age?: Pick<LithostratAgePartsFragment, 'eon' | 'era' | 'system'> | null,
): string | null {
  if (!age) return null;
  return age.system ?? age.era ?? age.eon ?? null;
}

export const ageSortOrder = [
  'Quaternary',
  'Neogene',
  'Paleogene',
  'Cretaceous',
  'Jurassic',
  'Triassic',
  'Permian',
  'Carboniferous',
  'Devonian',
  'Silurian',
  'Ordovician',
  'Cambrian',
];
