import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { GeologyType } from '~/apollo/generated/v4/graphql';
import { ReactSelectInput } from '~/components/ui/forms/react-select-input';
import { Select } from '~/components/ui/forms/Select';
import type { OutcropFormValues } from '~/utils/modules/outcrop-v4';
import { readableGqlEnum } from '~/utils/text';

const ENUM_OPTIONS = graphql(`
  query OutcropEnumFieldOptions {
    outcropType: outcropEnumerations(type: OUTCROP_TYPE) {
      values
    }
    outcropCategory: outcropEnumerations(type: OUTCROP_CATEGORY) {
      values
    }
  }
`);

export function OutcropEnumFields() {
  const { data, loading } = useQuery(ENUM_OPTIONS);
  const { watch, setValue, control } = useFormContext<OutcropFormValues>();

  const geologyType = watch('geologyType');
  const dominantGeologyType = watch('dominantGeologyType');

  // Unset dominant geology type if it's no longer in the geology types value
  useEffect(() => {
    if (dominantGeologyType && !geologyType.includes(dominantGeologyType)) {
      setValue('dominantGeologyType', '');
      const message =
        'The dominant geology type is no longer available and has been unset.';
      toast.info(message);
    }
  }, [geologyType, dominantGeologyType, setValue]);

  return (
    <>
      <Controller
        control={control}
        name="geologyType"
        render={({ field }) => (
          <ReactSelectInput
            {...field}
            label={{
              label: 'Geology Type',
              helpText:
                'Select from the dropdown to reflect the key aspects of the outcrop',
            }}
            options={Object.values(GeologyType).map(value => ({
              value,
              label: readableGqlEnum(value),
            }))}
            multiple
            required
          />
        )}
      />

      <Controller
        name="outcropType"
        render={({ field }) => (
          <Select
            {...field}
            label="Outcrop type"
            options={data?.outcropType.values ?? []}
            disabled={loading}
            required
          />
        )}
      />

      <Controller
        name="dominantGeologyType"
        render={({ field }) => (
          <Select
            {...field}
            label={{
              label: 'Dominant geology type',
              helpText: 'Select the dominant geology type',
            }}
            options={geologyType.map(value => ({
              value,
              label: readableGqlEnum(value),
            }))}
            disabled={loading}
            required
          />
        )}
      />

      <Controller
        name="outcropCategory"
        render={({ field }) => (
          <Select
            {...field}
            label="Analogue category"
            options={data?.outcropCategory.values ?? []}
            disabled={loading}
            required
          />
        )}
      />
    </>
  );
}
