import { useMutation, useQuery } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type { UploadOutcropAdditionalFieldsPageQuery } from '~/apollo/generated/v4/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { OutcropAdditionalFields } from '~/components/upload/outcrop/outcrop-additional-form-fields';
import { useRouteParam } from '~/hooks/routing';
import { useZodForm } from '~/utils/forms';
import { ppArrayOrNull, ppStringOrNull } from '~/utils/zod';

type Outcrop = NonNullable<
  UploadOutcropAdditionalFieldsPageQuery['outcropGet']
>;

const additionalFieldsSchema = z.object({
  systemsTract: z.preprocess(ppArrayOrNull, z.array(z.string()).nullable()),
  shorelineTractory: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  duneShape: z.preprocess(ppArrayOrNull, z.array(z.string()).nullable()),
  channelMorphology: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  riverProfileLocation: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  dominantLithology: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  waterTemperature: z.preprocess(ppArrayOrNull, z.array(z.string()).nullable()),
  netToGross: z.preprocess(ppStringOrNull, z.string().nullable()),
  diageneticProcess: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  diageneticSetting: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  diageneticGeometry: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  tectonicSetting: z.preprocess(ppArrayOrNull, z.array(z.string()).nullable()),
  synSedimentaryDeformation: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  faultRocksMembranes: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  interactionNetwork: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  reactivation1stPhase: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  reactivation2ndPhase: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  symmetryGeometry: z.preprocess(ppArrayOrNull, z.array(z.string()).nullable()),
  multipleFolds: z.preprocess(ppArrayOrNull, z.array(z.string()).nullable()),
  secondaryStructures: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
  lateralAggregation: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullable(),
  ),
});

// The form values are just non-nullables of the above schema
type TmpFormValues = z.infer<typeof additionalFieldsSchema>;
export type OutcropAdditionalFieldsFormValues = {
  [K in keyof TmpFormValues]: NonNullable<TmpFormValues[K]>;
};

function initialValues(outcrop: Outcrop) {
  return {
    systemsTract: outcrop?.systemsTract ?? [],
    shorelineTractory: outcrop?.shorelineTractory ?? [],
    duneShape: outcrop?.duneShape ?? [],
    channelMorphology: outcrop?.channelMorphology ?? [],
    riverProfileLocation: outcrop?.riverProfileLocation ?? [],
    dominantLithology: outcrop?.dominantLithology ?? [],
    waterTemperature: outcrop?.waterTemperature ?? [],
    netToGross: outcrop?.netToGross ?? '',
    diageneticProcess: outcrop?.diageneticProcess ?? [],
    diageneticSetting: outcrop?.diageneticSetting ?? [],
    diageneticGeometry: outcrop?.diageneticGeometry ?? [],
    tectonicSetting: outcrop?.tectonicSetting ?? [],
    synSedimentaryDeformation: outcrop?.synSedimentaryDeformation ?? [],
    faultRocksMembranes: outcrop?.faultRocksMembranes ?? [],
    interactionNetwork: outcrop?.interactionNetwork ?? [],
    reactivation1stPhase: outcrop?.reactivation1stPhase ?? [],
    reactivation2ndPhase: outcrop?.reactivation2ndPhase ?? [],
    symmetryGeometry: outcrop?.symmetryGeometry ?? [],
    multipleFolds: outcrop?.multipleFolds ?? [],
    secondaryStructures: outcrop?.secondaryStructures ?? [],
    lateralAggregation: outcrop?.lateralAggregation ?? [],
  };
}

export const UPLOAD_OUTCROP_ADDITIONAL_FIELDS_PAGE = graphql(`
  query UploadOutcropAdditionalFieldsPage($id: ID!) {
    outcropGet(id: $id) {
      ...outcropParts
      keyParameters {
        ...keyParametersParts
      }
    }
  }
`);

const UPDATE_OUTCROP_ADDTL_FIELDS = graphql(`
  mutation UpdateOutcropAdditionalFields(
    $id: ID!
    $input: OutcropUpdateInput!
  ) {
    outcropUpdate(id: $id, input: $input) {
      result {
        ...outcropParts
      }
    }
  }
`);

export default function UploadOutcropAdditionalFieldsPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const outcropId = useRouteParam('outcropId', parseInt);

  const { data, loading } = useQuery(UPLOAD_OUTCROP_ADDITIONAL_FIELDS_PAGE, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropGet;

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return <UpdateOutcropAdditionalFieldsForm outcrop={outcrop} />;
}

function UpdateOutcropAdditionalFieldsForm({ outcrop }: { outcrop: Outcrop }) {
  const [updateOutcrop, { loading: loadingUpdateOutcrop, error }] = useMutation(
    UPDATE_OUTCROP_ADDTL_FIELDS,
  );

  const form = useZodForm({
    schema: additionalFieldsSchema,
    values: initialValues(outcrop),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateOutcrop({
        variables: {
          id: outcrop.id,
          input: values,
        },
      });
      toast.success('Outcrop updated successfully.');
    } catch (err) {
      console.log('Error updating outcrop', err);
      toast.error('There was a problem saving the outcrop.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <OutcropAdditionalFields
          entity="outcrop"
          geologyTypes={outcrop.geologyType ?? []}
          keyParameters={outcrop.keyParameters}
        />
        <HookFormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loadingUpdateOutcrop}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
