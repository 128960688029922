import type { HTMLProps, ReactNode } from 'react';
import React, { useContext } from 'react';
import { LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { assertExhaustive, cn } from '~/utils/common';

type PanelVariant =
  | 'default'
  | 'primary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

const PanelContext = React.createContext<PanelVariant>('default');

export type PanelProps = HTMLProps<HTMLDivElement> & {
  variant?: PanelVariant;
};

export function Panel({
  variant = 'default',
  className,
  ...props
}: PanelProps) {
  return (
    <PanelContext.Provider value={variant}>
      <div
        className={cn(className, 'shadow-sm border rounded-md', {
          'border-primary': variant === 'primary',
          'border-info': variant === 'info',
          'border-success': variant === 'success',
          'border-warning': variant === 'warning',
          'border-error': variant === 'error',
          'border-slate-100': variant === 'default',
        })}
        {...props}
      />
    </PanelContext.Provider>
  );
}

function PanelHeading({ className, ...props }: HTMLProps<HTMLDivElement>) {
  const variant = useContext(PanelContext);

  return (
    <div
      className={cn(className, 'px-3 py-2', {
        'bg-primary text-white': variant === 'primary',
        'bg-info text-info-content': variant === 'info',
        'bg-success text-success-content': variant === 'success',
        'bg-warning text-warning-content': variant === 'warning',
        'bg-error text-error-content': variant === 'error',
        'bg-slate-100 text-base-content': variant === 'default',
      })}
      {...props}
    />
  );
}

type PanelTitleProps = HTMLProps<HTMLDivElement>;
function PanelTitle(props: PanelTitleProps) {
  return <div className="text-base text-base-content" {...props} />;
}

type PanelBodyProps = HTMLProps<HTMLDivElement>;
function PanelBody(props: PanelBodyProps) {
  const hasCustomPadding = props.className?.match(/\b(p)(x|y)?-\d\b/)?.length;
  return (
    <div
      {...props}
      className={cn(props.className, {
        'p-4': !hasCustomPadding,
      })}
    />
  );
}

type PanelFooterProps = {
  children?: React.ReactNode;
  align?: 'left' | 'right' | 'center';
} & HTMLProps<HTMLDivElement>;
function PanelFooter({ align, className, ...props }: PanelFooterProps) {
  return (
    <div
      className={cn(
        'bg-slate-50 px-3 py-2',
        {
          'text-left': align === 'left',
          'text-right': align === 'right',
          'text-center': align === 'center',
        },
        className,
      )}
      {...props}
    />
  );
}

Panel.Heading = PanelHeading;
Panel.Title = PanelTitle;
Panel.Body = PanelBody;
Panel.Footer = PanelFooter;

export function SimplePanel({
  title,
  children,
  variant,
}: {
  title: ReactNode;
  children?: ReactNode;
  variant?: PanelProps['variant'];
}) {
  return (
    <Panel variant={variant}>
      <Panel.Heading>
        <Panel.Title>{title}</Panel.Title>
      </Panel.Heading>
      {children && <Panel.Body>{children}</Panel.Body>}
    </Panel>
  );
}

export function panelLifecycleVariant(
  lifecycleStatus: LifecycleStatus,
): PanelProps['variant'] {
  switch (lifecycleStatus) {
    case LifecycleStatus.Draft:
      return undefined;
    case LifecycleStatus.Approved:
      return 'success';
    case LifecycleStatus.ReadyForApproval:
      return 'warning';
    case LifecycleStatus.Published:
      return 'info';
    default:
      throw assertExhaustive(lifecycleStatus);
  }
}
