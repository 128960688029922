import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import type {
  PointInput,
  UploadOutcropFieldPicturesPageQuery,
} from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { Checkbox } from '~/components/ui/forms/Checkbox';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { TextInput } from '~/components/ui/forms/text-input';
import { UpdateFieldPictureLocationField } from '~/components/upload/supporting-object/field-picture/update-field-picture-location-field';
import { UPDATE_FIELD_PICTURE } from '~/components/upload/supporting-object/so-operations';
import { useZodForm } from '~/utils/forms';
import { ppStringOrNull } from '~/utils/zod';

type Outcrop = NonNullable<UploadOutcropFieldPicturesPageQuery['outcropGet']>;
type FieldPicture = Outcrop['fieldPictures'][number];

export type UpdateFieldPictureFormValues = {
  author: string;
  description: string;
  location: PointInput;
  locationApproximate: boolean;
};

function initialValues(fp: FieldPicture): UpdateFieldPictureFormValues {
  return {
    author: fp.author ?? '',
    description: fp.description ?? '',
    location: fp.location,
    locationApproximate: fp.locationApproximate,
  };
}

const schema = z.object({
  author: z.preprocess(ppStringOrNull, z.string().nullish()),
  description: z.preprocess(ppStringOrNull, z.string().nullish()),
  location: z
    .object({
      latitude: z.number(),
      longitude: z.number(),
    })
    .nullish(),
  locationApproximate: z.boolean(),
});

type Props = {
  fieldPicture: FieldPicture;
  onUpdateSuccess: () => unknown;
};

export function UpdateFieldPictureForm({
  fieldPicture,
  onUpdateSuccess,
}: Props) {
  const [updateFieldPicture, { loading, error }] =
    useMutation(UPDATE_FIELD_PICTURE);

  const form = useZodForm({
    schema,
    values: initialValues(fieldPicture),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateFieldPicture({
        variables: {
          id: fieldPicture.id,
          input: values,
        },
      });
      toast.success('Changes saved successfully.');
      onUpdateSuccess();
    } catch (err) {
      console.log('Error updating field picture', err);
      toast.error('There was a problem saving, please try again.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <div className="space-y-2">
          <TextInput {...form.register('author')} label="Author" />
          <TextInput {...form.register('description')} label="Description" />

          <div className="space-y-2 text-center">
            <UpdateFieldPictureLocationField />
            <Checkbox
              {...form.register('locationApproximate')}
              label="Approximate location"
            />
          </div>

          <HookFormErrors graphQLError={error} />

          <div className="text-center">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
