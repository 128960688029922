import { useQuery } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { graphql } from '~/apollo/generated/v4';
import type { OutcropQualityParametersFieldOptionsQuery } from '~/apollo/generated/v4/graphql';
import { Select } from '~/components/ui/forms/Select';
import type { OutcropFormValues } from '~/utils/modules/outcrop-v4';

const OUTCROP_QUALITY_PARAMETERS_FIELD_OPTIONS = graphql(`
  query OutcropQualityParametersFieldOptions {
    depositionalDipDirection: outcropEnumerations(
      type: DEPOSITIONAL_DIP_DIRECTION
    ) {
      values
    }
    orientation: outcropEnumerations(type: ORIENTATION) {
      values
    }
    structuralComplexity: outcropEnumerations(type: STRUCTURAL_COMPLEXITY) {
      values
    }
    exposureQuality: outcropEnumerations(type: EXPOSURE_QUALITY) {
      values
    }
    outcrop3dControl: outcropEnumerations(type: OUTCROP_3D_CONTROL) {
      values
    }
    datasetScale: outcropEnumerations(type: DATASET_SCALE) {
      values
    }
  }
`);

export function OutcropQualityParametersFormFields() {
  const { control } = useFormContext<OutcropFormValues>();
  const { data, loading } = useQuery(OUTCROP_QUALITY_PARAMETERS_FIELD_OPTIONS);

  function options(
    key: keyof Omit<OutcropQualityParametersFieldOptionsQuery, '__typename'>,
  ) {
    return data?.[key].values ?? [];
  }

  return (
    <div className="space-y-2">
      <Controller
        control={control}
        name="depositionalDipDirection"
        render={({ field }) => (
          <Select
            {...field}
            label={{
              label: 'Depositional Dip Direction',
              helpText: 'The dominant direction for the study interval.',
            }}
            options={options('depositionalDipDirection')}
            disabled={loading}
          />
        )}
      />

      <Controller
        control={control}
        name="orientation"
        render={({ field }) => (
          <Select
            {...field}
            label={{
              label: 'Outcrop Orientation',
              helpText: 'Dominant orientation of the outcrop.',
            }}
            options={options('orientation')}
            disabled={loading}
          />
        )}
      />

      <Controller
        control={control}
        name="outcrop3dControl"
        render={({ field }) => (
          <Select
            {...field}
            label={{
              label: 'Outcrop 3D control',
              helpText: 'How good is the 3D control?',
            }}
            options={options('outcrop3dControl')}
            disabled={loading}
            required
          />
        )}
      />

      <Controller
        control={control}
        name="exposureQuality"
        render={({ field }) => (
          <Select
            {...field}
            label={{
              label: 'Exposure Quality',
              helpText: 'How good is the outcrop?',
            }}
            options={options('exposureQuality')}
            disabled={loading}
            required
          />
        )}
      />

      <Controller
        control={control}
        name="structuralComplexity"
        render={({ field }) => (
          <Select
            {...field}
            label={{
              label: 'Structural Complexity',
              helpText: 'How deformed is the outcrop?',
            }}
            options={options('structuralComplexity')}
            disabled={loading}
            required
          />
        )}
      />

      <Controller
        control={control}
        name="datasetScale"
        render={({ field }) => (
          <Select
            {...field}
            label={{
              label: 'Dataset Scale',
              helpText:
                'Select the appropriate scale: Lithofacies - metre; AE at the tens m; SE at the hundreds of m, depositional system >1km',
            }}
            options={options('datasetScale')}
            disabled={loading}
            required
          />
        )}
      />
    </div>
  );
}
