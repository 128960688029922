import { useQuery } from '@apollo/client';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreateMiniModelForm } from '~/components/upload/supporting-object/mini-model/create-mini-model-form';
import { MiniModelItem } from '~/components/upload/supporting-object/mini-model/mini-model-item';
import { Alert } from '~/components/ui/alert';
import { CollapsibleCard } from '~/components/ui/collapse';
import { useRouteParam } from '~/hooks/routing';
import { createRefetchQueries } from '~/utils/graphql';
import { LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { useShowUnpublished } from '~/components/upload/supporting-object/use-show-unpublished';

const UPLOAD_OUTCROP_MINI_MODELS_PAGE = graphql(`
  query UploadOutcropMiniModelsPage($id: ID!) {
    outcropGet(id: $id) {
      id
      geologyType
      miniModels {
        ...miniModelParts
        placement {
          id
        }
      }
    }
  }
`);

export default function UploadOutcropMiniModelsPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const outcropId = useRouteParam('outcropId', parseInt);
  const [isCreating, setIsCreating] = useState(false);

  const queryVars = { id: outcropId };

  const { data, loading } = useQuery(UPLOAD_OUTCROP_MINI_MODELS_PAGE, {
    variables: queryVars,
  });

  const refetchQueries = createRefetchQueries([
    { query: UPLOAD_OUTCROP_MINI_MODELS_PAGE, variables: queryVars },
  ]);

  const outcrop = data?.outcropGet;
  const allMiniModels = R.sortBy(mm => mm.name, outcrop?.miniModels ?? []);
  const awaitingApproval = allMiniModels.reduce(
    (acc, cur) =>
      acc + (cur.lifecycleStatus === LifecycleStatus.ReadyForApproval ? 1 : 0),
    0,
  );
  const unpublishedCount = allMiniModels.reduce(
    (acc, mm) =>
      acc + (mm.lifecycleStatus !== LifecycleStatus.Published ? 1 : 0),
    0,
  );

  const { PublishedToggle, showUnpublished } =
    useShowUnpublished(unpublishedCount);

  const miniModels = R.pipe(
    () => allMiniModels,
    mms =>
      showUnpublished
        ? mms
        : mms.filter(mm => mm.lifecycleStatus === LifecycleStatus.Published),
    mms =>
      R.sortWith(
        [R.ascend(mm => mm.priority ?? Infinity), R.ascend(mm => mm.name)],
        mms,
      ),
  )();

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      <CollapsibleCard
        title="Create Mini-Model"
        icon="arrow"
        open={isCreating}
        onToggle={() => setIsCreating(!isCreating)}
      >
        <CreateMiniModelForm
          outcropId={outcropId}
          geologyTypes={outcrop.geologyType ?? []}
          refetchQueries={refetchQueries}
          onCreateSuccess={() => setIsCreating(false)}
        />
      </CollapsibleCard>

      <PublishedToggle />

      <NoItemsAlert show={!miniModels.length} entity="mini-models" />

      <div className="space-y-4">
        {awaitingApproval > 0 && (
          <Alert status="warning">
            <FontAwesomeIcon icon={faFlag} /> {awaitingApproval} mini-models are
            ready for approval.
          </Alert>
        )}

        {miniModels.map(mm => (
          <MiniModelItem
            key={mm.id}
            outcropId={outcropId}
            miniModel={mm}
            geologyTypes={outcrop.geologyType ?? []}
            refetchQueries={refetchQueries}
          />
        ))}
      </div>
    </div>
  );
}
