import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { graphql } from '~/apollo/generated/v3';
import {
  BookmarkParentType,
  BookmarkTargetType,
} from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { PictureThumbnail } from '~/components/common/PictureThumbnail';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useShowUnpublished } from '~/components/upload/supporting-object/use-show-unpublished';
import { useStudyOutletContext } from '~/routes/study/$studyId';
import { createRefetchQueries } from '~/utils/graphql';
import { soBookmarkPath } from '~/utils/modules/bookmark';

const STUDY_PICTURES_ROUTE = graphql(`
  query StudyPicturesRoute($studyId: Int!) {
    studyList(id: $studyId) {
      ...studyParts
      pictures {
        ...pictureParts
        file {
          ...fileParts
          signedUrl
        }
      }
    }
  }
`);

export default function StudyPicturesRoute() {
  const ctx = useStudyOutletContext();
  const studyId = ctx.study.id;

  const { data, loading } = useQuery(STUDY_PICTURES_ROUTE, {
    variables: { studyId },
  });

  const refetchQueries = createRefetchQueries([
    { query: STUDY_PICTURES_ROUTE, variables: { studyId } },
  ]);

  const study = data?.studyList.find(s => s.id === studyId);
  const allPictures = study?.pictures ?? [];

  const unpublishedCount = allPictures.reduce(
    (acc, pic) => acc + (pic.published ? 0 : 1),
    0,
  );

  const { showUnpublished, PublishedToggle } =
    useShowUnpublished(unpublishedCount);

  const pictures = allPictures
    .filter(picture => showUnpublished || picture.published)
    .sort(R.ascend(picture => picture.priority ?? Infinity));

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <div className="space-y-4">
      <PublishedToggle />

      <div className="grid md:grid-cols-2 gap-4">
        {pictures.map(picture => (
          <div key={picture.id}>
            <PictureThumbnail
              picture={picture}
              imgSrc={picture.file.signedUrl}
              bookmarkable={{
                parentType: BookmarkParentType.Study,
                parentId: studyId,
                targetType: BookmarkTargetType.Picture,
                targetId: picture.id,
                path: soBookmarkPath(
                  'study',
                  studyId,
                  BookmarkParentType.Study,
                  picture.outcropTagId,
                ),
              }}
              refetchQueries={refetchQueries}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
