import { faPencil, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button } from '~/components/ui/button';

export function useEditToggle() {
  const [isEditing, setIsEditing] = useState(false);

  function EditButton() {
    return (
      <Button
        type="button"
        color="ghost"
        onClick={() => setIsEditing(true)}
        size="xs"
        startIcon={<FontAwesomeIcon icon={faPencil} />}
      >
        Edit
      </Button>
    );
  }

  function CancelButton() {
    return (
      <Button
        type="button"
        color="ghost"
        onClick={() => setIsEditing(false)}
        size="xs"
        startIcon={<FontAwesomeIcon icon={faX} />}
      >
        Cancel
      </Button>
    );
  }

  return {
    isEditing,
    setIsEditing,
    EditButtonToggle: isEditing ? CancelButton : EditButton,
  };
}
