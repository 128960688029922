import { z } from 'zod';

z.setErrorMap((issue, ctx) => {
  if (issue.code === 'invalid_type' && issue.received === 'null') {
    return { message: 'This field is required' };
  }

  return {
    message: ctx.defaultError,
  };
});

// Preprocessors

/** Casts a zero-length string to null */
export function ppStringOrNull(originalValue: unknown) {
  let castedValue: string;

  if (typeof originalValue === 'number') {
    // Cast numbers to strings
    castedValue = String(originalValue);
  } else if (typeof originalValue === 'object') {
    // Get objects outta here
    return null;
  } else if (!originalValue) {
    // Throw away falsy values
    return null;
  } else {
    castedValue = String(originalValue).trim();
  }

  return castedValue.length > 0 ? castedValue : null;
}

/** Casts a string into a number or null */
export function ppNumberOrNull(originalValue: unknown): number | null {
  if (typeof originalValue === 'number') {
    return originalValue;
  } else if (typeof originalValue === 'string') {
    const value = originalValue.trim();
    return value.length ? Number(value) : null;
  }

  return null;
}

/** Converts empty arrays into a null value */
export function ppArrayOrNull<T>(originalValue: unknown): T[] | null {
  if (!Array.isArray(originalValue)) {
    console.error("Original value can't be pre-processed as an array!");
    return null;
  }

  if (!originalValue.length) {
    return null;
  }

  return originalValue;
}
