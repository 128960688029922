export const pictureTypes: [string, ...string[]] = [
  'single overview photo',
  'panorama overview photo',
  'detailed photo',
  'figure',
  'map',
  'other',
  'undefined',
  'photo montage',
  'log',
  'table',
  'core photo',
  'thin-section',
  'data plot',
  'schematic diagrams',
];
