import { useFormContext } from 'react-hook-form';
import { TextInput } from '~/components/ui/forms/text-input';
import type { VideoFormValues } from '~/utils/modules/supporting-object';

export function VideoFormFields() {
  const { register } = useFormContext<VideoFormValues>();

  return (
    <div className="space-y-2">
      <TextInput {...register('name')} label="Name" required />
      <TextInput
        {...register('url')}
        label={{
          label: 'URL',
          helpText: (
            <div>
              <p>Vimeo embed src:</p>
              <p className="text-xs text-muted">
                https://player.vimeo.com/video/447113021?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479
              </p>
            </div>
          ),
        }}
        placeholder="https://player.vimeo.com/video/..."
        required
      />

      <div className="grid lg:grid-cols-2 gap-6">
        <TextInput {...register('collectedBy')} label="Collected by" required />
        <TextInput
          type="number"
          {...register('yearCollected')}
          label="Year collected"
          required
          placeholder={new Date().getFullYear().toString()}
        />
      </div>

      <TextInput {...register('equipment')} label="Equipment" required />
    </div>
  );
}
