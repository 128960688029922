import { useQuery } from '@apollo/client';
import type { FieldProps } from 'formik';
import React from 'react';
import { graphql } from '~/apollo/generated/v3';
import { FormikSelectField } from '~/components/common/FormikField/FormikSelectField';

const OUTCROP_TAG_FIELD = graphql(`
  query OutcropTagField($studyId: Int!) {
    studyList(id: $studyId) {
      id
      outcrops {
        id
        name
      }
    }
  }
`);

type Props = FieldProps & {
  studyId: number;
};

/** Field for selecting an outcrop associated with the given study */
export function OutcropTagFieldFormik({
  form,
  field,
  studyId,
  ...props
}: Props) {
  const { data, loading } = useQuery(OUTCROP_TAG_FIELD, {
    variables: { studyId },
  });

  const study = data?.studyList.find(s => s.id === studyId);
  const options = study?.outcrops.map(oc => ({ value: oc.id, label: oc.name }));

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    form.setFieldValue(field.name, ~~e.target.value || '');

  return (
    <FormikSelectField
      {...props}
      field={field}
      form={form}
      onChange={onChange}
      options={options ?? []}
      disabled={loading}
    />
  );
}
