import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type {
  StudyEnumsQuery,
  UploadStudyUpdatePageQuery,
} from '~/apollo/generated/v4/graphql';
import { Panel } from '~/components/common/Panel';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { StudyFormFields } from '~/components/upload/study/study-form-fields';
import { filterUnique } from '~/utils/common';
import { useZodForm } from '~/utils/forms';
import { initialStudy, studySchemaUpdate } from '~/utils/modules/study';

const UPDATE_STUDY = graphql(`
  mutation UpdateStudy($id: ID!, $input: StudyUpdateInput!) {
    studyUpdate(id: $id, input: $input) {
      result {
        ...studyUpdateParts
      }
    }
  }
`);

export function UpdateStudyForm({
  study,
  studyEnums,
}: {
  study: NonNullable<UploadStudyUpdatePageQuery['studyGet']>;
  studyEnums: StudyEnumsQuery;
}) {
  const [updateStudy, { loading, error }] = useMutation(UPDATE_STUDY);

  const form = useZodForm({
    schema: studySchemaUpdate,
    values: initialStudy(study),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateStudy({
        variables: {
          id: study.id,
          input: values,
        },
      });
      toast.success('Study updated successfully');
    } catch (err) {
      console.log('Error updating study', err);
      toast.error('There was a problem updating the study.');
    }
  });

  const geologyTypes = study.outcrops
    .flatMap(oc => oc.geologyType ?? [])
    .filter(filterUnique);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Study Details</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="space-y-4">
              <StudyFormFields
                mode="update"
                studyEnums={studyEnums}
                geologyTypes={geologyTypes ?? []}
              />

              <HookFormErrors graphQLError={error} />
            </div>
          </Panel.Body>

          <Panel.Footer align="right">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </form>
    </FormProvider>
  );
}

export default UpdateStudyForm;
