import { useState } from 'react';
import { Badge } from '~/components/ui/badge';
import { cn } from '~/utils/common';

export function useShowUnpublished(numUnpublished: number) {
  const [showUnpublished, setShowUnpublished] = useState(true);

  const toggleVisibility = () => setShowUnpublished(!showUnpublished);

  function PublishedToggle() {
    return (
      <label
        className={cn('label', {
          disabled: !numUnpublished,
        })}
      >
        <input
          type="checkbox"
          checked={showUnpublished}
          onChange={toggleVisibility}
          className="checkbox checkbox-sm"
          disabled={!numUnpublished}
        />
        Show unpublished items <Badge color="ghost">{numUnpublished}</Badge>
      </label>
    );
  }

  return { showUnpublished, PublishedToggle };
}
