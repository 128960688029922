import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FieldProps } from 'formik';
import { ErrorMessage } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { filterUnique } from '~/utils/common';
import { FormLabel } from './FormLabel';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';

function splitTags(tags: string) {
  return tags.split(',').filter(Boolean);
}

type Props = FieldProps & {
  isInvalid?: boolean;
  label?: React.ReactNode;
  helpText?: string;
  required?: boolean;
  renderInput?: (input: JSX.Element) => JSX.Element;
  /** Store the values as text instead of a string-array */
  textField?: boolean;
  disabled?: boolean;
};

export function FormikTagsinputField({
  form,
  field,
  isInvalid,
  label,
  helpText,
  required = false,
  renderInput,
  textField = false,
  disabled,
  ...props
}: Props) {
  const { setFieldValue } = form;
  const { name: fieldName } = field;

  const initialValue: string = textField
    ? field.value
    : field.value.slice().sort().join(',');

  const [tags, setTags] = useState<string>(initialValue);
  const [inputValue, setInputValue] = useState('');

  const valueStrValue = useMemo((): string => {
    if (textField) {
      return splitTags(field.value).sort().join(',');
    } else {
      return field.value.slice().sort().join(',');
    }
  }, [field.value, textField]);

  useEffect(() => {
    setTags(splitTags(valueStrValue).sort().join(','));
  }, [valueStrValue]);

  useEffect(() => {
    if (textField) {
      setFieldValue(fieldName, tags);
    } else {
      setFieldValue(fieldName, splitTags(tags));
    }
  }, [tags, fieldName, setFieldValue, textField]);

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const value = inputValue.trim();
      if (value.length) {
        setTags(prevTags =>
          splitTags(prevTags)
            .concat([value])
            .sort()
            .filter(filterUnique)
            .join(','),
        );
      }

      setInputValue('');
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  function removeTag(tag: string) {
    setTags(prevTags =>
      splitTags(prevTags)
        .filter(t => t !== tag)
        .join(','),
    );
  }

  const tagsField = (
    <div className="input input-bordered w-full h-auto min-h-10 py-1 flex gap-2 flex-wrap">
      {splitTags(tags).map((tag, index) => (
        <Badge key={index} color="ghost">
          {tag}
          <Button
            type="button"
            color="ghost"
            size="xs"
            onClick={() => removeTag(tag)}
          >
            <FontAwesomeIcon icon={faXmarkCircle} className="text-xs" />
          </Button>
        </Badge>
      ))}

      <input
        {...props}
        type="text"
        name={field.name}
        value={inputValue}
        onChange={handleInputChange}
        readOnly={disabled}
        placeholder="Add value"
        onKeyDown={handleKeyPress}
        className="grow"
      />
    </div>
  );

  if (renderInput) return renderInput(tagsField);

  return (
    <div className="form-group">
      <FormLabel
        name={field.name}
        label={label}
        helpText={helpText}
        required={required}
      />
      {tagsField}
      {isInvalid && (
        <label className="label">
          <span className="label-text-alt text-error">
            <ErrorMessage name={field.name} />
          </span>
        </label>
      )}
      {!disabled && (
        <label className="label">
          <label className="label-text-alt">
            Type a value into the text box and press{' '}
            <span className="kbd">return</span> or{' '}
            <span className="kbd">,</span> to add it to the list.
          </label>
        </label>
      )}
    </div>
  );
}
