import { kml as toGeoJSON } from '@tmcw/togeojson';
import { v4 } from 'uuid';
import type {
  GeoreferenceCreateInput,
  GeoreferenceParentType,
} from '~/apollo/generated/v4/graphql';
import { GeoreferenceDataType } from '~/apollo/generated/v3/graphql';

export type GeoJSONFeatureCollection = {
  type: 'FeatureCollection';
  features: GeoJSONFeature[];
};

enum GeoJSONGeometryType {
  Point = 'Point',
  Polygon = 'Polygon',
  LineString = 'LineString',
}

type GeoJSONCoordinates = [number, number];

type GeoJSONGeometry =
  | {
      type: GeoJSONGeometryType.Point;
      coordinates: GeoJSONCoordinates;
    }
  | {
      type: GeoJSONGeometryType.LineString;
      coordinates: GeoJSONCoordinates[];
    }
  | {
      type: GeoJSONGeometryType.Polygon;
      coordinates: GeoJSONCoordinates[] | GeoJSONCoordinates[][];
    };

type GeoJSONProperties = {
  /** Georeference name */
  name: string;
  /** Georeference description */
  description: string;
};

type GeoJSONFeature = {
  type: 'Feature';
  geometry: GeoJSONGeometry;
  properties: GeoJSONProperties;
};

export type GeoreferenceImportUploadStatus =
  | null
  | 'pending'
  | 'success'
  | 'failed';

export type GeoreferenceImportInput = GeoreferenceCreateInput & {
  tempId: string;
  isEditing: boolean;
  uploadStatus: GeoreferenceImportUploadStatus;
};

function geoJSONGeometryTypeToGeoreferenceDataType(
  geometryType: GeoJSONGeometryType,
): GeoreferenceDataType {
  switch (geometryType) {
    case GeoJSONGeometryType.Point:
      return GeoreferenceDataType.Point;

    case GeoJSONGeometryType.Polygon:
      return GeoreferenceDataType.Polygon;

    case GeoJSONGeometryType.LineString:
      return GeoreferenceDataType.Polyline;

    default:
      throw new Error(`Unsupported GeoJSON geometry type '${geometryType}`);
  }
}

function geoJSONFeatureToGeoreference(
  parentType: GeoreferenceParentType,
  parentId: number,
  feature: GeoJSONFeature,
): GeoreferenceImportInput {
  const dataType = geoJSONGeometryTypeToGeoreferenceDataType(
    feature.geometry.type,
  );

  const input: GeoreferenceImportInput = {
    parentType,
    parentId,
    dataType,
    tempId: v4(),
    uploadStatus: null,
    isEditing: false,
    name: feature.properties.name,
    description: feature.properties.description,
    data: [],
  };

  if (feature.geometry.type === GeoJSONGeometryType.Point) {
    const [lng, lat] = feature.geometry.coordinates;
    input.data = [{ lat, lng }];
  } else if (feature.geometry.type === GeoJSONGeometryType.LineString) {
    input.data = feature.geometry.coordinates.map(([lng, lat]) => ({
      lat,
      lng,
    }));
  } else {
    // It's possible for the input to have multiple polygons. Safari only supports
    // a single polygon per georeference, so we will only take the first one in the list.
    // (TypeScript can't seem to infer the check being done here)
    const coords =
      feature.geometry.coordinates[0] instanceof Array
        ? (feature.geometry.coordinates[0] as GeoJSONCoordinates[])
        : (feature.geometry.coordinates as GeoJSONCoordinates[]);

    input.data = coords.map(([lng, lat]) => ({ lat, lng }));
  }

  return input;
}

export function kmlToGeoreferences(
  parentType: GeoreferenceParentType,
  parentId: number,
  kml: string,
): GeoreferenceImportInput[] {
  const featureCollection: GeoJSONFeatureCollection = toGeoJSON(
    new DOMParser().parseFromString(kml, 'text/xml'),
  );
  console.log('Feature collection:', featureCollection);
  try {
    const georeferences = featureCollection.features.map(feature =>
      geoJSONFeatureToGeoreference(parentType, parentId, feature),
    );
    return georeferences;
  } catch (err) {
    console.log('Error parsing feature collection', err);
    return [];
  }
}
