import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Select } from '~/components/ui/forms/Select';

async function getTemperatureOptions(region: string) {
  await new Promise(resolve => setTimeout(resolve, 500));

  if (region === 'north') {
    return ['warm', 'cold', 'really cold'];
  } else if (region === 'south') {
    return ['hot', 'warm', 'cold'];
  } else {
    return [];
  }
}

export function ZZZTestPage() {
  const [tempOptions, setTempOptions] = useState<string[]>([]);

  const form = useForm({
    values: {
      region: '',
      temperatures: ['warm'],
    },
  });

  const region = form.watch('region');

  useEffect(() => {
    getTemperatureOptions(region).then(options => {
      setTempOptions(options);
    });
  }, [region]);

  console.log('Form values:', form.watch());

  return (
    <FormProvider {...form}>
      <div className="w-72">
        <Select
          {...form.register('region')}
          label="Region"
          options={['north', 'south']}
        />
        <Controller
          control={form.control}
          name="temperatures"
          render={({ field }) => (
            <Select
              {...field}
              label="Temperatures"
              options={tempOptions}
              multiple
            />
          )}
        />
      </div>
    </FormProvider>
  );
}
