import { useQuery } from '@apollo/client';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import type { Viewer } from 'cesium';
import { Cartesian3, Cartographic, HeadingPitchRoll } from 'cesium';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import type { TilesetParams } from '~/components/cesium/cesiumUtils';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import type { CaLocationPartsFragment } from '~/apollo/generated/v3/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerIcon } from '~/components/common/SpinnerIcon';
import { PageInner } from '~/components/vft-viewer/viewer';
import { Heading } from '~/components/common/Heading';
import { graphql } from '~/apollo/generated/v4';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import type { OutcropVftViewerPageQuery } from '~/apollo/generated/v4/graphql';

const outcropVFTViewerPageQuery = graphql(`
  query OutcropVFTViewerPage($id: ID!) {
    outcropGet(id: $id) {
      id
      name
      virtualOutcropModels {
        id
        name
        cesiumAsset {
          ...cesiumAssetParts
          tilesetToken {
            token
          }
          location {
            ...cesiumLocationParts
          }
          defaultCamera {
            ...cesiumLocationParts
          }
        }
      }
      pictures {
        ...placementPictureParts
        placement {
          ...placementSOPlacementParts
        }
      }
      facies {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      crossSections {
        id
        name
        georeferences {
          ...placementGeorefParts
        }
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      sedimentaryLogs {
        id
        name
        georeferences {
          ...placementGeorefParts
        }
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      wellLogs {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      production {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      reservoirModels {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      trainingImages {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      variograms {
        id
        name
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      gigaPans {
        id
        name
        georeferences {
          ...placementGeorefParts
        }
        pictures {
          ...placementPictureParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      fieldPictures {
        id
        file {
          ...fileParts
        }
        placement {
          ...placementSOPlacementParts
        }
      }
      miniModels {
        id
        name
        url
        placement {
          ...placementSOPlacementParts
        }
      }
      photo360s {
        id
        name
        url
        placement {
          ...placementSOPlacementParts
        }
      }
      videos {
        id
        name
        url
        placement {
          ...placementSOPlacementParts
        }
      }

      studies {
        id
        name
        collectedBy
        collectedYear
        pictures {
          ...placementPictureParts
          placement {
            ...placementSOPlacementParts
          }
        }
        facies {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        crossSections {
          id
          name
          outcropTagId
          georeferences {
            ...placementGeorefParts
          }
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        sedimentaryLogs {
          id
          name
          outcropTagId
          georeferences {
            ...placementGeorefParts
          }
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        wellLogs {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        production {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        reservoirModels {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        trainingImages {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        variograms {
          id
          name
          outcropTagId
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
        gigaPans {
          id
          name
          outcropTagId
          georeferences {
            ...placementGeorefParts
          }
          pictures {
            ...placementPictureParts
          }
          placement {
            ...placementSOPlacementParts
          }
        }
      }
    }
  }
`);

type Outcrop = NonNullable<OutcropVftViewerPageQuery['outcropGet']>;

type CALocation = Pick<
  CaLocationPartsFragment,
  'latitude' | 'longitude' | 'height' | 'heading' | 'pitch' | 'roll'
>;

type VftViewerPageContextValue = {
  outcrop: Outcrop;
  setViewer: (viewer: Viewer) => void;
  visibleTilesets: TilesetParams[];
  viewer: Viewer | null;
  flyToLocation: (dc: CALocation, index: number) => void;
  vftState: number;
  setVftState: (n: number) => void;
};

const VftViewerPageContext = createContext<VftViewerPageContextValue | null>(
  null,
);

export function initialTilesets(outcrop: Outcrop): TilesetParams[] {
  return outcrop.virtualOutcropModels
    .map(vom => vom.cesiumAsset)
    .reduce<TilesetParams[]>((acc, cur) => {
      if (
        cur &&
        cur.tilesetToken?.token &&
        cur.tilesetUrlLocal &&
        cur.location
      ) {
        acc.push({
          transform: {
            location: Cartographic.fromDegrees(
              cur.location.longitude,
              cur.location.latitude,
              cur.location.height,
            ),
            orientation: new HeadingPitchRoll(
              cur.location.heading,
              cur.location.pitch,
              cur.location.roll,
            ),
          },
          assetToken: cur.tilesetToken?.token,
          localPath: cur.tilesetUrlLocal,
        });
      }
      return acc;
    }, []);
}

function VftViewerPageContextProvider({
  outcrop,
  children,
}: {
  outcrop: Outcrop;
  children: ReactNode;
}) {
  const availableTilesets = useMemo(() => initialTilesets(outcrop), [outcrop]);

  const [viewer, setViewer] = useState<Viewer | null>(null);
  const [vftState, setVftState] = useState<number>(-1);
  const [visibleTilesets] = useState<TilesetParams[]>([...availableTilesets]);

  function flyToLocation(loc: CALocation, i: number) {
    viewer?.scene.camera.flyTo({
      destination: Cartesian3.fromDegrees(
        loc.longitude,
        loc.latitude,
        loc.height,
      ),
      orientation: new HeadingPitchRoll(
        loc.heading || 0,
        loc.pitch || 0,
        loc.roll || 0,
      ),
    });
    setVftState(i);
  }

  return (
    <VftViewerPageContext.Provider
      value={{
        outcrop,
        setViewer,
        viewer,
        visibleTilesets,
        setVftState,
        vftState,
        flyToLocation,
      }}
    >
      {children}
    </VftViewerPageContext.Provider>
  );
}

export function useVftViewerPageContext() {
  const ctx = useContext(VftViewerPageContext);
  invariant(ctx, 'VftViewerPageContext not initialized!');
  return ctx;
}

export function OutcropVftViewerRoute() {
  useBreadcrumb('routes/outcrop/vft-viewer', 'Virtual Fieldtrips Viewer');
  const params = useParams();
  invariant(params.outcropId, 'Outcrop ID missing from route params');
  const outcropId = parseInt(params.outcropId);

  const { data, loading } = useQuery(outcropVFTViewerPageQuery, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropGet;

  if (loading) return <SpinnerIcon />;
  if (!outcrop) return <NotFound />;

  return (
    <VftViewerPageContextProvider outcrop={outcrop}>
      <Heading level={2} className="text-center">
        Virtual Field Trip
      </Heading>
      <PageInner outcrop={outcrop} />
    </VftViewerPageContextProvider>
  );
}

export default function V4Wrapper() {
  return (
    <ApolloProviderV4>
      <OutcropVftViewerRoute />
    </ApolloProviderV4>
  );
}
