import { faWarning, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { GoogleMapProps } from '@react-google-maps/api';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { FieldPicsOutcropListQuery } from '~/apollo/generated/v4/graphql';
import type { ButtonProps } from '~/components/ui/button';
import { Button } from '~/components/ui/button';
import {
  CollapsibleCard,
  useControlledCollapsibleCard,
} from '~/components/ui/collapse';
import { TextInput } from '~/components/ui/forms/text-input';
import type { BulkFieldPictureUploaderFormValues } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader';
import { OutcropPicker } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader/outcrop-picker';

type Outcrop = NonNullable<
  NonNullable<FieldPicsOutcropListQuery['outcropList']>['results']
>[number];

export function DefaultValuesFormV4({
  initialOutcropId,
  outcrops,
}: {
  /** When embedded on the outcrop page, provide initialOutcropId to lock the form to this outcrop */
  initialOutcropId?: number;
  outcrops: Outcrop[];
}) {
  const { register, watch, setValue } =
    useFormContext<BulkFieldPictureUploaderFormValues>();

  const [googleMap, setGoogleMap] = useState<google.maps.Map>();
  const collapsibleCard = useControlledCollapsibleCard(!!initialOutcropId);

  const defaultLocationValue = watch('defaults.location');
  const defaultOutcropIdvalue = watch('defaults.outcropId');

  const metadata = watch('metadata');

  function handleOutcropChange(outcropId: number | null) {
    const outcropCenter = outcrops.find(
      oc => parseInt(oc.id) === outcropId,
    )?.center;

    if (outcropCenter) {
      setValue('defaults.outcropId', outcropId);
      setValue('defaults.location', {
        latitude: outcropCenter.lat,
        longitude: outcropCenter.lng,
      });

      googleMap?.panTo(outcropCenter);
      googleMap?.setZoom(10);
    } else {
      setValue('defaults.outcropId', outcropId);
    }
  }

  const handleMapLoaded: GoogleMapProps['onLoad'] = map => {
    map.setCenter({ lat: 30, lng: 0 });
    map.setZoom(2);
    setGoogleMap(map);
  };

  function handleMapMouseEvent(event: google.maps.MapMouseEvent) {
    const loc = event.latLng?.toJSON();
    if (loc) {
      const location = {
        latitude: loc.lat,
        longitude: loc.lng,
      };
      setValue('defaults.location', location);

      for (let i = 0; i < metadata.length; i++) {
        const item = metadata[i];
        if (!item.location) {
          setValue(`metadata.${i}.location`, location);
        }
      }
    } else {
      setValue('defaults.location', null);
    }
  }

  const handleClearLocation: ButtonProps['onClick'] = () => {
    setValue('defaults.location', null);
  };

  return (
    <CollapsibleCard
      {...collapsibleCard}
      title="Default Values"
      icon="arrow"
      titleClassName="card-title"
    >
      <div className="space-y-2">
        <div className="grid lg:grid-cols-2 gap-4">
          <div>
            <TextInput {...register('defaults.author')} label="Author" />
          </div>
          <div>
            <OutcropPicker
              name="defaults.outcropId"
              value={initialOutcropId ?? defaultOutcropIdvalue}
              onChange={handleOutcropChange}
              outcrops={outcrops}
              disabled={!!initialOutcropId}
            />
          </div>
        </div>

        <div className="space-y-2">
          <label className="label">Default location</label>

          <GoogleMap
            mapContainerClassName="w-full h-[400px]"
            onLoad={handleMapLoaded}
            onClick={handleMapMouseEvent}
            options={{
              mapTypeId: google.maps.MapTypeId.HYBRID,
              fullscreenControl: false,
              streetViewControl: false,
            }}
          >
            {defaultLocationValue && (
              <MarkerF
                position={{
                  lat: defaultLocationValue.latitude,
                  lng: defaultLocationValue.longitude,
                }}
                onDragEnd={handleMapMouseEvent}
                draggable
              />
            )}
          </GoogleMap>

          <div className="bg-amber-50 border border-amber-100 rounded-md py-1 px-4 text-warning-content">
            <FontAwesomeIcon icon={faWarning} className="text-warning mr-2" />
            Changing the default location will modify all pending uploads.
            Pictures with embedded location EXIF data will not be affected.
          </div>
        </div>

        {defaultLocationValue && (
          <div className="text-center">
            <Button
              type="button"
              color="ghost"
              size="sm"
              onClick={handleClearLocation}
              startIcon={<FontAwesomeIcon icon={faXmark} />}
            >
              Clear default location
            </Button>
          </div>
        )}
      </div>
    </CollapsibleCard>
  );
}
