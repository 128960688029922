import type {
  RegionInput,
  RegionPartsFragment,
} from '~/apollo/generated/v3/graphql';
import { rejectNil } from '~/utils/common';
import { yup } from '~/utils/validation';

// Hide the map for these regions
// https://github.com/omt-tech/safari/issues/1953
export function isGlobalRegion(region: {
  location: Pick<RegionPartsFragment['location'], 'country'>;
}) {
  return region.location.country === 'Unassigned';
}

export type RegionFormValues = {
  name: string;
  description: string;
  basins: string;
  country: string;
  location: string;
};
export type LocationFormValues = {};

export function locationString(location: RegionPartsFragment['location']) {
  return [location.location, location.country].filter(rejectNil).join(', ');
}

export const initialRegion = (
  region?: RegionPartsFragment,
): RegionFormValues => ({
  name: region?.name ?? '',
  description: region?.description ?? '',
  basins: region?.basins ?? '',
  country: region?.location?.country ?? '',
  location: region?.location?.location ?? '',
});

export const regionValidationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().required(),
  basins: yup.string().nullable(),
  location: yup.string().required().label('location'),
  country: yup.string().required().label('country'),
});

export const toRegionInput = (values: RegionFormValues): RegionInput => ({
  ...values,
});
