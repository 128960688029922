import {
  faBan,
  faCheckCircle,
  faExternalLink,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { useState } from 'react';
import type { UploadOutcropPhoto360sPageQuery } from '~/apollo/generated/v4/graphql';
import { Panel } from '~/components/common/Panel';
import { DeleteUrlBasedSO } from '~/components/upload/supporting-object/delete-url-based-so';
import { UpdatePhoto360Form } from '~/components/upload/supporting-object/photo-360/update-photo-360-form';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';
import type { RefetchQueries } from '~/utils/graphql';

type Outcrop = NonNullable<UploadOutcropPhoto360sPageQuery['outcropGet']>;
type Photo360 = Outcrop['photo360s'][number];

export function Photo360Items({
  photo360s,
  refetchQueries,
}: {
  photo360s: Photo360[];
  refetchQueries?: RefetchQueries;
}) {
  return (
    <div className="space-y-4">
      {photo360s.map(item => (
        <Photo360Item
          key={item.id}
          photo360={item}
          refetchQueries={refetchQueries}
        />
      ))}
    </div>
  );
}

function Photo360Item({
  photo360,
  refetchQueries,
}: {
  photo360: Photo360;
  refetchQueries?: RefetchQueries;
}) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Panel key={photo360.id}>
      <Panel.Heading>
        <div className="flex justify-between gap-6 items-center w-full">
          <Panel.Title>
            {photo360.name}{' '}
            <span className="text-muted text-sm">{photo360.id}</span>
          </Panel.Title>

          <div className="space-x-2">
            {photo360.placement && (
              <Badge color="ghost" className="space-x-2">
                <span className="">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  />{' '}
                  Placed
                </span>
              </Badge>
            )}
          </div>
        </div>
      </Panel.Heading>

      <Panel.Body>
        {isEditing ? (
          <UpdatePhoto360Form
            photo360={photo360}
            onUpdateSuccess={() => setIsEditing(false)}
          />
        ) : (
          <ItemDetails item={photo360} />
        )}
      </Panel.Body>

      <Panel.Footer>
        <div className="flex justify-between items-center">
          <div className="text-right space-x-1">
            <Button
              type="button"
              onClick={() => setIsEditing(!isEditing)}
              color="ghost"
              size="xs"
              startIcon={
                <FontAwesomeIcon icon={isEditing ? faBan : faPencil} />
              }
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>

            {!isEditing && (
              <DeleteUrlBasedSO
                soType="Photo360"
                id={parseInt(photo360.id)}
                isPlaced={!!photo360.placement?.id}
                refetchQueries={refetchQueries}
              >
                {(deleteItem, isDeleting) => (
                  <Button
                    type="button"
                    color="ghost"
                    size="xs"
                    onClick={deleteItem}
                    disabled={isDeleting}
                    loading={isDeleting}
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Delete
                  </Button>
                )}
              </DeleteUrlBasedSO>
            )}
          </div>
        </div>
      </Panel.Footer>
    </Panel>
  );
}

function RowLabel({ children }: { children: ReactNode }) {
  return (
    <div className="lg:col-span-2 md:col-span-2 text-muted">{children}</div>
  );
}

function RowValue({ children }: { children: ReactNode }) {
  return <div className="lg:col-span-8 md:col-span-4">{children}</div>;
}

function ItemDetails({ item }: { item: Photo360 }) {
  return (
    <div className="grid lg:grid-cols-10 md:grid-cols-6 gap-x-6 gap-y-0">
      <RowLabel>URL</RowLabel>
      <RowValue>
        <div className="break-all">
          <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            {item.url}{' '}
            <FontAwesomeIcon
              icon={faExternalLink}
              className="text-sm text-slate-300"
            />
          </a>
        </div>
      </RowValue>

      <RowLabel>Collected</RowLabel>
      <RowValue>
        {item.collectedBy}, {item.yearCollected}
      </RowValue>

      <RowLabel>Equipment</RowLabel>
      <RowValue>{item.equipment}</RowValue>
    </div>
  );
}
