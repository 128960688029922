import { useQuery } from '@apollo/client';
import { Link } from 'react-router';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { FilterSearch } from '~/components/common/FilterSearch';
import { GeologyTypeIconV4 } from '~/components/common/icons/geology-type-icon-v4';
import { LifecycleStatusIcon } from '~/components/common/icons/LifecycleStatusIcon';
import { PageHeading } from '~/components/common/PageHeading';
import { Panel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useSortFilter } from '~/hooks/data';
import {
  uploadOutcropSOPlacementsRoute,
  uploadOutcropUpdateRoute,
  uploadRegionUpdateRoute,
} from '~/paths';
import { readableGqlEnum } from '~/utils/text';

const UPLOAD_VFT_OVERVIEW_PAGE = graphql(`
  query UploadVftOverviewPage {
    outcropList(first: 9999) {
      results {
        id
        name
        geologyType
        lifecycleStatus
        region {
          id
          name
          country
        }
        lifecycleStatus
        countOfSupportingObjectPlacements
      }
    }
  }
`);

export default function UploadVftOverviewPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  useBreadcrumb('routes/upload/vft-overview', 'VFT Overview');

  const { data, loading } = useQuery(UPLOAD_VFT_OVERVIEW_PAGE);

  const outcrops = data?.outcropList?.results ?? [];

  const {
    items,
    sortIndicatorProps: siProps,
    filterSearchProps,
  } = useSortFilter(outcrops, 'name', 'name', 'upload-vft-overview');

  if (loading) {
    return (
      <SpinnerPlaceholder>
        Loading outcrops and their supporting objects...
      </SpinnerPlaceholder>
    );
  }

  return (
    <>
      <PageHeading>VFT Overview</PageHeading>

      <Panel>
        <Panel.Heading>
          <Panel.Title>
            Outcrop List{' '}
            <span className="text-muted text-sm">
              (<strong>{items.length}</strong> results displayed)
            </span>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Body className="space-y-4">
          <FilterSearch {...filterSearchProps} />

          <table className="table w-full table-compact table-pin-rows">
            <thead>
              <tr>
                <th className="text-center">
                  <SortTrigger
                    colName="lifecycleStatus"
                    sortIndicatorProps={siProps}
                    filterable
                    renderFilterOption={readableGqlEnum}
                  >
                    Status
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger colName="id" sortIndicatorProps={siProps}>
                    ID
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger colName="name" sortIndicatorProps={siProps}>
                    Name
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="geologyType"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Geology Type
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="region.name"
                    sortIndicatorProps={siProps}
                  >
                    Region
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="region.location.country"
                    sortIndicatorProps={siProps}
                    filterable
                  >
                    Country
                  </SortTrigger>
                </th>
                <th className="text-center">
                  <SortTrigger
                    colName="countOfSupportingObjectPlacements"
                    sortIndicatorProps={siProps}
                  >
                    Placed SOs
                  </SortTrigger>
                </th>
              </tr>
            </thead>

            <tbody>
              {items.map(outcrop => (
                <tr key={outcrop.id}>
                  <td className="text-center">
                    <LifecycleStatusIcon status={outcrop.lifecycleStatus} />
                  </td>
                  <td>{outcrop.id}</td>
                  <td>
                    <Link
                      to={uploadOutcropUpdateRoute(parseInt(outcrop.id))}
                      className="link"
                    >
                      {outcrop.name}
                    </Link>
                  </td>
                  <td>
                    <div className="space-x-1 flex items-center">
                      {outcrop.geologyType?.map(gt => (
                        <GeologyTypeIconV4
                          key={gt}
                          geologyType={gt}
                          className="h-4"
                        />
                      ))}
                    </div>
                  </td>
                  <td>
                    <Link
                      to={uploadRegionUpdateRoute(parseInt(outcrop.region.id))}
                      className="link"
                    >
                      {outcrop.region.name}
                    </Link>
                  </td>
                  <td className="whitespace-nowrap">
                    {outcrop.region.country}
                  </td>
                  <td className="text-center">
                    <Link
                      to={uploadOutcropSOPlacementsRoute(parseInt(outcrop.id))}
                      className="link"
                    >
                      {outcrop.countOfSupportingObjectPlacements}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Panel.Body>
      </Panel>
    </>
  );
}
