import { useMutation } from '@apollo/client';
import {
  faCancel,
  faCloudDownload,
  faCloudUpload,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import {
  GeoreferenceDataType,
  type GeoreferenceParentType,
  type GeoreferencePartsFragment,
} from '~/apollo/generated/v4/graphql';
import { Confirm } from '~/components/common/Confirm';
import { Heading } from '~/components/common/Heading';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { Panel } from '~/components/common/Panel';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { GeoreferenceFormFields } from '~/components/upload/georeference-v4/georeference-form-fields';
import { KmlExport } from '~/components/upload/georeference-v4/kml-export';
import { KmlImport } from '~/components/upload/georeference-v4/kml-import';
import { MapEditor } from '~/components/upload/georeference-v4/map-editor';
import { SODetailText } from '~/components/upload/supporting-object/so-detail-text';
import { useZodForm } from '~/utils/forms';
import type { RefetchQueries } from '~/utils/graphql';
import {
  georeferenceSchema,
  initialGeoreference,
  toGeoreferenceCreateInput,
} from '~/utils/modules/georeference-v4';
import { readableGqlEnum } from '~/utils/text';

const GEOREFERENCE_MGR_CREATE = graphql(`
  mutation GeoreferenceMgrCreate($input: GeoreferenceCreateInput!) {
    georeferenceCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

const GEOREFERENCE_MGR_DELETE = graphql(`
  mutation GeoreferenceMgrDelete($input: GeoreferenceDeleteInput!) {
    georeferenceDelete(input: $input)
  }
`);

type Props = {
  parentType: GeoreferenceParentType;
  parentId: number;
  georeferences: GeoreferencePartsFragment[];
  refetchQueries: RefetchQueries;
};

export function GeoreferenceManagerV4(props: Props) {
  return (
    <ApolloProviderV4>
      <GeoreferenceManagerV4Inner {...props} />
    </ApolloProviderV4>
  );
}

function GeoreferenceManagerV4Inner({
  parentType,
  parentId,
  georeferences,
  refetchQueries,
}: Props) {
  const [isCreateMode, setIsCreateMode] = useState(false);

  const toggleMode = () => setIsCreateMode(!isCreateMode);

  const [georeferenceCreate, { loading, error }] = useMutation(
    GEOREFERENCE_MGR_CREATE,
    { refetchQueries },
  );

  const form = useZodForm({
    schema: georeferenceSchema,
    values: initialGeoreference(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    const input = toGeoreferenceCreateInput(parentType, parentId, values);

    try {
      await georeferenceCreate({ variables: { input } });
      toast.success('Georeference created successfully.');
      form.reset(initialGeoreference());
    } catch (err) {
      console.log('Error creating georeference', err);
      toast.error('There was a problem creating the georeference.');
    }
  });

  const dataTypeValue = form.watch('dataType');
  const drawingModes = useMemo(() => {
    switch (dataTypeValue) {
      case GeoreferenceDataType.Centre:
      case GeoreferenceDataType.Point:
        return [google.maps.drawing.OverlayType.MARKER];
      case GeoreferenceDataType.Outline:
      case GeoreferenceDataType.Polygon:
        return [google.maps.drawing.OverlayType.POLYGON];
      case GeoreferenceDataType.Polyline:
        return [google.maps.drawing.OverlayType.POLYLINE];
      default:
        return [];
    }
  }, [dataTypeValue]);

  const hasCenter = georeferences.some(
    g => g.dataType === GeoreferenceDataType.Centre,
  );
  const hasOutline = georeferences.some(
    g => g.dataType === GeoreferenceDataType.Outline,
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <div className="grid lg:grid-cols-3 gap-4">
          <div className="space-y-4">
            <div className="flex justify-between gap-1 text-center items-center">
              <div className="grow">
                <KmlImport
                  parentType={parentType}
                  parentId={parentId}
                  currentGeoreferences={georeferences}
                  refetchQueries={refetchQueries}
                >
                  {showImportModal => (
                    <Button
                      type="button"
                      size="xs"
                      color="ghost"
                      startIcon={<FontAwesomeIcon icon={faCloudDownload} />}
                      onClick={showImportModal}
                      block
                    >
                      Import KML
                    </Button>
                  )}
                </KmlImport>
              </div>
              <div className="grow">
                <KmlExport georeferences={georeferences}>
                  {kmlExport => (
                    <Button
                      type="button"
                      onClick={kmlExport}
                      size="xs"
                      color="ghost"
                      startIcon={<FontAwesomeIcon icon={faCloudUpload} />}
                      disabled={!georeferences.length}
                      block
                    >
                      Export KML
                    </Button>
                  )}
                </KmlExport>
              </div>
              <div className="grow">
                <Button
                  type="button"
                  size="xs"
                  color={isCreateMode ? 'ghost' : 'primary'}
                  startIcon={
                    <FontAwesomeIcon icon={isCreateMode ? faCancel : faPlus} />
                  }
                  block
                  onClick={toggleMode}
                >
                  {isCreateMode ? 'Close' : 'Add Georeference'}
                </Button>
              </div>
            </div>

            {isCreateMode && (
              <Panel>
                <Panel.Heading>
                  <Panel.Title>Create Georeference</Panel.Title>
                </Panel.Heading>
                <Panel.Body className="space-y-4">
                  <GeoreferenceFormFields
                    hasCenter={hasCenter}
                    hasOutline={hasOutline}
                  />
                  <HookFormErrors graphQLError={error} condensed />
                </Panel.Body>
                <Panel.Footer align="right">
                  <Button type="submit" color="primary" loading={loading}>
                    Save
                  </Button>
                </Panel.Footer>
              </Panel>
            )}

            <div className="space-y-1">
              {georeferences.map(g => (
                <GeoreferenceCard
                  key={g.id}
                  georeference={g}
                  refetchQueries={refetchQueries}
                />
              ))}
            </div>
            <NoItemsAlert show={!georeferences.length} entity="georeferences" />
          </div>

          <div className="lg:col-span-2">
            <MapEditor
              georeferences={georeferences}
              drawingModes={drawingModes}
              onDrawingComplete={data => {
                form.setValue('data', data);
              }}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

function GeoreferenceCard({
  georeference,
  refetchQueries,
}: {
  georeference: GeoreferencePartsFragment;
  refetchQueries: RefetchQueries;
}) {
  const [deleteGeoreference, { loading }] = useMutation(
    GEOREFERENCE_MGR_DELETE,
    {
      variables: { input: { id: parseInt(georeference.id) } },
      refetchQueries,
    },
  );

  async function handleDelete() {
    try {
      await deleteGeoreference();
      toast.success('Georeference deleted.');
    } catch (err) {
      console.log('Error deleting georeference', err);
      toast.error(
        'There was a problem deleting the georeference. Please reload the page and try again.',
      );
    }
  }

  return (
    <div className="border border-slate-200 p-2">
      <div className="flex justify-between gap-4">
        <Heading level={5}>{readableGqlEnum(georeference.dataType)}</Heading>

        <Confirm
          onConfirm={handleDelete}
          text="The selected georeference will be permanently deleted."
        >
          {confirmDelete => (
            <Button
              type="button"
              onClick={confirmDelete}
              size="xs"
              color="ghost"
              startIcon={<FontAwesomeIcon icon={faTrash} />}
              loading={loading}
            >
              Delete
            </Button>
          )}
        </Confirm>
      </div>

      <div className="space-y-0">
        <SODetailText label="Name" value={georeference.name} />
        <SODetailText label="Description" value={georeference.description} />
      </div>
    </div>
  );
}
