import { ApolloProviderV4 } from '~/apollo/client-v4';
import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { CreateOutcropForm } from '~/components/upload/outcrop/create-outcrop-form';
import { UploadOutcropNavigation } from '~/routes/upload/model/outcrop/$outcropId/__UploadOutcropNavigation';

export default function UploadOutcropCreatePage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  useBreadcrumb('routes/upload/model.outcrop/create', 'Create Outcrop');

  return (
    <>
      <PageHeading>Create Outcrop</PageHeading>
      <div className="grid lg:grid-cols-12 gap-6">
        <div className="lg:col-span-2">
          <UploadOutcropNavigation outcropId={-1} disabled />
        </div>
        <div className="lg:col-span-10">
          <CreateOutcropForm />
        </div>
      </div>
    </>
  );
}
