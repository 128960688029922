import * as R from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FilterSearchProps } from '~/components/common/FilterSearch';
import type { SortIndicatorProps } from '~/components/common/icons/SortIndicator';

type SortDirection = 'asc' | 'desc';

const directionFn = (
  dirStr: SortDirection,
): typeof R.ascend | typeof R.descend => {
  if (dirStr === 'asc') return R.ascend;
  if (dirStr === 'desc') return R.descend;
  throw new Error('Unknown sort direction');
};

const flipDirection = (dirStr: SortDirection): SortDirection => {
  return dirStr === 'asc' ? 'desc' : 'asc';
};

export type SortHistoryItem = [property: string, direction: SortDirection];
export type SortHistory = SortHistoryItem[];

/** Record of {columnName: ["filterValue1", "filterValue2"]} */
type CategoryFilters = Record<string, string[]>;

export type SortChangeFn = (
  property: string,
  overrideReverse?: boolean,
) => void;

type UseSortFilterReturn<T> = {
  items: T[];
  onSortChange: SortChangeFn;
  filterSearchProps: FilterSearchProps;
  sortIndicatorProps: SortIndicatorProps;
  isSorted: boolean;
  isFiltered: boolean;
};

// Session storage key in which to store sort history
const storageKey = 'safari-sort-state';
type SortStateStorage = { s: SortHistory; c: CategoryFilters };

function retrieveFullHistory(): Record<string, SortStateStorage> {
  if (typeof window === 'undefined') return {};
  const value = sessionStorage.getItem(storageKey) ?? '{}';
  return JSON.parse(value);
}

function retrieveSortState(name: string): SortStateStorage | undefined {
  const storedHistory = retrieveFullHistory();
  return storedHistory[name];
}

function storeSortState(
  name: string,
  s: SortHistory,
  c: CategoryFilters,
): void {
  if (typeof window === 'undefined') return;
  const storedHistory = retrieveFullHistory();
  const updatedStorage = { ...storedHistory, [name]: { s, c } };
  sessionStorage.setItem(storageKey, JSON.stringify(updatedStorage));
}

type FilterMergeFn = (column: string, value: string) => string;
export const defaultFilterMergeFn: FilterMergeFn = (col, val) => val;

type OptionsSortFn = (column: string, options: string[]) => string[];
export const defaultOptionsSortFn: OptionsSortFn = (col, opts) =>
  R.sortBy(R.identity, opts);

export function useSortFilter<T>(
  unsortedItems: T[],
  /** The default dot.notation property to sort by on load */
  defaultSortProp: string | SortHistory,
  /** The name that AlphabetFilter use for search-based filtering */
  searchProp: string,
  /** Name in browser session storage to store the sort history.
   *  Needed so that multiple useSortFilter renders do not collide history. */
  storageName = 'unnamed',
  /** Start the sorting in reverse */
  defaultDirection: SortDirection = 'asc',
  /** Specify a custom function to alter the value of filterable items */
  filterMergeFn: FilterMergeFn = defaultFilterMergeFn,
  /** Specify a custom function to handle sorting the filter options */
  optionsSortFn: OptionsSortFn = defaultOptionsSortFn,
): UseSortFilterReturn<T> {
  const initialState = useMemo(() => {
    return retrieveSortState(storageName);
  }, [storageName]);

  const initialHistory: SortHistory = useMemo(() => {
    if (initialState?.s) return initialState.s;
    if (defaultSortProp instanceof Array) return defaultSortProp;
    return [[defaultSortProp, defaultDirection]];
    // Only want this to run on the initial sort, I prefer it to not be an infinite loop when an array is passed in initially.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialCategoryFilters = useMemo(
    () => initialState?.c ?? {},
    [initialState],
  );

  // sortHistory and categoryFilters are initialized to empty to support server
  // side rendering. Once hydrated, they are set to their determined initial state
  const [sortHistory, setSortHistory] = useState<SortHistory>(initialHistory);
  const [categoryFilters, setCategoryFilters] = useState<CategoryFilters>(
    initialCategoryFilters,
  );
  const [includesFilter, setIncludesFilter] = useState('');
  const [startsWithFilter, setStartsWithFilter] = useState('');

  // Effect to set the initial states of history and filters once hydrated
  useEffect(() => {
    setSortHistory(initialHistory);
    setCategoryFilters(initialCategoryFilters);
  }, [initialHistory, initialCategoryFilters]);

  const [currentSort, sortDirection] = sortHistory[0];

  // Push state changes to session storage so it is retained on browser navigation
  useEffect(() => {
    storeSortState(storageName, sortHistory, categoryFilters);
  }, [storageName, sortHistory, categoryFilters]);

  // Push the given property to the top of the sort history
  // The final sort list is filtered so any given property can only exist once
  const handleSortChange = useCallback(
    (property: string, overrideReverse?: boolean) => {
      let nextDirection =
        currentSort === property ? flipDirection(sortDirection) : 'asc';
      if (overrideReverse) {
        nextDirection = 'asc';
      }
      const nextHistoryItem: SortHistoryItem = [property, nextDirection];

      const nextSortHistory = R.pipe(
        R.prepend(nextHistoryItem),
        R.uniqBy(R.head), // Make sure the column names are unique in sort history
      )(sortHistory) as SortHistory;

      setSortHistory(nextSortHistory);
    },
    [currentSort, sortDirection, sortHistory],
  );

  function handleStartsWithChange(value: string) {
    const nextVal = typeof value === 'string' ? value.toLowerCase() : value;
    setStartsWithFilter(startsWithFilter === nextVal ? '' : nextVal);
  }

  const handleIncludesChange = useCallback((value: string) => {
    setIncludesFilter(value);
  }, []);

  const filterByCategory: (filters: CategoryFilters) => (items: T[]) => T[] = (
    filters: CategoryFilters,
  ) =>
    R.filter(item =>
      R.pipe(
        R.keys,
        R.reduce((acc, key) => {
          if (!acc) return acc;
          const vals = R.prop(key, filters);
          if (R.either(R.isEmpty, R.isNil)(vals)) return true;

          let value = R.pathOr<any>(null, R.split('.', key), item);
          value = filterMergeFn(key, value);

          const normalize = (val: typeof value) => R.trim(String(val));

          if (Array.isArray(value)) {
            return R.any(R.contains(R.__, vals), value.map(normalize));
          } else {
            return vals.includes(normalize(value));
          }
        }, true),
        R.equals(true),
      )(filters),
    );
  const categoryFilteredItems =
    filterByCategory(categoryFilters)(unsortedItems);

  const filteredItems = R.isEmpty(searchProp)
    ? // Skip filtering if the search property is not set
      categoryFilteredItems
    : categoryFilteredItems.filter(item => {
        const propPath = R.split('.', searchProp);
        const value = R.pipe(
          R.path(propPath),
          R.defaultTo(''),
          String,
          R.toLower,
          R.trim,
        )(item);
        if (!R.isEmpty(startsWithFilter) && !value.startsWith(startsWithFilter))
          return false;
        if (
          !R.isEmpty(includesFilter) &&
          !value.includes(includesFilter.toLowerCase())
        )
          return false;
        return true;
      });

  /** Returns the property specified by dot.path in an object, lowercased */
  const valueProp = (property: string): any =>
    R.pipe(
      R.path(R.split('.', property)),
      R.defaultTo(''),
      // If the value is a string, trim and lowercase it for comparison purposes
      val => (typeof val === 'string' ? R.toLower(val) : val),
      val => (typeof val === 'string' ? R.trim(val) : val),
      // Make sure IDs are numbers for correct sorting
      val =>
        property === 'id' && typeof val === 'string' ? parseInt(val, 10) : val,
    );

  /** Create a sort function for the given property and direction */
  const sortFns = sortHistory.map(([prop, dir]) =>
    directionFn(dir)(valueProp(prop)),
  );
  const sortedItems = R.sortWith(sortFns)(filteredItems) as T[];

  /** Build list of unique items for a given category **/
  const categoryFilterOptions = useCallback(
    (propertyPath: string) =>
      R.pipe(
        R.map(R.pathOr<any>(null, propertyPath.split('.'))),
        items => {
          return items.flatMap(item => {
            if (Array.isArray(item)) {
              return item.map(item1 => filterMergeFn(propertyPath, item1));
            }
            return filterMergeFn(propertyPath, item);
          });
        },
        R.reject(R.isNil),
        R.map(R.pipe(String, R.trim)),
        R.uniq,
        items => optionsSortFn(propertyPath, items),
      )(unsortedItems),
    [unsortedItems, filterMergeFn, optionsSortFn],
  );

  function getFiltersForCategory(category: string) {
    return R.pipe(
      R.pathOr<string[]>([], [category]),
      R.defaultTo<string[]>([]),
    )(categoryFilters);
  }

  const handleCategoryFilterChange = (category: string) => (value: string) => {
    const curFilters = getFiltersForCategory(category);

    let nextFilters: string[];
    if (R.contains(value, curFilters)) {
      nextFilters = R.without([value], curFilters);
    } else {
      nextFilters = R.append(value, curFilters);
    }

    setCategoryFilters(R.assoc(category, nextFilters));
  };

  // Check to ensure there aren't any category filters selected that are no longer
  // present in the items, causing the result list to be empty
  useEffect(() => {
    let updatedCatFilterCount = 0;
    const nextCatFilters = Object.keys(categoryFilters).reduce<CategoryFilters>(
      (acc, catName) => {
        const availableOpts = categoryFilterOptions(catName);
        const staleOpts = categoryFilters[catName].filter(
          opt => !availableOpts.includes(opt),
        );
        if (staleOpts.length) {
          console.log(`Removing stale options from ${catName}:`, staleOpts);
          updatedCatFilterCount = updatedCatFilterCount + 1;
          const nextVals = R.without(staleOpts, categoryFilters[catName]);
          return R.assoc(catName, nextVals, acc);
        } else {
          return acc;
        }
      },
      categoryFilters,
    );

    // Don't cause unnecessary state updates
    if (updatedCatFilterCount > 0) {
      setCategoryFilters(nextCatFilters);
    }
  }, [unsortedItems, categoryFilters, categoryFilterOptions]);

  const isSorted = useMemo(() => {
    return sortHistory.length > 0;
  }, [sortHistory.length]);

  const isFiltered = useMemo(() => {
    return Object.keys(categoryFilters).some(key => {
      return categoryFilters[key].length > 0;
    });
  }, [categoryFilters]);

  return {
    items: sortedItems,
    onSortChange: handleSortChange,
    filterSearchProps: {
      includesValue: includesFilter,
      startsWithValue: startsWithFilter,
      onIncludesChange: handleIncludesChange,
      onStartsWithChange: handleStartsWithChange,
    },
    sortIndicatorProps: {
      sort: currentSort,
      sortReverse: sortDirection === 'asc',
      onSortChange: handleSortChange,
      categoryFilterOptions,
      getFiltersForCategory,
      onCategoryFilterChange: handleCategoryFilterChange,
    },
    isSorted,
    isFiltered,
  };
}
