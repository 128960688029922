import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import {
  faCheckCircle,
  faExclamationTriangle,
  faFile,
  faHourglass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReducer } from 'react';
import Dropzone from 'react-dropzone';
import invariant from 'tiny-invariant';
import { v4 } from 'uuid';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type {
  FileCreateForParentInput,
  FileParentType,
} from '~/apollo/generated/v4/graphql';
import {
  DropzoneContainer,
  handleDropRejected,
} from '~/components/common/DropzoneContainer';
import { SpinnerIcon } from '~/components/common/SpinnerIcon';
import { Button } from '~/components/ui/button';
import { useRefetchQueriesV4 } from '~/hooks/apollo';
import { assertExhaustive, cn } from '~/utils/common';
import { fileSizeText } from '~/utils/text';

const FILE_UPLOAD_V4 = graphql(`
  mutation FileUploadV4($input: FileCreateForParentInput!) {
    fileCreateForParent(input: $input) {
      path
      token
    }
  }
`);

async function postFile(path: string, token: string, file: File) {
  const body = new FormData();
  body.set('file_data', file);
  body.set('token', token);

  const result = await fetch(path, {
    method: 'post',
    body,
  });

  return result.status === 200;
}

type FileUploadV4Props = {
  parentType: FileParentType;
  parentId: number;
  refetchQueries: PureQueryOptions[];
};

function FileUploadV4Inner({
  parentType,
  parentId,
  refetchQueries,
}: FileUploadV4Props) {
  const [queue, dispatch] = useReducer(reducer, queueInitialState());

  const [fileCreate] = useMutation(FILE_UPLOAD_V4);
  const [refetch] = useRefetchQueriesV4(refetchQueries);

  function handleDrop(files: File[]) {
    dispatch({ action: 'ADD_ITEMS', payload: { files } });
  }

  function handleRemove(tmpId: QueueItem['tmpId']) {
    dispatch({ action: 'REMOVE_ITEM', payload: { tmpId } });
  }

  function setItemStatus(tmpId: string, status: ItemStatus) {
    dispatch({
      action: 'UPDATE_ITEM_STATUS',
      payload: { tmpId, status },
    });
  }

  async function uploadItem(item: QueueItem) {
    const input: FileCreateForParentInput = {
      parentType,
      parentId,
    };

    try {
      setItemStatus(item.tmpId, 'uploading');
      const res = await fileCreate({ variables: { input } });
      const createResult = res.data?.fileCreateForParent;
      const path = createResult?.path;
      const token = createResult?.token;
      invariant(path && token, 'Missing upload path or token');

      const uploadResult = await postFile(path, token, item.file);
      if (!uploadResult) {
        throw new Error('POST file failed');
      }
      setItemStatus(item.tmpId, 'success');
    } catch (err) {
      console.log('Error uploading file', err);
      setItemStatus(item.tmpId, 'failed');
    }
  }

  async function startUpload() {
    const items = queue.items.filter(
      item => item.status === 'pending' || item.status === 'failed',
    );

    dispatch({ action: 'SET_QUEUE_STATUS', payload: { status: 'uploading' } });
    for (const item of items) {
      await uploadItem(item);
    }
    dispatch({ action: 'SET_QUEUE_STATUS', payload: { status: 'idle' } });

    console.log('Refetching!');
    await refetch();
  }

  const hasPendingItems = queue.items.some(
    item => item.status === 'pending' || item.status === 'failed',
  );

  const numFailedItems = queue.items.reduce(
    (acc, item) => (item.status === 'failed' ? acc + 1 : acc),
    0,
  );

  const canStartUpload = hasPendingItems;

  return (
    <div className="space-y-4">
      <Dropzone
        onDropAccepted={handleDrop}
        onDropRejected={handleDropRejected}
        multiple
        maxSize={100_000_000}
        disabled={queue.status !== 'idle'}
      >
        {({ getRootProps, getInputProps }) => (
          <DropzoneContainer
            {...getRootProps()}
            className={!queue.items.length ? 'h-72' : undefined}
          >
            <div className="flex items-center h-full">
              <input {...getInputProps()} />
              <div className="text-center">
                <div>Drop files here or click to browse.</div>
                <div>Maximum file size: 100 MB</div>
              </div>
            </div>
          </DropzoneContainer>
        )}
      </Dropzone>

      <div className="space-y-2">
        {queue.items.map(item => (
          <ItemEditor key={item.tmpId} item={item} onRemove={handleRemove} />
        ))}
      </div>

      <div className="text-center space-y-2">
        <Button
          type="button"
          onClick={startUpload}
          color="primary"
          disabled={!canStartUpload}
          loading={queue.status === 'uploading'}
        >
          Upload Files
        </Button>
        {numFailedItems > 0 && (
          <div className="text-error">
            <FontAwesomeIcon icon={faExclamationTriangle} /> {numFailedItems}{' '}
            items failed to upload.
          </div>
        )}
      </div>
    </div>
  );
}

type QueueStatus = 'idle' | 'uploading';

type ItemStatus = 'pending' | 'uploading' | 'success' | 'failed';

type QueueItem = {
  tmpId: string;
  status: ItemStatus;
  file: File;
};

type Queue = {
  status: QueueStatus;
  items: QueueItem[];
};

type Action =
  | {
      action: 'ADD_ITEMS';
      payload: {
        files: File[];
      };
    }
  | {
      action: 'REMOVE_ITEM';
      payload: { tmpId: QueueItem['tmpId'] };
    }
  | {
      action: 'UPDATE_ITEM_STATUS';
      payload: {
        tmpId: QueueItem['tmpId'];
        status: ItemStatus;
      };
    }
  | {
      action: 'SET_QUEUE_STATUS';
      payload: {
        status: QueueStatus;
      };
    };

function initQueueItem(file: File): QueueItem {
  return {
    file,
    tmpId: v4(),
    status: 'pending',
  };
}

function reducer(state: Queue, { action, payload }: Action): Queue {
  switch (action) {
    case 'ADD_ITEMS':
      return {
        ...state,
        items: [...state.items, ...payload.files.map(initQueueItem)],
      };

    case 'REMOVE_ITEM':
      return {
        ...state,
        items: state.items.filter(item => item.tmpId !== payload.tmpId),
      };

    case 'SET_QUEUE_STATUS':
      return {
        ...state,
        status: payload.status,
      };

    case 'UPDATE_ITEM_STATUS':
      return {
        ...state,
        items: state.items.map(item => {
          if (item.tmpId !== payload.tmpId) return item;
          return { ...item, status: payload.status };
        }),
      };

    default:
      assertExhaustive(action, `${action} not handled!`);
      return state;
  }
}

function queueInitialState(): Queue {
  return {
    status: 'idle',
    items: [],
  };
}

function ItemEditor({
  item,
  onRemove,
}: {
  item: QueueItem;
  onRemove: (tmpId: QueueItem['tmpId']) => unknown;
}) {
  const isRemovable = item.status === 'pending' || item.status === 'failed';

  return (
    <div
      className={cn('border p-4', {
        'border-slate-200 bg-transparent': item.status === 'pending',
        'border-success bg-emerald-50': item.status === 'success',
        'border-error bg-rose-50': item.status === 'failed',
      })}
    >
      <div className="flex gap-4 items-center">
        <div className="w-32 shrink-0 space-y-1">
          <div className="text-center">
            <FontAwesomeIcon icon={faFile} className="text-3xl" />
          </div>

          {isRemovable && (
            <div className="text-center">
              <Button
                type="button"
                onClick={() => onRemove(item.tmpId)}
                color="ghost"
                size="xs"
              >
                Remove
              </Button>
            </div>
          )}
        </div>

        <div className="grow">
          <table className="table table-compact table-sm">
            <tbody>
              <tr>
                <th className="lg:w-32">Filename</th>
                <td>{item.file.name}</td>
              </tr>
              <tr>
                <th>Content type</th>
                <td>{item.file.type}</td>
              </tr>
              <tr>
                <th>Size</th>
                <td>{fileSizeText(item.file.size)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="w-32 shrink-0 flex items-center justify-center">
          <ItemStatusIcon status={item.status} />
        </div>
      </div>
    </div>
  );
}

function ItemStatusIcon({ status }: { status: ItemStatus }) {
  switch (status) {
    case 'pending':
      return (
        <FontAwesomeIcon
          icon={faHourglass}
          className="text-3xl text-slate-300"
        />
      );
    case 'success':
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-3xl text-success"
        />
      );
    case 'failed':
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-3xl text-error"
        />
      );
    case 'uploading':
      return <SpinnerIcon className="text-3xl text-slate-800" />;
    default:
      assertExhaustive(status, `Item status ${status} not handled`);
      return null;
  }
}

export function FileUploadV4(props: FileUploadV4Props) {
  return (
    <ApolloProviderV4>
      <FileUploadV4Inner {...props} />
    </ApolloProviderV4>
  );
}
