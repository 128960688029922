import ReactSelect from 'react-select';
import type { FieldPicsOutcropListQuery } from '~/apollo/generated/v4/graphql';
import { InputBase } from '~/components/ui/forms/InputBase';

type Outcrop = NonNullable<
  NonNullable<FieldPicsOutcropListQuery['outcropList']>['results']
>[number];

export function OutcropPicker({
  name,
  value,
  onChange,
  outcrops,
  disabled,
  required,
}: {
  name: string;
  outcrops: Outcrop[];
  value: number | null;
  onChange: (outcropId: number | null) => unknown;
  disabled?: boolean;
  required?: boolean;
}) {
  const selectOptions = outcrops.map(oc => ({
    value: parseInt(oc.id),
    label: `${oc.name} (${oc.id})`,
    isDisabled: !oc.center,
  }));

  const selectValue = selectOptions.find(opt => opt.value === value);

  return (
    <InputBase id={name} name={name} label="Outcrop" required={required}>
      <ReactSelect
        id="outcropId"
        isSearchable
        className="w-full"
        options={selectOptions}
        value={selectValue}
        onChange={val => onChange(val?.value ?? null)}
        isClearable
        isDisabled={disabled}
      />
    </InputBase>
  );
}
