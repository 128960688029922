import { useFormContext } from 'react-hook-form';
import { GeoreferenceDataType } from '~/apollo/generated/v4/graphql';
import { Select } from '~/components/ui/forms/Select';
import { TextInput } from '~/components/ui/forms/text-input';
import type { ConfirmGeoreferenceFormValues } from '~/components/upload/georeference-v4/kml-import/confirm-data/confirm-georeference-form';
import { assertExhaustive } from '~/utils/common';
import { markerTypes, polygonTypes, polylineTypes } from '~/utils/georeference';
import { readableGqlEnum } from '~/utils/text';

export function ConfirmGeoreferenceFormFields({
  dataType,
}: {
  dataType: GeoreferenceDataType;
}) {
  const { register } = useFormContext<ConfirmGeoreferenceFormValues>();

  const mapOpts = (value: string) => ({ value, label: readableGqlEnum(value) });

  function dataTypeOptions(dataType: GeoreferenceDataType) {
    switch (dataType) {
      case GeoreferenceDataType.Centre:
      case GeoreferenceDataType.Point:
        return markerTypes.map(mapOpts);
      case GeoreferenceDataType.Outline:
      case GeoreferenceDataType.Polygon:
        return polygonTypes.map(mapOpts);
      case GeoreferenceDataType.Polyline:
        return polylineTypes.map(mapOpts);
      default:
        throw assertExhaustive(dataType);
    }
  }

  return (
    <div className="space-y-2">
      <Select
        {...register('dataType')}
        label="Type"
        options={dataTypeOptions(dataType)}
        withBlankOption={false}
        required
      />
      <TextInput {...register('name')} label="Name" required />
      <TextInput {...register('name')} label="Description" />
    </div>
  );
}
