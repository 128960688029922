import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { VideoFormFields } from '~/components/upload/supporting-object/video/video-form-fields';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { useZodForm } from '~/utils/forms';
import { initialVideo, videoSchema } from '~/utils/modules/supporting-object';

const CREATE_VIDEO = graphql(`
  mutation CreateVideo($input: VideoCreateInput!) {
    videoCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

export function CreateVideoForm({
  outcropId,
  refetchQueries,
  onCreateSuccess,
}: {
  outcropId: number;
  refetchQueries: PureQueryOptions[];
  onCreateSuccess?: () => void;
}) {
  const [createVideo, { loading, error }] = useMutation(CREATE_VIDEO, {
    refetchQueries,
  });

  const form = useZodForm({
    schema: videoSchema,
    values: initialVideo(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await createVideo({
        variables: {
          input: {
            outcropId,
            ...values,
          },
        },
      });
      toast.success('Video created successfully.');
      form.reset(initialVideo());
      if (onCreateSuccess) onCreateSuccess();
    } catch (err) {
      console.log('Error creating video', err);
      toast.error('There was a problem creating the video. Please try again.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <VideoFormFields />
          <HookFormErrors graphQLError={error} />

          <div className="text-center">
            <Button color="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
