import type { PureQueryOptions } from '@apollo/client';
import * as R from 'ramda';
import type { SoManagerPicturePartsFragment } from '~/apollo/generated/v4/graphql';
import { PictureInitialParentType } from '~/apollo/generated/v4/graphql';
import { Heading } from '~/components/common/Heading';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { SOPictureCard } from '~/components/upload/supporting-object/so-picture-card';
import { UploadPicturesModal } from '~/components/upload/supporting-object/upload-pictures-modal';

export function SOPictureManager({
  parentType,
  parentId,
  pictures,
  refetchQueries,
}: {
  parentType: PictureInitialParentType;
  parentId: number;
  pictures: SoManagerPicturePartsFragment[];
  refetchQueries: PureQueryOptions[];
}) {
  const sortedPictures = R.sortBy(
    picture => picture.priority ?? Infinity,
    pictures,
  );

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between gap-4">
        <Heading level={3}>Pictures</Heading>
        <UploadPicturesModal
          parentType={parentType}
          parentId={parentId}
          refetchQueries={refetchQueries}
        />
      </div>

      <div className="space-y-2">
        {sortedPictures.map(picture => (
          <SOPictureCard
            key={picture.id}
            picture={picture}
            refetchQueries={refetchQueries}
            studyId={
              // Provide the studyId only on the study pictures themselves;
              // this allows tagging an outcrop
              parentType === PictureInitialParentType.Study
                ? parentId
                : undefined
            }
          />
        ))}
        <NoItemsAlert show={!pictures.length} entity="pictures" />
      </div>
    </div>
  );
}
