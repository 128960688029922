import { useQuery } from '@apollo/client';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { UploadOutcropList } from '~/components/upload/outcrop/UploadOutcropList';
import { useSortFilter } from '~/hooks/data';
import { useRouteParam } from '~/hooks/routing';
import { uploadOutcropCreateRoute } from '~/paths';
import { toSortableOutcropV4 } from '~/utils/modules/outcrop-v4';

const UPLOAD_REGION_OUTCROPS_PAGE = graphql(`
  query UploadRegionOutcropsPage($regionId: Int!) {
    outcropList(first: 9999, filter: { regionId: { eq: $regionId } }) {
      results {
        ...outcropListParts
      }
    }
  }
`);

export default function UploadRegionOutcropsPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const regionId = useRouteParam('regionId', parseInt);

  const { data, loading } = useQuery(UPLOAD_REGION_OUTCROPS_PAGE, {
    variables: { regionId },
  });

  const outcrops = data?.outcropList?.results?.map(toSortableOutcropV4) ?? [];

  const { items, filterSearchProps, sortIndicatorProps } = useSortFilter(
    outcrops,
    'name',
    'name',
    'uploadRegionOutcropList',
  );

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>
          Outcrops
          {!loading && (
            <small className="text-muted" style={{ marginLeft: '10px' }}>
              (<strong>{items.length}</strong> results displayed)
            </small>
          )}
        </Panel.Title>

        <Link
          to={uploadOutcropCreateRoute()}
          className="btn btn-primary btn-xs gap-1"
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Create Outcrop
        </Link>
      </Panel.Heading>

      <Panel.Body>
        {loading && <SpinnerPlaceholder />}
        {!loading && (
          <UploadOutcropList
            outcrops={items}
            sortIndicatorProps={sortIndicatorProps}
            filterSearchProps={filterSearchProps}
          />
        )}
      </Panel.Body>
    </Panel>
  );
}
