import { forwardRef } from 'react';
import { useController } from 'react-hook-form';
import type {
  MultiValue,
  Props as ReactSelectProps,
  SingleValue,
} from 'react-select';
import ReactSelect from 'react-select';
import { InputBase } from '~/components/ui/forms/InputBase';
import { cn } from '~/utils/common';
import type { FormLabelProps } from './FormLabel';

type TValue = string | number | boolean;

type SelectOption<T extends TValue> = {
  value: T;
  label: string;
  disabled?: boolean;
};

type ReactSelectOption<T extends TValue> = {
  value: T;
  label: string;
  isDisabled?: boolean;
};

export type ReactSelectInputProps<T extends TValue> = {
  options: SelectOption<T>[];
  name: string;
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  label?: FormLabelProps['label'];
  renderInput?: (input: JSX.Element) => JSX.Element;
  reactSelectProps?: ReactSelectProps;
  multiple?: boolean;
  onChange?: (option: T | T[] | null) => void;
};

export const ReactSelectInput = forwardRef(ReactSelectInputInner) as <
  T extends TValue,
>(
  props: ReactSelectInputProps<T> & { ref?: React.Ref<ReactSelect> },
) => JSX.Element;

function ReactSelectInputInner<T extends TValue>(
  {
    name,
    options,
    loading = false,
    disabled = false,
    required = false,
    label,
    renderInput,
    reactSelectProps,
    onChange,
    multiple,
  }: ReactSelectInputProps<T>,
  // This ref has some crazy typing, let's just ignore it
  _ref: unknown,
) {
  const { field } = useController({ name, disabled });

  function handleChange(
    option: SingleValue<SelectOption<T>> | MultiValue<SelectOption<T>>,
  ) {
    if (Array.isArray(option) && multiple) {
      handleMultipleChange(option);
    } else if (!Array.isArray(option)) {
      // Not sure how to narrow this
      handleSingleChange(option as SingleValue<SelectOption<T>>);
    }
  }

  function handleSingleChange(option: SingleValue<SelectOption<T>>) {
    if (multiple) return;
    const value = option?.value ?? null;
    if (onChange) {
      onChange(value);
    } else {
      field.onChange(value);
    }
  }
  function handleMultipleChange(options: MultiValue<SelectOption<T>>) {
    if (!multiple) return;
    const values = options.map(opt => opt.value);
    if (onChange) {
      onChange(values);
    } else {
      field.onChange(values);
    }
  }

  const selectedValue = multiple
    ? options.filter(opt => (field.value ?? []).includes(opt.value))
    : (options.find(opt => opt.value === field.value) ?? null);

  // Convert generic `disabled` used everywhere else into react-select's `isDisabled` prop
  const selectOptions = options.map(opt => {
    const nextOpt: ReactSelectOption<T> = {
      value: opt.value,
      label: opt.label,
    };
    if (opt.disabled) nextOpt.isDisabled = true;
    return nextOpt;
  });

  const input = (
    <ReactSelect
      value={selectedValue}
      onChange={handleChange}
      isMulti={multiple}
      isLoading={loading}
      isDisabled={disabled}
      options={selectOptions}
      isSearchable={reactSelectProps?.isSearchable ?? true}
      isClearable={reactSelectProps?.isClearable ?? true}
      closeMenuOnSelect={!multiple}
      classNames={{
        control: () => 'text-[0.875rem]',
        option: state =>
          cn('p-2 text-sm', {
            'bg-sky-200': state.isSelected,
            'bg-sky-50': state.isFocused && !state.isSelected,
          }),
      }}
      // Blank `option` key equired to override react-select's default option styles with classes
      styles={{ option: () => ({}) }}
      menuPortalTarget={document.body}
    />
  );

  return (
    <InputBase id={name} name={name} label={label} required={required}>
      {renderInput ? renderInput(input) : input}
    </InputBase>
  );
}
