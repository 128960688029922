import { useFormContext } from 'react-hook-form';
import { TextInput } from '~/components/ui/forms/text-input';
import { OutcropTagField } from '~/components/upload/supporting-object/outcrop-tag-field';
import type { SOType } from '~/components/upload/supporting-object/so-manager';
import { assertExhaustive } from '~/utils/common';

type FieldConfig = {
  name: string;
  label: string;
  helpText?: string;
};

const SOFieldConfigs = {
  name: { name: 'name', label: 'Name' },
  description: { name: 'description', label: 'Description' },
  author: { name: 'author', label: 'Author' },
  scale: { name: 'scale', label: 'Scale' },
  comments: { name: 'comments', label: 'Comments' },
  interpretation: { name: 'interpretation', label: 'Interpretation' },
  gigaPanHash: { name: 'gigaPanHash', label: 'GigaPan Hash' },
  nugget: {
    name: 'nugget',
    label: 'Nugget',
    helpText:
      'Nugget n: The height of the jump of the semivariogram at the discontinuity at the origin.',
  },
  sill: {
    name: 'sill',
    label: 'Sill',
    helpText:
      'Sill s: Limit of the variogram tending to infinity lag distances.',
  },
  range: {
    name: 'range',
    label: 'Range',
    helpText:
      'Range r: The distance in which the difference of the variogram from the sill becomes negligible. In models with a fixed sill, it is the distance at which this is first reached; for models with an asymptotic sill, it is conventionally taken to be the distance when the semivariance first reaches 95% of the sill.',
  },
  published: {
    name: 'published',
    label: 'Published',
    helpText: 'Only published entities will be shown to non-administrators.',
  },
  priority: {
    name: 'priority',
    label: 'Priority',
  },
} as const;
type SOFieldKey = keyof typeof SOFieldConfigs;
const SOField: Record<SOFieldKey, FieldConfig> = SOFieldConfigs;

type SOFormFieldList = {
  fields: SOFieldKey[];
  required: SOFieldKey[];
};

function fields(soType: SOType): SOFormFieldList {
  switch (soType) {
    case 'CROSS_SECTION':
      return {
        fields: [
          'name',
          'description',
          'author',
          'scale',
          'comments',
          'priority',
        ],
        required: ['name', 'description'],
      };

    case 'FACIES_SCHEMA':
      return {
        fields: [
          'name',
          'description',
          'author',
          'interpretation',
          'comments',
          'priority',
        ],
        required: ['name', 'description'],
      };

    case 'SEDIMENTARY_LOG':
      return {
        fields: [
          'name',
          'description',
          'author',
          'scale',
          'comments',
          'priority',
        ],
        required: ['name', 'description'],
      };
    case 'VARIOGRAM':
      return {
        fields: [
          'name',
          'description',
          'author',
          'comments',
          'priority',
          'nugget',
          'sill',
          'range',
        ],
        required: ['name', 'description'],
      };
    case 'GIGA_PAN':
      return {
        fields: ['name', 'gigaPanHash', 'priority'],
        required: ['name'],
      };
    case 'WELL_LOG':
    case 'PRODUCTION':
    case 'RESERVOIR_MODEL':
    case 'TRAINING_IMAGE':
      return {
        fields: ['name', 'description', 'author', 'comments', 'priority'],
        required: ['name', 'description'],
      };

    default:
      throw assertExhaustive(soType);
  }
}

export function SOFormFields({
  soType,
  studyId,
}: {
  soType: SOType;
  studyId?: number;
}) {
  const form = useFormContext();
  const soFields = fields(soType);

  return (
    <>
      {soFields.fields.map(fieldName => (
        <TextInput
          {...form.register(fieldName)}
          key={fieldName}
          label={{
            label: SOField[fieldName].label,
            helpText: SOField[fieldName].helpText,
          }}
          required={soFields.required.includes(fieldName)}
        />
      ))}

      {studyId && (
        <OutcropTagField
          studyId={studyId}
          name="outcropTagId"
          label="Outcrop Tag"
        />
      )}
    </>
  );
}
