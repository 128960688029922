import { useMutation } from '@apollo/client';
import { faCancel, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import {
  FileParentType,
  type UploadStudyLiteraturePageQuery,
} from '~/apollo/generated/v4/graphql';
import { Confirm } from '~/components/common/Confirm';
import { Panel } from '~/components/common/Panel';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';
import {
  SODetailText,
  SOOutcropTagText,
} from '~/components/upload/supporting-object/so-detail-text';
import { SOFileManager } from '~/components/upload/supporting-object/so-file-manager';
import type { RefetchQueries } from '~/utils/graphql';
import { UpdateLiteratureForm } from './update-literature-form';

type Study = NonNullable<UploadStudyLiteraturePageQuery['studyGet']>;
type Literature = Study['literatureReferences'][number];

type Props = {
  studyId: number;
  literature: Literature;
  refetchQueries: RefetchQueries;
};

export function StudyLiteratureListItem({
  studyId,
  literature,
  refetchQueries,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const toggleIsEditing = () => setIsEditing(!isEditing);

  return (
    <Panel>
      <Panel.Heading className="flex justify-between gap-2">
        <Panel.Title>
          {literature.title}{' '}
          <Badge color="ghost" mono>
            {literature.id}
          </Badge>
        </Panel.Title>

        <div className="shrink-0 space-x-1">
          <DeleteLiterature
            id={parseInt(literature.id)}
            refetchQueries={refetchQueries}
          >
            {(confirmDelete, isDeleting) => (
              <Button
                type="button"
                color="ghost"
                size="xs"
                onClick={confirmDelete}
                loading={isDeleting}
                startIcon={<FontAwesomeIcon icon={faTrash} />}
              >
                Delete
              </Button>
            )}
          </DeleteLiterature>

          <Button
            type="button"
            onClick={toggleIsEditing}
            size="xs"
            color="ghost"
            startIcon={
              <FontAwesomeIcon icon={isEditing ? faCancel : faPencil} />
            }
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
        </div>
      </Panel.Heading>

      <Panel.Body>
        {isEditing ? (
          <UpdateLiteratureForm
            studyId={studyId}
            literature={literature}
            onUpdateSuccess={() => setIsEditing(false)}
          />
        ) : (
          <div className="space-y-4">
            <LiteratureDetails literature={literature} />
            <SOFileManager
              parentType={FileParentType.LiteratureReference}
              parentId={parseInt(literature.id)}
              files={literature.files}
              refetchQueries={refetchQueries}
            />
          </div>
        )}
      </Panel.Body>
    </Panel>
  );
}

function LiteratureDetails({ literature }: { literature: Literature }) {
  return (
    <div className="space-y-0">
      <SODetailText label="Title" value={literature.title} />
      <SODetailText label="Author" value={literature.author} />
      <SODetailText label="Comments" value={literature.comments} />
      <SODetailText
        label="Digital Object Identifier"
        value={literature.digitalObjectIdentifier}
      />
      <SODetailText
        label="Publication Source"
        value={literature.publicationSource}
      />
      <SODetailText
        label="Publication Type"
        value={literature.publicationType}
      />
      <SODetailText label="Web Source" value={literature.webSource} />
      <SODetailText label="Year" value={literature.year} />
      <SODetailText label="Priority" value={literature.priority} />
      <SOOutcropTagText
        label="Outcrop tag"
        outcropTag={literature.outcropTag}
      />
    </div>
  );
}

export const DELETE_LITERATURE_REFERENCE = graphql(`
  mutation DeleteLiteratureReference($id: ID!) {
    literatureReferenceDelete(id: $id) {
      result {
        id
      }
    }
  }
`);

function DeleteLiterature({
  children,
  id,
  refetchQueries,
}: {
  children: (confirmDelete: () => unknown, isDeleting: boolean) => ReactNode;
  id: number;
  refetchQueries: RefetchQueries;
}) {
  const [deleteLiterature, { loading }] = useMutation(
    DELETE_LITERATURE_REFERENCE,
    {
      variables: { id },
      refetchQueries,
    },
  );

  async function handleDelete() {
    try {
      await deleteLiterature();
      toast.success('Literature reference deleted.');
    } catch (err) {
      console.log('Error deleting literature reference', err);
      toast.error('There was a problem deleting the literature reference.');
    }
  }

  return (
    <Confirm
      text="The literature reference and its files will be permanently deleted."
      onConfirm={handleDelete}
    >
      {confirmDelete => children(confirmDelete, loading)}
    </Confirm>
  );
}
