import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import type { SoManagerProductionPartsFragment } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { SOFormFields } from '~/components/upload/supporting-object/so-form-fields';
import type { SOParent } from '~/components/upload/supporting-object/so-manager';
import { UPDATE_PRODUCTION } from '~/components/upload/supporting-object/so-operations';
import { useZodForm } from '~/utils/forms';
import {
  initialProduction,
  productionSchema,
} from '~/utils/modules/supporting-object';

type Props = SOParent & {
  production: SoManagerProductionPartsFragment;
  onUpdateSuccess?: () => unknown;
};

export function ProductionUpdateForm({
  production,
  studyId,
  onUpdateSuccess,
}: Props) {
  const [updateProduction, { loading, error }] = useMutation(UPDATE_PRODUCTION);

  const form = useZodForm({
    schema: productionSchema,
    values: initialProduction(production),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateProduction({
        variables: {
          id: production.id,
          input: values,
        },
      });
      toast.success('Production updated successfully.');
      form.reset();
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error updating production', err);
      toast.error('There was a problem updating the production.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <SOFormFields soType="PRODUCTION" studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="card-actions justify-end">
          <Button type="submit" loading={loading} color="primary">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
