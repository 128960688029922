import { useMutation, type PureQueryOptions } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type { CrossSectionCreateInput } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { SOFormFields } from '~/components/upload/supporting-object/so-form-fields';
import { useZodForm } from '~/utils/forms';
import {
  crossSectionSchema,
  initialCrossSection,
} from '~/utils/modules/supporting-object';

const CREATE_CROSS_SECTION = graphql(`
  mutation CreateCrossSection($input: CrossSectionCreateInput!) {
    crossSectionCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

type Props = {
  refetchQueries?: PureQueryOptions[];
} & (
  | {
      outcropId: number;
      studyId?: never;
    }
  | {
      outcropId?: never;
      studyId: number;
    }
);

export function CrossSectionCreateForm({
  outcropId,
  studyId,
  refetchQueries,
}: Props) {
  const [createCrossSection, { loading, error }] = useMutation(
    CREATE_CROSS_SECTION,
    { refetchQueries },
  );

  const form = useZodForm({
    schema: crossSectionSchema,
    values: initialCrossSection(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    const input: CrossSectionCreateInput = {
      ...values,
      outcropId,
      studyId,
    };

    try {
      await createCrossSection({ variables: { input } });
      toast.success('Cross section created successfully.');
      form.reset();
    } catch (err) {
      console.log('Error creating cross section', err);
      toast.error('There was a problem creating the cross section.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <SOFormFields soType="CROSS_SECTION" studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="card-actions justify-end">
          <Button type="submit" loading={loading} color="primary">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
