import { useQuery } from '@apollo/client';
import { useController } from 'react-hook-form';
import { graphql } from '~/apollo/generated/v4';
import { Select, type SelectProps } from '~/components/ui/forms/Select';

const OUTCROP_TAG_FIELD = graphql(`
  query OutcropTagField($id: ID!) {
    studyGet(id: $id) {
      id
      outcrops {
        id
        name
      }
    }
  }
`);

type Props = {
  studyId: number;
} & Omit<SelectProps, 'options'>;

/** Field for selecting an outcrop associated with the given study */
export function OutcropTagField({ studyId, name, ...props }: Props) {
  const { field } = useController({ name });
  const { data, loading } = useQuery(OUTCROP_TAG_FIELD, {
    variables: { id: studyId },
  });

  const outcrops = data?.studyGet?.outcrops ?? [];

  return (
    <Select
      {...props}
      name={field.name}
      value={props.value ?? field.value}
      onChange={props.onChange ?? field.onChange}
      options={outcrops.map(oc => ({ value: oc.id, label: oc.name }))}
      disabled={props.disabled || loading}
    />
  );
}
