import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { useState } from 'react';
import { useParams } from 'react-router';
import invariant from 'tiny-invariant';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { CreatePhoto360Form } from '~/components/upload/supporting-object/photo-360/create-photo-360-form';
import { Photo360Items } from '~/components/upload/supporting-object/photo-360/photo-360-items';
import { CollapsibleCard } from '~/components/ui/collapse';
import { createRefetchQueries } from '~/utils/graphql';

const UPLOAD_OUTCROP_PHOTO_360S_PAGE = graphql(`
  query UploadOutcropPhoto360sPage($id: ID!) {
    outcropGet(id: $id) {
      id
      photo360s {
        ...photo360Parts
        placement {
          id
        }
      }
    }
  }
`);

export default function UploadOutcropPhoto360Page() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const params = useParams();
  invariant(params.outcropId);
  const outcropId = parseInt(params.outcropId);

  const [isCreating, setIsCreating] = useState(false);

  const queryVars = { id: outcropId };

  const { data, loading } = useQuery(UPLOAD_OUTCROP_PHOTO_360S_PAGE, {
    variables: queryVars,
  });

  const refetchQueries = createRefetchQueries([
    { query: UPLOAD_OUTCROP_PHOTO_360S_PAGE, variables: queryVars },
  ]);

  const outcrop = data?.outcropGet;
  const photo360s = R.sortBy(p => p.name, outcrop?.photo360s ?? []);

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <div className="space-y-4">
      <CollapsibleCard
        title="Create 360 Photo"
        open={isCreating}
        onToggle={() => setIsCreating(!isCreating)}
      >
        <CreatePhoto360Form
          outcropId={outcropId}
          refetchQueries={refetchQueries}
          onCreateSuccess={() => setIsCreating(false)}
        />
      </CollapsibleCard>

      <NoItemsAlert show={!photo360s.length} entity="360 photos" />
      <Photo360Items photo360s={photo360s} refetchQueries={refetchQueries} />
    </div>
  );
}
