import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type {
  GeoreferenceParentType,
  GeoreferencePartsFragment,
} from '~/apollo/generated/v4/graphql';
import { GeoreferenceDataType } from '~/apollo/generated/v4/graphql';
import { Heading } from '~/components/common/Heading';
import { Modal } from '~/components/common/Modal';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Button } from '~/components/ui/button';
import { useModalState } from '~/hooks/modal';
import type { GeoreferenceImportInput } from '~/utils/geojson-v4';
import { kmlToGeoreferences } from '~/utils/geojson-v4';
import { importToGeoreferenceInput } from '~/utils/modules/georeference-v4';
import { ClearExistingOption } from './clear-existing-option';
import { ConfirmData } from './confirm-data';
import { KmlFileUpload } from './kml-file-upload';

const KML_IMPORT_CREATE_GEOREFERENCE = graphql(`
  mutation KmlImportCreateGeoreference($input: GeoreferenceCreateInput!) {
    georeferenceCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

const KML_IMPORT_DELETE_GEOREFERENCE = graphql(`
  mutation KmlImportDeleteGeoreference($input: GeoreferenceDeleteInput!) {
    georeferenceDelete(input: $input)
  }
`);

export function KmlImport({
  parentType,
  parentId,
  children,
  currentGeoreferences,
  refetchQueries,
}: {
  parentType: GeoreferenceParentType;
  parentId: number;
  children: (showModal: () => void) => JSX.Element;
  currentGeoreferences: GeoreferencePartsFragment[];
  refetchQueries?: PureQueryOptions[];
}) {
  const { show, showModal, hideModal } = useModalState();
  const [shouldClearExisting, setShouldClearExisting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [importInput, setImportInput] = useState<GeoreferenceImportInput[]>();
  const [createGeoreference, { loading: isCreating }] = useMutation(
    KML_IMPORT_CREATE_GEOREFERENCE,
  );
  const [deleteGeoreference, { loading: isDeleting }] = useMutation(
    KML_IMPORT_DELETE_GEOREFERENCE,
  );

  const loading = isCreating || isDeleting;

  // If existing georeferences will be cleared on import, ignore them when calculating the limits
  const importedGeorefs: GeoreferenceImportInput[] =
    importInput?.filter(g => g.uploadStatus === null) ?? [];

  const alreadyHasCenter = currentGeoreferences.some(
    g => g.dataType === GeoreferenceDataType.Centre,
  );
  const alreadyHasOutline = currentGeoreferences.some(
    g => g.dataType === GeoreferenceDataType.Outline,
  );

  const isTryingToAddCenter = importedGeorefs.some(
    g => g.dataType === GeoreferenceDataType.Centre,
  );
  const isTryingToAddOutline = importedGeorefs.some(
    g => g.dataType === GeoreferenceDataType.Outline,
  );

  const isTooManyCenter = alreadyHasCenter && isTryingToAddCenter;
  const isTooManyOutline = alreadyHasOutline && isTryingToAddOutline;

  const isLimitsExceeded =
    !shouldClearExisting && (isTooManyCenter || isTooManyOutline);

  const isEditingAny = importInput?.some(inp => inp.isEditing) ?? false;

  /** Sets the first matching point/polygon to a centre/outline if none is set already */
  function setFirstPrimaryTypes(
    curGeorefs: GeoreferencePartsFragment[],
    pendingGeorefs: GeoreferenceImportInput[],
  ): GeoreferenceImportInput[] {
    const curOutline = curGeorefs.find(
      g => g.dataType === GeoreferenceDataType.Outline,
    );
    const curCentre = curGeorefs.find(
      g => g.dataType === GeoreferenceDataType.Centre,
    );

    const curPendingOutline = pendingGeorefs.find(
      g => g.dataType === GeoreferenceDataType.Outline,
    );
    const curPendingCentre = pendingGeorefs.find(
      g => g.dataType === GeoreferenceDataType.Centre,
    );

    let nextGeorefs = pendingGeorefs;

    // Try to
    if (!curOutline && !curPendingOutline) {
      const firstPolygon = nextGeorefs.find(
        g => g.dataType === GeoreferenceDataType.Polygon,
      );
      if (firstPolygon) {
        nextGeorefs = nextGeorefs.map(georef => {
          if (georef.tempId === firstPolygon.tempId) {
            return { ...georef, dataType: GeoreferenceDataType.Outline };
          }
          return georef;
        });
      }
    }

    if (!curCentre && !curPendingCentre) {
      const firstPoint = nextGeorefs.find(
        g => g.dataType === GeoreferenceDataType.Point,
      );
      if (firstPoint) {
        nextGeorefs = nextGeorefs.map(georef => {
          if (georef.tempId === firstPoint.tempId) {
            return { ...georef, dataType: GeoreferenceDataType.Centre };
          }
          return georef;
        });
      }
    }

    return nextGeorefs;
  }

  useEffect(() => {
    if (shouldClearExisting) {
      // When the clear existing box is checked, try to set a new centre/outline
      const primaries = setFirstPrimaryTypes([], importInput ?? []);
      setImportInput(primaries);
    }
    // Would cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClearExisting]);

  function handleParseFileSuccess(kml: string) {
    try {
      console.log('Converting to GeoJSON...');
      const parsedGeorefs = kmlToGeoreferences(parentType, parentId, kml);
      const parsedWithPrimaries = setFirstPrimaryTypes(
        currentGeoreferences,
        parsedGeorefs,
      );
      setImportInput(parsedWithPrimaries);
      console.log('Parsed georefs from file:', parsedGeorefs);
    } catch (err) {
      console.log('Error parsing to geojson', err);
    }

    setIsUploading(false);
  }

  async function handleUpload(queue: GeoreferenceImportInput[]) {
    if (isEditingAny) {
      window.alert(
        `There is a georeference currently being edited. Save or discard your changes before continuing.`,
      );
      return;
    }
    if (!importInput) {
      throw new Error(
        'No georeferences pending. This state should not be possible.',
      );
    }

    // Set all georeferences to pending
    const nextGeorefs = queue.map(
      g => ({ ...g, uploadStatus: 'pending' }) as GeoreferenceImportInput,
    );
    setIsUploading(true);
    setImportInput(nextGeorefs);

    // Upload georeferences
    // let numUploadSuccess = 0;
    let numUploadFailed = 0;
    let i;
    for (i = 0; i < nextGeorefs.length; i++) {
      const g = nextGeorefs[i];
      // Only attach refetchQueries to the last upload, so we don't reload it multiple times
      const attachRefetchQueries = i === nextGeorefs.length - 1;

      console.log('Creating georef with parent:', parentType, parentId);

      try {
        await createGeoreference({
          variables: {
            input: importToGeoreferenceInput(parentType, parentId, g),
          },
          refetchQueries: attachRefetchQueries ? refetchQueries : undefined,
        });
        console.log('upload successful', i);
        g.uploadStatus = 'success';
        // numUploadSuccess++;
      } catch (err) {
        console.log('upload failed', i);
        g.uploadStatus = 'failed';
        numUploadFailed++;
        console.log('Error uploading georef');
      }

      setImportInput([...nextGeorefs]);
    }

    if (numUploadFailed === 0) {
      toast.success('All imported georeferences saved successfully.');
    } else {
      // Something went wrong with uploading one or more new georefs,
      // show an error toast and bail out of deleting if they checked the box.
      let errorMessage = `There was a problem saving ${numUploadFailed} georeferences.`;
      if (shouldClearExisting) {
        errorMessage +=
          ' Cancelling request - existing georeferences will NOT be deleted.';
      }
      toast.error(errorMessage);
      return;
    }

    // Delete existing georeferences if option was checked AND none failed the upload
    if (shouldClearExisting && numUploadFailed === 0) {
      let j: number;
      // let numDeleteSuccess = 0;
      let numDeleteFailed = 0;
      for (j = 0; j < currentGeoreferences.length; j++) {
        const georef = currentGeoreferences[j];
        const attachRefetchQueries = j === currentGeoreferences.length - 1;

        try {
          await deleteGeoreference({
            variables: {
              input: {
                id: parseInt(georef.id),
              },
            },
            refetchQueries: attachRefetchQueries ? refetchQueries : undefined,
          });
          // numDeleteSuccess++;
        } catch (err) {
          console.log(`Error deleting georeference '${georef.name}'`, err);
          numDeleteFailed++;
        }
      }

      if (numDeleteFailed === 0) {
        toast.success('All current georeferences cleared successfully.');
      } else {
        toast.error(
          `There was a problem deleting ${numDeleteFailed} existing georeferences.`,
        );
      }
    }
  }

  function percentComplete() {
    if (!importInput) return 0;
    const completedGeorefs = importInput.filter(
      g => g.uploadStatus === 'success' || g.uploadStatus === 'failed',
    );
    const percent = Math.ceil(
      (completedGeorefs.length / importInput.length) * 100,
    );
    return percent;
  }

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal} size="lg">
        <Modal.Body heading="Import Georeferences">
          <div className="space-y-2">
            <Heading level={3}>Step 1</Heading>
            <KmlFileUpload onUploadSuccess={handleParseFileSuccess} />
          </div>

          {importInput && importInput.length > 0 && (
            <div className="space-y-4 mt-4">
              <Heading level={3}>Step 2</Heading>
              <p>
                Confirm imported data. In this step, you can remove elements
                from being saved, and change the data type if an element
                represents a <b>centre</b> or an <b>outline</b>.
              </p>

              <div>
                <ClearExistingOption
                  shouldClear={shouldClearExisting}
                  setShouldClear={setShouldClearExisting}
                />
              </div>

              <div>
                <ConfirmData
                  georeferences={importInput}
                  setGeoreferences={setImportInput}
                />
              </div>
            </div>
          )}

          {/* Limit warning */}
          {isLimitsExceeded && (
            <div className="grid grid-cols-12 gap-6 my-4">
              <div className="col-span-8 col-start-2">
                <div className="alert alert-error py-2 px-4">
                  <div className="block">
                    <Heading level={3}>Limits Exceeded</Heading>
                    <p>There are too many georeferences of the same type:</p>
                    <ul className="list-disc list-inside">
                      {isTooManyCenter && <li>Centre</li>}
                      {isTooManyOutline && <li>Outline</li>}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Save button */}
          {importInput && importInput.length > 0 && (
            <div className="text-center mt-4">
              <Button
                type="button"
                color="primary"
                onClick={() => handleUpload([...importInput])}
                disabled={isUploading || isLimitsExceeded}
                className="gap-1"
              >
                <FontAwesomeIcon icon={faCloudUpload} /> Commit Changes
              </Button>
            </div>
          )}

          {/* Progress bar for upload queue */}
          {isUploading && (
            <progress
              className="progress progress-info mt-4 w-full h-6"
              value={percentComplete()}
              max={100}
              color="info"
            />
          )}

          <div className="text-center">
            {isCreating && (
              <SpinnerPlaceholder>
                Uploading geoferences, please wait...
              </SpinnerPlaceholder>
            )}
            {/* {isDeleting && (
              <SpinnerPlaceholder>
                Deleting current georeferences, please wait...
              </SpinnerPlaceholder>
            )} */}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            color="ghost"
            onClick={hideModal}
            disabled={loading}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
