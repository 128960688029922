import { useQuery } from '@apollo/client';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import {
  LifecycleStatus,
  PictureInitialParentType,
} from '~/apollo/generated/v4/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { SOPictureManager } from '~/components/upload/supporting-object/so-picture-manager';
import { useShowUnpublished } from '~/components/upload/supporting-object/use-show-unpublished';
import { useRouteParam } from '~/hooks/routing';
import { createRefetchQueries } from '~/utils/graphql';

const UPLOAD_STUDY_PICTURES_PAGE = graphql(`
  query UploadStudyPicturesPage($id: ID!) {
    studyGet(id: $id) {
      ...studyParts
      pictures {
        ...pictureParts
        file {
          ...fileParts
        }
        outcropTag {
          ...outcropParts
        }
      }
    }
  }
`);

export default function UploadStudyPicturesPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const studyId = useRouteParam('studyId', parseInt);

  const { data, loading } = useQuery(UPLOAD_STUDY_PICTURES_PAGE, {
    variables: { id: studyId },
  });

  const refetchQueries = createRefetchQueries([
    {
      query: UPLOAD_STUDY_PICTURES_PAGE,
      variables: { id: studyId },
    },
  ]);

  const study = data?.studyGet;
  const allPictures = study?.pictures ?? [];

  const unpublishedCount =
    allPictures.reduce(
      (acc, cur) =>
        acc + (cur.lifecycleStatus === LifecycleStatus.Published ? 0 : 1),
      0,
    ) ?? 0;

  const { showUnpublished, PublishedToggle } =
    useShowUnpublished(unpublishedCount);

  const pictures = showUnpublished
    ? allPictures
    : allPictures.filter(
        picture => picture.lifecycleStatus === LifecycleStatus.Published,
      );

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <div className="space-y-4">
      <PublishedToggle />

      <SOPictureManager
        parentType={PictureInitialParentType.Study}
        parentId={studyId}
        pictures={pictures}
        refetchQueries={refetchQueries}
      />
    </div>
  );
}
