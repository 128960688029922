import { useLazyQuery } from '@apollo/client';
import {
  faCheckCircle,
  faCircleDot,
  faExclamationTriangle,
  faMapMarkerAlt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { graphql } from '~/apollo/generated/v4';
import type {
  FieldPicsOutcropListQuery,
  PointInput,
} from '~/apollo/generated/v4/graphql';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Tooltip } from '~/components/common/Tooltip';
import { Button } from '~/components/ui/button';
import { FieldErrors } from '~/components/ui/forms/FieldErrors';
import { TextInput } from '~/components/ui/forms/text-input';
import type {
  BulkFieldPictureUploaderFormValues,
  UploadQueueItem,
} from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader';
import { LocationEditor } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader/location-editor';
import { OutcropPicker } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader/outcrop-picker';
import { OutcropSuggestions } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader/outcrop-suggestions';
import { PreviewMap } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader/preview-map';
import { CoordinatesText } from '~/components/upload/supporting-object/field-picture/coordinates-text';
import { assertExhaustive, cn } from '~/utils/common';

const NEAREST_OUTCROPS = graphql(`
  query NearestOutcrops($point: LatLngInput!) {
    outcropList(
      sort: {
        distanceFromPointInput: { location: $point }
        field: DISTANCE_FROM_POINT
        order: ASC_NULLS_LAST
      }
      # filter: {
      #   distanceFromPoint: { input: { location: $point }, lessThan: 500000 }
      # }
      first: 3
    ) {
      results {
        id
        name
        distanceFromPoint(location: $point)
        center {
          lat
          lng
        }
      }
    }
  }
`);

type Outcrop = NonNullable<
  NonNullable<FieldPicsOutcropListQuery['outcropList']>['results']
>[number];

type MetadataEditorProps = {
  item: UploadQueueItem;
  index: number;
  initialOutcropId?: number;
  outcrops: Outcrop[];
  onRemove: (tmpId: string, index: number) => unknown;
};

export function FieldPictureMetadataFields({
  item,
  index,
  initialOutcropId,
  outcrops,
  onRemove,
}: MetadataEditorProps) {
  type FormValues = BulkFieldPictureUploaderFormValues;
  type MetadataFormValues = FormValues['metadata'][number];

  const form = useFormContext<BulkFieldPictureUploaderFormValues>();
  const { setValue } = form;

  const [loadNearestOutcrops, { loading, data }] =
    useLazyQuery(NEAREST_OUTCROPS);

  const nearestOutcrops = data?.outcropList?.results ?? [];

  const handleRemove = () => onRemove(item.tmpId, index);

  const disabled = item.state !== 'pending' && item.state !== 'failed';

  const fieldName = useCallback(
    <TFieldName extends keyof MetadataFormValues>(name: TFieldName) => {
      return `metadata.${index}.${name}` as const;
    },
    [index],
  );

  const locationValue = form.watch(fieldName('location'));
  const locationApproximateValue = form.watch(fieldName('locationApproximate'));
  const outcropIdValue = form.watch(fieldName('outcropId'));
  const defaultLocationValue = form.watch('defaults.location');
  const defaultAuthorValue = form.watch('defaults.author');
  const defaultOutcropId = form.watch('defaults.outcropId');

  // Load nearest outcrops automatically if picture has a location
  const exifLat = item.exifLocation?.latitude;
  const exifLng = item.exifLocation?.longitude;
  useEffect(() => {
    if (exifLat && exifLng) {
      loadNearestOutcrops({
        variables: {
          point: {
            lat: exifLat,
            lng: exifLng,
          },
        },
      });
    }
  }, [exifLat, exifLng, loadNearestOutcrops]);

  useEffect(() => {
    setValue(fieldName('author'), defaultAuthorValue);
  }, [defaultAuthorValue, fieldName, setValue]);

  useEffect(() => {
    setValue(fieldName('outcropId'), defaultOutcropId);
  }, [defaultOutcropId, fieldName, setValue]);

  function handleOutcropIdChange(outcropId: number | null) {
    const outcrop = outcrops.find(oc => parseInt(oc.id) === outcropId);
    const outcropCenter = outcrop?.center;

    setValue(fieldName('outcropId'), outcropId);

    if (outcropCenter && !locationValue) {
      setValue(fieldName('location'), {
        latitude: outcropCenter.lat,
        longitude: outcropCenter.lng,
      });
    }
  }

  const handleLocationChange = useCallback(
    (location: PointInput | null) => {
      setValue(fieldName('location'), location);
      if (location) {
        loadNearestOutcrops({
          variables: {
            point: {
              lat: location.latitude,
              lng: location.longitude,
            },
          },
        });
      }
    },
    [loadNearestOutcrops, setValue, fieldName],
  );

  const handleLocationApproximateChange = (value: boolean) =>
    setValue(fieldName('locationApproximate'), value);

  return (
    <div className={cn('card shadow-sm transition-colors')}>
      <div className="card-body">
        <div className="absolute right-1 top-1">
          <Tooltip message="Remove from queue">
            <Button
              type="button"
              onClick={() => handleRemove()}
              color="ghost"
              size="sm"
              disabled={item.state === 'uploading' || item.state === 'success'}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>
        </div>

        <h3 className="card-title">{item.file.name}</h3>

        <div className="flex justify-between items-start gap-6">
          <div className="space-y-6 text-center">
            <img
              src={URL.createObjectURL(item.file)}
              className="w-48 h-auto shadow-sm border border-slate-200"
              alt={item.file.name}
            />

            <StateIcon state={item.state} />
          </div>

          <div className="grow space-y-4">
            {item.exifLocation && (
              <CoordinatesText
                exifLocation={item.exifLocation}
                location={locationValue}
              />
            )}

            <div className="grid lg:grid-cols-2 gap-6">
              <Controller
                name={fieldName('author')}
                render={({ field }) => (
                  <TextInput {...field} label="Author" disabled={disabled} />
                )}
              />
              <Controller
                name={fieldName('description')}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label="Description"
                    disabled={disabled}
                  />
                )}
              />
            </div>

            <OutcropPicker
              outcrops={outcrops}
              value={outcropIdValue}
              onChange={handleOutcropIdChange}
              disabled={!!initialOutcropId || disabled}
              name={fieldName('outcropId')}
              required
            />

            <SpinnerPlaceholder show={loading} />
            {!loading && !initialOutcropId && nearestOutcrops.length > 0 && (
              <OutcropSuggestions
                nearestOutcrops={nearestOutcrops}
                selectedOutcropId={outcropIdValue}
                onOutcropClick={outcropId =>
                  setValue(fieldName('outcropId'), outcropId)
                }
              />
            )}
          </div>

          <div className="block min-w-56 text-center space-y-2">
            <div>
              <PreviewMap location={locationValue} />
            </div>

            <div>
              <LocationEditor
                nearestOutcrops={nearestOutcrops}
                outcropsDisabled={!!initialOutcropId}
                outcropId={outcropIdValue}
                location={locationValue}
                exifLocation={item.exifLocation}
                defaultLocation={defaultLocationValue}
                locationApproximate={locationApproximateValue}
                onLocationChange={handleLocationChange}
                onLocationApproximateChange={handleLocationApproximateChange}
                onOutcropIdChange={handleOutcropIdChange}
              >
                {showLocationEditor => (
                  <Button
                    type="button"
                    onClick={showLocationEditor}
                    color="ghost"
                    size="sm"
                    startIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
                    className="h-auto"
                    disabled={disabled}
                  >
                    {locationValue ? 'Adjust location' : 'Set location'}
                  </Button>
                )}
              </LocationEditor>

              {locationApproximateValue && (
                <div className="text-warning">
                  <FontAwesomeIcon icon={faCircleDot} /> Approximate
                </div>
              )}

              <FieldErrors name={fieldName('location')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function StateIcon({ state }: { state: UploadQueueItem['state'] }) {
  switch (state) {
    case 'uploading':
      return <SpinnerPlaceholder />;
    case 'success':
      return (
        <div className="space-y-2 text-center text-success">
          <FontAwesomeIcon icon={faCheckCircle} className="text-5xl" />
          <p>Uploaded successfully</p>
        </div>
      );
    case 'failed':
      return (
        <div className="space-y-2 text-center text-error">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-5xl" />
          <p>Upload error</p>
        </div>
      );
    case 'pending':
      return null;
    default:
      throw assertExhaustive(state);
  }
}
