import { Navigate, createBrowserRouter } from 'react-router';
import { Role } from '~/apollo/generated/v3/graphql';
import { Authorize } from '~/components/auth/Authorize';
import { ErrorBoundary } from '~/components/common/ErrorBoundary';
import { NotFound } from '~/components/common/NotFound';
import { AppShellRoute } from '~/components/layout/AppShellWrapper';
import {
  uploadLithostratFormationListRoute,
  uploadOutcropsRoute,
} from '~/paths';
import Root from '~/root';
import AboutRoute from '~/routes/about';
import AboutPageRoute from '~/routes/about/$pageName';
import AdminTemplate from '~/routes/admin';
import CompanyAdminPage from '~/routes/admin/company';
import CompanyAdminOptionsPage from '~/routes/admin/company/_index';
import { CompanyAdminUpdateMachineTokenPage } from '~/routes/admin/company/machine-tokens/$tokenId';
import CompanyAdminMachineTokensPage from '~/routes/admin/company/machine-tokens/_index';
import { CompanyAdminCreateMachineTokenPage } from '~/routes/admin/company/machine-tokens/create';
import CompanyAdminUsersPage from '~/routes/admin/company/users';
import AdminIndexRoute from '~/routes/admin/index';
import AdminPagePage from '~/routes/admin/page';
import AdminPageUpdate from '~/routes/admin/page/$pageId';
import AdminPageCreate from '~/routes/admin/page/index';
import SuperadminPage from '~/routes/admin/superadmin';
import AdminUploadRoute from '~/routes/admin/upload';
import BookmarkedDataRoute from '~/routes/bookmarked-data/$sdsId';
import SdsRevisionDetailRoute from '~/routes/bookmarked-data/$sdsId/revision.$revisionNumber';
import ConfirmNewUsernameRoute from '~/routes/confirm-new-username.$hash';
import DashboardRoute from '~/routes/dashboard';
import DashboardSearchGeologyResultsRoute from '~/routes/dashboard/wiki-search/geology';
import DashboardSearchLithostratResultsRoute from '~/routes/dashboard/wiki-search/lithostrat';
import DashboardOtherResultsRoute from '~/routes/dashboard/wiki-search/other';
import DataSearchRoute from '~/routes/data';
import DocumentsRoute from '~/routes/documents';
import ForbiddenRoute from '~/routes/forbidden';
import HomeRoute from '~/routes/home';
import HowToRoute from '~/routes/how-to';
import HomeRedirector from '~/routes/index';
import LimeRoute from '~/routes/lime';
import LimeAitRoute from '~/routes/lime-ait';
import LimeInfoRoute from '~/routes/lime-info';
import LimeInstallRoute from '~/routes/lime-install';
import LoginRoute from '~/routes/login';
import LoginErrorRoute from '~/routes/login-error';
import ModernRoute from '~/routes/modern';
import ModernBackgroundRoute from '~/routes/modern/background';
import ModernToolRouteFormWrapper from '~/routes/modern/index';
import MySafariRoute from '~/routes/my-safari';
import MySafariBookmarksRoute from '~/routes/my-safari/bookmarks';
import CollectionRoute from '~/routes/my-safari/bookmarks/collection/$collectionId';
import CompanyBookmarksRoute from '~/routes/my-safari/bookmarks/company';
import MySafariCollectionsPage from '~/routes/my-safari/collections/_index';
import MySafariCompanyCollectionsPage from '~/routes/my-safari/collections/company';
import EditReportPageWrapper from '~/routes/my-safari/reports/$reportId';
import ReportPage from '~/routes/my-safari/reports/$reportId/_index';
import EditReportPage from '~/routes/my-safari/reports/$reportId/edit/_index';
import EditReportAddItemPage from '~/routes/my-safari/reports/$reportId/edit/add';
import MyReportsRoute from '~/routes/my-safari/reports/_index';
import CompanyReportsRoute from '~/routes/my-safari/reports/company';
import { CreateReportPage } from '~/routes/my-safari/reports/create';
import OutcropDetailRoute from '~/routes/outcrop/$outcropId';
import OutcropPhoto360sPage from '~/routes/outcrop/$outcropId/360-photos';
import OutcropAnaloguesRoute from '~/routes/outcrop/$outcropId/analogues';
import OutcropCrossSectionsRoute from '~/routes/outcrop/$outcropId/cross-sections';
import OutcropFaciesRoute from '~/routes/outcrop/$outcropId/facies';
import { OutcropFieldPicturesRoute } from '~/routes/outcrop/$outcropId/field-pictures';
import OutcropDetailIndexRoute from '~/routes/outcrop/$outcropId/index';
import OutcropKeyParametersRoute from '~/routes/outcrop/$outcropId/key-parameters';
import OutcropMeasurementsTabWrapper from '~/routes/outcrop/$outcropId/measurements';
import OutcropMiniModelsPage from '~/routes/outcrop/$outcropId/mini-models';
import OutcropPalaeogeographyRoute from '~/routes/outcrop/$outcropId/palaeogeography';
import OutcropPanoramasRoute from '~/routes/outcrop/$outcropId/panoramas';
import OutcropPicturesRoute from '~/routes/outcrop/$outcropId/pictures';
import OutcropProductionRoute from '~/routes/outcrop/$outcropId/production';
import OutcropReservoirModelsRoute from '~/routes/outcrop/$outcropId/reservoir-models';
import OutcropSedimentaryLogsRoute from '~/routes/outcrop/$outcropId/sedimentary-logs';
import OutcropStudiesRoute from '~/routes/outcrop/$outcropId/studies';
import OutcropTrainingImagesRoute from '~/routes/outcrop/$outcropId/training-images';
import OutcropVariogramsRoute from '~/routes/outcrop/$outcropId/variograms';
import OutcropVideosPage from '~/routes/outcrop/$outcropId/videos';
import OutcropVomsRoute from '~/routes/outcrop/$outcropId/virtual-outcrops';
import VomDetailRoute from '~/routes/outcrop/$outcropId/virtual-outcrops/$vomId';
import VomLimeUploadRoute from '~/routes/outcrop/$outcropId/virtual-outcrops/$vomId/lime-upload';
import OutcropVomsIndexRoute from '~/routes/outcrop/$outcropId/virtual-outcrops/index';
import OutcropWellLogsRoute from '~/routes/outcrop/$outcropId/well-logs';
import ProfileRoute from '~/routes/profile';
import ProfileCompanyAdminsRoute from '~/routes/profile/company-admins';
import ProfileIndexRoute from '~/routes/profile/index';
import RegionDetailRoute from '~/routes/region.$regionId';
import RegisterRoute, {
  loader as registerRouteLoader,
} from '~/routes/register';
import SearchRoute from '~/routes/search';
import StandardRoute from '~/routes/standard';
import StatisticsRoute from '~/routes/stats';
import StudyRoute from '~/routes/study/$studyId';
import StudyCrossSectionsRoute from '~/routes/study/$studyId/cross-sections';
import StudyFaciesRoute from '~/routes/study/$studyId/facies';
import StudyIndexRoute from '~/routes/study/$studyId/index';
import StudyKeyParametersRoute from '~/routes/study/$studyId/key-parameters';
import StudyMeasurementsRoute from '~/routes/study/$studyId/measurements';
import StudyPanoramasRoute from '~/routes/study/$studyId/panoramas';
import StudyPicturesRoute from '~/routes/study/$studyId/pictures';
import StudyProductionRoute from '~/routes/study/$studyId/production';
import StudyReservoirModelsRoute from '~/routes/study/$studyId/reservoir-models';
import StudySedimentaryLogsRoute from '~/routes/study/$studyId/sedimentary-logs';
import StudyTrainingImagesRoute from '~/routes/study/$studyId/training-images';
import StudyVariogramsRoute from '~/routes/study/$studyId/variograms';
import StudyWellLogsRoute from '~/routes/study/$studyId/well-logs';
import SupportBreadcrumbWrapper from '~/routes/support';
import IssueDetailRoute from '~/routes/support/$issueId';
import SupportIndexRoute from '~/routes/support/index';
import { TutorialsPage } from '~/routes/tutorials';
import UploadRouteWrapper, { UploadRouteRedirect } from '~/routes/upload';
import DepositionalBreadcrumbWrapperRoute from '~/routes/upload/depositional';
import DepositionalOverviewRoute from '~/routes/upload/depositional.overview';
import DepositionalUpdateRoute from '~/routes/upload/depositional/$depositionalId';
import DepositionalUpdateDiagramRoute from '~/routes/upload/depositional/$depositionalId/diagram';
import DepositionalWikiUpdateOverviewRoute from '~/routes/upload/depositional/$depositionalId/index';
import UploadDepositionalListRoute from '~/routes/upload/depositional/__list';
import DepositionalWikiCreateRoute from '~/routes/upload/depositional/create';
import ExportGeoreferencesRoute from '~/routes/upload/export/georeferences';
import ExportGeoreferencesAllOutcropsRoute from '~/routes/upload/export/georeferences/all-outcrops';
import ExportGeoreferencesAllRegionsRoute from '~/routes/upload/export/georeferences/all-regions';
import ExportGeoreferencesAllVomsRoute from '~/routes/upload/export/georeferences/all-voms';
import ExportGeoreferencesOutcropRoute from '~/routes/upload/export/georeferences/outcrop';
import ExportOutcropsRoute from '~/routes/upload/export/outcrops';
import ExportStudiesRoute from '~/routes/upload/export/studies';
import ExportVomsRoute from '~/routes/upload/export/voms';
import BulkUploadFieldPicsRoute from '~/routes/upload/field-pictures';
import FileManagerPage from '~/routes/upload/file-manager';
import UploadLithostratTypesBreadcrumbRoute from '~/routes/upload/lithostrat-type';
import UploadLithostratTypeUpdateRoute from '~/routes/upload/lithostrat-type/$lithostratTypeId';
import LithostratTypeUpdateDetailsRoute from '~/routes/upload/lithostrat-type/$lithostratTypeId/_index';
import LithostratTypeUpdateDiagramRoute from '~/routes/upload/lithostrat-type/$lithostratTypeId/diagram';
import LithostratTypeUpdateEntitiesRoute from '~/routes/upload/lithostrat-type/$lithostratTypeId/entities';
import UploadLithostratTypesRoute from '~/routes/upload/lithostrat-type/index';
import UploadLithostratigraphyRoute from '~/routes/upload/lithostrat/__overview';
import UploadFormationListRoute from '~/routes/upload/lithostrat/__overview/formations';
import UploadGroupListRoute from '~/routes/upload/lithostrat/__overview/groups';
import UploadMemberListRoute from '~/routes/upload/lithostrat/__overview/members';
import UploadLithostratBreadcrumbWrapper from '~/routes/upload/lithostrat/_index';
import FormationUpdateRoute from '~/routes/upload/lithostrat/formation/$formationId';
import UpdateFormationDetailPage from '~/routes/upload/lithostrat/formation/$formationId/_index';
import FormationUpdateExamplesRoute from '~/routes/upload/lithostrat/formation/$formationId/examples';
import FormationUpdateGeologicalAgeRoute from '~/routes/upload/lithostrat/formation/$formationId/geological-age';
import FormationUpdateMembersRoute from '~/routes/upload/lithostrat/formation/$formationId/members';
import FormationUpdateWikiRoute from '~/routes/upload/lithostrat/formation/$formationId/wiki';
import UploadFormationCreateRoute from '~/routes/upload/lithostrat/formation/create';
import GroupUpdateRoute from '~/routes/upload/lithostrat/group/$groupId';
import GroupUpdateExamplesRoute from '~/routes/upload/lithostrat/group/$groupId/examples';
import GroupUpdateFormationsRoute from '~/routes/upload/lithostrat/group/$groupId/formations';
import GroupUpdateGeologicalAgeRoute from '~/routes/upload/lithostrat/group/$groupId/geological-age';
import GroupUpdateDetailsRoute from '~/routes/upload/lithostrat/group/$groupId/index';
import GroupUpdateWikiRoute from '~/routes/upload/lithostrat/group/$groupId/wiki';
import MemberUpdateRoute from '~/routes/upload/lithostrat/member/$memberId';
import MemberUpdateExamplesRoute from '~/routes/upload/lithostrat/member/$memberId/examples';
import MemberUpdateGeologicalAgeRoute from '~/routes/upload/lithostrat/member/$memberId/geological-age';
import MemberUpdateDetailsRoute from '~/routes/upload/lithostrat/member/$memberId/index';
import MemberUpdateWikiRoute from '~/routes/upload/lithostrat/member/$memberId/wiki';
import UploadMiniModelsPage from '~/routes/upload/mini-models';
import UploadModelPage from '~/routes/upload/model/__uploadModelsPage';
import OutcropListPage from '~/routes/upload/model/__uploadModelsPage/outcrops';
import UploadProjectListPage from '~/routes/upload/model/__uploadModelsPage/projects';
import UploadRegionListPage from '~/routes/upload/model/__uploadModelsPage/regions';
import UploadStudyListPage from '~/routes/upload/model/__uploadModelsPage/studies';
import UploadOutcropsBreadcrumbWrapper from '~/routes/upload/model/outcrop';
import UploadOutcropUpdatePage from '~/routes/upload/model/outcrop/$outcropId';
import UploadOutcropPhoto360sPage from '~/routes/upload/model/outcrop/$outcropId/360-photos';
import UploadOutcropUpdateAdditionalFieldsRoute from '~/routes/upload/model/outcrop/$outcropId/additional-fields';
import OutcropAuditLogsRoute from '~/routes/upload/model/outcrop/$outcropId/audit-logs';
import UpdateOutcropCrossSectionsRoute from '~/routes/upload/model/outcrop/$outcropId/cross-sections';
import { OutcropDefaultMeasurementsViewPage } from '~/routes/upload/model/outcrop/$outcropId/default-measurements-view';
import UpdateOutcropDeleteOutcropRoute from '~/routes/upload/model/outcrop/$outcropId/delete';
import UpdateOutcropFaciesRoute from '~/routes/upload/model/outcrop/$outcropId/facies';
import UploadOutcropFieldPicsRoute from '~/routes/upload/model/outcrop/$outcropId/field-pictures';
import UpdateOutcropGeologicalAgeRoute from '~/routes/upload/model/outcrop/$outcropId/geological-age';
import UpdateOutcropGeoreferencesRoute from '~/routes/upload/model/outcrop/$outcropId/georeferences';
import UploadOutcropUpdateOverviewPage from '~/routes/upload/model/outcrop/$outcropId/index';
import UpdateOutcropKeyParametersRoute from '~/routes/upload/model/outcrop/$outcropId/key-parameters';
import UpdateOutcropLithostratigraphyRoute from '~/routes/upload/model/outcrop/$outcropId/lithostratigraphy';
import UploadOutcropMiniModelsPage from '~/routes/upload/model/outcrop/$outcropId/mini-models';
import UploadOutcropTourPage from '~/routes/upload/model/outcrop/$outcropId/outcrop-tour';
import UpdateOutcropPalaeogeographyRoute from '~/routes/upload/model/outcrop/$outcropId/palaeogeography';
import UpdateOutcropPanoramasRoute from '~/routes/upload/model/outcrop/$outcropId/panoramas';
import UpdateOutcropPicturesRoute from '~/routes/upload/model/outcrop/$outcropId/pictures';
import UpdateOutcropProductionRoute from '~/routes/upload/model/outcrop/$outcropId/production';
import UpdateOutcropReservoirModelsRoute from '~/routes/upload/model/outcrop/$outcropId/reservoir-models';
import UpdateOutcropSedimentaryLogsRoute from '~/routes/upload/model/outcrop/$outcropId/sedimentary-logs';
import UploadOutcropSOPlacementsPage from '~/routes/upload/model/outcrop/$outcropId/so-placements';
import UpdateOutcropStudiesRoute from '~/routes/upload/model/outcrop/$outcropId/studies';
import UploadOutcropSupplementalDocumentsRoute from '~/routes/upload/model/outcrop/$outcropId/supplemental-documents';
import UpdateOutcropTrainingImagesRoute from '~/routes/upload/model/outcrop/$outcropId/training-images';
import UpdateOutcropVariogramsRoute from '~/routes/upload/model/outcrop/$outcropId/variograms';
import UploadOutcropVideosPage from '~/routes/upload/model/outcrop/$outcropId/videos';
import UpdateOutcropVirtualOutcropsRoute from '~/routes/upload/model/outcrop/$outcropId/virtual-outcrops';
import UpdateOutcropWellLogsRoute from '~/routes/upload/model/outcrop/$outcropId/well-logs';
import UploadOutcropCreatePage from '~/routes/upload/model/outcrop/create';
import UploadProjectBreadcrumbWrapper from '~/routes/upload/model/project';
import UploadProjectUpdatePage from '~/routes/upload/model/project/$projectId';
import UploadProjectCreatePage from '~/routes/upload/model/project/create';
import UploadRegionBreadcrumbWrapper from '~/routes/upload/model/region';
import UploadRegionUpdatePage from '~/routes/upload/model/region/$regionId';
import UpdateRegionGeoreferencesRoute from '~/routes/upload/model/region/$regionId/georeferences';
import UploadRegionOverviewTab from '~/routes/upload/model/region/$regionId/index';
import UploadRegionOutcropsPage from '~/routes/upload/model/region/$regionId/outcrops';
import { UploadRegionStudiesPage } from '~/routes/upload/model/region/$regionId/studies';
import UploadRegionCreatePage from '~/routes/upload/model/region/create';
import UploadStudyBreadcrumbWrapper from '~/routes/upload/model/study';
import UploadStudyUpdatePage from '~/routes/upload/model/study/$studyId';
import UpdateStudyAccessRoute from '~/routes/upload/model/study/$studyId/access';
import UploadStudyUpdateArchitecturalElementsRoute from '~/routes/upload/model/study/$studyId/architectural-elements';
import UpdateAERoute from '~/routes/upload/model/study/$studyId/architectural-elements/$aeId';
import BulkAEImportRoute from '~/routes/upload/model/study/$studyId/architectural-elements/import-aes';
import BulkMeasurementImportRoute from '~/routes/upload/model/study/$studyId/architectural-elements/import-measurements';
import UploadStudyAEsIndexRoute from '~/routes/upload/model/study/$studyId/architectural-elements/index';
import StudyAuditLogsRoute from '~/routes/upload/model/study/$studyId/audit-logs';
import UpdateStudyCrossSectionsRoute from '~/routes/upload/model/study/$studyId/cross-sections';
import { StudyDefaultMeasurementsViewPage } from '~/routes/upload/model/study/$studyId/default-measurements-view';
import UpdateStudyDeleteRoute from '~/routes/upload/model/study/$studyId/delete';
import UpdateStudyFaciesRoute from '~/routes/upload/model/study/$studyId/facies';
import UpdateStudyGeoreferencesRoute from '~/routes/upload/model/study/$studyId/georeferences';
import UploadStudyUpdateOverviewPage from '~/routes/upload/model/study/$studyId/index';
import UpdateStudyKeyParametersRoute from '~/routes/upload/model/study/$studyId/key-parameters';
import UpdateStudyLiteratureRoute from '~/routes/upload/model/study/$studyId/literature';
import UpdateStudyLithostratigraphyRoute from '~/routes/upload/model/study/$studyId/lithostratigraphy';
import MergeStudyRoute from '~/routes/upload/model/study/$studyId/merge';
import UpdateStudyOutcropsRoute from '~/routes/upload/model/study/$studyId/outcrops';
import UpdateStudyPanoramasRoute from '~/routes/upload/model/study/$studyId/panoramas';
import UpdateStudyPicturesRoute from '~/routes/upload/model/study/$studyId/pictures';
import UpdateStudyProductionRoute from '~/routes/upload/model/study/$studyId/production';
import UpdateStudyReservoirModelsRoute from '~/routes/upload/model/study/$studyId/reservoir-models';
import UpdateStudySedimentaryLogsRoute from '~/routes/upload/model/study/$studyId/sedimentary-logs';
import UploadStudySupplementalDocumentsRoute from '~/routes/upload/model/study/$studyId/supplemental-documents';
import UpdateStudyTrainingImagesRoute from '~/routes/upload/model/study/$studyId/training-images';
import UpdateStudyVariogramsRoute from '~/routes/upload/model/study/$studyId/variograms';
import UpdateStudyWellLogsRoute from '~/routes/upload/model/study/$studyId/well-logs';
import UploadStudyCreatePage from '~/routes/upload/model/study/create';
import UploadOtherWikiBreadcrumbWrapper from '~/routes/upload/other';
import OtherWikiUpdatePage from '~/routes/upload/other/$otherWikiId';
import CreateOtherWikiRoute from '~/routes/upload/other/create';
import UploadOtherWikiListRoute from '~/routes/upload/other/index';
import UploadPaleomapBreadcrumbWrapper from '~/routes/upload/paleomap';
import UpdatePaleomapRoute from '~/routes/upload/paleomap/$paleoMapId';
import UpdatePaleomapDetailsRoute from '~/routes/upload/paleomap/$paleoMapId/index';
import UpdatePaleoMapPicturesRoute from '~/routes/upload/paleomap/$paleoMapId/pictures';
import UploadPaleomapIndexRoute from '~/routes/upload/paleomap/index';
import ReviewCommentsPage from '~/routes/upload/review-comments';
import { PictureNamesUtilPage } from '~/routes/upload/util/picture-names';
import OutcropPictureNamesPage from '~/routes/upload/util/picture-names/outcrops';
import StudyPictureNamesPage from '~/routes/upload/util/picture-names/studies';
import UploadVftOverviewPage from '~/routes/upload/vft-overview';
import UploadVomWrapperRoute from '~/routes/upload/vom';
import UpdateVomRoute from '~/routes/upload/vom/$vomId';
import UpdateVomGeoreferencesRoute from '~/routes/upload/vom/$vomId/georeferences';
import UpdateVomOverviewTabRoute from '~/routes/upload/vom/$vomId/index';
import UpdateVomInterpretationsRoute from '~/routes/upload/vom/$vomId/interpretations';
import UpdateVomModelDataPage from '~/routes/upload/vom/$vomId/model-data';
import VomPicturesPage from '~/routes/upload/vom/$vomId/pictures';
import UpdateVomVOSpecsRoute from '~/routes/upload/vom/$vomId/vo-specs';
import UploadVomListRoute from '~/routes/upload/vom/_index';
import CreateVomRoute from '~/routes/upload/vom/create';
import V3GeoModelViewerRoute from '~/routes/v3geo-viewer.$modelId';
import VftRoute from '~/routes/vft';
import OutcropVFTViewer from '~/routes/viewer/$outcropId/vft';
import VomViewerRoute from '~/routes/viewer/_index';
import WikiRoute from '~/routes/wiki';
import GeologyWikiPage from '~/routes/wiki/geology/$wikiId';
import GeologyWikiExamplesRoute from '~/routes/wiki/geology/$wikiId/examples';
import GeologyWikiDetailIndexRoute from '~/routes/wiki/geology/$wikiId/index';
import GeologyWikiVirtualOutcropRoute from '~/routes/wiki/geology/$wikiId/virtual-outcrop';
import GeologyWikiIndexRoute from '~/routes/wiki/geology/index';
import WikiIndexRoute from '~/routes/wiki/index';
import OtherWikiBreadcrumbRoute from '~/routes/wiki/other';
import OtherWikiDetailRoute from '~/routes/wiki/other/$otherId';
import OtherWikiIndexRoute from '~/routes/wiki/other/index';
import LithostratWikiOverviewRoute from '~/routes/wiki/regional/lithostrat';
import LithostratFormationRoute from '~/routes/wiki/regional/lithostrat/formation.$formationId';
import FormationDetailIndexRoute from '~/routes/wiki/regional/lithostrat/formation.$formationId/_index';
import FormationAnaloguesRoute from '~/routes/wiki/regional/lithostrat/formation.$formationId/analogues';
import FormationExamplesRoute from '~/routes/wiki/regional/lithostrat/formation.$formationId/examples';
import LithsotratGroupRoute from '~/routes/wiki/regional/lithostrat/group.$groupId';
import LithostratGroupAnaloguesRoute from '~/routes/wiki/regional/lithostrat/group.$groupId/analogues';
import LithostratGroupExamplesRoute from '~/routes/wiki/regional/lithostrat/group.$groupId/examples';
import LithostratGroupIndexRoute from '~/routes/wiki/regional/lithostrat/group.$groupId/index';
import LithostratMemberRoute from '~/routes/wiki/regional/lithostrat/member.$memberId';
import MemberAnaloguesRoute from '~/routes/wiki/regional/lithostrat/member.$memberId/analogues';
import MemberExamplesRoute from '~/routes/wiki/regional/lithostrat/member.$memberId/examples';
import LithostratMemberIndexRoute from '~/routes/wiki/regional/lithostrat/member.$memberId/index';
import LithostratTypeDetailRoute from '~/routes/wiki/regional/lithostrat/type.$lithostratTypeId';
import RegionsWikiRoute from '~/routes/wiki/regional/regions';
import { ZZZTestPage } from '~/routes/zzz_test';

export const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: 'test',
        element: (
          <Authorize roles={[Role.RoleAdmin]}>
            <ZZZTestPage />
          </Authorize>
        ),
      },
      { id: 'routes/index', index: true, element: <HomeRedirector /> },
      { id: 'routes/home', path: 'home', element: <HomeRoute /> },
      {
        id: 'routes/my-safari/reports/$reportId',
        path: 'my-safari/reports/:reportId',
        element: (
          <Authorize>
            <ReportPage />
          </Authorize>
        ),
      },
      {
        id: 'routes/v3geo-viewer.$modelId',
        path: 'v3geo-viewer/:modelId',
        element: (
          <Authorize>
            <V3GeoModelViewerRoute />
          </Authorize>
        ),
      },
      {
        path: '*',
        element: <AppShellRoute />,
        children: [
          { path: '*', element: <NotFound /> },
          {
            id: 'routes/forbidden',
            path: 'forbidden',
            element: <ForbiddenRoute />,
          },
          {
            id: 'routes/about',
            path: 'about',
            element: <AboutRoute />,
            children: [
              {
                id: 'routes/about/$pageName',
                path: ':pageName',
                element: <AboutPageRoute />,
              },
            ],
          },
          {
            id: 'routes/tutorials',
            path: 'tutorials',
            element: (
              <Authorize roles={[Role.RoleAdmin]}>
                <TutorialsPage />
              </Authorize>
            ),
          },
          {
            id: 'routes/admin',
            path: 'admin',
            element: (
              <Authorize roles={[Role.RoleAdmin, Role.RoleCompanyAdmin]}>
                <AdminTemplate />
              </Authorize>
            ),
            children: [
              {
                id: 'routes/admin/index',
                index: true,
                element: <AdminIndexRoute />,
              },
              {
                id: 'routes/admin/company',
                path: 'company',
                element: (
                  <Authorize roles={[Role.RoleCompanyAdmin]}>
                    <CompanyAdminPage />
                  </Authorize>
                ),
                children: [
                  {
                    id: 'routes/admin/company/_index',
                    index: true,
                    element: <CompanyAdminOptionsPage />,
                  },
                  {
                    id: 'routes/admin/company/users',
                    path: 'users',
                    element: <CompanyAdminUsersPage />,
                  },
                  {
                    id: 'routes/admin/company/machine-tokens',
                    path: 'machine-tokens',
                    element: <CompanyAdminMachineTokensPage />,
                  },
                  {
                    id: 'routes/admin/company/machine-tokens/create',
                    path: 'machine-tokens/create',
                    element: <CompanyAdminCreateMachineTokenPage />,
                  },
                  {
                    id: 'routes/admin/company/machine-tokens/$tokenId',
                    path: 'machine-tokens/:tokenId',
                    element: <CompanyAdminUpdateMachineTokenPage />,
                  },
                ],
              },
              {
                id: 'routes/admin/page',
                path: 'page',
                element: (
                  <Authorize roles={[Role.RoleAdmin]}>
                    <AdminPagePage />
                  </Authorize>
                ),
                children: [
                  {
                    id: 'routes/admin/page/$pageId',
                    path: ':pageId',
                    element: <AdminPageUpdate />,
                  },
                  {
                    id: 'routes/admin/page/index',
                    path: '*',
                    element: <AdminPageCreate />,
                  },
                ],
              },
              {
                id: 'routes/admin/superadmin',
                path: 'superadmin',
                element: (
                  <Authorize roles={[Role.RoleAdmin]}>
                    <SuperadminPage />
                  </Authorize>
                ),
              },
              {
                id: 'routes/admin/upload',
                path: 'upload',
                element: (
                  <Authorize roles={[Role.RoleAdmin]}>
                    <AdminUploadRoute />
                  </Authorize>
                ),
              },
            ],
          },
          {
            id: 'routes/confirm-new-username/$hash',
            path: 'confirm-new-username/:hash',
            element: <ConfirmNewUsernameRoute />,
          },
          {
            id: 'routes/dashboard',
            path: 'dashboard',
            element: (
              <Authorize>
                <DashboardRoute />
              </Authorize>
            ),
            children: [
              {
                id: 'routes/wiki-search/geology',
                path: 'wiki-search/geology',
                element: <DashboardSearchGeologyResultsRoute />,
              },
              {
                id: 'routes/wiki-search/lithostrat',
                path: 'wiki-search/lithostrat',
                element: <DashboardSearchLithostratResultsRoute />,
              },
              {
                id: 'routes/wiki-search/other',
                path: 'wiki-search/other',
                element: <DashboardOtherResultsRoute />,
              },
            ],
          },
          {
            id: 'routes/data',
            path: 'data',
            element: (
              <Authorize roles={[Role.RoleSafari2]}>
                <DataSearchRoute />
              </Authorize>
            ),
          },
          {
            id: 'routes/documents',
            path: 'documents',
            element: (
              <Authorize>
                <DocumentsRoute />
              </Authorize>
            ),
          },
          {
            id: 'routes/how-to',
            path: 'how-to',
            element: (
              <Authorize>
                <HowToRoute />
              </Authorize>
            ),
          },
          {
            id: 'routes/lime-ait',
            path: 'lime-ait',
            element: <LimeAitRoute />,
          },
          {
            id: 'routes/lime-info',
            path: 'lime-info',
            element: <LimeInfoRoute />,
          },
          {
            id: 'routes/lime-install',
            path: 'lime-install',
            element: <LimeInstallRoute />,
          },
          {
            id: 'routes/lime',
            path: 'lime',
            element: <LimeRoute />,
          },
          {
            id: 'routes/login-error',
            path: 'login-error',
            element: <LoginErrorRoute />,
          },
          {
            id: 'routes/login',
            path: 'login',
            element: <LoginRoute />,
          },
          {
            id: 'routes/modern',
            path: 'modern',
            element: <ModernRoute />,
            children: [
              {
                id: 'routes/modern/index',
                index: true,
                element: <ModernToolRouteFormWrapper />,
              },
              {
                id: 'routes/modern/background',
                path: 'background',
                element: <ModernBackgroundRoute />,
              },
            ],
          },
          {
            id: 'routes/my-safari',
            path: 'my-safari',
            element: (
              <Authorize>
                <MySafariRoute />
              </Authorize>
            ),
            children: [
              {
                id: 'routes/my-safari/bookmarks/index',
                path: 'bookmarks',
                element: <MySafariBookmarksRoute />,
              },
              {
                id: 'routes/my-safari/bookmarks/company',
                path: 'bookmarks/company',
                element: <CompanyBookmarksRoute />,
              },
              {
                id: 'routes/my-safari/collections/_index',
                path: 'collections',
                element: <MySafariCollectionsPage />,
              },
              {
                id: 'routes/my-safari/collections/company',
                path: 'collections/company',
                element: <MySafariCompanyCollectionsPage />,
              },
              {
                id: 'routes/my-safari/bookmarks/collection/$collectionId',
                path: 'bookmarks/collection/:collectionId',
                element: <CollectionRoute />,
              },
              {
                id: 'routes/my-safari/reports',
                path: 'reports',
                element: <MyReportsRoute />,
              },
              {
                id: 'routes/my-safari/reports/company',
                path: 'reports/company',
                element: <CompanyReportsRoute />,
              },
              {
                id: 'routes/my-safari/reports/create',
                path: 'reports/create',
                element: <CreateReportPage />,
              },
              {
                id: 'routes/my-safari/reports/$reportId/edit',
                path: 'reports/:reportId/edit',
                element: <EditReportPageWrapper />,
                children: [
                  {
                    id: 'routes/my-safari/reports/$reportId/edit/_index',
                    index: true,
                    element: <EditReportPage />,
                  },
                  {
                    id: 'routes/my-safari/reports/$reportId/edit/add-item',
                    path: 'add-item',
                    element: <EditReportAddItemPage />,
                  },
                ],
              },
            ],
          },
          {
            id: 'routes/outcrop/$outcropId',
            path: 'outcrop/:outcropId',
            element: <OutcropDetailRoute />,
            children: [
              {
                id: 'routes/outcrop/$outcropId/index',
                index: true,
                element: <OutcropDetailIndexRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/analogues',
                path: 'analogues',
                element: <OutcropAnaloguesRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/cross-sections',
                path: 'cross-sections',
                element: <OutcropCrossSectionsRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/facies',
                path: 'facies',
                element: <OutcropFaciesRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/key-parameters',
                path: 'key-parameters',
                element: <OutcropKeyParametersRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/measurements',
                path: 'measurements',
                element: <OutcropMeasurementsTabWrapper />,
              },
              {
                id: 'routes/outcrop/$outcropId/palaeogeography',
                path: 'palaeogeography',
                element: <OutcropPalaeogeographyRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/panoramas',
                path: 'panoramas',
                element: <OutcropPanoramasRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/pictures',
                path: 'pictures',
                element: <OutcropPicturesRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/production',
                path: 'production',
                element: <OutcropProductionRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/reservoir-models',
                path: 'reservoir-models',
                element: <OutcropReservoirModelsRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/sedimentary-logs',
                path: 'sedimentary-logs',
                element: <OutcropSedimentaryLogsRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/studies',
                path: 'studies',
                element: <OutcropStudiesRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/training-images',
                path: 'training-images',
                element: <OutcropTrainingImagesRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/variograms',
                path: 'variograms',
                element: <OutcropVariogramsRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/vft',
                path: 'vft',
                element: <OutcropVFTViewer />,
              },
              {
                id: 'routes/outcrop/$outcropId/virtual-outcrops',
                path: 'virtual-outcrops',
                element: <OutcropVomsRoute />,
                children: [
                  {
                    id: 'routes/outcrop/$outcropId/virtual-outcrops/index',
                    index: true,
                    element: <OutcropVomsIndexRoute />,
                  },
                  {
                    id: 'routes/outcrop/$outcropId/virtual-outcrops/$vomId',
                    path: ':vomId',
                    element: <VomDetailRoute />,
                  },
                ],
              },
              {
                id: 'routes/outcrop/$outcropId/well-logs',
                path: 'well-logs',
                element: <OutcropWellLogsRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/field-pictures',
                path: 'field-pictures',
                element: <OutcropFieldPicturesRoute />,
              },
              {
                id: 'routes/outcrop/$outcropId/mini-models',
                path: 'mini-models',
                element: <OutcropMiniModelsPage />,
              },
              {
                id: 'routes/outcrop/$outcropId/360-photos',
                path: '360-photos',
                element: <OutcropPhoto360sPage />,
              },
              {
                id: 'routes/outcrop/$outcropId/videos',
                path: 'videos',
                element: <OutcropVideosPage />,
              },
            ],
          },
          {
            id: 'routes/outcrop/$outcropId/virtual-outcrops/$vomId/viewer',
            path: 'outcrop/:outcropId/virtual-outcrops/:vomId/viewer',
            element: (
              <Authorize>
                <VomViewerRoute />
              </Authorize>
            ),
          },
          {
            id: 'routes/outcrop/$outcropId/virtual-outcrops/$vomId/lime-upload',
            path: 'outcrop/:outcropId/virtual-outcrops/:vomId/lime-upload',
            element: (
              <Authorize roles={[Role.RoleAdmin]}>
                <VomLimeUploadRoute />
              </Authorize>
            ),
          },
          {
            id: 'routes/profile',
            path: 'profile',
            element: (
              <Authorize>
                <ProfileRoute />
              </Authorize>
            ),
            children: [
              {
                id: 'routes/profile/index',
                index: true,
                element: <ProfileIndexRoute />,
              },
              {
                id: 'routes/profile/company-admins',
                path: 'company-admins',
                element: <ProfileCompanyAdminsRoute />,
              },
            ],
          },
          {
            id: 'routes/bookmarked-data/$sdsId',
            path: 'bookmarked-data/:sdsId',
            element: <BookmarkedDataRoute />,
            children: [
              {
                id: 'routes/bookmarked-data/$sdsId/revision.$revisionNumber',
                path: 'revision/:revisionNumber',
                element: <SdsRevisionDetailRoute />,
              },
            ],
          },
          {
            id: 'routes/study/$studyId',
            path: 'study/:studyId',
            element: (
              <Authorize>
                <StudyRoute />
              </Authorize>
            ),
            children: [
              {
                id: 'routes/study/$studyId/index',
                index: true,
                element: <StudyIndexRoute />,
              },
              {
                id: 'routes/study/$studyId/cross-sections',
                path: 'cross-sections',
                element: <StudyCrossSectionsRoute />,
              },
              {
                id: 'routes/study/$studyId/facies',
                path: 'facies',
                element: <StudyFaciesRoute />,
              },
              {
                id: 'routes/study/$studyId/key-parameters',
                path: 'key-parameters',
                element: <StudyKeyParametersRoute />,
              },
              {
                id: 'routes/study/$studyId/measurements',
                path: 'measurements',
                element: <StudyMeasurementsRoute />,
              },
              {
                id: 'routes/study/$studyId/panoramas',
                path: 'panoramas',
                element: <StudyPanoramasRoute />,
              },
              {
                id: 'routes/study/$studyId/pictures',
                path: 'pictures',
                element: <StudyPicturesRoute />,
              },
              {
                id: 'routes/study/$studyId/production',
                path: 'production',
                element: <StudyProductionRoute />,
              },
              {
                id: 'routes/study/$studyId/reservoir-models',
                path: 'reservoir-models',
                element: <StudyReservoirModelsRoute />,
              },
              {
                id: 'routes/study/$studyId/sedimentary-logs',
                path: 'sedimentary-logs',
                element: <StudySedimentaryLogsRoute />,
              },
              {
                id: 'routes/study/$studyId/training-images',
                path: 'training-images',
                element: <StudyTrainingImagesRoute />,
              },
              {
                id: 'routes/study/$studyId/variograms',
                path: 'variograms',
                element: <StudyVariogramsRoute />,
              },
              {
                id: 'routes/study/$studyId/well-logs',
                path: 'well-logs',
                element: <StudyWellLogsRoute />,
              },
            ],
          },
          {
            id: 'routes/support',
            path: 'support',
            element: (
              <Authorize>
                <SupportBreadcrumbWrapper />
              </Authorize>
            ),
            children: [
              {
                id: 'routes/support/index',
                index: true,
                element: <SupportIndexRoute />,
              },
              {
                id: 'routes/support/$issueId',
                path: ':issueId',
                element: <IssueDetailRoute />,
              },
            ],
          },
          {
            id: 'routes/region.$regionId',
            path: 'region/:regionId',
            element: <RegionDetailRoute />,
          },
          {
            id: 'routes/register',
            path: 'register',
            element: <RegisterRoute />,
            loader: registerRouteLoader,
          },
          {
            id: 'routes/search',
            path: 'search',
            element: (
              <Authorize>
                <SearchRoute />
              </Authorize>
            ),
          },
          {
            id: 'routes/standard',
            path: 'standard',
            element: (
              <Authorize>
                <StandardRoute />
              </Authorize>
            ),
          },
          {
            id: 'routes/stats',
            path: 'stats',
            element: (
              <Authorize>
                <StatisticsRoute />
              </Authorize>
            ),
          },
          {
            id: 'routes/upload',
            path: 'upload',
            element: (
              <Authorize roles={[Role.RoleAdmin]}>
                <UploadRouteWrapper />
              </Authorize>
            ),
            children: [
              {
                index: true,
                element: <UploadRouteRedirect />,
              },
              {
                id: 'routes/upload/field-pictures',
                path: 'field-pictures',
                element: <BulkUploadFieldPicsRoute />,
              },
              {
                id: 'routes/upload/file-manager',
                path: 'file-manager',
                element: <FileManagerPage />,
              },
              {
                id: 'routes/upload/review-comments',
                path: 'review-comments',
                element: <ReviewCommentsPage />,
              },
              {
                id: 'routes/upload/depositional',
                path: 'depositional',
                element: <DepositionalBreadcrumbWrapperRoute />,
                children: [
                  {
                    id: 'routes/upload/depositional/index',
                    index: true,
                    element: <UploadDepositionalListRoute />,
                  },
                  {
                    id: 'routes/upload/depositional/create',
                    path: 'create',
                    element: <DepositionalWikiCreateRoute />,
                  },
                  {
                    id: 'routes/upload/depositional/$depositionalId',
                    path: ':depositionalId',
                    element: <DepositionalUpdateRoute />,
                    children: [
                      {
                        id: 'routes/upload/depositional/$depositionalId/index',
                        index: true,
                        element: <DepositionalWikiUpdateOverviewRoute />,
                      },
                      {
                        id: 'routes/upload/depositional/$depositionalId/diagram',
                        path: 'diagram',
                        element: <DepositionalUpdateDiagramRoute />,
                      },
                    ],
                  },
                ],
              },
              {
                id: 'routes/upload/depositional.overview',
                path: 'depositional/overview',
                element: <DepositionalOverviewRoute />,
              },
              {
                id: 'routes/upload/export',
                path: 'export',
                children: [
                  {
                    id: 'routes/upload/export/georeferences',
                    path: 'georeferences',
                    element: <ExportGeoreferencesRoute />,
                    children: [
                      {
                        id: 'routes/upload/export/georeferences/all-outcrops',
                        path: 'all-outcrops',
                        element: <ExportGeoreferencesAllOutcropsRoute />,
                      },
                      {
                        id: 'routes/upload/export/georeferences/all-regions',
                        path: 'all-regions',
                        element: <ExportGeoreferencesAllRegionsRoute />,
                      },
                      {
                        id: 'routes/upload/export/georeferences/all-voms',
                        path: 'all-voms',
                        element: <ExportGeoreferencesAllVomsRoute />,
                      },
                      {
                        id: 'routes/upload/export/georeferences/outcrop',
                        path: 'outcrop',
                        element: <ExportGeoreferencesOutcropRoute />,
                      },
                    ],
                  },
                  {
                    id: 'routes/upload/export/outcrops',
                    path: 'outcrops',
                    element: <ExportOutcropsRoute />,
                  },
                  {
                    id: 'routes/upload/export/studies',
                    path: 'studies',
                    element: <ExportStudiesRoute />,
                  },
                  {
                    id: 'routes/upload/export/voms',
                    path: 'voms',
                    element: <ExportVomsRoute />,
                  },
                ],
              },
              {
                id: 'routes/upload/lithostrat/__overview',
                path: 'lithostrat',
                element: <UploadLithostratigraphyRoute />,
                children: [
                  {
                    id: 'routes/upload/lithostrat/__overview/index',
                    index: true,
                    element: (
                      <Navigate
                        to={uploadLithostratFormationListRoute()}
                        replace
                      />
                    ),
                  },
                  {
                    id: 'routes/upload/lithostrat/__overview/formations',
                    path: 'formations',
                    element: <UploadFormationListRoute />,
                  },
                  {
                    id: 'routes/upload/lithostrat/__overview/groups',
                    path: 'groups',
                    element: <UploadGroupListRoute />,
                  },
                  {
                    id: 'routes/upload/lithostrat/__overview/members',
                    path: 'members',
                    element: <UploadMemberListRoute />,
                  },
                ],
              },
              {
                id: 'routes/upload/lithostrat',
                path: 'lithostrat',
                element: <UploadLithostratBreadcrumbWrapper />,
                children: [
                  {
                    id: 'routes/upload/lithostrat/formation/create',
                    path: 'formation/create',
                    element: <UploadFormationCreateRoute />,
                  },
                  {
                    id: 'routes/upload/lithostrat/formation/$formationId',
                    path: 'formation/:formationId',
                    element: <FormationUpdateRoute />,
                    children: [
                      {
                        id: 'routes/upload/lithostrat/formation/$formationId/index',
                        index: true,
                        element: <UpdateFormationDetailPage />,
                      },
                      {
                        id: 'routes/upload/lithostrat/formation/$formationId/examples',
                        path: 'examples',
                        element: <FormationUpdateExamplesRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat/formation/$formationId/geological-age',
                        path: 'geological-age',
                        element: <FormationUpdateGeologicalAgeRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat/formation/$formationId/members',
                        path: 'members',
                        element: <FormationUpdateMembersRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat/formation/$formationId/wiki',
                        path: 'wiki',
                        element: <FormationUpdateWikiRoute />,
                      },
                    ],
                  },
                  {
                    id: 'routes/upload/lithostrat/group/$groupId',
                    path: 'group/:groupId',
                    element: <GroupUpdateRoute />,
                    children: [
                      {
                        id: 'routes/upload/lithostrat/group/$groupId/index',
                        index: true,
                        element: <GroupUpdateDetailsRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat/group/$groupId/examples',
                        path: 'examples',
                        element: <GroupUpdateExamplesRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat/group/$groupId/geological-age',
                        path: 'geological-age',
                        element: <GroupUpdateGeologicalAgeRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat/group/$groupId/formations',
                        path: 'formations',
                        element: <GroupUpdateFormationsRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat/group/$groupId/wiki',
                        path: 'wiki',
                        element: <GroupUpdateWikiRoute />,
                      },
                    ],
                  },
                  {
                    id: 'routes/upload/lithostrat/member/$memberId',
                    path: 'member/:memberId',
                    element: <MemberUpdateRoute />,
                    children: [
                      {
                        id: 'routes/upload/lithostrat/member/$memberId/index',
                        index: true,
                        element: <MemberUpdateDetailsRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat/member/$memberId/examples',
                        path: 'examples',
                        element: <MemberUpdateExamplesRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat/member/$memberId/geological-age',
                        path: 'geological-age',
                        element: <MemberUpdateGeologicalAgeRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat/member/$memberId/wiki',
                        path: 'wiki',
                        element: <MemberUpdateWikiRoute />,
                      },
                    ],
                  },
                ],
              },

              {
                id: 'routes/upload/lithostrat-type',
                path: 'lithostrat-type',
                element: <UploadLithostratTypesBreadcrumbRoute />,
                children: [
                  {
                    id: 'routes/upload/lithostrat-type/index',
                    index: true,
                    element: <UploadLithostratTypesRoute />,
                  },
                  {
                    id: 'routes/upload/lithostrat-type/$lithostratTypeId',
                    path: ':lithostratTypeId',
                    element: <UploadLithostratTypeUpdateRoute />,
                    children: [
                      {
                        id: 'routes/upload/lithostrat-type/$lithostratTypeId/index',
                        index: true,
                        element: <LithostratTypeUpdateDetailsRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat-type/$lithostratTypeId/diagram',
                        path: 'diagram',
                        element: <LithostratTypeUpdateDiagramRoute />,
                      },
                      {
                        id: 'routes/upload/lithostrat-type/$lithostratTypeId/entities',
                        path: 'entities',
                        element: <LithostratTypeUpdateEntitiesRoute />,
                      },
                    ],
                  },
                ],
              },
              {
                id: 'routes/upload/model/__uploadModelsPage',
                path: 'model',
                element: <UploadModelPage />,
                children: [
                  {
                    id: 'routes/upload/model/__uploadModelsPage/index',
                    index: true,
                    element: <Navigate to={uploadOutcropsRoute()} />,
                  },
                  {
                    id: 'routes/upload/model/__uploadModelsPage/projects',
                    path: 'projects',
                    element: <UploadProjectListPage />,
                  },
                  {
                    id: 'routes/upload/model/__uploadModelsPage/regions',
                    path: 'regions',
                    element: <UploadRegionListPage />,
                  },
                  {
                    id: 'routes/upload/model/__uploadModelsPage/outcrops',
                    path: 'outcrops',
                    element: <OutcropListPage />,
                  },
                  {
                    id: 'routes/upload/model/__uploadModelsPage/studies',
                    path: 'studies',
                    element: <UploadStudyListPage />,
                  },
                ],
              },
              {
                id: 'routes/upload/model/outcrop',
                path: 'model/outcrop',
                element: <UploadOutcropsBreadcrumbWrapper />,
                children: [
                  {
                    id: 'routes/upload/model/outcrop/create',
                    path: 'create',
                    element: <UploadOutcropCreatePage />,
                  },
                  {
                    id: 'routes/upload/model/outcrop/$outcropId',
                    path: ':outcropId',
                    element: <UploadOutcropUpdatePage />,
                    children: [
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/index',
                        index: true,
                        element: <UploadOutcropUpdateOverviewPage />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/additional-fields',
                        path: 'additional-fields',
                        element: <UploadOutcropUpdateAdditionalFieldsRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/cross-sections',
                        path: 'cross-sections',
                        element: <UpdateOutcropCrossSectionsRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/delete',
                        path: 'delete',
                        element: <UpdateOutcropDeleteOutcropRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/facies',
                        path: 'facies',
                        element: <UpdateOutcropFaciesRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/geological-age',
                        path: 'geological-age',
                        element: <UpdateOutcropGeologicalAgeRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/georeferences',
                        path: 'georeferences',
                        element: <UpdateOutcropGeoreferencesRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/key-parameters',
                        path: 'key-parameters',
                        element: <UpdateOutcropKeyParametersRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/lithostratigraphy',
                        path: 'lithostratigraphy',
                        element: <UpdateOutcropLithostratigraphyRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/default-measurements-view',
                        path: 'default-measurements-view',
                        element: <OutcropDefaultMeasurementsViewPage />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/palaeogeography',
                        path: 'palaeogeography',
                        element: <UpdateOutcropPalaeogeographyRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/panoramas',
                        path: 'panoramas',
                        element: <UpdateOutcropPanoramasRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/pictures',
                        path: 'pictures',
                        element: <UpdateOutcropPicturesRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/production',
                        path: 'production',
                        element: <UpdateOutcropProductionRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/reservoir-models',
                        path: 'reservoir-models',
                        element: <UpdateOutcropReservoirModelsRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/sedimentary-logs',
                        path: 'sedimentary-logs',
                        element: <UpdateOutcropSedimentaryLogsRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/studies',
                        path: 'studies',
                        element: <UpdateOutcropStudiesRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/training-images',
                        path: 'training-images',
                        element: <UpdateOutcropTrainingImagesRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/variograms',
                        path: 'variograms',
                        element: <UpdateOutcropVariogramsRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/virtual-outcrops',
                        path: 'virtual-outcrops',
                        element: <UpdateOutcropVirtualOutcropsRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/well-logs',
                        path: 'well-logs',
                        element: <UpdateOutcropWellLogsRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/audit-logs',
                        path: 'audit-logs',
                        element: <OutcropAuditLogsRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/field-pictures',
                        path: 'field-pictures',
                        element: <UploadOutcropFieldPicsRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/supplemental-documents',
                        path: 'supplemental-documents',
                        element: <UploadOutcropSupplementalDocumentsRoute />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/mini-models',
                        path: 'mini-models',
                        element: <UploadOutcropMiniModelsPage />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/360-photos',
                        path: '360-photos',
                        element: <UploadOutcropPhoto360sPage />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/videos',
                        path: 'videos',
                        element: <UploadOutcropVideosPage />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/so-placements',
                        path: 'so-placements',
                        element: <UploadOutcropSOPlacementsPage />,
                      },
                      {
                        id: 'routes/upload/model/outcrop/$outcropId/outcrop-tour',
                        path: 'outcrop-tour',
                        element: <UploadOutcropTourPage />,
                      },
                    ],
                  },
                ],
              },
              {
                id: 'routes/upload/model/project',
                path: 'model/project',
                element: <UploadProjectBreadcrumbWrapper />,
                children: [
                  {
                    id: 'routes/upload/model/project/create',
                    path: 'create',
                    element: <UploadProjectCreatePage />,
                  },
                  {
                    id: 'routes/upload/model/project/$projectId',
                    path: ':projectId',
                    element: <UploadProjectUpdatePage />,
                  },
                ],
              },
              {
                id: 'routes/upload/model/region',
                path: 'model/region',
                element: <UploadRegionBreadcrumbWrapper />,
                children: [
                  {
                    id: 'routes/upload/model/region/create',
                    path: 'create',
                    element: <UploadRegionCreatePage />,
                  },
                  {
                    id: 'routes/upload/model/region/$regionId',
                    path: ':regionId',
                    element: <UploadRegionUpdatePage />,
                    children: [
                      {
                        id: 'routes/upload/model/region/$regionId/index',
                        index: true,
                        element: <UploadRegionOverviewTab />,
                      },
                      {
                        id: 'routes/upload/model/region/$regionId/georeferences',
                        path: 'georeferences',
                        element: <UpdateRegionGeoreferencesRoute />,
                      },
                      {
                        id: 'routes/upload/model/region/$regionId/outcrops',
                        path: 'outcrops',
                        element: <UploadRegionOutcropsPage />,
                      },
                      {
                        id: 'routes/upload/model/region/$regionId/studies',
                        path: 'studies',
                        element: <UploadRegionStudiesPage />,
                      },
                    ],
                  },
                ],
              },
              {
                id: 'routes/upload/model/study',
                path: 'model/study',
                element: <UploadStudyBreadcrumbWrapper />,
                children: [
                  {
                    id: 'routes/upload/model/study/create',
                    path: 'create',
                    element: <UploadStudyCreatePage />,
                  },
                  {
                    id: 'routes/upload/model/study/$studyId',
                    path: ':studyId',
                    element: <UploadStudyUpdatePage />,
                    children: [
                      {
                        id: 'routes/upload/model/study/$studyId/index',
                        index: true,
                        element: <UploadStudyUpdateOverviewPage />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/access',
                        path: 'access',
                        element: <UpdateStudyAccessRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/cross-sections',
                        path: 'cross-sections',
                        element: <UpdateStudyCrossSectionsRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/delete',
                        path: 'delete',
                        element: <UpdateStudyDeleteRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/merge',
                        path: 'merge',
                        element: <MergeStudyRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/facies',
                        path: 'facies',
                        element: <UpdateStudyFaciesRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/georeferences',
                        path: 'georeferences',
                        element: <UpdateStudyGeoreferencesRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/key-parameters',
                        path: 'key-parameters',
                        element: <UpdateStudyKeyParametersRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/literature',
                        path: 'literature',
                        element: <UpdateStudyLiteratureRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/lithostratigraphy',
                        path: 'lithostratigraphy',
                        element: <UpdateStudyLithostratigraphyRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/default-measurements-view',
                        path: 'default-measurements-view',
                        element: <StudyDefaultMeasurementsViewPage />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/outcrops',
                        path: 'outcrops',
                        element: <UpdateStudyOutcropsRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/panoramas',
                        path: 'panoramas',
                        element: <UpdateStudyPanoramasRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/pictures',
                        path: 'pictures',
                        element: <UpdateStudyPicturesRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/production',
                        path: 'production',
                        element: <UpdateStudyProductionRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/reservoir-models',
                        path: 'reservoir-models',
                        element: <UpdateStudyReservoirModelsRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/sedimentary-logs',
                        path: 'sedimentary-logs',
                        element: <UpdateStudySedimentaryLogsRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/training-images',
                        path: 'training-images',
                        element: <UpdateStudyTrainingImagesRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/variograms',
                        path: 'variograms',
                        element: <UpdateStudyVariogramsRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/well-logs',
                        path: 'well-logs',
                        element: <UpdateStudyWellLogsRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/architectural-elements',
                        path: 'architectural-elements',
                        element: (
                          <UploadStudyUpdateArchitecturalElementsRoute />
                        ),
                        children: [
                          {
                            id: 'routes/upload/model/study/$studyId/architectural-elements/index',
                            index: true,
                            element: <UploadStudyAEsIndexRoute />,
                          },
                          {
                            id: 'routes/upload/model/study/$studyId/architectural-elements/$aeId',
                            path: ':aeId',
                            element: <UpdateAERoute />,
                          },
                          {
                            id: 'routes/upload/model/study/$studyId/architectural-elements/import-aes',
                            path: 'import-aes',
                            element: <BulkAEImportRoute />,
                          },
                          {
                            id: 'routes/upload/model/study/$studyId/architectural-elements/import-measurements',
                            path: 'import-measurements',
                            element: <BulkMeasurementImportRoute />,
                          },
                        ],
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/audit-logs',
                        path: 'audit-logs',
                        element: <StudyAuditLogsRoute />,
                      },
                      {
                        id: 'routes/upload/model/study/$studyId/supplemental-documents',
                        path: 'supplemental-documents',
                        element: <UploadStudySupplementalDocumentsRoute />,
                      },
                    ],
                  },
                ],
              },

              {
                id: 'routes/upload/other',
                path: 'other',
                element: <UploadOtherWikiBreadcrumbWrapper />,
                children: [
                  {
                    id: 'routes/upload/other/index',
                    index: true,
                    element: <UploadOtherWikiListRoute />,
                  },
                  {
                    id: 'routes/upload/other/create',
                    path: 'create',
                    element: <CreateOtherWikiRoute />,
                  },
                  {
                    id: 'routes/upload/other/$otherWikiId',
                    path: ':otherWikiId',
                    element: <OtherWikiUpdatePage />,
                  },
                ],
              },
              {
                id: 'routes/upload/paleomap',
                path: 'paleomap',
                element: <UploadPaleomapBreadcrumbWrapper />,
                children: [
                  {
                    id: 'routes/upload/paleomap/index',
                    index: true,
                    element: <UploadPaleomapIndexRoute />,
                  },
                  {
                    id: 'routes/upload/paleomap/$paleoMapId',
                    path: ':paleoMapId',
                    element: <UpdatePaleomapRoute />,
                    children: [
                      {
                        id: 'routes/upload/paleomap/$paleoMapId/index',
                        index: true,
                        element: <UpdatePaleomapDetailsRoute />,
                      },
                      {
                        id: 'routes/upload/paleomap/$paleoMapId/pictures',
                        path: 'pictures',
                        element: <UpdatePaleoMapPicturesRoute />,
                      },
                    ],
                  },
                ],
              },
              {
                id: 'routes/upload/vom',
                path: 'vom',
                element: <UploadVomWrapperRoute />,
                children: [
                  {
                    id: 'routes/upload/vom/index',
                    index: true,
                    element: <UploadVomListRoute />,
                  },
                  {
                    id: 'routes/upload/vom/create',
                    path: 'create',
                    element: <CreateVomRoute />,
                  },
                  {
                    id: 'routes/upload/vom/$vomId',
                    path: ':vomId',
                    element: <UpdateVomRoute />,
                    children: [
                      {
                        id: 'routes/upload/vom/$vomId/index',
                        index: true,
                        element: <UpdateVomOverviewTabRoute />,
                      },
                      {
                        id: 'routes/upload/vom/$vomId/georeferences',
                        path: 'georeferences',
                        element: <UpdateVomGeoreferencesRoute />,
                      },
                      {
                        id: 'routes/upload/vom/$vomId/interpretations',
                        path: 'interpretations',
                        element: <UpdateVomInterpretationsRoute />,
                      },
                      {
                        id: 'routes/upload/vom/$vomId/pictures',
                        path: 'pictures',
                        element: <VomPicturesPage />,
                      },
                      {
                        id: 'routes/upload/vom/$vomId/vo-specs',
                        path: 'vo-specs',
                        element: <UpdateVomVOSpecsRoute />,
                      },
                      {
                        id: 'routes/upload/vom/$vomId/model-data',
                        path: 'model-data',
                        element: <UpdateVomModelDataPage />,
                      },
                    ],
                  },
                ],
              },
              {
                id: 'routes/upload/vft-overview',
                path: 'vft-overview',
                element: <UploadVftOverviewPage />,
              },
              {
                id: 'routes/upload/mini-models',
                path: 'mini-models',
                element: <UploadMiniModelsPage />,
                loader: UploadMiniModelsPage.loader,
              },
              // Utils
              {
                id: 'routes/upload/util/picture-names',
                path: 'util/picture-names',
                element: <PictureNamesUtilPage />,
                children: [
                  {
                    id: 'routes/upload/util/picture-names/outcrops',
                    path: 'outcrops',
                    element: <OutcropPictureNamesPage />,
                  },
                  {
                    id: 'routes/upload/util/picture-names/studies',
                    path: 'studies',
                    element: <StudyPictureNamesPage />,
                  },
                ],
              },
            ],
          },
          {
            id: 'routes/vft',
            path: 'vft',
            element: (
              <Authorize>
                <VftRoute />
              </Authorize>
            ),
          },
          {
            id: 'routes/wiki',
            path: 'wiki',
            element: (
              <Authorize roles={[Role.RoleSafari2]}>
                <WikiRoute />
              </Authorize>
            ),
            children: [
              {
                id: 'routes/wiki/index',
                index: true,
                element: <WikiIndexRoute />,
              },
              {
                id: 'routes/wiki/geology',
                path: 'geology',
                element: <GeologyWikiIndexRoute />,
              },
              {
                id: 'routes/wiki/geology/$wikiId',
                path: 'geology/:wikiId',
                element: <GeologyWikiPage />,
                children: [
                  {
                    id: 'routes/wiki/geology/$wikiId/index',
                    index: true,
                    element: <GeologyWikiDetailIndexRoute />,
                  },
                  {
                    id: 'routes/wiki/geology/$wikiId/examples',
                    path: 'examples',
                    element: <GeologyWikiExamplesRoute />,
                  },
                  {
                    id: 'routes/wiki/geology/$wikiId/virtual-outcrop',
                    path: 'virtual-outcrop',
                    element: <GeologyWikiVirtualOutcropRoute />,
                  },
                ],
              },
              {
                id: 'routes/wiki/regional/regions',
                path: 'regional/regions',
                element: <RegionsWikiRoute />,
              },
              {
                id: 'routes/wiki/regional/lithostrat',
                path: 'regional/lithostrat',
                children: [
                  {
                    id: 'routes/wiki/regional/lithostrat/index',
                    index: true,
                    element: <LithostratWikiOverviewRoute />,
                  },
                  {
                    id: 'routes/wiki/region/lithostrat/lithostratType.$lithostratTypeId',
                    path: 'type/:lithostratTypeId',
                    element: <LithostratTypeDetailRoute />,
                  },
                  {
                    id: 'routes/wiki/regional/lithostrat/formation.$formationId',
                    path: 'formation/:formationId',
                    element: <LithostratFormationRoute />,
                    children: [
                      {
                        id: 'routes/wiki/regional/lithostrat/formation.$formationId/index',
                        index: true,
                        element: <FormationDetailIndexRoute />,
                      },
                      {
                        id: 'routes/wiki/regional/lithostrat/formation.$formationId/examples',
                        path: 'examples',
                        element: <FormationExamplesRoute />,
                      },
                      {
                        id: 'routes/wiki/regional/lithostrat/formation.$formationId/analogues',
                        path: 'analogues',
                        element: <FormationAnaloguesRoute />,
                      },
                    ],
                  },
                  {
                    id: 'routes/wiki/regional/lithostrat/group.$groupId',
                    path: 'group/:groupId',
                    element: <LithsotratGroupRoute />,
                    children: [
                      {
                        id: 'routes/wiki/regional/lithostrat/group.$groupId/index',
                        index: true,
                        element: <LithostratGroupIndexRoute />,
                      },
                      {
                        id: 'routes/wiki/regional/lithostrat/group.$groupId/examples',
                        path: 'examples',
                        element: <LithostratGroupExamplesRoute />,
                      },
                      {
                        id: 'routes/wiki/regional/lithostrat/group.$groupId/analogues',
                        path: 'analogues',
                        element: <LithostratGroupAnaloguesRoute />,
                      },
                    ],
                  },
                  {
                    id: 'routes/wiki/regional/lithostrat/member.$memberId',
                    path: 'member/:memberId',
                    element: <LithostratMemberRoute />,
                    children: [
                      {
                        id: 'routes/wiki/regional/lithostrat/member.$memberId/index',
                        index: true,
                        element: <LithostratMemberIndexRoute />,
                      },
                      {
                        id: 'routes/wiki/regional/lithostrat/member.$memberId/examples',
                        path: 'examples',
                        element: <MemberExamplesRoute />,
                      },
                      {
                        id: 'routes/wiki/regional/lithostrat/member.$memberId/analogues',
                        path: 'analogues',
                        element: <MemberAnaloguesRoute />,
                      },
                    ],
                  },
                ],
              },
              {
                id: 'routes/wiki/other',
                path: 'other',
                element: <OtherWikiBreadcrumbRoute />,
                children: [
                  {
                    id: 'routes/wiki/other/index',
                    index: true,
                    element: <OtherWikiIndexRoute />,
                  },
                  {
                    id: 'routes/wiki/other/$otherId',
                    path: ':otherId',
                    element: <OtherWikiDetailRoute />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);
