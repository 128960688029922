import { zodResolver } from '@hookform/resolvers/zod';
import type { FieldValues } from 'react-hook-form';
import { useForm as useReactHookForm } from 'react-hook-form';
import type { z, ZodType } from 'zod';

/**
 * Wrapper for react-hook-form's `useForm` with a few default settings.
 * Allows for passing a zod schema directly (as `schema`) rather than calling zodResolver(schema).
 * Overrides form values type with the inferred generic based on the provided `values` object */
export function useZodForm<
  TSchema extends ZodType,
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
>({
  schema,
  ...params
}: Parameters<
  typeof useReactHookForm<TFieldValues, TContext, TTransformedValues>
>[0] & {
  schema?: TSchema;
}) {
  type InferredTTransformedValues = typeof schema extends undefined
    ? TTransformedValues
    : z.infer<TSchema>;

  return useReactHookForm<TFieldValues, TContext, InferredTTransformedValues>({
    ...params,
    mode: params.mode ?? 'onSubmit',
    criteriaMode: params.criteriaMode ?? 'all',
    resolver: schema ? zodResolver(schema) : params.resolver,
  });
}
