import type { GeoreferenceImportInput } from '~/utils/geojson-v4';
import { ConfirmGeoreferenceForm } from './confirm-georeference-form';
import { ImportPreviewMap } from './import-preview-map';

export function ConfirmData({
  georeferences,
  setGeoreferences,
}: {
  georeferences: GeoreferenceImportInput[];
  setGeoreferences: (g: GeoreferenceImportInput[]) => unknown;
}) {
  function handleSubmit(georeference: GeoreferenceImportInput) {
    const nextGeorefs = georeferences.map(g => {
      if (g.tempId === georeference.tempId) {
        return georeference;
      }
      return g;
    });
    setGeoreferences(nextGeorefs);
  }

  function handleRemove(tempId: string) {
    const nextGeorefs = georeferences.filter(g => g.tempId === tempId);
    setGeoreferences(nextGeorefs);
  }

  return (
    <div className="grid grid-cols-2 gap-6">
      <div>
        {georeferences.map(g => (
          <ConfirmGeoreferenceForm
            key={g.tempId}
            georeference={g}
            onSubmit={handleSubmit}
            onRemove={handleRemove}
          />
        ))}
      </div>
      <div>
        <ImportPreviewMap georeferences={georeferences} />
      </div>
    </div>
  );
}
