import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';
import { FieldErrors } from '~/components/ui/forms/FieldErrors';
import type { FormLabelProps } from '~/components/ui/forms/FormLabel';
import { FormLabel } from '~/components/ui/forms/FormLabel';
import { filterUnique } from '~/utils/common';

function splitTags(tags: string) {
  return tags.split(',').filter(Boolean);
}

type Props = {
  name: string;
  label?: FormLabelProps['label'];
  /** Whether the values should be stored as a string-array, or comma-separated string */
  mode?: 'array' | 'string';
  disabled?: boolean;
  required?: boolean;
};

export function TagsInput({
  name,
  label,
  mode = 'array',
  disabled,
  required,
  ...props
}: Props) {
  const { watch, setValue } = useFormContext();
  const fieldValueRaw: unknown = watch(name);

  const setFieldValue = useCallback(
    (value: unknown) => setValue(name, value),
    [name, setValue],
  );

  const fieldValue = (() => {
    if (typeof fieldValueRaw === 'string' && mode === 'string') {
      return fieldValueRaw;
    }

    const stringArrayResult = z.array(z.string()).safeParse(fieldValueRaw);
    if (stringArrayResult.success) {
      return stringArrayResult.data;
    }

    return '';
  })();

  const valueStrValue = useMemo((): string => {
    if (typeof fieldValue === 'string') {
      return splitTags(fieldValue).sort().join(',');
    } else {
      return fieldValue.slice().sort().join(',');
    }
  }, [fieldValue]);

  const [tags, setTags] = useState<string>(valueStrValue);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setTags(splitTags(valueStrValue).sort().join(','));
  }, [valueStrValue]);

  useEffect(() => {
    if (mode === 'string') {
      setFieldValue(tags);
    } else {
      setFieldValue(splitTags(tags));
    }
  }, [tags, setFieldValue, mode]);

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const value = inputValue.trim();
      if (value.length) {
        setTags(prevTags =>
          splitTags(prevTags)
            .concat([value])
            .sort()
            .filter(filterUnique)
            .join(','),
        );
      }

      setInputValue('');
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  function removeTag(tag: string) {
    setTags(prevTags =>
      splitTags(prevTags)
        .filter(t => t !== tag)
        .join(','),
    );
  }

  const tagsField = (
    <div className="input input-bordered w-full h-auto min-h-10 py-1 flex gap-2 flex-wrap">
      {splitTags(tags).map((tag, index) => (
        <Badge key={index} color="ghost">
          {tag}
          <Button
            type="button"
            color="ghost"
            size="xs"
            onClick={() => removeTag(tag)}
          >
            <FontAwesomeIcon icon={faXmarkCircle} className="text-xs" />
          </Button>
        </Badge>
      ))}

      <input
        {...props}
        type="text"
        name={name}
        value={inputValue}
        onChange={handleInputChange}
        readOnly={disabled}
        placeholder="Add value"
        onKeyDown={handleKeyPress}
        className="grow"
      />
    </div>
  );

  return (
    <div className="form-group">
      <FormLabel name={name} label={label} required={required} />
      {tagsField}
      <FieldErrors name={name} />
      {!disabled && (
        <label className="label">
          <label className="label-text-alt">
            Type a value into the text box and press{' '}
            <span className="kbd">return</span> or{' '}
            <span className="kbd">,</span> to add it to the list.
          </label>
        </label>
      )}
    </div>
  );
}
