import { useQuery } from '@apollo/client';
import { faFileExport, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { UploadOutcropList } from '~/components/upload/outcrop/UploadOutcropList';
import { useSortFilter } from '~/hooks/data';
import { exportOutcropsRoute, uploadOutcropCreateRoute } from '~/paths';
import { toSortableOutcropV4 } from '~/utils/modules/outcrop-v4';

const UPLOAD_OUTCROP_LIST_PAGE = graphql(`
  query UploadOutcropListPage {
    outcropList(first: 9999) {
      results {
        ...outcropListParts
      }
    }
  }
`);

export default function OutcropListPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const { data, loading } = useQuery(UPLOAD_OUTCROP_LIST_PAGE);

  const sortableOutcrops =
    data?.outcropList?.results?.map(toSortableOutcropV4) ?? [];

  const { items, filterSearchProps, sortIndicatorProps } = useSortFilter(
    sortableOutcrops,
    'name',
    'name',
    'uploadOutcropList',
  );

  return (
    <Panel>
      <Panel.Heading className="flex justify-between">
        <Panel.Title>
          Outcrops
          {!loading && (
            <small className="text-muted" style={{ marginLeft: '10px' }}>
              (<strong>{items.length}</strong> results displayed)
            </small>
          )}
        </Panel.Title>

        <div className="space-x-2">
          <Link
            to={exportOutcropsRoute()}
            className="btn btn-ghost btn-xs"
            target="_blank"
          >
            <FontAwesomeIcon icon={faFileExport} /> Export Outcrops
          </Link>

          <Link
            to={uploadOutcropCreateRoute()}
            className="btn btn-primary btn-xs"
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Create Outcrop
          </Link>
        </div>
      </Panel.Heading>
      <Panel.Body>
        {loading && <SpinnerPlaceholder />}
        {!loading && (
          <UploadOutcropList
            outcrops={items}
            sortIndicatorProps={sortIndicatorProps}
            filterSearchProps={filterSearchProps}
          />
        )}
      </Panel.Body>
    </Panel>
  );
}
