import { SimplePanel } from '~/components/common/Panel';
import { BasinFormFields } from '~/components/upload/outcrop/basin-form-fields';
import { OutcropOverviewFormFields } from '~/components/upload/outcrop/outcrop-overview-form-fields';
import { OutcropQualityParametersFormFields } from '~/components/upload/outcrop/outcrop-quality-parameters-form-fields';
import { OutcropSourceAreaFormFields } from '~/components/upload/outcrop/outcrop-source-area-form-fields';

export function OutcropFormFields() {
  return (
    <div className="grid lg:grid-cols-3 gap-4">
      <div className="lg:col-span-2">
        <SimplePanel title="Overview">
          <OutcropOverviewFormFields />
        </SimplePanel>
      </div>

      <div className="space-y-4">
        <SimplePanel title="Basin">
          <BasinFormFields />
        </SimplePanel>
        <SimplePanel title="Source Area">
          <OutcropSourceAreaFormFields />
        </SimplePanel>
        <SimplePanel title="Quality Parameters">
          <OutcropQualityParametersFormFields />
        </SimplePanel>
      </div>
    </div>
  );
}
