/** Redirects to dashboard if logged in, or home if not */
export const rootRoute = () => '/';
export const homeRoute = () => '/home';
export const dashboardRoute = () => '/dashboard';

export const loginRoute = () => '/login';
export const loginErrorRoute = () => '/login-error';

export const forbiddenRoute = () => `/forbidden`;

export const myProfileRoute = () => '/profile';
export const myProfileAdminsRoute = () => `${myProfileRoute()}/company-admins`;

export const confirmNewUsernameRoute = (hash?: string) =>
  `/confirm-new-username${hash ? `/${hash}` : ''}`;

export const vftRoute = () => '/vft';
export const vftViewerRoute = (outcropId: number) => `/vft-viewer/${outcropId}`;

export const limeRoute = () => '/lime';
export const howToRoute = () => '/how-to';
export const statsRoute = () => '/stats';

export const aboutRoute = (pageName: string) => `/about/${pageName}`;
export const tutorialsRoute = () => '/tutorials';

export const documentsRoute = () => `/documents`;

// Issues
export const supportRoute = () => '/support';
export const issueDetailRoute = (issueId: number) =>
  `${supportRoute()}/${issueId}`;

// Search Analogues
export const searchRoute = () => `/search`;

// Data search
export const dataSearchRoute = () => `/data`;

// New data search
export const savedDataSearchRoute = (searchId: number) =>
  `/bookmarked-data/${searchId}`;
export const savedDataSearchRevisionRoute = (
  searchId: number,
  revisionNumber: number,
) => `${savedDataSearchRoute(searchId)}/revision/${revisionNumber}`;

export const regionRoute = (regionId: number) => `/region/${regionId}`;

// Outcrop page
export const outcropRoute = (outcropId: number) => `/outcrop/${outcropId}`;
export const outcropOverviewTabRoute = (outcropId: number) =>
  outcropRoute(outcropId);
export const outcropKeyParametersTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/key-parameters`;
export const outcropVirtualOutcropsTabRoute = (
  outcropId: number,
  vomId?: number,
) => `${outcropRoute(outcropId)}/virtual-outcrops${vomId ? `/${vomId}` : ''}`;
export const outcropAnaloguesTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/analogues`;
export const outcropPalaeogeographyTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/palaeogeography`;
export const outcropStudiesTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/studies`;
export const outcropMeasurementsTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/measurements`;
export const outcropPicturesTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/pictures`;
export const outcropFaciesTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/facies`;
export const outcropCrossSectionsTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/cross-sections`;
export const outcropSedimentaryLogsTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/sedimentary-logs`;
export const outcropWellLogsTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/well-logs`;
export const outcropProductionTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/production`;
export const outcropReservoirModelsTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/reservoir-models`;
export const outcropTrainingImagesTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/training-images`;
export const outcropVariogramsTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/variograms`;
export const outcropPanoramasTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/panoramas`;
export const outcropFieldPicturesTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/field-pictures`;
export const outcropMiniModelsTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/mini-models`;
export const outcropPhoto360sTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/360-photos`;
export const outcropVideosTabRoute = (outcropId: number) =>
  `${outcropRoute(outcropId)}/videos`;

// VOM page
export const vomRoute = (outcropId: number, vomId: number) =>
  `${outcropRoute(outcropId)}/virtual-outcrops/${vomId}`;

export const vomViewerRoute = (outcropId: number, vomId: number) =>
  `${vomRoute(outcropId, vomId)}/viewer`;

// V3Geo model viewer
export const v3GeoModelViewerRoute = (modelId: number) =>
  `/v3geo-viewer/${modelId}`;

// Study page
export const studyRoute = (studyId: number) => `/study/${studyId}`;
export const studySummaryRoute = (studyId: number) => `${studyRoute(studyId)}`;
export const studyKeyParametersRoute = (studyId: number) =>
  `${studyRoute(studyId)}/key-parameters`;
export const studyMeasurementsRoute = (studyId: number) =>
  `${studyRoute(studyId)}/measurements`;
export const studyPicturesTabRoute = (studyId: number) =>
  `${studyRoute(studyId)}/pictures`;
export const studyFaciesTabRoute = (studyId: number) =>
  `${studyRoute(studyId)}/facies`;
export const studyCrossSectionsTabRoute = (studyId: number) =>
  `${studyRoute(studyId)}/cross-sections`;
export const studySedimentaryLogsTabRoute = (studyId: number) =>
  `${studyRoute(studyId)}/sedimentary-logs`;
export const studyWellLogsTabRoute = (studyId: number) =>
  `${studyRoute(studyId)}/well-logs`;
export const studyProductionTabRoute = (studyId: number) =>
  `${studyRoute(studyId)}/production`;
export const studyReservoirModelsTabRoute = (studyId: number) =>
  `${studyRoute(studyId)}/reservoir-models`;
export const studyTrainingImagesTabRoute = (studyId: number) =>
  `${studyRoute(studyId)}/training-images`;
export const studyVariogramsTabRoute = (studyId: number) =>
  `${studyRoute(studyId)}/variograms`;
export const studyPanoramasTabRoute = (studyId: number) =>
  `${studyRoute(studyId)}/panoramas`;

// Wiki

export const wikiRoute = () => `/wiki`;

export const wikiSearchRoute = () => `${dashboardRoute()}/wiki-search`;
export const wikiSearchGeologyRoute = () => `${wikiSearchRoute()}/geology`;
export const wikiSearchLithostratigraphyRoute = () =>
  `${wikiSearchRoute()}/lithostrat`;
export const wikiSearchOtherRoute = () => `${wikiSearchRoute()}/other`;

// Geology
export const geologyWikiOverviewRoute = () => `${wikiRoute()}/geology`;
export const geologyWikiPageRoute = (wikiId: number) =>
  `${wikiRoute()}/geology/${wikiId}`;
export const geologyWikiPageExamplesRoute = (wikiId: number) =>
  `${geologyWikiPageRoute(wikiId)}/examples`;
export const geologyWikiPageVirtualOutcropRoute = (wikiId: number) =>
  `${geologyWikiPageRoute(wikiId)}/virtual-outcrop`;

// Regional
export const regionWikiBaseRoute = () => `${wikiRoute()}/regional`;
export const regionWikiOverviewRoute = () => `${regionWikiBaseRoute()}/regions`;

export const lithostratWikiBaseRoute = () =>
  `${regionWikiBaseRoute()}/lithostrat`;
export const lithostratWikiOverviewRoute = () => lithostratWikiBaseRoute();

export const lithostratFormationRoute = (formationId: number) =>
  `${lithostratWikiBaseRoute()}/formation/${formationId}`;
export const lithostratFormationExamplesRoute = (formationId: number) =>
  `${lithostratFormationRoute(formationId)}/examples`;
export const lithostratFormationAnaloguesRoute = (formationId: number) =>
  `${lithostratFormationRoute(formationId)}/analogues`;

export const lithostratGroupRoute = (groupId: number) =>
  `${lithostratWikiBaseRoute()}/group/${groupId}`;
export const lithostratGroupExamplesRoute = (groupId: number) =>
  `${lithostratGroupRoute(groupId)}/examples`;
export const lithostratGroupAnaloguesRoute = (groupId: number) =>
  `${lithostratGroupRoute(groupId)}/analogues`;

export const lithostratMemberRoute = (memberId: number) =>
  `${lithostratWikiBaseRoute()}/member/${memberId}`;
export const lithostratMemberExamplesRoute = (memberId: number) =>
  `${lithostratMemberRoute(memberId)}/examples`;
export const lithostratMemberAnaloguesRoute = (memberId: number) =>
  `${lithostratMemberRoute(memberId)}/analogues`;

export const lithostratTypeRoute = (lithostratTypeId: number) =>
  `${lithostratWikiBaseRoute()}/type/${lithostratTypeId}`;

// Other
export const otherWikiOverviewRoute = () => `${wikiRoute()}/other`;
export const otherWikiDetailRoute = (wikiId: number) =>
  `${wikiRoute()}/other/${wikiId}`;

// Modern
export const modernRoute = () => `/modern`;
export const modernBackgroundRoute = () => `${modernRoute()}/background`;

// My Safari
export const mySafariRoute = () => '/my-safari';

// Bookmarks
export const bookmarksRoute = () => `${mySafariRoute()}/bookmarks`;
export const companyBookmarksRoute = () => `${bookmarksRoute()}/company`;
export const bookmarkCollectionRoute = (collectionId: number) =>
  `${bookmarksRoute()}/collection/${collectionId}`;
export const collectionsRoute = () => `${mySafariRoute()}/collections`;
export const companyCollectionsRoute = () => `${collectionsRoute()}/company`;

// Reports
export const reportsRoute = () => `${mySafariRoute()}/reports`;
export const companyReportsRoute = () => `${reportsRoute()}/company`;

export const createReportRoute = () => `${reportsRoute()}/create`;

export const reportRoute = (reportId: number) =>
  `${reportsRoute()}/${reportId}`;
export const editReportRoute = (reportId: number) =>
  `${reportRoute(reportId)}/edit`;
export const editReportAddItemRoute = (reportId: number) =>
  `${editReportRoute(reportId)}/add-item`;

// Admin routes

export const adminRoute = () => '/admin';
export const adminPageRoute = () => `${adminRoute()}/page`;
export const adminPageUpdateRoute = (pageId: number) =>
  `${adminPageRoute()}/${pageId}`;

export const companyAdminRoute = () => `${adminRoute()}/company`;
export const companyAdminUsersRoute = () => `${companyAdminRoute()}/users`;
export const companyAdminMachineTokensRoute = () =>
  `${companyAdminRoute()}/machine-tokens`;
export const companyAdminMachineTokensCreateRoute = () =>
  `${companyAdminMachineTokensRoute()}/create`;
export const companyAdminMachineTokenUpdateRoute = (tokenId: number) =>
  `${companyAdminMachineTokensRoute()}/${tokenId}`;

export const adminUploadRoute = () => `${adminRoute()}/upload`;
const uploadRoute = () => '/upload'; // Only a base route, use /admin/upload for the page

export const fileManagerRoute = () => `${uploadRoute()}/file-manager`;

export const reviewCommentsRoute = () => `${uploadRoute()}/review-comments`;

// Model admin lists
export const uploadModelRoute = () => `${uploadRoute()}/model`;
export const uploadProjectsRoute = () => `${uploadModelRoute()}/projects`;
export const uploadRegionsRoute = () => `${uploadModelRoute()}/regions`;
export const uploadOutcropsRoute = () => `${uploadModelRoute()}/outcrops`;
export const uploadStudiesRoute = () => `${uploadModelRoute()}/studies`;

// Upload project
export const uploadProjectRoute = () => `${uploadModelRoute()}/project`;
export const uploadProjectCreateRoute = () => `${uploadProjectRoute()}/create`;
export const uploadProjectUpdateRoute = (projectId: number) =>
  `${uploadProjectRoute()}/${projectId}`;

// Upload region
export const uploadRegionRoute = () => `${uploadModelRoute()}/region`;
export const uploadRegionCreateRoute = () => `${uploadRegionRoute()}/create`;
export const uploadRegionUpdateRoute = (regionId: number) =>
  `${uploadRegionRoute()}/${regionId}`;
export const uploadRegionUpdateGeoreferencesRoute = (regionId: number) =>
  `${uploadRegionUpdateRoute(regionId)}/georeferences`;
export const uploadRegionUpdateOutcropsRoute = (regionId: number) =>
  `${uploadRegionUpdateRoute(regionId)}/outcrops`;
export const uploadRegionUpdateStudiesRoute = (regionId: number) =>
  `${uploadRegionUpdateRoute(regionId)}/studies`;

// Upload Outcrop
export const uploadOutcropRoute = () => `${uploadModelRoute()}/outcrop`;
export const uploadOutcropCreateRoute = () => `${uploadOutcropRoute()}/create`;
export const uploadOutcropUpdateRoute = (outcropId: number) =>
  `${uploadOutcropRoute()}/${outcropId}`;
export const uploadOutcropUpdateAdditionalFieldsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/additional-fields`;
export const uploadOutcropUpdateKeyParametersRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/key-parameters`;
export const uploadOutcropUpdateVirtualOutcropsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/virtual-outcrops`;
export const uploadOutcropUpdateStudiesRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/studies`;
export const uploadOutcropUpdateLithostratigraphyRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/lithostratigraphy`;
export const uploadOutcropUpdateDmvRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/default-measurements-view`;
export const uploadOutcropUpdatePalaeogeographyRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/palaeogeography`;
export const uploadOutcropUpdateGeoreferencesRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/georeferences`;
export const uploadOutcropUpdateGeologicalAgeRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/geological-age`;
export const uploadOutcropUpdatePicturesRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/pictures`;
export const uploadOutcropUpdateFaciesRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/facies`;
export const uploadOutcropUpdateCrossSectionsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/cross-sections`;
export const uploadOutcropUpdateSedimentaryLogsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/sedimentary-logs`;
export const uploadOutcropUpdateWellLogsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/well-logs`;
export const uploadOutcropUpdateProductionRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/production`;
export const uploadOutcropUpdateReservoirModelsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/reservoir-models`;
export const uploadOutcropUpdateTrainingImagesRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/training-images`;
export const uploadOutcropUpdateVariogramsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/variograms`;
export const uploadOutcropUpdatePanoramasRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/panoramas`;
export const outcropAuditLogsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/audit-logs`;
export const uploadOutcropUpdateFieldPicsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/field-pictures`;
export const uploadOutcropUpdateSupplementalDocumentsRoute = (
  outcropId: number,
) => `${uploadOutcropUpdateRoute(outcropId)}/supplemental-documents`;
export const uploadOutcropUpdateMiniModelsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/mini-models`;
export const uploadOutcropUpdatePhoto360sRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/360-photos`;
export const uploadOutcropUpdateVideosRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/videos`;
export const deleteOutcropRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/delete`;
export const uploadOutcropSOPlacementsRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/so-placements`;
export const uploadOutcropTourRoute = (outcropId: number) =>
  `${uploadOutcropUpdateRoute(outcropId)}/outcrop-tour`;

// Upload Study
export const uploadStudyRoute = () => `${uploadModelRoute()}/study`;
export const uploadStudyCreateRoute = () => `${uploadStudyRoute()}/create`;
export const uploadStudyUpdateRoute = (studyId: number) =>
  `${uploadStudyRoute()}/${studyId}`;
export const uploadStudyUpdateOutcropsRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/outcrops`;
export const uploadStudyUpdateKeyParametersRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/key-parameters`;
export const uploadStudyUpdateGeoreferencesRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/georeferences`;
export const uploadStudyUpdateAEsRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/architectural-elements`;
export const uploadStudyBulkAEImportRoute = (studyId: number) =>
  `${uploadStudyUpdateAEsRoute(studyId)}/import-aes`;
export const uploadStudyBulkMeasurementImportRoute = (studyId: number) =>
  `${uploadStudyUpdateAEsRoute(studyId)}/import-measurements`;
export const uploadStudyUpdateAEDetailRoute = (studyId: number, aeId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/architectural-elements/${aeId}`;
export const uploadStudyUpdateLithostratigraphyRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/lithostratigraphy`;
export const uploadStudyDmvRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/default-measurements-view`;
export const uploadStudyUpdatePicturesRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/pictures`;
export const uploadStudyUpdateFaciesRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/facies`;
export const uploadStudyUpdateCrossSectionsRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/cross-sections`;
export const uploadStudyUpdateSedimentaryLogsRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/sedimentary-logs`;
export const uploadStudyUpdateWellLogsRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/well-logs`;
export const uploadStudyUpdateProductionRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/production`;
export const uploadStudyUpdateLiteratureRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/literature`;
export const uploadStudyUpdateReservoirModelsRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/reservoir-models`;
export const uploadStudyUpdateTrainingImagesRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/training-images`;
export const uploadStudyUpdateVariogramsRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/variograms`;
export const uploadStudyUpdatePanoramasRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/panoramas`;
export const uploadStudyUpdateAccessRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/access`;
export const studyAuditLogsRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/audit-logs`;
export const mergeStudyRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/merge`;
export const deleteStudyRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/delete`;
export const uploadStudySupplementalDocumentsRoute = (studyId: number) =>
  `${uploadStudyUpdateRoute(studyId)}/supplemental-documents`;

// Upload VOM
export const uploadVomRoute = () => `${uploadRoute()}/vom`;
export const uploadVomCreateRoute = () => `${uploadVomRoute()}/create`;

export const uploadVomUpdateRoute = (vomId: number) =>
  `${uploadVomRoute()}/${vomId}`;
export const uploadVomUpdatePicturesRoute = (vomId: number) =>
  `${uploadVomUpdateRoute(vomId)}/pictures`;
export const uploadVomUpdateGeoreferencesRoute = (vomId: number) =>
  `${uploadVomUpdateRoute(vomId)}/georeferences`;
export const uploadVomUpdateInterpretationsRoute = (vomId: number) =>
  `${uploadVomUpdateRoute(vomId)}/interpretations`;
export const uploadVomUpdateVOSpecsRoute = (vomId: number) =>
  `${uploadVomUpdateRoute(vomId)}/vo-specs`;
export const uploadVomUpdateModelDataRoute = (vomId: number) =>
  `${uploadVomUpdateRoute(vomId)}/model-data`;
export const uploadVomUpdatePlacementRoute = (vomId: number) =>
  `${uploadVomUpdateRoute(vomId)}/placement`;

export const uploadMiniModelsRoute = () => `${uploadRoute()}/mini-models`;

export const uploadVftOverviewRoute = () => `${uploadRoute()}/vft-overview`;

// Upload Paleomap
export const uploadPaleoMapRoute = () => `${uploadRoute()}/paleomap`;
export const uploadPaleoMapUpdateRoute = (pmId: number) =>
  `${uploadPaleoMapRoute()}/${pmId}`;
export const uploadPaleoMapUpdatePicturesRoute = (pmId: number) =>
  `${uploadPaleoMapUpdateRoute(pmId)}/pictures`;

// Bulk field pics upload
export const uploadFieldPicsRoute = () => `${uploadRoute()}/field-pictures`;

// Export
export const exportBaseRoute = () => `${uploadRoute()}/export`;
export const exportOutcropsRoute = () => `${exportBaseRoute()}/outcrops`;
export const exportStudiesRoute = () => `${exportBaseRoute()}/studies`;
export const exportVomsRoute = () => `${exportBaseRoute()}/voms`;
export const exportGeoreferencesRoute = () =>
  `${exportBaseRoute()}/georeferences`;
export const exportGeoreferencesAllOutcropsRoute = () =>
  `${exportGeoreferencesRoute()}/all-outcrops`;
export const exportGeoreferencesOutcropRoute = () =>
  `${exportGeoreferencesRoute()}/outcrop`;
export const exportGeoreferencesAllRegionsRoute = () =>
  `${exportGeoreferencesRoute()}/all-regions`;
export const exportGeoreferencesAllVomsRoute = () =>
  `${exportGeoreferencesRoute()}/all-voms`;

// Upload depositional (geology) wiki pages
export const uploadGeologyRoute = () => `${uploadRoute()}/depositional`;
export const uploadGeologyCreateRoute = () => `${uploadGeologyRoute()}/create`;
export const uploadGeologyUpdateRoute = (wikiId: number) =>
  `${uploadGeologyRoute()}/${wikiId}`;
export const uploadGeologyUpdateDiagramRoute = (wikiId: number) =>
  `${uploadGeologyUpdateRoute(wikiId)}/diagram`;
export const uploadDepositionalOverviewRoute = () =>
  `${uploadGeologyRoute()}/overview`;

// Upload Lithostrat Wiki pages
export const uploadLithostratRoute = () => `${uploadRoute()}/lithostrat`;
export const uploadLithostratFormationListRoute = () =>
  `${uploadLithostratRoute()}/formations`;
export const uploadLithostratGroupListRoute = () =>
  `${uploadLithostratRoute()}/groups`;
export const uploadLithostratMemberListRoute = () =>
  `${uploadLithostratRoute()}/members`;

export const uploadLithostratFormationCreateRoute = () =>
  `${uploadLithostratRoute()}/formation/create`;
export const uploadLithostratFormationUpdateRoute = (formationId: number) =>
  `${uploadLithostratRoute()}/formation/${formationId}`;
export const uploadLithostratFormationUpdateGeologicalAgeRoute = (
  fmId: number,
) => `${uploadLithostratFormationUpdateRoute(fmId)}/geological-age`;
export const uploadLithostratFormationUpdateExamplesRoute = (fmId: number) =>
  `${uploadLithostratFormationUpdateRoute(fmId)}/examples`;
export const uploadLithostratFormationUpdateMembersRoute = (fmId: number) =>
  `${uploadLithostratFormationUpdateRoute(fmId)}/members`;
export const uploadLithostratFormationUpdateWikiRoute = (fmId: number) =>
  `${uploadLithostratFormationUpdateRoute(fmId)}/wiki`;

export const uploadLithostratGroupUpdateRoute = (groupId: number) =>
  `${uploadLithostratRoute()}/group/${groupId}`;
export const uploadLithostratGroupUpdateGeologicalAgeRoute = (
  groupId: number,
) => `${uploadLithostratGroupUpdateRoute(groupId)}/geological-age`;
export const uploadLithostratGroupUpdateExamplesRoute = (groupId: number) =>
  `${uploadLithostratGroupUpdateRoute(groupId)}/examples`;
export const uploadLithostratGroupUpdateWikiRoute = (groupId: number) =>
  `${uploadLithostratGroupUpdateRoute(groupId)}/wiki`;
export const uploadLithostratGroupFormationsRoute = (groupId: number) =>
  `${uploadLithostratGroupUpdateRoute(groupId)}/formations`;

export const uploadLithostratMemberUpdateRoute = (memberId: number) =>
  `${uploadLithostratRoute()}/member/${memberId}`;
export const uploadLithostratMemberUpdateGeologicalAgeRoute = (
  memberId: number,
) => `${uploadLithostratMemberUpdateRoute(memberId)}/geological-age`;
export const uploadLithostratMemberUpdateExamplesRoute = (memberId: number) =>
  `${uploadLithostratMemberUpdateRoute(memberId)}/examples`;
export const uploadLithostratMemberUpdateWikiRoute = (memberId: number) =>
  `${uploadLithostratMemberUpdateRoute(memberId)}/wiki`;

// Upload other wiki pages
export const uploadOtherWikiRoute = () => `${uploadRoute()}/other`;
export const uploadOtherWikiCreateRoute = () =>
  `${uploadOtherWikiRoute()}/create`;
export const uploadOtherWikiUpdateRoute = (wikiId: number) =>
  `${uploadOtherWikiRoute()}/${wikiId}`;

// Lithostrat type management
export const uploadLithostratTypesRoute = () =>
  `${uploadRoute()}/lithostrat-type`;
export const uploadLithostratTypeUpdateRoute = (ltId: number) =>
  `${uploadLithostratTypesRoute()}/${ltId}`;
export const uploadLithostratTypeUpdateDiagramTabRoute = (ltId: number) =>
  `${uploadLithostratTypesRoute()}/${ltId}/diagram`;
export const uploadLithostratTypeUpdateEntitiesTabRoute = (ltId: number) =>
  `${uploadLithostratTypesRoute()}/${ltId}/entities`;

// Admin utilities
export const adminUtilsRoute = () => `${uploadRoute()}/util`;
export const pictureNamesUtilRoute = () => `${adminUtilsRoute()}/picture-names`;
export const outcropPictureNamesUtilRoute = () =>
  `${pictureNamesUtilRoute()}/outcrops`;
export const studyPictureNamesUtilRoute = () =>
  `${pictureNamesUtilRoute()}/studies`;
