import type {
  MarkerProps,
  PolygonProps,
  PolylineProps,
} from '@react-google-maps/api';
import { z } from 'zod';
import type {
  GeoreferenceCreateInput,
  GeoreferenceParentType,
  GeoreferencePartsFragment,
  LatLngInput,
} from '~/apollo/generated/v4/graphql';
import { GeoreferenceDataType } from '~/apollo/generated/v4/graphql';
import { colorList } from '~/utils/chart';
import type { GeoreferenceImportInput } from '~/utils/geojson-v4';
import { polygonOptions, polylineOptions } from '~/utils/georeference';
import { ppStringOrNull } from '~/utils/zod';

export const georeferenceSchema = z.object({
  name: z.string().min(1),
  description: z.preprocess(ppStringOrNull, z.string().nullish()),
  dataType: z.nativeEnum(GeoreferenceDataType),
  data: z
    .array(
      z.object({
        lat: z.number(),
        lng: z.number(),
      }),
    )
    .min(1),
});

export type GeoreferenceFormValues = {
  name: string;
  description: string;
  dataType: GeoreferenceDataType | '';
  data: LatLngInput[] | null;
};

export function initialGeoreference(): GeoreferenceFormValues {
  return {
    name: '',
    description: '',
    dataType: '',
    data: null,
  };
}

export function toGeoreferenceCreateInput(
  parentType: GeoreferenceParentType,
  parentId: number,
  values: z.infer<typeof georeferenceSchema>,
): GeoreferenceCreateInput {
  return {
    parentType,
    parentId,
    ...values,
  };
}

export function createBoundsFromGeoreferences(
  georeferences: Pick<GeoreferencePartsFragment, 'data'>[],
) {
  const bounds = new google.maps.LatLngBounds();

  for (const g of georeferences) {
    for (const c of g.data) {
      bounds.extend(c);
    }
  }

  if (!georeferences.length) {
    bounds.extend({ lat: -50, lng: -50 });
    bounds.extend({ lat: 50, lng: 50 });
  }

  return bounds;
}

export const markerTypes = [
  GeoreferenceDataType.Point,
  GeoreferenceDataType.Centre,
];
export const polygonTypes = [
  GeoreferenceDataType.Polygon,
  GeoreferenceDataType.Outline,
];
export const polylineTypes = [GeoreferenceDataType.Polyline];

const isDataType = (matchTypes: GeoreferenceDataType[]) => (input: string) =>
  matchTypes.some(t => t === input);

export const isCenterType = isDataType([GeoreferenceDataType.Centre]);
export const isMarkerType = isDataType(markerTypes);
export const isPolygonType = isDataType(polygonTypes);
export const isPolylineType = isDataType(polylineTypes);

const red = colorList[1];
const blue = colorList[0];

export const georefColors = {
  outcrop: red,
  study: blue,
};

// Using a data URL is significantly more performant than google's builtin
// symbol drawing API when we have more than a few markers on the map.
// We need to base64 encode the SVG so that the color hex values are escaped.
export type MarkerIconSvgOptions = {
  size: number;
  fill: string;
  fillOpacity: number;
  stroke: string;
  strokeWidth: number;
  strokeOpacity: number;
};
const defaultOpts: MarkerIconSvgOptions = {
  size: 14,
  fill: red,
  fillOpacity: 0.8,
  stroke: red,
  strokeWidth: 1,
  strokeOpacity: 1,
};
export function markerIconSvgUrl(opts?: Partial<MarkerIconSvgOptions>) {
  const o = { ...defaultOpts, ...opts };
  const size = o.size + o.strokeWidth;

  const svg = `
    <svg width="${size}" height="${size}" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="50%"
        cy="50%"
        r="${Math.floor(size / 2) - o.strokeWidth}"
        fill="${o.fill}"
        fill-opacity="${o.fillOpacity}"
        stroke="${o.stroke}"
        stroke-width="${o.strokeWidth}"
        stroke-opacity="${o.strokeOpacity}"
      />
    </svg>
  `;

  return `data:image/svg+xml;base64, ${window.btoa(svg)}`;
}

export const markerIcon = (
  options?: Partial<MarkerIconSvgOptions>,
): NonNullable<google.maps.MarkerOptions['icon']> => ({
  // path: google.maps.SymbolPath.CIRCLE,
  // fillColor: '#ff4d4d',
  // fillOpacity: 0.8,
  // strokeColor: red,
  // strokeOpacity: 1,
  // strokeWeight: 1,
  // scale: 5,
  // ...options,
  url: markerIconSvgUrl(options),
});

export function defaultMarkerProps(
  georeference: GeoreferencePartsFragment,
): MarkerProps {
  return {
    position: georeference.data[0],
    icon: markerIcon(),
  };
}

export function defaultPolylineProps(
  georeference: GeoreferencePartsFragment,
  options?: PolylineProps['options'],
): PolylineProps {
  return {
    path: georeference.data,
    options: polylineOptions(options),
  };
}

export function defaultPolygonProps(
  georeference: GeoreferencePartsFragment,
  options?: PolygonProps['options'],
): PolygonProps {
  return {
    path: georeference.data,
    options: polygonOptions(options),
  };
}

export function importToGeoreferenceInput(
  parentType: GeoreferenceParentType,
  parentId: number,
  g: GeoreferenceImportInput,
): GeoreferenceCreateInput {
  return {
    parentType,
    parentId,
    name: g.name,
    description: g.description,
    dataType: g.dataType,
    data: g.data,
  };
}
