import { useSafeState } from './state';

export type ShowModalFn = (event?: React.MouseEvent) => void;

export function useModalState(initialState = false) {
  const [show, setShow] = useSafeState(initialState);

  const showModal = (event?: React.MouseEvent) => {
    if (event) event.preventDefault();
    setShow(true);
  };

  const hideModal = (event?: React.MouseEvent) => {
    if (event) event.preventDefault();
    setShow(false);
  };

  return {
    show: show ?? false,
    showModal,
    hideModal,
    modalProps: { open: show ?? false, onHide: hideModal },
  };
}
