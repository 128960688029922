import type { PureQueryOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import type { SoManagerPicturePartsFragment } from '~/apollo/generated/v4/graphql';
import { Modal } from '~/components/common/Modal';
import { Tooltip } from '~/components/common/Tooltip';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { PictureFormFields } from '~/components/upload/supporting-object/picture/picture-form-fields';
import { UPDATE_PICTURE } from '~/components/upload/supporting-object/so-operations';
import type { ShowModalFn } from '~/hooks/modal';
import { useModalState } from '~/hooks/modal';
import { useZodForm } from '~/utils/forms';
import {
  initialPicture,
  pictureSchema,
} from '~/utils/modules/supporting-object';

export function SOUpdatePictureModal({
  studyId,
  picture,
  refetchQueries,
  children,
}: {
  studyId?: number;
  picture: SoManagerPicturePartsFragment;
  refetchQueries: PureQueryOptions[];
  children?: (showModal: ShowModalFn) => React.ReactNode;
}) {
  const { show, showModal, hideModal } = useModalState();

  const [updatePicture, { loading, error }] = useMutation(UPDATE_PICTURE, {
    refetchQueries,
  });

  const form = useZodForm({
    schema: pictureSchema,
    values: initialPicture(picture),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updatePicture({
        variables: {
          id: picture.id,
          input: values,
        },
      });
      toast.success('Picture updated successfully.');
      hideModal();
    } catch (err) {
      console.log('Error updating picture', err);
      toast.error('There was a problem updating the picture.');
    }
  });

  return (
    <>
      {children ? (
        children(showModal)
      ) : (
        <Tooltip message="Edit details">
          <Button type="button" color="ghost" size="xs" onClick={showModal}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        </Tooltip>
      )}

      <span className="text-left">
        <Modal open={show} onHide={hideModal} size="lg">
          <FormProvider {...form}>
            <form onSubmit={handleSubmit}>
              <Modal.Body heading="Edit Picture">
                <div className="space-y-4">
                  <PictureFormFields studyId={studyId} />
                  <HookFormErrors graphQLError={error} />

                  <div className="text-center space-x-2">
                    <Button type="button" color="ghost" onClick={hideModal}>
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" loading={loading}>
                      Save
                    </Button>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <img
                  src={picture.file.signedUrl}
                  alt="Picture thumbnail"
                  className="w-full h-auto"
                />
              </Modal.Footer>
            </form>
          </FormProvider>
        </Modal>
      </span>
    </>
  );
}
