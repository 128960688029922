import { useQuery } from '@apollo/client';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { UpdateOutcropForm } from '~/components/upload/outcrop/update-outcrop-form';
import { useRouteParam } from '~/hooks/routing';

export const UPLOAD_OUTCROP_OVERVIEW_PAGE = graphql(`
  query UploadOutcropOverviewPage($id: ID!) {
    outcropGet(id: $id) {
      ...outcropParts
    }
  }
`);

export default function UploadOutcropUpdateOverviewPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const outcropId = useRouteParam('outcropId', parseInt);

  const { data, loading } = useQuery(UPLOAD_OUTCROP_OVERVIEW_PAGE, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropGet;

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return <UpdateOutcropForm outcrop={outcrop} />;
}
