import { useQuery } from '@apollo/client';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link, Outlet, useOutletContext } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import type { UploadOutcropUpdatePageQuery } from '~/apollo/generated/v3/graphql';
import { CollapseButton } from '~/components/common/buttons/CollapseButton';
import { NotFound } from '~/components/common/NotFound';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useRouteParam } from '~/hooks/routing';
import { outcropRoute } from '~/paths';
import { UploadOutcropNavigation } from '~/routes/upload/model/outcrop/$outcropId/__UploadOutcropNavigation';
import { cn } from '~/utils/common';

export const UPLOAD_OUTCROP_UPDATE_PAGE = graphql(`
  query UploadOutcropUpdatePage($id: Int!) {
    outcropList(id: $id) {
      ...outcropParts
    }
  }
`);

export type UploadOutcropUpdateRouteOutletContext = {
  outcrop: UploadOutcropUpdatePageQuery['outcropList'][number];
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: (collapsed: boolean) => void;
};

export default function UploadOutcropUpdatePage() {
  const outcropId = useRouteParam('outcropId', parseInt);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const { data, loading } = useQuery(UPLOAD_OUTCROP_UPDATE_PAGE, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropList?.find(oc => oc.id === outcropId);

  useBreadcrumb(
    'routes/upload/model/outcrop/$outcropId',
    outcrop?.name ?? 'Update Outcrop',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  const outletContext: UploadOutcropUpdateRouteOutletContext = {
    outcrop,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
  };

  return (
    <>
      <div className="flex justify-between gap-6 items-center">
        <div>
          <PageHeading hasSubtitle>Update Outcrop</PageHeading>
          <PageHeading.Subtitle>{outcrop.name}</PageHeading.Subtitle>
        </div>

        <Link
          to={outcropRoute(outcropId)}
          className="btn btn-primary btn-sm"
          target="_blank"
        >
          View Outcrop <FontAwesomeIcon icon={faExternalLink} />
        </Link>
      </div>

      <CollapseButton
        collapsed={isSidebarCollapsed}
        onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
        size="sm"
      >
        Toggle Navigation
      </CollapseButton>

      <div className="grid grid-cols-12 gap-6 mt-4">
        {!isSidebarCollapsed && (
          <div className="col-span-12 lg:col-span-2">
            <UploadOutcropNavigation
              outcropId={outcropId}
              isSidebarCollapsed={isSidebarCollapsed}
              setIsSidebarCollapsed={setIsSidebarCollapsed}
            />
          </div>
        )}

        <div
          className={cn('col-span-12', {
            'lg:col-span-10': !isSidebarCollapsed,
          })}
        >
          <Outlet context={outletContext} />
        </div>
      </div>
    </>
  );
}

export function useUploadOutcropUpdateRouteOutletContext() {
  return useOutletContext<UploadOutcropUpdateRouteOutletContext>();
}
