import type {
  ApolloQueryResult,
  DocumentNode,
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  PureQueryOptions,
  QueryResult,
} from '@apollo/client';
import { useLazyQuery as apolloLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { apolloClient } from '~/apollo/client-v3';
import { apolloClientV4 } from '~/apollo/client-v4';

export function useImperativeQuery<
  TData,
  TVars extends OperationVariables = OperationVariables,
>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, TVars>,
): [
  (
    options?: LazyQueryHookOptions<TData, TVars>,
  ) => Promise<QueryResult<TData, TVars>>,
  LazyQueryResult<TData, TVars>,
] {
  const [execute, result] = apolloLazyQuery<TData, TVars>(query, options);
  const promiseRef = useRef<{
    resolve: (value: any) => void;
    reject: (err: any) => void;
  }>();

  useEffect(() => {
    if (result.called && !result.loading && promiseRef.current) {
      if (result.error) {
        promiseRef.current.reject(result.error);
      } else {
        promiseRef.current.resolve(result);
      }
      promiseRef.current = undefined;
    }
  }, [result.loading, result.called, result]);

  const queryLazily = useCallback(
    (options?: LazyQueryHookOptions<TData, TVars>) => {
      execute(options);

      return new Promise<QueryResult<TData, TVars>>((resolve, reject) => {
        promiseRef.current = { resolve, reject };
      });
    },
    [execute],
  );

  return [queryLazily, result];
}

/** Hook to imperatively call refetchQueries */
export function useRefetchQueries(
  refetchQueries: PureQueryOptions[] | undefined | null,
): [() => Promise<ApolloQueryResult<any>[]>, { loading: boolean }] {
  const [loading, setLoading] = useState(false);

  async function refetch() {
    setLoading(true);

    const result = await apolloClient.refetchQueries({
      include: refetchQueries?.map(q => q.query) ?? [],
    });
    setLoading(false);
    return result;
  }

  return [refetch, { loading }];
}

/** Hook to imperatively call refetchQueries */
export function useRefetchQueriesV4(
  refetchQueries: PureQueryOptions[] | undefined | null,
): [() => Promise<ApolloQueryResult<any>[]>, { loading: boolean }] {
  const [loading, setLoading] = useState(false);

  async function refetch() {
    setLoading(true);

    const result = await apolloClientV4.refetchQueries({
      include: refetchQueries?.map(q => q.query) ?? [],
    });
    setLoading(false);
    return result;
  }

  return [refetch, { loading }];
}
