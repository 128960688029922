import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { Photo360FormFields } from '~/components/upload/supporting-object/photo-360/photo-360-form-fields';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { useZodForm } from '~/utils/forms';
import type { RefetchQueries } from '~/utils/graphql';
import {
  initialPhoto360,
  photo360Schema,
} from '~/utils/modules/supporting-object';

const CREATE_PHOTO_360 = graphql(`
  mutation CreatePhoto360($input: Photo360CreateInput!) {
    photo360Create(input: $input) {
      result {
        id
      }
    }
  }
`);

export function CreatePhoto360Form({
  outcropId,
  refetchQueries,
  onCreateSuccess,
}: {
  outcropId: number;
  refetchQueries: RefetchQueries;
  onCreateSuccess?: () => unknown;
}) {
  const [createPhoto360, { loading, error }] = useMutation(CREATE_PHOTO_360, {
    refetchQueries,
  });

  const form = useZodForm({
    schema: photo360Schema,
    values: initialPhoto360(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await createPhoto360({
        variables: {
          input: {
            outcropId,
            ...values,
          },
        },
      });
      toast.success('360 Photo created successfully.');
      form.reset(initialPhoto360());
      if (onCreateSuccess) onCreateSuccess();
    } catch (err) {
      console.log('Error creating 360 Photo', err);
      toast.error(
        'There was a problem creating the 360 Photo. Please try again.',
      );
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <Photo360FormFields />
          <HookFormErrors graphQLError={error} />

          <div className="text-center">
            <Button color="primary" loading={loading} disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
