import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { PageHeading } from '~/components/common/PageHeading';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { CreateStudyForm } from '~/components/upload/study/create-study-form';
import { STUDY_ENUMS } from '~/components/upload/study/study-operations';
import { UploadStudyNavigation } from '~/routes/upload/model/study/$studyId/__UploadStudyNavigation';

export default function UploadStudyCreatePage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  useBreadcrumb('routes/upload/model.study/create', 'Create Study');

  // An outcropId may be provided in the query string to set an initial OC link
  const [searchParams] = useSearchParams();
  const queryOCId = searchParams.get('outcropId');
  const outcropId = queryOCId ? parseInt(queryOCId) : undefined;

  const { data, loading } = useQuery(STUDY_ENUMS);

  return (
    <>
      <PageHeading>Create Study</PageHeading>

      <div className="grid grid-cols-12">
        <div className="lg:col-span-2">
          <UploadStudyNavigation studyId={-1} disabled />
        </div>

        <div className="lg:col-span-10">
          <SpinnerPlaceholder show={loading} />
          {!loading && data && (
            <CreateStudyForm outcropId={outcropId} studyEnums={data} />
          )}
        </div>
      </div>
    </>
  );
}
