import { PopoverTrigger } from '@radix-ui/react-popover';
import React from 'react';
import { InfoIcon } from '~/components/common/icons/InfoIcon';
import { RequiredField } from '~/components/common/icons/RequiredField';
import type { PopoverContentProps } from '~/components/common/Popover';
import { Popover, PopoverContent } from '~/components/common/Popover';
import {
  WikiPopover,
  type WikiPopoverProps,
} from '~/components/wiki/WikiPopover';

export type FormLabelProps = {
  name?: string;
  required?: boolean;
  label?:
    | string
    | {
        label: React.ReactNode;
        helpText?: React.ReactNode;
        helpTextPlacement?: PopoverContentProps['align'];
        wikiPopoverId?: number;
        wikiPopoverProps?: WikiPopoverProps;
      };
};

export function FormLabel({ name, required = false, label }: FormLabelProps) {
  if (!label) return null;

  return (
    <>
      <label htmlFor={name} className="label flex justify-between gap-4">
        <span>
          {typeof label === 'string' ? label : label.label}
          {required && <RequiredField />}
        </span>
        {typeof label === 'object' && (
          <>
            {label.helpText && (
              <Popover>
                <PopoverTrigger>
                  <InfoIcon />
                </PopoverTrigger>
                <PopoverContent
                  align={label.helpTextPlacement}
                  className="text-base"
                >
                  {label.helpText}
                </PopoverContent>
              </Popover>
            )}
            {label.wikiPopoverId && (
              <WikiPopover
                {...label.wikiPopoverProps}
                otherWikiId={label.wikiPopoverId}
              />
            )}
          </>
        )}
      </label>
    </>
  );
}
