import { graphql } from '~/apollo/generated/v4';

// Fragments

export const soManagerPictureParts = graphql(`
  fragment soManagerPictureParts on Picture {
    ...pictureParts
    file {
      ...fileParts
    }
    outcropTag {
      id
      name
    }
  }
`);

export const soManagerCrossSectionParts = graphql(`
  fragment soManagerCrossSectionParts on CrossSection {
    ...crossSectionParts
    pictures {
      ...soManagerPictureParts
    }
    files {
      ...fileParts
    }
    georeferences {
      ...georeferenceParts
    }
    outcropTag {
      id
      name
    }
  }
`);

export const soManagerFaciesParts = graphql(`
  fragment soManagerFaciesParts on FaciesSchema {
    ...faciesParts
    pictures {
      ...soManagerPictureParts
    }
    outcropTag {
      id
      name
    }
  }
`);

export const soManagerSedimentaryLogParts = graphql(`
  fragment soManagerSedimentaryLogParts on SedimentaryLog {
    ...sedimentaryLogParts
    pictures {
      ...soManagerPictureParts
    }
    files {
      ...fileParts
    }
    georeferences {
      ...georeferenceParts
    }
    outcropTag {
      id
      name
    }
  }
`);

export const soManagerWellLogParts = graphql(`
  fragment soManagerWellLogParts on WellLog {
    ...wellLogParts
    pictures {
      ...soManagerPictureParts
    }
    files {
      ...fileParts
    }
    georeferences {
      ...georeferenceParts
    }
    outcropTag {
      id
      name
    }
  }
`);

export const soManagerProductionParts = graphql(`
  fragment soManagerProductionParts on Production {
    ...productionParts
    pictures {
      ...soManagerPictureParts
    }
    files {
      ...fileParts
    }
    outcropTag {
      id
      name
    }
  }
`);

export const soManagerReservoirModelParts = graphql(`
  fragment soManagerReservoirModelParts on ReservoirModel {
    ...reservoirModelParts
    pictures {
      ...soManagerPictureParts
    }
    files {
      ...fileParts
    }
    outcropTag {
      id
      name
    }
  }
`);

export const soManagerTrainingImageParts = graphql(`
  fragment soManagerTrainingImageParts on TrainingImage {
    ...trainingImageParts
    pictures {
      ...soManagerPictureParts
    }
    files {
      ...fileParts
    }
    outcropTag {
      id
      name
    }
  }
`);

export const soManagerVariogramParts = graphql(`
  fragment soManagerVariogramParts on Variogram {
    ...variogramParts
    pictures {
      ...soManagerPictureParts
    }
    files {
      ...fileParts
    }
    outcropTag {
      id
      name
    }
  }
`);

export const soManagerGigaPanParts = graphql(`
  fragment soManagerGigaPanParts on GigaPan {
    ...gigaPanParts
    pictures {
      ...soManagerPictureParts
    }
    georeferences {
      ...georeferenceParts
    }
    outcropTag {
      id
      name
    }
  }
`);

// Operations

export const UPDATE_PICTURE = graphql(`
  mutation UpdatePicture($id: ID!, $input: PictureUpdateInput!) {
    pictureUpdate(id: $id, input: $input) {
      result {
        ...soManagerPictureParts
      }
    }
  }
`);

export const UPDATE_CROSS_SECTION = graphql(`
  mutation UpdateCrossSection($id: ID!, $input: CrossSectionUpdateInput!) {
    crossSectionUpdate(id: $id, input: $input) {
      result {
        ...soManagerCrossSectionParts
      }
    }
  }
`);

export const UPDATE_FACIES = graphql(`
  mutation UpdateFacies($id: ID!, $input: FaciesSchemaUpdateInput!) {
    faciesSchemaUpdate(id: $id, input: $input) {
      result {
        ...soManagerFaciesParts
      }
    }
  }
`);

export const UPDATE_SEDLOG = graphql(`
  mutation UpdateSedlog($id: ID!, $input: SedimentaryLogUpdateInput!) {
    sedimentaryLogUpdate(id: $id, input: $input) {
      result {
        ...soManagerSedimentaryLogParts
      }
    }
  }
`);

export const UPDATE_WELL_LOG = graphql(`
  mutation UpdateWellLog($id: ID!, $input: WellLogUpdateInput!) {
    wellLogUpdate(id: $id, input: $input) {
      result {
        ...soManagerWellLogParts
      }
    }
  }
`);

export const UPDATE_PRODUCTION = graphql(`
  mutation UpdateProduction($id: ID!, $input: ProductionUpdateInput!) {
    productionUpdate(id: $id, input: $input) {
      result {
        ...soManagerProductionParts
      }
    }
  }
`);

export const UPDATE_RESERVOIR_MODEL = graphql(`
  mutation UpdateReservoirModel($id: ID!, $input: ReservoirModelUpdateInput!) {
    reservoirModelUpdate(id: $id, input: $input) {
      result {
        ...soManagerReservoirModelParts
      }
    }
  }
`);

export const UPDATE_TRAINING_IMAGE = graphql(`
  mutation UpdateTrainingImage($id: ID!, $input: TrainingImageUpdateInput!) {
    trainingImageUpdate(id: $id, input: $input) {
      result {
        ...soManagerTrainingImageParts
      }
    }
  }
`);

export const UPDATE_VARIOGRAM = graphql(`
  mutation UpdateVariogram($id: ID!, $input: VariogramUpdateInput!) {
    variogramUpdate(id: $id, input: $input) {
      result {
        ...soManagerVariogramParts
      }
    }
  }
`);

export const UPDATE_GIGA_PAN = graphql(`
  mutation UpdateGigaPan($id: ID!, $input: GigaPanUpdateInput!) {
    gigaPanUpdate(id: $id, input: $input) {
      result {
        ...soManagerGigaPanParts
      }
    }
  }
`);

export const UPDATE_FIELD_PICTURE = graphql(`
  mutation UpdateFieldPicture($id: ID!, $input: FieldPictureUpdateInput!) {
    fieldPictureUpdate(id: $id, input: $input) {
      result {
        ...fieldPictureParts
      }
    }
  }
`);
