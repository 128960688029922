import { z } from 'zod';
import { type LiteraturePartsFragment } from '~/apollo/generated/v4/graphql';
import { ppNumberOrNull, ppStringOrNull } from '~/utils/zod';

export const literatureSchema = z.object({
  author: z.string().min(1),
  publicationSource: z.string().min(1),
  publicationType: z.string().min(1),
  title: z.string().min(1),
  year: z.preprocess(ppNumberOrNull, z.number().int().positive()),

  // Nullable
  digitalObjectIdentifier: z.preprocess(ppStringOrNull, z.string().nullish()),
  webSource: z.preprocess(ppStringOrNull, z.string().nullish()),
  comments: z.preprocess(ppStringOrNull, z.string().nullish()),
  outcropTagId: z.preprocess(ppNumberOrNull, z.number().int().nullish()),
  priority: z.preprocess(ppNumberOrNull, z.number().int().nullish()),
});
export interface LiteratureFormValues {
  author: string;
  comments: string;
  digitalObjectIdentifier: string;
  publicationSource: string;
  publicationType: string;
  title: string;
  webSource: string;
  year: string;
  outcropTagId: string;
  priority: string;
}
export function initialLiterature(
  lit?: LiteraturePartsFragment,
): LiteratureFormValues {
  return {
    author: lit?.author ?? '',
    comments: lit?.comments ?? '',
    digitalObjectIdentifier: lit?.digitalObjectIdentifier ?? '',
    publicationSource: lit?.publicationSource ?? '',
    publicationType: lit?.publicationType ?? '',
    title: lit?.title ?? '',
    webSource: lit?.webSource ?? '',
    year: String(lit?.year ?? ''),
    outcropTagId: String(lit?.outcropTagId ?? ''),
    priority: String(lit?.priority ?? ''),
  };
}
