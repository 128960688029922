import type {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ReactNode,
} from 'react';
import { forwardRef } from 'react';
import type { FormLabelProps } from '~/components/ui/forms/FormLabel';
import { InputBase } from '~/components/ui/forms/InputBase';
import { cn } from '~/utils/common';

export type TextInputProps = {
  name: string;
  containerProps?: ComponentPropsWithoutRef<'div'>;
  label?: FormLabelProps['label'];
  renderInput?: (input: ReactNode) => ReactNode;
  join?: boolean;
} & ComponentPropsWithRef<'input'>;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      containerProps,
      label,
      className,
      renderInput,
      join,
      ...inputProps
    }: TextInputProps,
    ref,
  ) => {
    // Default the ID to the name in case an ID isn't set
    const elementId = inputProps.id ?? inputProps.name;

    const input = (
      <input
        {...inputProps}
        ref={ref}
        id={elementId}
        className={cn('input w-full', className, {
          'join-item': join,
        })}
      />
    );

    return (
      <InputBase
        id={elementId}
        name={inputProps.name}
        label={label}
        required={inputProps.required}
      >
        {renderInput ? renderInput(input) : input}
      </InputBase>
    );
  },
);
TextInput.displayName = 'TextInput';
