import { z } from 'zod';
import type { StudyUpdatePartsFragment } from '~/apollo/generated/v4/graphql';
import {
  GeologyType,
  LifecycleStatus,
  StudyDataSourceType,
} from '~/apollo/generated/v4/graphql';
import { ppArrayOrNull, ppNumberOrNull, ppStringOrNull } from '~/utils/zod';

export const studySchemaUpdate = z.object({
  name: z.string().min(1),
  type: z.string().min(1),
  abstract: z.preprocess(ppStringOrNull, z.string().nullish()),
  keyReferences: z.preprocess(ppStringOrNull, z.string().nullish()),
  lifecycleStatus: z.nativeEnum(LifecycleStatus),
  qcCompleted: z.boolean(),
  redundant: z.boolean(),
  dominantGeologyType: z.preprocess(
    ppStringOrNull,
    z.nativeEnum(GeologyType).nullish(),
  ),
  numericalDataAspects: z.preprocess(ppStringOrNull, z.string().nullish()),
  dataSourceType: z.nativeEnum(StudyDataSourceType),
  keywords: z.preprocess(ppStringOrNull, z.string().nullish()),
  publicationLicense: z.preprocess(ppStringOrNull, z.string().nullish()),
  collectedBy: z.string().min(1),
  collectedYear: z.preprocess(ppNumberOrNull, z.number().int().positive()),
  spatialObservationType: z.preprocess(ppStringOrNull, z.string().nullish()),
  dataAcquisitionMethod: z.preprocess(
    ppArrayOrNull,
    z.array(z.string()).nullish(),
  ),
  publicationType: z.array(z.string().min(1)).min(1),
});

export const studySchemaCreate = studySchemaUpdate.extend({
  projectId: z.preprocess(ppNumberOrNull, z.number()),
  outcropId: z.preprocess(ppNumberOrNull, z.number()),
});

export type StudyFormValues = {
  name: string;
  outcropId: string;
  projectId: string;
  type: string;
  abstract: string;
  keyReferences: string;
  collectedBy: string;
  collectedYear: string;
  spatialObservationType: string;
  dataAcquisitionMethod: string[];
  publicationType: string[];
  qcCompleted: boolean;
  redundant: boolean;
  dominantGeologyType: string;
  keywords: string;
  publicationLicense: string;
  numericalDataAspects: string;
  dataSourceType: string;
  lifecycleStatus: string;
};

export function initialStudy(
  study?: StudyUpdatePartsFragment,
  outcropId?: number | null,
): StudyFormValues {
  return {
    name: study?.name ?? '',
    type: study?.type ?? '',
    projectId: String(study?.projectId ?? ''),
    outcropId: String(outcropId ?? ''),
    abstract: study?.abstract ?? '',
    keyReferences: study?.keyReferences ?? '',
    lifecycleStatus: study?.lifecycleStatus ?? LifecycleStatus.Draft,
    qcCompleted: study?.qcCompleted ?? false,
    redundant: study?.redundant ?? false,
    dominantGeologyType: study?.dominantGeologyType ?? '',
    keywords: study?.keywords || '',
    publicationLicense: study?.publicationLicense || '',
    numericalDataAspects: study?.numericalDataAspects || '',
    dataSourceType: study?.dataSourceType ?? '',
    collectedBy: study?.collectedBy ?? '',
    collectedYear: String(study?.collectedYear ?? ''),
    spatialObservationType: study?.spatialObservationType ?? '',
    dataAcquisitionMethod: study?.dataAcquisitionMethod ?? [],
    publicationType: study?.publicationType ?? [],
  };
}
