import { ApolloProviderV4 } from '~/apollo/client-v4';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useRouteParam } from '~/hooks/routing';

export default function UploadOutcropTourPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

export function PageInner() {
  useBreadcrumb(
    'routes/upload/model/outcrop/$outcropId/outcrop-tour',
    'Outcrop Tour',
  );

  const outcropId = useRouteParam('outcropId', parseInt);

  return (
    <>
      <p>Placeholder for outcrop tour page for outcrop {outcropId}</p>
    </>
  );
}
