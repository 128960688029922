import type {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ReactNode,
} from 'react';
import { forwardRef } from 'react';
import type { FormLabelProps } from '~/components/ui/forms/FormLabel';
import { InputBase } from '~/components/ui/forms/InputBase';
import { cn } from '~/utils/common';

export type SelectOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

function mapOption(option: SelectOption | string): SelectOption {
  if (typeof option === 'string') {
    return {
      value: option,
      label: option,
    };
  }

  return option;
}

export type SelectProps = ComponentPropsWithRef<'select'> & {
  name: string;
  containerProps?: ComponentPropsWithoutRef<'div'>;
  label?: FormLabelProps['label'];
  options: SelectOption[] | string[];
  withBlankOption?: boolean;
  renderInput?: (input: ReactNode) => ReactNode;
  join?: boolean;
};

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      containerProps,
      label,
      className,
      options,
      withBlankOption = true,
      renderInput,
      join,
      ...inputProps
    },
    ref,
  ) => {
    // Default the ID to the name in case an ID isn't set
    const elementId = inputProps.id ?? inputProps.name;

    const input = (
      <select
        {...inputProps}
        ref={ref}
        id={elementId}
        className={
          className ||
          cn('select w-full', {
            'min-h-28': inputProps?.multiple && options.length > 2,
            'join-item': join,
          })
        }
      >
        {withBlankOption && !inputProps.multiple && (
          <option value="">- Select -</option>
        )}
        {options.map(mapOption).map(option => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>
    );

    return (
      <InputBase
        id={elementId}
        name={inputProps.name}
        label={label}
        required={inputProps.required}
      >
        {renderInput ? renderInput(input) : input}
      </InputBase>
    );
  },
);
Select.displayName = 'Select';
