import type { ReactNode } from 'react';
import type { GeoreferencePartsFragment } from '~/apollo/generated/v4/graphql';
import { saveAs } from '~/utils/export';
import { createKmlDocument, createKmlFolder } from '~/utils/kml-v4';

export function KmlExport({
  name = 'Georeferences',
  georeferences,
  children,
}: {
  name?: string;
  georeferences: GeoreferencePartsFragment[];
  children: (downloadFile: () => void) => ReactNode;
}) {
  const documentName = `${name} Export`;
  const filename = documentName + '.kml';

  async function handleClick() {
    try {
      const description = 'Exported SafariDB Georeferences';
      const folder = createKmlFolder(name, description, georeferences);
      const kml = await createKmlDocument([folder]);
      saveAs(kml, filename, 'application/vnd;charset=utf-8');
    } catch (err) {
      console.log('Error writing KML', err);
    }
    console.groupEnd();
  }

  return children(handleClick);
}
