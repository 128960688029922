import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { forwardRef } from 'react';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import {
  ReactSelectInput,
  type ReactSelectInputProps,
} from '~/components/ui/forms/react-select-input';

const OUTCROP_SELECT_OPTIONS = graphql(`
  query OutcropSelectOptions {
    outcropList {
      results {
        id
        name
      }
    }
  }
`);

type Props = Omit<ReactSelectInputProps<number>, 'options'> & {
  disabledIds?: number[];
};

export const OutcropSelect = forwardRef<HTMLDivElement, Props>(
  (props, _ref) => {
    return (
      <ApolloProviderV4>
        <OutcropSelectInner {...props} />
      </ApolloProviderV4>
    );
  },
);
OutcropSelect.displayName = 'OutcropSelect';

function OutcropSelectInner({
  name,
  disabled,
  disabledIds = [],
  ...props
}: Props) {
  const { loading, data } = useQuery(OUTCROP_SELECT_OPTIONS);
  const outcrops = R.sortBy(oc => oc.name, data?.outcropList?.results ?? []);

  const options = outcrops.map(oc => ({
    value: parseInt(oc.id),
    label: `${oc.name} (${oc.id})`,
    disabled: disabledIds.length > 0 && disabledIds.includes(parseInt(oc.id)),
  }));

  return (
    <ReactSelectInput
      {...props}
      name={name}
      options={options}
      disabled={disabled || loading}
      reactSelectProps={{
        isSearchable: true,
      }}
    />
  );
}
