import React from 'react';
import type { SortIndicatorProps } from '~/components/common/icons/SortIndicator';
import { SortIndicator } from '~/components/common/icons/SortIndicator';
import type { ButtonProps } from '~/components/ui/button';
import FilterButton from './FilterButton';

interface Props {
  /** The property name of the column to be sorted */
  colName: string;
  /** JSX rendered as text */
  children: React.ReactNode;
  sortIndicatorProps: SortIndicatorProps;
  filterable?: boolean;
  renderFilterOption?: (value: string) => React.ReactNode;
  disabled?: boolean;
  buttonProps?: Omit<ButtonProps, 'onClick'>;
}

export function SortTrigger({
  colName,
  sortIndicatorProps,
  children,
  filterable = false,
  renderFilterOption,
  disabled = false,
  buttonProps,
}: Props): JSX.Element | null {
  function handleClick() {
    sortIndicatorProps.onSortChange(colName);
  }

  if (disabled) {
    return (
      <span className="btn btn-ghost btn-sm cursor-default p-[2px]">
        {children}
      </span>
    );
  }

  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className={
          buttonProps?.className ?? 'btn btn-ghost btn-sm inline-block p-0.5'
        }
        {...buttonProps}
      >
        {children}
        <SortIndicator {...sortIndicatorProps} colName={colName} />
      </button>

      {filterable && (
        <FilterButton
          name={typeof children === 'string' ? children : ''}
          options={sortIndicatorProps.categoryFilterOptions(colName)}
          values={sortIndicatorProps.getFiltersForCategory(colName)}
          onChange={sortIndicatorProps.onCategoryFilterChange(colName)}
          renderFilterOption={renderFilterOption}
        />
      )}
    </>
  );
}
