import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert } from '~/components/ui/alert';

type Props = {
  show?: boolean;
  entity?: string;
};

export function NoItemsAlert({ show = false, entity = 'items' }: Props) {
  if (!show) return null;

  return (
    <Alert icon={<FontAwesomeIcon icon={faInfoCircle} className="text-xl" />}>
      No {entity} to display.
    </Alert>
  );
}
