import { useQuery } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { graphql } from '~/apollo/generated/v4';
import { Select } from '~/components/ui/forms/Select';
import { TextInput } from '~/components/ui/forms/text-input';
import { OutcropTagField } from '~/components/upload/supporting-object/outcrop-tag-field';
import type { LiteratureFormValues } from '~/utils/modules/literatureReference';

const LITERATURE_FORM_FIELDS_OPTIONS = graphql(`
  query LiteratureFormFieldsOptions {
    outcropEnumerations(type: PUBLICATION_TYPE) {
      values
    }
  }
`);

export function LiteratureFormFields({ studyId }: { studyId: number }) {
  const { register } = useFormContext<LiteratureFormValues>();

  const { data, loading } = useQuery(LITERATURE_FORM_FIELDS_OPTIONS);

  const publicationTypes = data?.outcropEnumerations.values ?? [];

  return (
    <div className="space-y-2">
      <TextInput {...register('title')} label="Title" required />
      <TextInput {...register('author')} label="Author" required />
      <TextInput {...register('year')} label="Year" required />
      <TextInput
        {...register('publicationSource')}
        label="Publication source"
        required
      />
      <Controller
        name="publicationType"
        render={({ field }) => (
          <Select
            {...field}
            label="Publication type"
            disabled={loading}
            required
            options={publicationTypes.map(value => ({
              value,
              label: value,
            }))}
          />
        )}
      />

      <TextInput
        {...register('digitalObjectIdentifier')}
        label="Digital Object Identifier"
      />
      <TextInput {...register('webSource')} label="Web source" />
      <TextInput {...register('comments')} label="Comments" />
      <TextInput {...register('priority')} label="Priority" />
      <OutcropTagField
        name="outcropTagId"
        label="Outcrop tag"
        studyId={studyId}
      />
    </div>
  );
}
