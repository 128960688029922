import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import type { FieldPath, FieldValues } from 'react-hook-form';
import { FieldErrors } from '~/components/ui/forms/FieldErrors';
import type { FormLabelProps } from '~/components/ui/forms/FormLabel';
import { FormLabel } from '~/components/ui/forms/FormLabel';

export type InputBaseProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>;
  id?: string;
  containerProps?: ComponentPropsWithoutRef<'div'>;
  label?: FormLabelProps['label'];
  required?: boolean;
  children: ReactNode;
};

export function InputBase<TFieldValues extends FieldValues = FieldValues>({
  containerProps,
  name,
  label,
  required,
  children,
}: InputBaseProps<TFieldValues>) {
  return (
    <div {...containerProps}>
      <FormLabel name={name} label={label} required={required} />
      {children}
      <FieldErrors name={name} />
    </div>
  );
}
