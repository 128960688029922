import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import {
  CollapsibleCard,
  useControlledCollapsibleCard,
} from '~/components/ui/collapse';
import { CreateLiteratureForm } from '~/components/upload/study/literature/create-literature-form';
import { StudyLiteratureListItem } from '~/components/upload/study/literature/listerature-item';
import { useRouteParam } from '~/hooks/routing';
import { createRefetchQueries } from '~/utils/graphql';

export const UPLOAD_STUDY_LITERATURE_PAGE = graphql(`
  query UploadStudyLiteraturePage($id: ID!) {
    studyGet(id: $id) {
      id
      literatureReferences {
        ...literatureParts
        outcropTag {
          id
          name
        }
        files {
          ...fileParts
        }
      }
    }
  }
`);

export default function UpdateStudyLiteratureRoute() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const studyId = useRouteParam('studyId', parseInt);

  const card = useControlledCollapsibleCard();

  const { data, loading } = useQuery(UPLOAD_STUDY_LITERATURE_PAGE, {
    variables: { id: studyId },
  });

  const refetchQueries = createRefetchQueries([
    {
      query: UPLOAD_STUDY_LITERATURE_PAGE,
      variables: { id: studyId },
    },
  ]);

  const study = data?.studyGet;
  const literatureReferences = R.sortBy(
    lr => lr.priority ?? Infinity,
    study?.literatureReferences ?? [],
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!study) return <NotFound />;

  return (
    <div className="space-y-4">
      <CollapsibleCard {...card} title="Create Literature Reference">
        <CreateLiteratureForm
          studyId={studyId}
          refetchQueries={refetchQueries}
          onCreateSuccess={() => card.setIsOpen(false)}
        />
      </CollapsibleCard>

      <NoItemsAlert
        show={!literatureReferences.length}
        entity="literature references"
      />

      {literatureReferences.map(l => (
        <StudyLiteratureListItem
          key={l.id}
          studyId={studyId}
          literature={l}
          refetchQueries={refetchQueries}
        />
      ))}
    </div>
  );
}
