import { useMutation } from '@apollo/client';
import type { ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { graphql } from '~/apollo/generated/v4';
import { Modal } from '~/components/common/Modal';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { OutcropSelect } from '~/components/upload/outcrop/outcrop-select-v4';
import { useModalState } from '~/hooks/modal';
import { useZodForm } from '~/utils/forms';
import type { RefetchQueries } from '~/utils/graphql';

const TRANSFER_MINI_MODEL = graphql(`
  mutation TransferMiniModel($id: ID!, $input: MiniModelUpdateInput!) {
    miniModelUpdate(id: $id, input: $input) {
      result {
        id
      }
    }
  }
`);

export function TransferMiniModelModal({
  children,
  miniModelId,
  refetchQueries,
}: {
  children: (showModal: () => void) => ReactNode;
  miniModelId: number;
  refetchQueries?: RefetchQueries;
}) {
  const { show, showModal, hideModal } = useModalState();

  const [transferMiniModel, { loading, error }] = useMutation(
    TRANSFER_MINI_MODEL,
    { refetchQueries },
  );

  const form = useZodForm({
    schema: z.object({
      outcropId: z.number().int().positive(),
    }),
    values: { outcropId: null },
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await transferMiniModel({
        variables: {
          id: miniModelId,
          input: { outcropId: values.outcropId },
        },
      });
      toast.success('Mini-Model transferred successfully.');
    } catch (err) {
      console.log('Unexpected error transferring mini-model:', err);
      toast.error(
        'Something went wrong transferring the mini-model, please reload the page and try again.',
      );
    }
  });

  return (
    <>
      {children(showModal)}

      <Modal open={show} onHide={hideModal} closeable>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <Modal.Body heading="Transfer Mini-Model">
              <div className="space-y-4">
                <p>
                  This Mini-Model will be transferred to the selected Outcrop.
                  After the transfer is complete, ensure its characteristics
                  (e.g. Architectural Elements and placement) are valid on the
                  new Outcrop.
                </p>

                <OutcropSelect name="outcropId" label="Outcrop" required />
                <HookFormErrors graphQLError={error} />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className="text-right space-x-1">
                <Button
                  type="button"
                  color="ghost"
                  onClick={hideModal}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={loading}>
                  Transfer
                </Button>
              </div>
            </Modal.Footer>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}
