import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CSSProperties } from 'react';
import React from 'react';
import { GeologyType } from '~/apollo/generated/v4/graphql';
import { Tooltip } from '~/components/common/Tooltip';
import { readableGqlEnum } from '~/utils/text';

const Icons = {
  [GeologyType.Clastic]: 'carbonate',
  [GeologyType.Carbonate]: 'clastic',
  [GeologyType.Glacial]: 'glacial',
  [GeologyType.Structural]: 'structural',
  [GeologyType.Igneous]: 'volcanic',
} as const satisfies Record<GeologyType, string>;

function iconSrc(geologyType: GeologyType) {
  return `/assets/images/geology-type/${Icons[geologyType]}.PNG`;
}

type Props = React.HTMLAttributes<HTMLImageElement> & {
  geologyType: GeologyType;
  hideTooltip?: boolean;
  dominant?: boolean | null;
  style?: CSSProperties | undefined;
  render?: (imgSrc: string) => JSX.Element;
};

export function GeologyTypeIconV4({
  geologyType,
  dominant,
  hideTooltip = false,
  style,
  render,
  ...props
}: Props) {
  const imgSrc = iconSrc(geologyType);

  if (!imgSrc)
    return (
      <FontAwesomeIcon
        {...(props as FontAwesomeIconProps)}
        style={style}
        icon={faQuestionCircle}
      />
    );

  if (render) return render(imgSrc);

  const getOverlay = (): string => {
    const gtLabel = readableGqlEnum(geologyType);
    // if (overlay) return overlay;
    if (dominant) return `${gtLabel} (dominant)`;
    return gtLabel;
  };

  const imgStyle = (): CSSProperties => {
    const dominantStyles: CSSProperties = {
      filter: 'brightness(110%) contrast(70%) sepia(30%)',
      border: '4px solid hsl(36, 100%, 80%)',
    };

    if (dominant) return { ...dominantStyles, ...style };
    return style ?? {};
  };

  return (
    <Tooltip message={getOverlay()} disabled={hideTooltip} position="top">
      <img src={imgSrc} style={imgStyle()} alt={geologyType} {...props} />
    </Tooltip>
  );
}
