import { useMutation, useQuery } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type { UploadOutcropAgePageQuery } from '~/apollo/generated/v4/graphql';
import { NotFound } from '~/components/common/NotFound';
import { SimplePanel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { LithostratAgeFormFieldsV4 } from '~/components/upload/lithostrat-age/lithostrat-age-form-fields-v4';
import { useRouteParam } from '~/hooks/routing';
import { useZodForm } from '~/utils/forms';
import type { AgeFormValues } from '~/utils/modules/lithostrat-age-v4';
import { ageSchema, initialAge } from '~/utils/modules/lithostrat-age-v4';

const UPLOAD_OUTCROP_AGE_PAGE = graphql(`
  query UploadOutcropAgePage($id: ID!) {
    outcropGet(id: $id) {
      id
      startAge {
        ...lithostratAgeParts
      }
      endAge {
        ...lithostratAgeParts
      }
    }
  }
`);

export const UPDATE_OUTCROP_AGE = graphql(`
  mutation UpdateOutcropAge($id: ID!, $input: OutcropUpdateInput!) {
    outcropUpdate(id: $id, input: $input) {
      result {
        id
        startAge {
          ...lithostratAgeParts
        }
        endAge {
          ...lithostratAgeParts
        }
      }
    }
  }
`);

export default function UpdateOutcropGeologicalAgeRoute() {
  return (
    <ApolloProviderV4>
      <Loader />
    </ApolloProviderV4>
  );
}

export function Loader() {
  const outcropId = useRouteParam('outcropId', parseInt);

  const { data, loading } = useQuery(UPLOAD_OUTCROP_AGE_PAGE, {
    variables: { id: outcropId },
  });

  const outcrop = data?.outcropGet;

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return <PageInner outcrop={outcrop} />;
}

type Outcrop = NonNullable<UploadOutcropAgePageQuery['outcropGet']>;

const schema = z.object({
  startAge: ageSchema,
  endAge: ageSchema,
});

type FormValues = {
  startAge: AgeFormValues;
  endAge: AgeFormValues;
};

function initialValues(outcrop: Outcrop): FormValues {
  return {
    startAge: initialAge(outcrop.startAge),
    endAge: initialAge(outcrop.endAge),
  };
}

function PageInner({ outcrop }: { outcrop: Outcrop }) {
  const [updateOutcrop, { loading, error }] = useMutation(UPDATE_OUTCROP_AGE);

  const form = useZodForm({
    schema,
    values: initialValues(outcrop),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateOutcrop({
        variables: {
          id: outcrop.id,
          input: values,
        },
      });
      toast.success('Outcrop saved successfully.');
    } catch (err) {
      console.log('Error saving outcrop', err);
      toast.error('There was a problem saving the outcrop.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid lg:grid-cols-2 gap-4">
          <SimplePanel title="Start Age">
            <LithostratAgeFormFieldsV4 fieldNamePrefix="startAge" />
          </SimplePanel>
          <SimplePanel title="End Age">
            <LithostratAgeFormFieldsV4 fieldNamePrefix="endAge" />
          </SimplePanel>
        </div>

        <HookFormErrors graphQLError={error} />

        <div className="text-center">
          <Button type="submit" color="primary" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
