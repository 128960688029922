import * as R from 'ramda';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import {
  LifecycleStatus,
  type SoManagerCrossSectionPartsFragment,
  type SoManagerFaciesPartsFragment,
  type SoManagerGigaPanPartsFragment,
  type SoManagerProductionPartsFragment,
  type SoManagerReservoirModelPartsFragment,
  type SoManagerSedimentaryLogPartsFragment,
  type SoManagerTrainingImagePartsFragment,
  type SoManagerVariogramPartsFragment,
  type SoManagerWellLogPartsFragment,
} from '~/apollo/generated/v4/graphql';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { CollapsibleCard } from '~/components/ui/collapse';
import { CrossSectionCreateForm } from '~/components/upload/supporting-object/cross-section/cross-section-create-form';
import { FaciesCreateForm } from '~/components/upload/supporting-object/facies/facies-create-form';
import { GigaPanCreateForm } from '~/components/upload/supporting-object/giga-pan/giga-pan-create-form';
import { ProductionCreateForm } from '~/components/upload/supporting-object/production/production-create-form';
import { ReservoirModelCreateForm } from '~/components/upload/supporting-object/reservoir-model/reservoir-model-create-form';
import { SedlogCreateForm } from '~/components/upload/supporting-object/sedimentary-log/sedlog-create-form';
import { SOManagerItem } from '~/components/upload/supporting-object/so-manager-item';
import { TrainingImageCreateForm } from '~/components/upload/supporting-object/training-image/training-image-create-form';
import { useShowUnpublished } from '~/components/upload/supporting-object/use-show-unpublished';
import { VariogramCreateForm } from '~/components/upload/supporting-object/variogram/variogram-create-form';
import { WellLogCreateForm } from '~/components/upload/supporting-object/well-log/well-log-create-form';
import { assertExhaustive } from '~/utils/common';
import type { RefetchQueries } from '~/utils/graphql';
import { readableGqlEnum } from '~/utils/text';

export type SOParent =
  | { outcropId: number; studyId?: never }
  | { outcropId?: never; studyId: number };

type BaseProps = {
  parent: SOParent;
  refetchQueries: RefetchQueries;
};

type CrossSection = {
  soType: 'CROSS_SECTION';
  items: SoManagerCrossSectionPartsFragment[];
};

type Facies = {
  soType: 'FACIES_SCHEMA';
  items: SoManagerFaciesPartsFragment[];
};

type SedimentaryLog = {
  soType: 'SEDIMENTARY_LOG';
  items: SoManagerSedimentaryLogPartsFragment[];
};

type WellLog = {
  soType: 'WELL_LOG';
  items: SoManagerWellLogPartsFragment[];
};

type Production = {
  soType: 'PRODUCTION';
  items: SoManagerProductionPartsFragment[];
};

type ReservoirModel = {
  soType: 'RESERVOIR_MODEL';
  items: SoManagerReservoirModelPartsFragment[];
};

type TrainingImage = {
  soType: 'TRAINING_IMAGE';
  items: SoManagerTrainingImagePartsFragment[];
};

type Variogram = {
  soType: 'VARIOGRAM';
  items: SoManagerVariogramPartsFragment[];
};

type GigaPan = {
  soType: 'GIGA_PAN';
  items: SoManagerGigaPanPartsFragment[];
};

type SOProps =
  | CrossSection
  | Facies
  | SedimentaryLog
  | WellLog
  | Production
  | ReservoirModel
  | TrainingImage
  | Variogram
  | GigaPan;

export type SOType = SOProps['soType'];

type SOManagerV4Props = BaseProps & SOProps;

export function SOManagerV4(props: SOManagerV4Props) {
  return (
    <ApolloProviderV4>
      <SOManagerV4Inner {...props} />
    </ApolloProviderV4>
  );
}

export function SOManagerV4Inner({
  parent,
  soType,
  items: allItems,
  refetchQueries,
}: SOManagerV4Props) {
  const unpublishedCount = allItems.reduce(
    (acc, item) =>
      acc + (item.lifecycleStatus !== LifecycleStatus.Published ? 1 : 0),
    0,
  );

  const { showUnpublished, PublishedToggle } =
    useShowUnpublished(unpublishedCount);

  const items = allItems
    .filter(item => {
      return showUnpublished
        ? true
        : item.lifecycleStatus !== LifecycleStatus.Published;
    })
    .sort(R.ascend(item => item.priority ?? Infinity));

  return (
    <div className="space-y-4">
      <PublishedToggle />

      <CollapsibleCard title={`Create ${readableGqlEnum(soType)}`}>
        <CreateForm
          {...parent}
          soType={soType}
          refetchQueries={refetchQueries}
        />
      </CollapsibleCard>

      <div className="space-y-4">
        {items.map(item => (
          <SOManagerItem
            key={item.id}
            item={item}
            parent={parent}
            refetchQueries={refetchQueries}
          />
        ))}
        <NoItemsAlert show={!items.length} entity={readableGqlEnum(soType)} />
      </div>
    </div>
  );
}

function CreateForm({
  soType,
  ...props
}: SOParent & {
  soType: SOType;
  refetchQueries: RefetchQueries;
}) {
  switch (soType) {
    case 'CROSS_SECTION':
      return <CrossSectionCreateForm {...props} />;
    case 'FACIES_SCHEMA':
      return <FaciesCreateForm {...props} />;
    case 'SEDIMENTARY_LOG':
      return <SedlogCreateForm {...props} />;
    case 'WELL_LOG':
      return <WellLogCreateForm {...props} />;
    case 'PRODUCTION':
      return <ProductionCreateForm {...props} />;
    case 'RESERVOIR_MODEL':
      return <ReservoirModelCreateForm {...props} />;
    case 'TRAINING_IMAGE':
      return <TrainingImageCreateForm {...props} />;
    case 'VARIOGRAM':
      return <VariogramCreateForm {...props} />;
    case 'GIGA_PAN':
      return <GigaPanCreateForm {...props} />;
    default:
      throw assertExhaustive(soType);
  }
}
