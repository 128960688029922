import { useFormContext } from 'react-hook-form';
import { TextInput } from '~/components/ui/forms/text-input';
import type { Photo360FormValues } from '~/utils/modules/supporting-object';

export function Photo360FormFields() {
  const { register } = useFormContext<Photo360FormValues>();

  return (
    <div className="space-y-2">
      <TextInput {...register('name')} label="Name" required />
      <TextInput
        {...register('url')}
        label={{
          label: 'URL',
          helpText: (
            <div>
              <p>Kuula embed src:</p>
              <p className="text-xs text-muted">
                https://kuula.co/share/h5dBD?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1
              </p>
            </div>
          ),
        }}
        placeholder="https://kuula.co/..."
        required
      />

      <div className="grid lg:grid-cols-2 gap-6">
        <TextInput {...register('collectedBy')} label="Collected by" required />
        <TextInput
          type="number"
          {...register('yearCollected')}
          label="Year collected"
          required
          placeholder={new Date().getFullYear().toString()}
        />
      </div>

      <TextInput {...register('equipment')} label="Equipment" required />
    </div>
  );
}
