import { useQuery } from '@apollo/client';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Papa from 'papaparse';
import { Link } from 'react-router';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { type ExportOutcropsPageQuery } from '~/apollo/generated/v4/graphql';
import { PageHeading } from '~/components/common/PageHeading';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useFullWidthPage } from '~/components/layout/fullWidthContext';
import { Button } from '~/components/ui/button';
import { useSortFilter } from '~/hooks/data';
import { uploadOutcropUpdateRoute } from '~/paths';
import { saveAsCSV } from '~/utils/export';
import { readableGqlEnum, toISODateString } from '~/utils/text';

const EXPORT_OUTCROPS_PAGE = graphql(`
  query ExportOutcropsPage {
    outcropList {
      results {
        id
        regionId
        name
        description
        introduction
        lifecycleStatus
        outcropType
        geologyType
        location
        environments
        stratigraphy
        notablesAndAnalogues
        safari
        orientation
        depositionalDipDirection
        qcCompleted
        outcropCategory
        keyWords
        diagenesisAndPetrophysicalProperties
        structuralGeology
        dominantGeologyType
        systemsTract
        shorelineTractory
        duneShape
        channelMorphology
        riverProfileLocation
        dominantLithology
        waterTemperature
        netToGross
        diageneticSetting
        diageneticProcess
        diageneticGeometry
        tectonicSetting
        synSedimentaryDeformation
        faultRocksMembranes
        interactionNetwork
        reactivation1stPhase
        reactivation2ndPhase
        symmetryGeometry
        multipleFolds
        secondaryStructures
        lateralAggregation
        author
        datasetScale
        exposureQuality
        outcrop3dControl
        structuralComplexity
        insertedAt
        updatedAt
        thumbnail {
          id
        }
        region {
          name
          country
        }
        basinName
        basinDescription
        basinType
        basinClimate
        basinTerrestrialMarine
        basinWaterDepth
        basinCatchmentArea
        sourceAreaName
        sourceAreaClimate
        sourceAreaDescription
        sourceAreaGeography
        sourceAreaStructure
        sourceAreaDistanceToSourceArea
        sourceAreaDistanceToShoreline
        sourceAreaDistanceToShelfEdge
        keyParameters {
          ...keyParametersParts
        }
        startAge {
          ...lithostratAgeParts
        }
        georeferences {
          id
          dataType
        }
        virtualOutcropModels {
          id
        }
        studies {
          id
        }
        paleoMaps {
          id
        }
        pictures {
          id
        }
        crossSections {
          id
        }
        facies {
          id
        }
        sedimentaryLogs {
          id
        }
        wellLogs {
          id
        }
        reservoirModels {
          id
        }
        production {
          id
        }
        trainingImages {
          id
        }
        variograms {
          id
        }
        gigaPans {
          id
        }
        lithostratOutcropLinks {
          id
        }
        center {
          lat
          lng
        }
        outline {
          id
        }
        miniModels {
          id
        }
        photo360s {
          id
        }
        videos {
          id
        }
      }
    }
  }
`);

function formatDate(date?: Date | string | null | undefined) {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD hh:mm:ss');
}

type OutcropResult = NonNullable<
  NonNullable<NonNullable<ExportOutcropsPageQuery['outcropList']>['results']>
>[number];

type BoolString = 'yes' | 'no';

function countItems<T extends Array<K>, K extends {}>(items?: T | null) {
  return String(items?.length || 0);
}

const hasTextValue = (value?: string | null) => !!value && value.length > 0;

const boolToText = (val: boolean): BoolString => (val ? 'yes' : 'no');

function toRow(oc: OutcropResult) {
  return {
    ID: String(oc.id),
    Name: oc.name,
    Region: oc.region.name ?? '',
    Country: oc.region?.country ?? '',

    'Outcrop Type': oc.outcropType ?? '',
    'Geology Type': oc.geologyType?.map(readableGqlEnum).join(', ') ?? '',
    'Analogue Category': oc.outcropCategory ?? '',
    'Description Text': boolToText(hasTextValue(oc.description)),
    'Lithology and Depositional Environment': boolToText(
      hasTextValue(oc.environments),
    ),
    'Litho- and Sequence Stratigraphy': boolToText(
      hasTextValue(oc.stratigraphy),
    ),
    'Analogues and Notable Aspects': boolToText(
      hasTextValue(oc.notablesAndAnalogues),
    ),
    'Location and Accessibility': boolToText(hasTextValue(oc.location)),
    'Key References': boolToText(hasTextValue(oc.safari)),
    Introduction: boolToText(hasTextValue(oc.introduction)),
    'Key Words': boolToText(hasTextValue(oc.keyWords)),
    'Diagenesis and Petrophysical Properties': boolToText(
      hasTextValue(oc.diagenesisAndPetrophysicalProperties),
    ),
    'Structural Geology': boolToText(hasTextValue(oc.structuralGeology)),
    'Lifecycle Status': readableGqlEnum(oc.lifecycleStatus),
    'QC Completed': boolToText(oc.qcCompleted),
    'Basin Name': oc.basinName ?? '',
    'Basin Description': boolToText(hasTextValue(oc.basinDescription)),
    'Basin Type': oc.basinType ?? '',
    Climate: oc.basinClimate ?? '',
    'Terrestrial/Marine': oc.basinTerrestrialMarine ?? '',
    'Water Depth': oc.basinWaterDepth ?? '',
    'Catchment Area': String(oc.basinCatchmentArea ?? ''),
    'Source Area Name': oc.sourceAreaName ?? '',
    'Source Area Description': boolToText(
      hasTextValue(oc.sourceAreaDescription),
    ),
    Geography: oc.sourceAreaGeography ?? '',
    Structure: oc.sourceAreaStructure ?? '',
    'Source Area Climate': oc.sourceAreaClimate ?? '',
    'Distance to SA': String(oc.sourceAreaDistanceToSourceArea ?? ''),
    'Distance to Shoreline': String(oc.sourceAreaDistanceToShoreline ?? ''),
    'Disctane to Shelf Edge': String(oc.sourceAreaDistanceToShelfEdge ?? ''),
    'Depositional Dip Direction': oc.depositionalDipDirection ?? '',
    'Outcrop Orientation': oc.orientation ?? '',
    'Outcrop 3D control': oc.outcrop3dControl ?? '',
    'Exposure Quality': oc.exposureQuality ?? '',
    'Structural Complexity': oc.structuralComplexity ?? '',
    'Dataset Scale': oc.datasetScale ?? '',

    GDE: oc.keyParameters.map(kp => kp.grossDepositionalEnvironment).join(', '),
    DE: oc.keyParameters.map(kp => kp.depositionalEnvironment).join(', '),
    SE: oc.keyParameters.map(kp => kp.depositionalSubEnvironment).join(', '),
    /** Whether the outcrop has any AEs set in key parameters */
    AE: boolToText(
      oc.keyParameters.reduce(
        (acc, kp) => acc || !!kp.architecturalElement,
        false,
      ),
    ),
    'Virtual Outcrops': countItems(oc.virtualOutcropModels),
    Palaeogeography: boolToText(oc.paleoMaps.length > 0),
    Georeferences: boolToText(oc.georeferences.length > 0),
    'Geological Age': oc.startAge?.system ?? '',
    Studies: countItems(oc.studies),
    Lithostratigraphy: boolToText(
      !!oc.lithostratOutcropLinks && oc.lithostratOutcropLinks.length > 0,
    ),
    Thumbnail: boolToText(!!oc.thumbnail?.id),
    Pictures: countItems(oc.pictures),
    Facies: countItems(oc.facies),
    'Cross Sections': countItems(oc.crossSections),
    'Sedimentary Logs': countItems(oc.sedimentaryLogs),
    'Well logs': countItems(oc.wellLogs),
    Production: countItems(oc.production),
    'Reservoir Models': countItems(oc.reservoirModels),
    'Training Images': countItems(oc.trainingImages),
    Variograms: countItems(oc.variograms),
    Panoramas: countItems(oc.gigaPans),
    'Mini-Models': countItems(oc.miniModels),
    '360 Photos': countItems(oc.photo360s),
    Videos: countItems(oc.videos),
    'Has Center': boolToText(oc.center !== null),
    'Has Outline': boolToText(!!oc.outline),

    // Additional fields
    'Dominant geology type': readableGqlEnum(oc.dominantGeologyType ?? ''),
    'Systems tract': oc.systemsTract?.join(', ') ?? '',
    'Shoreline tractory': oc.shorelineTractory?.join(', ') ?? '',
    'Dune shape': oc.duneShape?.join(', ') ?? '',
    'Channel morphology': oc.channelMorphology?.join(', ') ?? '',
    'River profile location': oc.riverProfileLocation?.join(', ') ?? '',
    'Dominant lithology': oc.dominantLithology?.join(', ') ?? '',
    'Water temperature': oc.waterTemperature?.join(', ') ?? '',
    'Net to gross': oc.netToGross ?? '',
    'Diagenetic setting': oc.diageneticSetting?.join(', ') ?? '',
    'Diagenetic process': oc.diageneticProcess?.join(', ') ?? '',
    'Diagenetic geometry': oc.diageneticGeometry?.join(', ') ?? '',
    'Tectonic setting': oc.tectonicSetting?.join(', ') ?? '',
    'Syn sedimentary deformation':
      oc.synSedimentaryDeformation?.join(', ') ?? '',
    'Fault rocks membranes': oc.faultRocksMembranes?.join(', ') ?? '',
    'Interaction network': oc.interactionNetwork?.join(', ') ?? '',
    'Reactivation1st phase': oc.reactivation1stPhase?.join(', ') ?? '',
    'Reactivation2nd phase': oc.reactivation2ndPhase?.join(', ') ?? '',
    'Symmetry geometry': oc.symmetryGeometry?.join(', ') ?? '',
    'Multiple folds': oc.multipleFolds?.join(', ') ?? '',
    'Secondary structures': oc.secondaryStructures?.join(', ') ?? '',
    'Lateral aggregation': oc.lateralAggregation?.join(', ') ?? '',
    'Inserted At': formatDate(oc.insertedAt) ?? '',
    'Updated At': formatDate(oc.updatedAt) ?? '',
  } as const satisfies Record<string, string>;
}
type Row = ReturnType<typeof toRow>;

export default function ExportOutcropsPage() {
  return (
    <ApolloProviderV4>
      <Loader />
    </ApolloProviderV4>
  );
}

function Loader() {
  useFullWidthPage();
  useBreadcrumb('routes/upload/export/outcrops', 'Outcrop Master Sheet');
  const { data, loading } = useQuery(EXPORT_OUTCROPS_PAGE);

  const rows = data?.outcropList?.results?.map(toRow) ?? [];
  const columns = Object.keys(rows.at(0) ?? {}) as Array<keyof Row>;

  if (loading) {
    return (
      <SpinnerPlaceholder>
        Loading all outcrops, please wait...
      </SpinnerPlaceholder>
    );
  }

  return <PageInner columns={columns} rows={rows} />;
}

function PageInner({
  columns,
  rows,
}: {
  columns: Array<keyof Row>;
  rows: Row[];
}) {
  const { items, sortIndicatorProps: siProps } = useSortFilter(
    rows,
    'ID',
    'Name',
    'exportOutcropsList',
  );

  function handleSave() {
    const data = Papa.unparse(rows);
    const date = toISODateString(new Date());
    saveAsCSV(data, `Outcrops Export ${date}.csv`);
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-end">
        <div>
          <PageHeading>Outcrop Master Sheet</PageHeading>
          <p>Master list of all outcrops</p>
        </div>
        <Button
          type="button"
          variant="link"
          onClick={handleSave}
          className="gap-1"
        >
          <FontAwesomeIcon icon={faFileCsv} /> Save as CSV
        </Button>
      </div>

      <div className="overflow-scroll max-h-svh">
        <table className="table table-xs table-compact table-zebra">
          <thead className="sticky top-0 bg-sky-100">
            <tr>
              {columns.map(field => (
                <td key={field} className="w-12 h-auto">
                  <SortTrigger
                    colName={field}
                    sortIndicatorProps={siProps}
                    buttonProps={{
                      className: 'block w-12 text-xs break-words text-left',
                    }}
                  >
                    {field}
                  </SortTrigger>
                </td>
              ))}
            </tr>
          </thead>

          <tbody>
            {items.map(item => (
              <tr key={item.ID}>
                {columns.slice(0, 1).map(fieldName => (
                  <th key={fieldName} className="sticky left-0 bg-sky-100">
                    <Link
                      to={uploadOutcropUpdateRoute(parseInt(item.ID))}
                      target="_blank"
                      className="link"
                    >
                      {item[fieldName]}
                    </Link>
                  </th>
                ))}
                {columns.slice(1).map(fieldName => (
                  <td key={fieldName} className="border border-slate-100">
                    {item[fieldName]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
