import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';
import { graphql } from '~/apollo/generated/v4';
import type { StudyEnumsQuery } from '~/apollo/generated/v4/graphql';
import { Panel } from '~/components/common/Panel';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { StudyFormFields } from '~/components/upload/study/study-form-fields';
import { uploadStudyUpdateRoute } from '~/paths';
import { filterUnique } from '~/utils/common';
import { useZodForm } from '~/utils/forms';
import { initialStudy, studySchemaCreate } from '~/utils/modules/study';

const CREATE_STUDY = graphql(`
  mutation CreateStudy($input: StudyCreateInput!) {
    studyCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

const OUTCROP_GEOLOGY_TYPES = graphql(`
  query OutcropGeologyTypes($outcropId: ID!) {
    outcropGet(id: $outcropId) {
      id
      geologyType
    }
  }
`);

export function CreateStudyForm({
  outcropId,
  studyEnums,
}: {
  outcropId: number | undefined;
  studyEnums: StudyEnumsQuery;
}) {
  const navigate = useNavigate();
  const [createStudy, { loading, error }] = useMutation(CREATE_STUDY);
  const [loadOutcropGeologyTypes, { data: dataOutcrop }] = useLazyQuery(
    OUTCROP_GEOLOGY_TYPES,
  );

  const form = useZodForm({
    schema: studySchemaCreate,
    values: initialStudy(undefined, outcropId),
  });

  const outcropIdValue = form.watch('outcropId');

  useEffect(() => {
    if (outcropIdValue) {
      loadOutcropGeologyTypes({
        variables: {
          outcropId: outcropIdValue,
        },
      });
    }
  }, [outcropIdValue, loadOutcropGeologyTypes]);

  const handleSubmit = form.handleSubmit(async values => {
    try {
      const res = await createStudy({ variables: { input: values } });
      const studyId = res.data?.studyCreate?.result?.id;
      invariant(studyId, "Couldn't parse create response");

      toast.success('Study created successfully');
      navigate(uploadStudyUpdateRoute(parseInt(studyId)));
    } catch (err) {
      console.log('Error creating study', err);
      toast.error('There was a problem creating the study.');
    }
  });

  const geologyTypes =
    dataOutcrop?.outcropGet?.geologyType?.filter(filterUnique) ?? [];

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit}>
        <Panel>
          <Panel.Heading>
            <Panel.Title>Study Overview</Panel.Title>
          </Panel.Heading>

          <Panel.Body>
            <div className="space-y-4">
              <StudyFormFields
                mode="create"
                studyEnums={studyEnums}
                geologyTypes={geologyTypes}
              />
              <HookFormErrors graphQLError={error} />
            </div>
          </Panel.Body>

          <Panel.Footer align="right">
            <Button type="submit" color="primary" loading={loading}>
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </form>
    </FormProvider>
  );
}
