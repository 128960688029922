import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormProvider } from 'react-hook-form';
import { z } from 'zod';
import { GeoreferenceDataType } from '~/apollo/generated/v4/graphql';
import { Heading } from '~/components/common/Heading';
import { Panel } from '~/components/common/Panel';
import { Button } from '~/components/ui/button';
import { useZodForm } from '~/utils/forms';
import type { GeoreferenceImportInput } from '~/utils/geojson-v4';
import { readableGqlEnum } from '~/utils/text';
import { ConfirmGeoreferenceFormFields } from './confirm-georeference-form-fields';
import { ImportStatusIcon } from './import-status-icon';

const confirmGeoreferenceSchema = z.object({
  name: z.string().min(1),
  description: z.string().nullish(),
  dataType: z.nativeEnum(GeoreferenceDataType),
});
export type ConfirmGeoreferenceFormValues = z.infer<
  typeof confirmGeoreferenceSchema
>;

function initialValues(
  input: GeoreferenceImportInput,
): ConfirmGeoreferenceFormValues {
  return {
    name: input.name,
    description: input.description ?? '',
    dataType: input.dataType,
  };
}

export function ConfirmGeoreferenceForm({
  georeference,
  onSubmit,
  onRemove,
}: {
  georeference: GeoreferenceImportInput;
  onSubmit: (georeference: GeoreferenceImportInput) => unknown;
  onRemove: (tempId: string) => unknown;
}) {
  const { isEditing } = georeference;

  const setIsEditing = (val: boolean) =>
    onSubmit({ ...georeference, isEditing: val });

  const form = useZodForm({
    schema: confirmGeoreferenceSchema,
    values: initialValues(georeference),
  });

  const handleSubmit = form.handleSubmit(values => {
    onSubmit({
      ...georeference,
      ...values,
      isEditing: false,
    });
  });

  function handleRemove() {
    if (window.confirm('Remove this georeference from being imported?')) {
      onRemove(georeference.tempId);
    }
  }

  if (isEditing) {
    return (
      <FormProvider {...form}>
        <form onSubmit={handleSubmit}>
          <Panel>
            <Panel.Body>
              <ConfirmGeoreferenceFormFields dataType={georeference.dataType} />
            </Panel.Body>
            <Panel.Footer align="right">
              <Button
                type="button"
                color="ghost"
                size="sm"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
              <Button type="submit" color="primary" size="sm">
                Confirm
              </Button>
            </Panel.Footer>
          </Panel>
        </form>
      </FormProvider>
    );
  }

  return (
    <Panel
      variant={
        georeference.uploadStatus === 'success'
          ? 'success'
          : georeference.uploadStatus === 'failed'
            ? 'error'
            : 'default'
      }
      className="mb-4"
    >
      <Panel.Body>
        <div className="grid grid-cols-12 gap-6">
          {georeference.uploadStatus !== null && (
            <div className="col-span-3 text-center pt-4">
              <ImportStatusIcon uploadStatus={georeference.uploadStatus} />
            </div>
          )}

          <div
            className={
              georeference.uploadStatus === null ? 'col-span-12' : 'col-span-9'
            }
          >
            {georeference.uploadStatus === null && (
              <div className="float-right space-x-1">
                <Button
                  type="button"
                  color="ghost"
                  size="xs"
                  onClick={() => setIsEditing(true)}
                >
                  <FontAwesomeIcon icon={faPencil} />
                </Button>
                <Button
                  type="button"
                  color="ghost"
                  size="xs"
                  onClick={handleRemove}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            )}

            <Heading level={5}>
              {readableGqlEnum(georeference.dataType)}
            </Heading>
            <div>
              <strong>{georeference.name}</strong>
            </div>
            <div className="text-muted">{georeference.description}</div>
            <div className="clear-both" />
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
}
