import { Controller, useFormContext } from 'react-hook-form';
import {
  LifecycleStatus,
  StudyDataSourceType,
  type GeologyType,
  type StudyEnumsQuery,
} from '~/apollo/generated/v4/graphql';
import { Panel, SimplePanel } from '~/components/common/Panel';
import { Checkbox } from '~/components/ui/forms/Checkbox';
import { ReactSelectInput } from '~/components/ui/forms/react-select-input';
import type { SelectOption } from '~/components/ui/forms/Select';
import { Select } from '~/components/ui/forms/Select';
import { TagsInput } from '~/components/ui/forms/tags-input';
import { TextInput } from '~/components/ui/forms/text-input';
import { TinyMceInput } from '~/components/ui/forms/tinymce-input';
import { LifecycleStatusSelect } from '~/components/upload/lifecycle/lifecycle-status-select';
import { OutcropSelect } from '~/components/upload/outcrop/outcrop-select-v4';
import { ProjectSelect } from '~/components/upload/project/project-select-v4';
import type { StudyFormValues } from '~/utils/modules/study';
import { readableGqlEnum } from '~/utils/text';

export function StudyFormFields({
  mode,
  studyEnums,
  geologyTypes,
}: {
  mode: 'create' | 'update';
  studyEnums: StudyEnumsQuery;
  geologyTypes: GeologyType[];
}) {
  const { register, watch, control } = useFormContext<StudyFormValues>();

  const lifecycleStatusValue = watch('lifecycleStatus');

  type EnumFieldKey = keyof Omit<StudyEnumsQuery, '__typename'>;
  function enumOptions(fieldName: EnumFieldKey) {
    const values = studyEnums[fieldName]?.values ?? [];
    return values.map<SelectOption>(opt => ({ value: opt, label: opt }));
  }

  return (
    <div className="space-y-2">
      <TextInput {...register('name')} label="Name" required />
      <Select
        {...register('type')}
        label="Study Type"
        options={studyEnums.studyType.values.map(opt => ({
          value: opt,
          label: opt,
        }))}
        required
      />
      <Controller
        control={control}
        name="projectId"
        render={({ field }) => (
          <ProjectSelect {...field} label="Project" required />
        )}
      />

      {/* Sets the first outcrop linked to the study */}
      {/* After create, outcrop links may be managed via the Outcrops tab */}
      {mode === 'create' && (
        <Controller
          control={control}
          name="outcropId"
          render={({ field }) => (
            <OutcropSelect {...field} label="Outcrop" required />
          )}
        />
      )}

      <Controller
        control={control}
        name="dominantGeologyType"
        render={({ field }) => (
          <Select
            {...field}
            label="Dominant geology type"
            options={geologyTypes.map(value => ({
              value,
              label: readableGqlEnum(value),
            }))}
          />
        )}
      />

      <Select
        {...register('dataSourceType')}
        label="Data source type"
        options={Object.values(StudyDataSourceType).map(value => ({
          value,
          label: readableGqlEnum(value),
        }))}
        required
      />

      <TagsInput name="keywords" label="Keywords" mode="string" />

      <TinyMceInput name="abstract" label="Abstract" />

      <TinyMceInput
        name="numericalDataAspects"
        label="Numerical data and other notable aspects"
      />

      <TinyMceInput name="keyReferences" label="Citation" />

      <SimplePanel title="Data History">
        <div className="space-y-2">
          <TextInput
            {...register('collectedBy')}
            label={{
              label: 'Collected by',
              helpText:
                'Add the individual and or group who are responsible for this dataset.',
            }}
            required
          />
          <TextInput
            {...register('collectedYear')}
            type="number"
            label={{
              label: 'Collected year',
              helpText: 'The year the study began',
            }}
            placeholder="YYYY"
            required
          />

          <TextInput
            {...register('publicationLicense')}
            label="Publication license"
          />
        </div>
      </SimplePanel>

      <SimplePanel title="Quality Parameters">
        <div className="space-y-2">
          <Controller
            control={control}
            name="dataAcquisitionMethod"
            render={({ field }) => (
              <ReactSelectInput
                {...field}
                label="Data Acquisition Method"
                options={enumOptions('dataAcquisitionMethod')}
                multiple
              />
            )}
          />
          <Select
            {...register('spatialObservationType')}
            label="Spatial Observation Type"
            options={enumOptions('spatialObservationType')}
          />
          <Controller
            control={control}
            name="publicationType"
            render={({ field }) => (
              <ReactSelectInput
                {...field}
                label={{
                  label: 'Publication type',
                  helpText:
                    'Select how the data from this study has been published.',
                }}
                options={enumOptions('publicationType')}
                multiple
                required
              />
            )}
          />
        </div>
      </SimplePanel>

      <Panel
        variant={
          lifecycleStatusValue === LifecycleStatus.Published
            ? 'info'
            : lifecycleStatusValue === LifecycleStatus.Approved
              ? 'success'
              : lifecycleStatusValue === LifecycleStatus.ReadyForApproval
                ? 'warning'
                : undefined
        }
        className="mt-4"
      >
        <Panel.Heading>
          <Panel.Title>Lifecycle</Panel.Title>
        </Panel.Heading>
        <Panel.Body className="space-y-2">
          <Controller
            control={control}
            name="lifecycleStatus"
            render={({ field }) => (
              <LifecycleStatusSelect {...field} label="Status" />
            )}
          />
          <Checkbox
            {...register('qcCompleted')}
            label="Quality Control Completed"
          />
        </Panel.Body>
      </Panel>
    </div>
  );
}
