import {
  faCheckCircle,
  faHourglass,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '~/utils/common';
import type { GeoreferenceImportUploadStatus } from '~/utils/geojson';

type Props = {
  uploadStatus: GeoreferenceImportUploadStatus;
};

export function ImportStatusIcon({ uploadStatus }: Props) {
  if (uploadStatus === null) {
    return null;
  }

  let icon: FontAwesomeIconProps['icon'] = faHourglass;
  if (uploadStatus === 'pending') {
    icon = faHourglass;
  } else if (uploadStatus === 'success') {
    icon = faCheckCircle;
  } else if (uploadStatus === 'failed') {
    icon = faXmarkCircle;
  }

  return (
    <FontAwesomeIcon
      icon={icon}
      className={cn('text-3xl', {
        'text-muted': uploadStatus === 'pending',
        'text-success': uploadStatus === 'success',
        'text-error': uploadStatus === 'failed',
      })}
    />
  );
}
