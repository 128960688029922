import { Dialog, Transition } from '@headlessui/react';
import type { HTMLProps } from 'react';
import React, { Fragment } from 'react';
import { cn } from '~/utils/common';

type Props = {
  open: boolean;
  onHide: () => void;
  children: React.ReactNode;
  closeable?: boolean;
  size?: 'md' | 'lg';
};

export function Modal({
  open,
  onHide,
  children,
  closeable = true,
  size = 'md',
}: Props) {
  const hideModal = () => {
    if (!closeable) return () => {};
    return onHide();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={hideModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={cn(
                  'relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg',
                  { 'md:max-w-4xl md:mx-10': size === 'lg' },
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

type ModalBodyProps = {
  heading?: React.ReactNode;
  icon?: React.ReactNode;
} & HTMLProps<HTMLDivElement>;

function ModalBody({
  children,
  className,
  heading,
  icon,
  ...divProps
}: ModalBodyProps) {
  return (
    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full">
      <div
        {...divProps}
        className={cn('mt-3 text-center sm:mt-0 sm:text-left', className)}
      >
        {heading && (
          <Dialog.Title
            as="h3"
            className="text-xl font-medium leading-6 text-slate-900 mb-2"
          >
            {heading}
          </Dialog.Title>
        )}

        <div>{children}</div>
      </div>
    </div>
  );
}

Modal.Body = ModalBody;

type ModalFooterProps = HTMLProps<HTMLDivElement>;

function ModalFooter({ children, className, ...divProps }: ModalFooterProps) {
  return (
    <div
      {...divProps}
      className={cn(
        'bg-slate-50 px-4 py-3 sm:px-6 flex justify-end align-middle gap-1',
        className,
      )}
    >
      {children}
    </div>
  );
}

Modal.Footer = ModalFooter;
