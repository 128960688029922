import { useQuery } from '@apollo/client';
import { faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Link } from 'react-router';
import { graphql } from '~/apollo/generated/v3';
import type { UploadStudyListQuery } from '~/apollo/generated/v3/graphql';
import { Panel } from '~/components/common/Panel';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { StudyList } from '~/components/upload/study/StudyList';
import { useSortFilter } from '~/hooks/data';
import { exportStudiesRoute, uploadStudyCreateRoute } from '~/paths';

type StudyType = UploadStudyListQuery['studyList'][number];

type ApprovalState = 'approved' | 'ready for approval' | 'draft';
export function approvalState(s: StudyType): ApprovalState {
  if (s.approved) return 'approved';
  if (s.readyForApproval) return 'ready for approval';
  return 'draft';
}

export type SortableStudy = StudyType & {
  hasOutcrops: boolean;
  approvalState: ApprovalState;
  aeCount: number;
  measurementCount: number;
  searchableText: string;
};

export const toSortableStudy = (study: StudyType): SortableStudy => ({
  ...study,
  outcrops: R.sortBy(oc => oc.name, study.outcrops),
  hasOutcrops: (study.outcrops ?? []).length > 0,
  approvalState: approvalState(study),
  aeCount: study.architecturalElements.length,
  measurementCount: study.architecturalElements.reduce(
    (acc, cur) => acc + cur.measurementCount,
    0,
  ),
  searchableText:
    `${study.name} - ${study.dataHistory?.collectedBy}`.toLowerCase(),
});

export const UPLOAD_STUDY_LIST = graphql(`
  query UploadStudyList($outcropId: Int) {
    studyList(outcropId: $outcropId) {
      id
      name
      type
      approved
      readyForApproval
      redundant
      qcCompleted
      qualityParameters {
        publicationType
      }
      dataHistory {
        collectedBy
      }
      project {
        id
        name
      }
      outcrops {
        id
        name
      }
      architecturalElements {
        id
        measurementCount
      }
    }
  }
`);

export default function UploadStudyListPage() {
  const { data, loading } = useQuery(UPLOAD_STUDY_LIST);

  const sortableStudies = data?.studyList.map(toSortableStudy) ?? [];

  const { items, sortIndicatorProps, filterSearchProps } = useSortFilter(
    sortableStudies,
    'id',
    'searchableText',
    'uploadStudyList',
  );

  return (
    <Panel>
      <Panel.Heading className="flex justify-between items-center">
        <Panel.Title>
          Studies
          {!loading && (
            <small className="text-muted ml-2">
              (<strong>{items.length}</strong> results displayed)
            </small>
          )}
        </Panel.Title>

        <div className="space-x-2">
          <Link
            to={exportStudiesRoute()}
            target="_blank"
            className="btn btn-ghost btn-xs gap-1"
          >
            <FontAwesomeIcon icon={faFileExport} />
            Export Studies
          </Link>

          <Link
            to={uploadStudyCreateRoute()}
            className="btn btn-primary btn-xs gap-1"
          >
            <FontAwesomeIcon icon={faPlus} />
            Create Study
          </Link>
        </div>
      </Panel.Heading>
      <Panel.Body>
        <SpinnerPlaceholder show={loading} />
        {!loading && (
          <StudyList
            studies={items}
            sortIndicatorProps={sortIndicatorProps}
            filterSearchProps={filterSearchProps}
          />
        )}
      </Panel.Body>
    </Panel>
  );
}
