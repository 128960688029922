import { z } from 'zod';
import type {
  OutcropListPartsFragment,
  OutcropPartsFragment,
  UploadOutcropOverviewPageQuery,
} from '~/apollo/generated/v4/graphql';
import { GeologyType, LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { ppNumberOrNull, ppStringOrNull } from '~/utils/zod';

export const outcropSchemaUnpublished = z.object({
  isBeingPublished: z.literal(false),

  name: z.string().min(1),
  regionId: z.preprocess(ppNumberOrNull, z.number().int().positive()),
  outcropType: z.preprocess(ppStringOrNull, z.string().nullable()),
  geologyType: z.array(z.nativeEnum(GeologyType)),
  dominantGeologyType: z.preprocess(
    ppStringOrNull,
    z.nativeEnum(GeologyType).nullable(),
  ),
  description: z.preprocess(ppStringOrNull, z.string().nullable()),
  introduction: z.preprocess(ppStringOrNull, z.string().nullable()),
  environments: z.preprocess(ppStringOrNull, z.string().nullable()),
  stratigraphy: z.preprocess(ppStringOrNull, z.string().nullable()),
  notablesAndAnalogues: z.preprocess(ppStringOrNull, z.string().nullable()),
  location: z.preprocess(ppStringOrNull, z.string().nullable()),
  safari: z.preprocess(ppStringOrNull, z.string().nullable()),
  lifecycleStatus: z.nativeEnum(LifecycleStatus),
  orientation: z.preprocess(ppStringOrNull, z.string().nullable()),
  depositionalDipDirection: z.preprocess(ppStringOrNull, z.string().nullable()),
  author: z.preprocess(ppStringOrNull, z.string().nullable()),
  qcCompleted: z.boolean(),
  outcropCategory: z.preprocess(ppStringOrNull, z.string().nullable()),
  keyWords: z.preprocess(ppStringOrNull, z.string().nullable()),
  diagenesisAndPetrophysicalProperties: z.preprocess(
    ppStringOrNull,
    z.string().nullable(),
  ),
  structuralGeology: z.preprocess(ppStringOrNull, z.string().nullable()),

  // Quality parameters
  structuralComplexity: z.preprocess(ppStringOrNull, z.string().nullable()),
  outcrop3dControl: z.preprocess(ppStringOrNull, z.string().nullable()),
  exposureQuality: z.preprocess(ppStringOrNull, z.string().nullable()),
  datasetScale: z.preprocess(ppStringOrNull, z.string().nullable()),

  // Basin
  basinName: z.preprocess(ppStringOrNull, z.string().nullable()),
  basinType: z.preprocess(ppStringOrNull, z.string().nullable()),
  basinClimate: z.preprocess(ppStringOrNull, z.string().nullable()),
  basinCatchmentArea: z.preprocess(ppNumberOrNull, z.number().nullable()),
  basinComments: z.preprocess(ppStringOrNull, z.string().nullable()),
  basinDescription: z.preprocess(ppStringOrNull, z.string().nullable()),
  basinTerrestrialMarine: z.preprocess(ppStringOrNull, z.string().nullable()),
  basinWaterDepth: z.preprocess(ppStringOrNull, z.string().nullable()),
  basinAccommodationRegime: z.preprocess(ppStringOrNull, z.string().nullable()),

  // Source area
  sourceAreaName: z.preprocess(ppStringOrNull, z.string().nullable()),
  sourceAreaDescription: z.preprocess(ppStringOrNull, z.string().nullable()),
  sourceAreaGeography: z.preprocess(ppStringOrNull, z.string().nullable()),
  sourceAreaStructure: z.preprocess(ppStringOrNull, z.string().nullable()),
  sourceAreaClimate: z.preprocess(ppStringOrNull, z.string().nullable()),
  sourceAreaDistanceToSourceArea: z.preprocess(
    ppNumberOrNull,
    z.number().nullable(),
  ),
  sourceAreaDistanceToSourceAreaDesc: z.preprocess(
    ppStringOrNull,
    z.string().nullable(),
  ),
  sourceAreaDistanceToShoreline: z.preprocess(
    ppNumberOrNull,
    z.number().nullable(),
  ),
  sourceAreaDistanceToShorelineDesc: z.preprocess(
    ppStringOrNull,
    z.string().nullable(),
  ),
  sourceAreaDistanceToShelfEdge: z.preprocess(
    ppNumberOrNull,
    z.number().nullable(),
  ),
  sourceAreaDistanceToShelfEdgeDesc: z.preprocess(
    ppStringOrNull,
    z.string().nullable(),
  ),
  sourceAreaComments: z.preprocess(ppStringOrNull, z.string().nullable()),
});

export const outcropSchemaPublished = outcropSchemaUnpublished.extend({
  isBeingPublished: z.literal(true),

  description: z.string().min(1),
  location: z.string().min(1),
  geologyType: z.array(z.nativeEnum(GeologyType)).min(1),
  outcropCategory: z.string().min(1),
  outcropType: z.string().min(1),

  basinName: z.string().min(1),
  basinType: z.string().min(1),
  basinClimate: z.string().min(1),

  structuralComplexity: z.string().min(1),
  outcrop3dControl: z.string().min(1),
  exposureQuality: z.string().min(1),
  datasetScale: z.string().min(1),
});

export const outcropSchema = z.discriminatedUnion('isBeingPublished', [
  outcropSchemaUnpublished,
  outcropSchemaPublished,
]);

export type OutcropFormValues = {
  /** Validation discriminator */
  isBeingPublished: boolean;

  name: string;
  regionId: string;
  outcropType: string;
  geologyType: string[];
  dominantGeologyType: string;
  description: string;
  introduction: string;
  environments: string;
  stratigraphy: string;
  notablesAndAnalogues: string;
  location: string;
  safari: string;
  lifecycleStatus: LifecycleStatus;
  qcCompleted: boolean;
  outcropCategory: string;
  keyWords: string;
  author: string;
  diagenesisAndPetrophysicalProperties: string;
  structuralGeology: string;

  orientation: string;
  depositionalDipDirection: string;

  // Quality parameters
  structuralComplexity: string;
  outcrop3dControl: string;
  exposureQuality: string;
  datasetScale: string;

  // Basin
  basinName: string;
  basinType: string;
  basinCatchmentArea: string;
  basinClimate: string;
  basinComments: string;
  basinDescription: string;
  basinTerrestrialMarine: string;
  basinWaterDepth: string;
  basinAccommodationRegime: string;

  // Source area
  sourceAreaName: string;
  sourceAreaDescription: string;
  sourceAreaGeography: string;
  sourceAreaStructure: string;
  sourceAreaClimate: string;
  sourceAreaDistanceToSourceArea: string;
  sourceAreaDistanceToSourceAreaDesc: string;
  sourceAreaDistanceToShoreline: string;
  sourceAreaDistanceToShorelineDesc: string;
  sourceAreaDistanceToShelfEdge: string;
  sourceAreaDistanceToShelfEdgeDesc: string;
  sourceAreaComments: string;
};

export function initialOutcrop(
  oc?: NonNullable<UploadOutcropOverviewPageQuery['outcropGet']>,
): OutcropFormValues {
  return {
    isBeingPublished: oc?.lifecycleStatus
      ? oc.lifecycleStatus !== LifecycleStatus.Draft
      : false,

    name: oc?.name ?? '',
    regionId: String(oc?.regionId ?? ''),
    outcropType: oc?.outcropType ?? '',
    geologyType: oc?.geologyType ?? [],
    dominantGeologyType: oc?.dominantGeologyType ?? '',
    description: oc?.description ?? '',
    introduction: oc?.introduction ?? '',
    environments: oc?.environments ?? '',
    stratigraphy: oc?.stratigraphy ?? '',
    notablesAndAnalogues: oc?.notablesAndAnalogues ?? '',
    location: oc?.location ?? '',
    safari: oc?.safari ?? '',
    lifecycleStatus: oc?.lifecycleStatus ?? LifecycleStatus.Draft,
    orientation: oc?.orientation ?? '',
    depositionalDipDirection: oc?.depositionalDipDirection ?? '',
    author: oc?.author ?? '',
    qcCompleted: oc?.qcCompleted ?? false,
    outcropCategory: oc?.outcropCategory ?? '',
    keyWords: oc?.keyWords ?? '',
    diagenesisAndPetrophysicalProperties:
      oc?.diagenesisAndPetrophysicalProperties ?? '',
    structuralGeology: oc?.structuralGeology ?? '',

    // Quality parameters
    structuralComplexity: oc?.structuralComplexity ?? '',
    exposureQuality: oc?.exposureQuality ?? '',
    datasetScale: oc?.datasetScale ?? '',
    outcrop3dControl: oc?.outcrop3dControl ?? '',

    // Basin
    basinType: oc?.basinType ?? '',
    basinCatchmentArea: String(oc?.basinCatchmentArea ?? ''),
    basinClimate: oc?.basinClimate ?? '',
    basinComments: oc?.basinComments ?? '',
    basinDescription: oc?.basinDescription ?? '',
    basinName: oc?.basinName ?? '',
    basinTerrestrialMarine: oc?.basinTerrestrialMarine ?? '',
    basinWaterDepth: oc?.basinWaterDepth ?? '',
    basinAccommodationRegime: oc?.basinAccommodationRegime ?? '',

    // Source area
    sourceAreaName: oc?.sourceAreaName ?? '',
    sourceAreaDescription: oc?.sourceAreaDescription ?? '',
    sourceAreaGeography: oc?.sourceAreaGeography ?? '',
    sourceAreaStructure: oc?.sourceAreaStructure ?? '',
    sourceAreaClimate: oc?.sourceAreaClimate ?? '',
    sourceAreaDistanceToSourceArea: String(
      oc?.sourceAreaDistanceToSourceArea ?? '',
    ),
    sourceAreaDistanceToSourceAreaDesc:
      oc?.sourceAreaDistanceToSourceAreaDesc ?? '',
    sourceAreaDistanceToShoreline: String(
      oc?.sourceAreaDistanceToShoreline ?? '',
    ),
    sourceAreaDistanceToShorelineDesc:
      oc?.sourceAreaDistanceToShorelineDesc ?? '',
    sourceAreaDistanceToShelfEdge: String(
      oc?.sourceAreaDistanceToShelfEdge ?? '',
    ),
    sourceAreaDistanceToShelfEdgeDesc:
      oc?.sourceAreaDistanceToShelfEdgeDesc ?? '',
    sourceAreaComments: oc?.sourceAreaComments ?? '',
  };
}

/** Get the label of the given HTML field based on the outcrop's outcropCategory */
export function fieldLabel(
  field: keyof OutcropPartsFragment,
  ocCategory: string | null = 'outcrop',
) {
  if (field === 'description') {
    return 'Description';
  }
  if (field === 'introduction') {
    return 'Introduction';
  }

  if (field === 'environments') {
    switch (ocCategory) {
      case 'modern':
        return 'Sedimentology and Depositional Environment';
      default:
        return 'Lithology and Depositional Environment';
    }
  }

  if (field === 'stratigraphy') {
    switch (ocCategory) {
      case 'production':
        return 'Structural Complexity';
      default:
        return 'Litho- and Sequence Stratigraphy';
    }
  }

  if (field === 'location') {
    switch (ocCategory) {
      case 'seismic':
        return 'Dataset';
      case 'production':
        return 'Reservoir Engineering Aspects';
      default:
        return 'Location and Accessibility';
    }
  }

  if (field === 'notablesAndAnalogues') {
    switch (ocCategory) {
      case 'production':
        return 'Production Aspects';
      default:
        return 'Analogues and Notable Aspects';
    }
  }

  if (field === 'safari') {
    switch (ocCategory) {
      case 'production':
        return 'Key References and Links';
      default:
        return 'Key References';
    }
  }

  if (field === 'keyWords') return 'Keywords';

  if (field === 'diagenesisAndPetrophysicalProperties')
    return 'Diagenesis and Petrophysical Properties';

  if (field === 'structuralGeology') return 'Structural Geology';

  throw new Error(`Field ${field} does not have a defined label`);
}

export type SortableOutcropV4 = OutcropListPartsFragment & {
  numStudies: number;
  hasPaleoMaps: boolean;
  numVoms: number;
};

export function toSortableOutcropV4(
  outcrop: OutcropListPartsFragment,
): SortableOutcropV4 {
  return {
    ...outcrop,
    numStudies: outcrop.studies.length,
    numVoms: outcrop.virtualOutcropModels.length,
    hasPaleoMaps: Boolean(outcrop.paleoMaps.length),
  };
}
