import { useQuery } from '@apollo/client';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Papa from 'papaparse';
import { Link } from 'react-router';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import type { ExportStudiesPageQuery } from '~/apollo/generated/v4/graphql';
import { PageHeading } from '~/components/common/PageHeading';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { useFullWidthPage } from '~/components/layout/fullWidthContext';
import { Button } from '~/components/ui/button';
import { useSortFilter } from '~/hooks/data';
import { uploadStudyUpdateRoute } from '~/paths';
import { saveAsCSV } from '~/utils/export';
import { mergeKeyParameters } from '~/utils/modules/keyParameters';
import { readableGqlEnum, toISODateString } from '~/utils/text';

type Study = NonNullable<
  NonNullable<ExportStudiesPageQuery['studyList']>['results']
>[number];

const EXPORT_STUDIES_PAGE = graphql(`
  query ExportStudiesPage {
    studyList {
      results {
        id
        name
        type
        abstract
        dominantGeologyType
        lifecycleStatus
        qcCompleted
        redundant
        insertedAt
        updatedAt
        outcrops {
          id
          region {
            country
          }
        }
        dataAcquisitionMethod
        spatialObservationType
        publicationType
        collectedBy
        collectedYear
        # keyParameters {
        #   ...keyParametersParts
        # }
        project {
          name
        }
        # architecturalElements {
        #   measurementCount
        # }
        pictures {
          id
        }
        sedimentaryLogs {
          id
        }
        wellLogs {
          id
        }
        crossSections {
          id
        }
        facies {
          id
        }
        production {
          id
        }
        literatureReferences {
          id
        }
        literatureReferences {
          id
        }
        reservoirModels {
          id
        }
        variograms {
          id
        }
        gigaPans {
          id
        }
        trainingImages {
          id
        }
        # lithostratStudyLinks {
        #   id
        # }
      }
    }
  }
`);

function numSO<T>(items: T[] | undefined | null) {
  return items?.length ?? 0;
}

function formatDate(date?: Date | string | null | undefined) {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD hh:mm:ss');
}

function toRow(s: Study) {
  // TODO: Replace when implemented in v4 API
  // const kps = mergeKeyParameters(s.keyParameters);
  const kps = mergeKeyParameters([]);

  // TODO: Replace when implemented in v4 API
  // const numAEsWithMeasurements = s.architecturalElements.reduce(
  //   (acc, cur) => acc + (cur.measurementCount ? 1 : 0),
  //   0,
  // );
  const numAEsWithMeasurements = -9999;

  return {
    ID: parseInt(s.id),
    Name: s.name,
    'Study Type': s.type,
    'Geology Type': s.dominantGeologyType ?? '',
    Country: s.outcrops?.at(0)?.region?.country ?? '',
    Project: s.project.name ?? '',
    Abstract: s.abstract ? 'yes' : 'no',
    'Collected By': s.collectedBy,
    Date: s.collectedYear,
    'Data Acquisition Method': s.dataAcquisitionMethod?.join(', '),
    'Spatial Observation Type': s.spatialObservationType ?? '',
    'Publication Type': s.publicationType?.join(', '),
    'Key Reference': s.literatureReferences ? 'yes' : 'no',
    'Lifecycle Status': readableGqlEnum(s.lifecycleStatus),
    'QC Completed': s.qcCompleted ? 'yes' : 'no',
    Redundant: s.redundant ? 'yes' : 'no',
    'Outcrop Links': s.outcrops?.length || 0,
    GDE: kps.grossDepositionalEnvironment.join(', '),
    DE: kps.depositionalEnvironment.join(', '),
    SE: kps.depositionalSubEnvironment.join(', '),
    AE: numAEsWithMeasurements,

    // TODO: Replace when implemented in v4 API
    // Lithostrat: s.lithostratStudyLinks.length > 0 ? 'yes' : 'no',
    Lithostrat: 'TODO',

    Pictures: numSO(s.pictures),
    Facies: numSO(s.facies),
    'Cross Sections': numSO(s.crossSections),
    'Sedimentary Logs': numSO(s.sedimentaryLogs),
    'Well Logs': numSO(s.wellLogs),
    Production: numSO(s.production),
    Literature: numSO(s.literatureReferences),
    'Reservoir Models': numSO(s.reservoirModels),
    'Training Images': numSO(s.trainingImages),
    Variograms: numSO(s.variograms),
    Panoramas: numSO(s.gigaPans),
    'Inserted At': formatDate(s.insertedAt) ?? '',
    'Updated At': formatDate(s.updatedAt) ?? '',
  } as const;
}
type Column = keyof ReturnType<typeof toRow>;

export default function ExportStudiesRoute() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  useFullWidthPage();
  useBreadcrumb('routes/upload/export/studies', 'Study Master Sheet');

  const { data, loading } = useQuery(EXPORT_STUDIES_PAGE);

  const studies = data?.studyList?.results ?? [];
  const rows = studies.map(toRow);

  const fields = rows.length ? (Object.keys(rows[0]) as Column[]) : [];

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    rows,
    'ID',
    'Name',
    'exportStudiesList',
  );

  function handleSave() {
    const data = Papa.unparse(rows);
    const date = toISODateString(new Date());
    saveAsCSV(data, `Studies Export ${date}.csv`);
  }

  if (loading) return <SpinnerPlaceholder />;

  return (
    <div className="space-y-4">
      <div className="float-right">
        <Button
          type="button"
          variant="link"
          onClick={handleSave}
          className="gap-1"
        >
          <FontAwesomeIcon icon={faFileCsv} /> Save as CSV
        </Button>
      </div>

      <PageHeading>Study Master Sheet</PageHeading>
      <p>Master list of all studies</p>

      <div className="overflow-scroll max-h-svh">
        <table className="table table-xs table-compact table-zebra">
          <thead className="sticky top-0 bg-sky-100">
            <tr>
              {fields.map(f => (
                <th key={f}>
                  <SortTrigger colName={f} sortIndicatorProps={siProps}>
                    <span style={{ fontSize: '9pt' }}>{f}</span>
                  </SortTrigger>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {items.map(item => (
              <tr key={item.ID}>
                {fields.slice(0, 1).map(f => (
                  <td key={f} className="sticky left-0 bg-sky-100">
                    <Link
                      to={uploadStudyUpdateRoute(item.ID)}
                      target="blank"
                      className="link"
                    >
                      {item[f]}
                    </Link>
                  </td>
                ))}
                {fields.slice(1).map(f => (
                  <td key={f} className="border border-slate-100">
                    {item[f]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
