import { ApolloProviderV4 } from '~/apollo/client-v4';
import { PageHeading } from '~/components/common/PageHeading';
import { useBreadcrumb } from '~/components/layout/Breadcrumb';
import { BulkFieldPictureUploader } from '~/components/upload/supporting-object/field-picture/bulk-field-picture-uploader';

export default function BulkUploadFieldPicsRoute() {
  useBreadcrumb('routes/upload/field-pictures', 'Bulk Upload Field Pictures');

  return (
    <ApolloProviderV4>
      <PageHeading>Upload Field Pictures</PageHeading>
      <BulkFieldPictureUploader />
    </ApolloProviderV4>
  );
}
