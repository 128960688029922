import { Link } from 'react-router';
import { uploadOutcropUpdateRoute } from '~/paths';

export function SODetailText({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) {
  if (!value) {
    return null;
  }

  return (
    <div className="p-0 space-x-2">
      <span className="text-xs text-muted cursor-default font-bold">
        {label}
      </span>
      <span className="text-sm">{value}</span>
    </div>
  );
}

export function SOOutcropTagText({
  label,
  outcropTag,
}: {
  label: string;
  outcropTag?: {
    id: string;
    name: string;
  } | null;
}) {
  if (!outcropTag) return null;

  return (
    <SODetailText
      label={label}
      value={
        <Link
          to={uploadOutcropUpdateRoute(parseInt(outcropTag.id))}
          className="link"
        >
          {outcropTag.name}
        </Link>
      }
    />
  );
}
