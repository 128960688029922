import { useMutation } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import type { SoManagerSedimentaryLogPartsFragment } from '~/apollo/generated/v4/graphql';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { SOFormFields } from '~/components/upload/supporting-object/so-form-fields';
import type { SOParent } from '~/components/upload/supporting-object/so-manager';
import { UPDATE_SEDLOG } from '~/components/upload/supporting-object/so-operations';
import { useZodForm } from '~/utils/forms';
import { initialSedlog, sedlogSchema } from '~/utils/modules/supporting-object';

type Props = SOParent & {
  sedlog: SoManagerSedimentaryLogPartsFragment;
  onUpdateSuccess?: () => unknown;
};

export function SedlogUpdateForm({ sedlog, studyId, onUpdateSuccess }: Props) {
  const [updateSedlog, { loading, error }] = useMutation(UPDATE_SEDLOG);

  const form = useZodForm({
    schema: sedlogSchema,
    values: initialSedlog(sedlog),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await updateSedlog({
        variables: {
          id: sedlog.id,
          input: values,
        },
      });
      toast.success('Sedimentary log updated successfully.');
      form.reset();
      if (onUpdateSuccess) onUpdateSuccess();
    } catch (err) {
      console.log('Error updating sedimentary log', err);
      toast.error('There was a problem updating the sedimentary log.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <SOFormFields soType="SEDIMENTARY_LOG" studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="card-actions justify-end">
          <Button type="submit" loading={loading} color="primary">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
