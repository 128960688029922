import { useMutation, type PureQueryOptions } from '@apollo/client';
import {
  faArrowRightArrowLeft,
  faBan,
  faCheckCircle,
  faExternalLink,
  faFlag,
  faMapMarker,
  faPencil,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { useState } from 'react';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import {
  LifecycleStatus,
  type GeologyType,
  type UploadOutcropMiniModelsPageQuery,
} from '~/apollo/generated/v4/graphql';
import { ExpandedIcon } from '~/components/common/icons/ExpandedIcon';
import { Panel } from '~/components/common/Panel';
import { Badge } from '~/components/ui/badge';
import { Button } from '~/components/ui/button';
import { DeleteUrlBasedSO } from '~/components/upload/supporting-object/delete-url-based-so';
import { TransferMiniModelModal } from '~/components/upload/supporting-object/mini-model/transfer-mini-model-modal';
import {
  UPDATE_MINI_MODEL,
  UpdateMiniModelForm,
} from '~/components/upload/supporting-object/mini-model/update-mini-model-form';
import { SODetailText } from '~/components/upload/supporting-object/so-detail-text';
import { uploadOutcropSOPlacementsRoute } from '~/paths';
import { cn } from '~/utils/common';

type Outcrop = NonNullable<UploadOutcropMiniModelsPageQuery['outcropGet']>;
type MiniModel = Outcrop['miniModels'][number];

export function MiniModelItem({
  outcropId,
  miniModel,
  geologyTypes,
  refetchQueries,
}: {
  outcropId: number;
  miniModel: MiniModel;
  geologyTypes: GeologyType[];
  refetchQueries?: PureQueryOptions[];
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(
    miniModel.lifecycleStatus !== LifecycleStatus.Approved,
  );

  return (
    <Panel key={miniModel.id}>
      <Panel.Heading>
        <div className="flex justify-between gap-6 items-center w-full">
          <Button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            color="ghost"
            size="md"
          >
            <span>{miniModel.name}</span>
            <span className="text-muted text-sm">{miniModel.id}</span>
            <ExpandedIcon expanded={isOpen} />
          </Button>

          <div className="space-x-2">
            <MiniModelPublishedButtons miniModel={miniModel} />
            <span className="text-slate-300">|</span>
            <Link
              to={uploadOutcropSOPlacementsRoute(outcropId)}
              className={cn('btn btn-xs btn-soft', {
                'btn-success': miniModel.placement,
                'btn-error': !miniModel.placement,
              })}
              target="_blank"
            >
              <FontAwesomeIcon
                icon={miniModel.placement ? faMapMarker : faBan}
              />
              {miniModel.placement
                ? 'Placed in Cesium'
                : 'Not Placed in Cesium'}
            </Link>
          </div>
        </div>
      </Panel.Heading>

      {(isOpen || isEditing) && (
        <Panel.Body>
          {isEditing ? (
            <UpdateMiniModelForm
              miniModel={miniModel}
              geologyTypes={geologyTypes ?? []}
              onUpdateSuccess={() => setIsEditing(false)}
            />
          ) : (
            <MiniModelsDetail miniModel={miniModel} />
          )}
        </Panel.Body>
      )}

      <Panel.Footer>
        <div className="flex justify-between items-center">
          <div className="shrink">
            {!isEditing && (
              <TransferMiniModelModal
                miniModelId={parseInt(miniModel.id)}
                refetchQueries={refetchQueries}
              >
                {showTransferModal => (
                  <Button
                    type="button"
                    onClick={showTransferModal}
                    color="ghost"
                    size="xs"
                    startIcon={<FontAwesomeIcon icon={faArrowRightArrowLeft} />}
                  >
                    Transfer
                  </Button>
                )}
              </TransferMiniModelModal>
            )}
          </div>

          <div className="text-right space-x-1">
            <Button
              type="button"
              onClick={() => setIsEditing(!isEditing)}
              color="ghost"
              size="xs"
              startIcon={
                <FontAwesomeIcon icon={isEditing ? faBan : faPencil} />
              }
            >
              {isEditing ? 'Cancel' : 'Edit'}
            </Button>

            {!isEditing && (
              <DeleteUrlBasedSO
                soType="MiniModel"
                id={parseInt(miniModel.id)}
                isPlaced={!!miniModel.placement?.id}
                refetchQueries={refetchQueries}
              >
                {(deleteItem, isDeleting) => (
                  <Button
                    type="button"
                    color="ghost"
                    size="xs"
                    onClick={deleteItem}
                    disabled={isDeleting}
                    loading={isDeleting}
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Delete
                  </Button>
                )}
              </DeleteUrlBasedSO>
            )}
          </div>
        </div>
      </Panel.Footer>
    </Panel>
  );
}

function MiniModelsDetail({ miniModel }: { miniModel: MiniModel }) {
  return (
    <div className="space-x-0">
      <SODetailText
        label="URL"
        value={
          <div className="break-all">
            <a
              href={miniModel.url}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              {miniModel.url}{' '}
              <FontAwesomeIcon
                icon={faExternalLink}
                className="text-sm text-slate-300"
              />
            </a>
          </div>
        }
      />

      <SODetailText
        label="Collected"
        value={`${miniModel.collectedBy}, ${miniModel.yearCollected}`}
      />

      <SODetailText label="Equipment" value={miniModel.equipment} />

      <SODetailText
        label="Scaniverse name"
        value={
          miniModel.scaniverseName ?? (
            <span className="text-error">NOT SET</span>
          )
        }
      />

      <SODetailText label="Sketchfab name" value={miniModel.sketchfabName} />

      <SODetailText label="Description" value={miniModel.description} />

      {(miniModel.latitude || miniModel.longitude) && (
        <SODetailText
          label="Coordinates"
          value={`${miniModel.longitude ?? '???'}, ${miniModel.latitude ?? '???'}`}
        />
      )}

      <SODetailText label="Comments" value={miniModel.comments} />

      {miniModel.architecturalElements?.length && (
        <SODetailText
          label="Architectural Elements"
          value={
            <div className="flex flex-wrap gap-1">
              {R.sortBy(ae => ae, miniModel.architecturalElements).map(ae => (
                <Badge key={ae} color="ghost">
                  {ae}
                </Badge>
              ))}
            </div>
          }
        />
      )}

      <SODetailText label="Priority" value={miniModel.priority} />
    </div>
  );
}

function MiniModelPublishedButtons({ miniModel }: { miniModel: MiniModel }) {
  const [updateMiniModel, { loading }] = useMutation(UPDATE_MINI_MODEL);

  async function approve() {
    try {
      await updateMiniModel({
        variables: {
          id: miniModel.id,
          input: { lifecycleStatus: LifecycleStatus.Approved },
        },
      });
      toast.success('Mini-model approved.');
    } catch (err) {
      console.log('Error approving mini-model', err);
      toast.error('Something went wrong, please reload the page and try again');
    }
  }

  async function unapprove() {
    try {
      await updateMiniModel({
        variables: {
          id: miniModel.id,
          input: { lifecycleStatus: LifecycleStatus.Draft },
        },
      });
      toast.info('Mini-model unapproved.');
    } catch (err) {
      console.log('Error approving mini-model', err);
      toast.error('Something went wrong, please reload the page and try again');
    }
  }

  return (
    <>
      {miniModel.lifecycleStatus === LifecycleStatus.ReadyForApproval && (
        <Badge color="warning">
          <FontAwesomeIcon icon={faFlag} /> Ready for Approval
        </Badge>
      )}

      {miniModel.lifecycleStatus === LifecycleStatus.Approved ? (
        <Button
          type="button"
          color="success"
          size="xs"
          variant="soft"
          startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
          onClick={unapprove}
          loading={loading}
        >
          Approved
        </Button>
      ) : (
        <Button
          type="button"
          color="error"
          size="xs"
          variant="soft"
          startIcon={<FontAwesomeIcon icon={faBan} />}
          onClick={approve}
          loading={loading}
        >
          Not Approved
        </Button>
      )}
    </>
  );
}
