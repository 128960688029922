import { useMutation, useQuery } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type {
  GeologyType,
  KeyParametersCreateInput,
  KeyParametersPartsFragment,
} from '~/apollo/generated/v4/graphql';
import { Confirm } from '~/components/common/Confirm';
import { EnabledIndicator } from '~/components/common/icons/EnabledIndicator';
import { GeologyTypeIconV4 } from '~/components/common/icons/geology-type-icon-v4';
import { NoItemsAlert } from '~/components/common/NoItemsAlert';
import { SimplePanel } from '~/components/common/Panel';
import { SortTrigger } from '~/components/common/SortTrigger';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { Button } from '~/components/ui/button';
import { CollapsibleCard } from '~/components/ui/collapse';
import { CreateKeyParametersForm } from '~/components/upload/key-parameters-v4/create-key-parameters-form';
import { DEPOSITIONAL_HIERARCHY } from '~/components/upload/key-parameters-v4/operations';
import { useSortFilter } from '~/hooks/data';
import { cn } from '~/utils/common';
import type { RefetchQueries } from '~/utils/graphql';

export type KeyParametersParent = Pick<
  KeyParametersCreateInput,
  'outcropId' | 'studyId' | 'virtualOutcropModelId' | 'wikiId'
>;

const DELETE_KEY_PARAMETERS = graphql(`
  mutation DeleteKeyParameters($id: ID!) {
    keyParametersDelete(id: $id) {
      result {
        id
      }
    }
  }
`);

export function KeyParametersManager({
  parent,
  keyParameters,
  geologyTypes,
  refetchQueries,
}: {
  parent: KeyParametersParent;
  geologyTypes: GeologyType[];
  keyParameters: KeyParametersPartsFragment[];
  refetchQueries: RefetchQueries;
}) {
  const { data, loading } = useQuery(DEPOSITIONAL_HIERARCHY);

  const { items, sortIndicatorProps: siProps } = useSortFilter(
    keyParameters,
    'id',
    'id',
    'outcropUploadKeyParametersTable',
  );

  if (loading) return <SpinnerPlaceholder />;
  if (!data?.depositionalHierarchy.length) {
    return <div>Error loading depositional hierarchy.</div>;
  }

  return (
    <div className="space-y-4">
      <CollapsibleCard title="Create Key Parameters">
        <CreateKeyParametersForm
          parent={parent}
          keyParameters={keyParameters}
          geologyTypes={geologyTypes}
          refetchQueries={refetchQueries}
          hierarchy={data.depositionalHierarchy}
        />
      </CollapsibleCard>

      <NoItemsAlert show={!keyParameters.length} entity="key parameters" />

      {items.length > 0 && (
        <SimplePanel title="Key Parameters">
          <table className="table table-compact w-full">
            <thead>
              <tr>
                <th className="text-center">
                  <SortTrigger
                    colName="valid"
                    sortIndicatorProps={siProps}
                    filterable
                    renderFilterOption={opt =>
                      opt === 'true' ? 'Valid' : 'Invalid'
                    }
                  >
                    Valid
                  </SortTrigger>
                </th>

                <th className="text-center">
                  <SortTrigger
                    colName="geologyType"
                    sortIndicatorProps={siProps}
                  >
                    GT
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="grossDepositionalEnvironment"
                    sortIndicatorProps={siProps}
                  >
                    GDE
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="depositionalEnvironment"
                    sortIndicatorProps={siProps}
                  >
                    DE
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="depositionalSubEnvironment"
                    sortIndicatorProps={siProps}
                  >
                    DSE
                  </SortTrigger>
                </th>
                <th>
                  <SortTrigger
                    colName="architecturalElement"
                    sortIndicatorProps={siProps}
                  >
                    AE
                  </SortTrigger>
                </th>
                <th className="text-center">Dominant</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {items.map(kp => (
                <KeyParametersItem
                  key={kp.id}
                  kp={kp}
                  refetchQueries={refetchQueries}
                />
              ))}
            </tbody>
          </table>
        </SimplePanel>
      )}
    </div>
  );
}

function KeyParametersItem({
  kp,
  refetchQueries,
}: {
  kp: KeyParametersPartsFragment;
  refetchQueries: RefetchQueries;
}) {
  const [deleteKP, { loading: loadingDeleteKP }] = useMutation(
    DELETE_KEY_PARAMETERS,
    { refetchQueries },
  );

  async function handleDelete() {
    await deleteKP({ variables: { id: kp.id } });
    toast.info('Key parameters deleted.');
  }

  return (
    <tr className={kp.dominant ? 'bg-amber-100' : ''}>
      <td className="text-center">
        <EnabledIndicator value={kp.valid ?? false} />
      </td>
      <td className="text-center">
        <GeologyTypeIconV4 geologyType={kp.geologyType} className="w-4" />
      </td>
      <td>{kp.grossDepositionalEnvironment}</td>
      <td>{kp.depositionalEnvironment}</td>
      <td>{kp.depositionalSubEnvironment}</td>
      <td>{kp.architecturalElement}</td>
      <td className="text-center">
        <ToggleDominance id={parseInt(kp.id)} dominant={kp.dominant} />
      </td>
      <td className="text-right">
        <Confirm
          onConfirm={handleDelete}
          text="Are you sure you want to delete this set of key parameters?"
        >
          {onConfirm => (
            <Button
              type="button"
              onClick={onConfirm}
              disabled={loadingDeleteKP}
              color="ghost"
              size="xs"
              startIcon={<FontAwesomeIcon icon={faTrash} />}
            />
          )}
        </Confirm>
      </td>
    </tr>
  );
}

const UPDATE_KEY_PARAMETERS = graphql(`
  mutation UpdateKeyParameters($id: ID!, $input: KeyParametersUpdateInput!) {
    keyParametersUpdate(id: $id, input: $input) {
      result {
        ...keyParametersParts
      }
    }
  }
`);

function ToggleDominance({ id, dominant }: { id: number; dominant: boolean }) {
  const [updateKP] = useMutation(UPDATE_KEY_PARAMETERS);

  async function handleToggle() {
    await updateKP({ variables: { id, input: { dominant: !dominant } } });
    toast.success('Key parameters updated.');
  }

  return (
    <label className="label">
      <input
        type="checkbox"
        checked={dominant}
        onChange={handleToggle}
        className={cn(
          'toggle toggle-sm toggle-neutral checked:bg-amber-300 checked:border-amber-400 checked:text-amber-400',
        )}
      />
    </label>
  );
}
