import { useMutation, type PureQueryOptions } from '@apollo/client';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import type { FilePartsFragment } from '~/apollo/generated/v4/graphql';
import { Confirm } from '~/components/common/Confirm';
import { Button } from '~/components/ui/button';
import { SODetailText } from '~/components/upload/supporting-object/so-detail-text';
import { cn } from '~/utils/common';
import { fileSizeText } from '~/utils/text';

export function SOFileCard({
  file,
  refetchQueries,
  condensed,
}: {
  file: FilePartsFragment;
  refetchQueries: PureQueryOptions[];
  condensed?: boolean;
}) {
  return (
    <div className="border border-slate-200 p-2">
      <div className="w-full flex gap-6">
        <div className="grow">
          <div className="w-full flex justify-between items-start gap-2 text-left break-all">
            <div className={cn('font-bold', { 'text-sm': condensed })}>
              {file.name}
            </div>

            <div className="space-x-1 shrink-0">
              <DeleteFileButton
                id={parseInt(file.id)}
                refetchQueries={refetchQueries}
              >
                {(confirmDelete, loadingDelete) => (
                  <Button
                    type="button"
                    color="ghost"
                    size="xs"
                    onClick={confirmDelete}
                    disabled={loadingDelete}
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                  >
                    Delete
                  </Button>
                )}
              </DeleteFileButton>
            </div>
          </div>

          <div
            className={cn({
              'space-y-0': condensed,
              'space-y-0.5': !condensed,
            })}
          >
            <SODetailText label="Content type" value={file.contentType} />
            {file.size && (
              <SODetailText label="Size" value={fileSizeText(file.size)} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const DELETE_FILE = graphql(`
  mutation Deletefile($input: FileDeleteInput!) {
    fileDelete(input: $input)
  }
`);

function DeleteFileButton({
  id,
  refetchQueries,
  children,
}: {
  id: number;
  refetchQueries: PureQueryOptions[];
  children: (confirmDelete: () => void, loading: boolean) => ReactNode;
}) {
  const [deletePicture, { loading }] = useMutation(DELETE_FILE, {
    variables: { input: { id } },
    refetchQueries,
  });

  async function handleDelete() {
    try {
      await deletePicture();
      toast.success('File deleted.');
    } catch (err) {
      console.log('Error deleting file', err);
      toast.error('There was a problem deleting the picture.');
    }
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      text="Are you sure you want to delete this file?"
    >
      {onConfirm => children(onConfirm, loading)}
    </Confirm>
  );
}
