import { useMutation, type PureQueryOptions } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { graphql } from '~/apollo/generated/v4';
import { Button } from '~/components/ui/button';
import { HookFormErrors } from '~/components/ui/forms/HookFormErrors';
import { SOFormFields } from '~/components/upload/supporting-object/so-form-fields';
import type { SOParent } from '~/components/upload/supporting-object/so-manager';
import { useZodForm } from '~/utils/forms';
import { initialSedlog, sedlogSchema } from '~/utils/modules/supporting-object';

const CREATE_SEDLOG = graphql(`
  mutation CreateSedlog($input: SedimentaryLogCreateInput!) {
    sedimentaryLogCreate(input: $input) {
      result {
        id
      }
    }
  }
`);

type Props = SOParent & {
  refetchQueries?: PureQueryOptions[];
};

export function SedlogCreateForm({
  outcropId,
  studyId,
  refetchQueries,
}: Props) {
  const [createSedlog, { loading, error }] = useMutation(CREATE_SEDLOG, {
    refetchQueries,
  });

  const form = useZodForm({
    schema: sedlogSchema,
    values: initialSedlog(),
  });

  const handleSubmit = form.handleSubmit(async values => {
    try {
      await createSedlog({
        variables: {
          input: {
            ...values,
            outcropId,
            studyId,
          },
        },
      });
      toast.success('Sedimentary log created successfully.');
      form.reset();
    } catch (err) {
      console.log('Error creating sedimentary log', err);
      toast.error('There was a problem creating the sedimentary log.');
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <SOFormFields soType="SEDIMENTARY_LOG" studyId={studyId} />
        <HookFormErrors graphQLError={error} />

        <div className="card-actions justify-end">
          <Button type="submit" loading={loading} color="primary">
            Save
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
