import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import { forwardRef } from 'react';
import { graphql } from '~/apollo/generated/v4';
import type { ReactSelectInputProps } from '~/components/ui/forms/react-select-input';
import { ReactSelectInput } from '~/components/ui/forms/react-select-input';
import type { SelectOption } from '~/components/ui/forms/Select';

const REGION_SELECT_FIELD_OPTIONS = graphql(`
  query RegionSelectFieldOptions {
    regionList {
      results {
        id
        name
      }
    }
  }
`);

type Props = Omit<ReactSelectInputProps<string>, 'options'>;

export const RegionSelect = forwardRef<never, Props>((props, _ref) => {
  const { data, loading } = useQuery(REGION_SELECT_FIELD_OPTIONS);

  const regions = data?.regionList?.results ?? [];
  const options = R.sortBy(region => region.name, regions).map<SelectOption>(
    region => ({
      value: region.id,
      label: `${region.name} (${region.id})`,
    }),
  );

  return (
    <ReactSelectInput
      {...props}
      options={options}
      disabled={props.disabled || loading}
    />
  );
});
RegionSelect.displayName = 'RegionSelect';
