import { useQuery } from '@apollo/client';
import { ApolloProviderV4 } from '~/apollo/client-v4';
import { graphql } from '~/apollo/generated/v4';
import { NotFound } from '~/components/common/NotFound';
import { SpinnerPlaceholder } from '~/components/common/SpinnerPlaceholder';
import { KeyParametersManager } from '~/components/upload/key-parameters-v4/key-parameters-manager';
import { useRouteParam } from '~/hooks/routing';
import { createRefetchQueries } from '~/utils/graphql';

export const UPLOAD_OUTCROP_KEY_PARAMETERS_PAGE = graphql(`
  query UploadOutcropKeyParametersPage($id: ID!) {
    outcropGet(id: $id) {
      id
      geologyType
      keyParameters {
        ...keyParametersParts
      }
    }
  }
`);

export default function UpdateOutcropKeyParametersPage() {
  return (
    <ApolloProviderV4>
      <PageInner />
    </ApolloProviderV4>
  );
}

function PageInner() {
  const outcropId = useRouteParam('outcropId', parseInt);

  const { data, loading } = useQuery(UPLOAD_OUTCROP_KEY_PARAMETERS_PAGE, {
    variables: { id: outcropId },
  });

  const refetchQueries = createRefetchQueries([
    {
      query: UPLOAD_OUTCROP_KEY_PARAMETERS_PAGE,
      variables: { id: outcropId },
    },
  ]);

  const outcrop = data?.outcropGet;

  if (loading) return <SpinnerPlaceholder />;
  if (!outcrop) return <NotFound />;

  return (
    <KeyParametersManager
      parent={{ outcropId }}
      keyParameters={outcrop.keyParameters}
      geologyTypes={outcrop.geologyType ?? []}
      refetchQueries={refetchQueries}
    />
  );
}
