import {
  faCheckCircle,
  faEarthAmericas,
  faFlag,
  faHourglass,
} from '@fortawesome/free-solid-svg-icons';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LifecycleStatus } from '~/apollo/generated/v4/graphql';
import { cn } from '~/utils/common';

export function LifecycleStatusIcon({
  status,
  className,
}: { status: LifecycleStatus } & Omit<FontAwesomeIconProps, 'icon'>) {
  switch (status) {
    case LifecycleStatus.Draft:
      return (
        <FontAwesomeIcon
          icon={faHourglass}
          className={cn('text-slate-400', className)}
        />
      );
    case LifecycleStatus.ReadyForApproval:
      return (
        <FontAwesomeIcon
          icon={faFlag}
          className={cn('text-warning', className)}
        />
      );
    case LifecycleStatus.Approved:
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          className={cn('text-success', className)}
        />
      );
    case LifecycleStatus.Published:
      return (
        <FontAwesomeIcon
          icon={faEarthAmericas}
          className={cn('text-info', className)}
        />
      );
  }
}
